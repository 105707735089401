import React, { Fragment } from 'react'

export default function ProducerPreviewStatus(props) {
  const { generating, message } = props
  return (
    <Fragment>
      {generating ? (
        <div className="producer-preview-status">
          <div className="spinner mb-2"></div>
          {message}
        </div>
      ) : null}
    </Fragment>
  )
}


