import { useState, useEffect } from "react"

// Media query breakpoints object
const mqObj = {
  xxl: 1400,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
}

const mqKeyRenamer = (key) => {
  return key.replace(/(Up|Down)$/, "")
}

// Returns an array of media query keys
const mqKeys = Object.keys(mqObj).reduce((acc, key) => {
  acc.push(key, `${key}Up`, `${key}Down`)
  return acc
}, [])

// Returns true if the window is smaller than the given media query key
const mqWindowSize = (key) => {
  const baseKey = mqKeyRenamer(key)
  return window.innerWidth < mqObj[baseKey]
}

// Returns the pixel value of the given media query key
const mqCheck = (key) => {
  const baseKey = mqKeyRenamer(key)
  return mqObj[baseKey]
}

// Returns a media query object for the given key
const mqMatch = (key) => {
  const baseKey = mqKeyRenamer(key)
  let comparison = ""
  if (key.endsWith("Up")) {
    comparison = ">"
  } else if (key.endsWith("Down")) {
    comparison = "<"
  } else {
    comparison = ">"
  }
  return window.matchMedia(`(width ${comparison} ${mqObj[baseKey]}px)`)
}

// Returns a boolean value for the given media query key
const useMediaQuery = (key) => {
  const [matches, setMatches] = useState(mqWindowSize(key))

  useEffect(() => {
    const media = mqMatch(key)

    const listener = () => setMatches(media.matches)
    window.addEventListener("resize", listener)

    // Initial check
    setMatches(media.matches)

    return () => window.removeEventListener("resize", listener)
  }, [key])

  return matches
}

export { mqWindowSize, mqCheck, mqMatch, useMediaQuery, mqKeys }
