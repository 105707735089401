import React from 'react'
import CustomSelectField from './CustomSelectField'

export default function SelectField(props) {
  const mappedOptions = props.options.map(option => {
    const [text, value] = option
    return {
      value: value,
      optionElement: text,
    }
  })

  return (
    <CustomSelectField {...props} options={mappedOptions} />
  )
}
