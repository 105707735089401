import React, { useContext, useEffect, useState } from 'react'
import { allTemplatesForTier, recentTemplates } from '../../lib/templates'
import Fuse from 'fuse.js'
import TemplatesFilter from './TemplatesFilter'
import TemplatePreviews from './TemplatePreviews'
import { useParams } from 'react-router'
import { store } from '../../stores/templates'
import TemplatesNav from './TemplatesNav'

export default function TemplatesRecent() {
  const {
    templateCategories,
    templates,
    plans,
    hasSubscriptionAccess,
    canManageTemplates,
    basename,
    subscriptionTier,
    isAdmin,
    subscribed,
    subscriptionLink,
  } = useContext(store)

  const [query, setQuery] = useState('')
  const isFiltering = query.trim() != ''

  const { plan } = useParams()

  const allRecent = recentTemplates(templates)
  const tieredRecent = allTemplatesForTier(allRecent, plans[plan])

  const fuse = new Fuse(tieredRecent, {
    keys: ['attributes.title', 'attributes.tags'],
    threshold: 0.3,
  })

  const displayTemplates = isFiltering ? fuse.search(query.trim()).map(f => f.item) : tieredRecent

  useEffect(() => {
    document.title = `Recently Released Templates | Template Library | Box Out Sports`
  }, [])

  return (
    <>
      <TemplatesNav active="recent" />
      <TemplatesFilter
        subscriptionTier={subscriptionTier}
        subscribed={subscribed}
        subscriptionLink={subscriptionLink}
        isAdmin={isAdmin}
        plans={plans}
        templates={templates}
        templateCategories={templateCategories.data}
        onChange={setQuery} />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            Recently Released Templates
          </li>
          {isFiltering ? (
            <li className="active breadcrumb-item text-transform-none" aria-current="page">
              &ldquo;{query}&rdquo;
            </li>
          ) : null}
        </ol>
      </nav>
      <TemplatePreviews
        subscriptionTier={subscriptionTier}
        hasSubscriptionAccess={hasSubscriptionAccess}
        canManageTemplates={canManageTemplates}
        templates={displayTemplates}
        templateCategories={templateCategories.data}
        basename={basename} />
    </>
  )
}
