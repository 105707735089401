import axios from 'redaxios'

const valueToString = (value) => {
  if (value === null) {
    return 'null'
  } else if (value === undefined) {
    return 'undefined'
  } else {
    return value.toString()
  }
}

const customAxios = axios.create({
  headers: {
    "X-CSRF-Token": document
      .querySelector('[name="csrf-token"]')
      .getAttribute("content"),
  },
  paramsSerializer: (data) => {
    const params = new URLSearchParams();

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        params.delete(key)
        value.forEach((value) => params.append(`${key}[]`, valueToString(value)));
      } else {
        params.append(key, valueToString(value));
      }
    });

    return params.toString();
  },
});


export default customAxios
