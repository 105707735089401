import React, { useContext } from 'react'
import { generatePath, useParams, Link } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'
import { store } from '../../stores/templates'

export default function TemplatesNav(props) {
  const { eventsEnabled, defaultPlan, hasSubscriptionAccess, myTemplatesEnabled } = useContext(store)

  const { routedPlan } = useParams()
  const { active } = props

  const plan = routedPlan || defaultPlan

  return (
    <div className="bo-content-nav">
      <Nav className="nav-toptab">
        <NavItem>
          <Link to={`/${plan}`} className={`nav-link ${active == 'library' ? " active" : ""}`}>
            Template Library
          </Link>
        </NavItem>
        {hasSubscriptionAccess && (
          <NavItem>
            <Link to={'/active'} className={`nav-link ${active == "my-templates" ? " active" : ""}`}>
              My Templates
            </Link>
          </NavItem>
        )}
        <NavItem>
          <Link to={generatePath('/:plan/recent', { plan: plan })} className={`nav-link ${active == 'recent' ? " active" : ""}`}>
            Recent Templates
          </Link>
        </NavItem>
        {eventsEnabled && (
          <NavItem>
            <Link to={generatePath('/:plan/events', { plan: plan })} className={`nav-link ${active == 'events' ? " active" : ""}`}>
              Event Templates
            </Link>
          </NavItem>
        )}
      </Nav>
    </div>
  )
}
