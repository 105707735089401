import React from 'react'
import { StateProvider } from '../stores/team_graphics'
import TeamGraphicsFilter from './TeamGraphics/TeamGraphicsFilter'
import TeamGraphicsTemplates from './TeamGraphics/TeamGraphicsTemplates'
import TeamGraphicsList from './TeamGraphics/TeamGraphicsList'

export default function TeamGraphics(props) {
  const page = new URLSearchParams(window.location.search).get('page') || 1

  return (
    <StateProvider {...props} page={page}>
      <TeamGraphicsFilter />
      <TeamGraphicsTemplates />
      <TeamGraphicsList />
    </StateProvider>
  )
}
