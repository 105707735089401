import React from 'react'
import { Row, Col } from 'reactstrap'
import CustomSelectField from './CustomSelectField'

export default function SelectWithPhotoField(props) {
  const photoPreview = (photo) => {
    if (photo) {
      return (
        <div>
          <img src={photo} className="select-field-preview-image" />
        </div>
      )
    } else {
      return null
    }
  }

  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const options = props.options || props.field.options

  const mappedOptions = options.map(option => {
    const { value, text, photo, headline } = option

    const preview = headline ? headline : (
      <div className={`align-items-center pe-0 d-flex gap-2 ms-n2 flex-shrink-1 ${photo ? 'ps-0' : 'ps-2'}`}>
        {photoPreview(photo)}
        <div className="text-truncate">
          {text}
        </div>
      </div>
    )

    const mappedOption = {
      headline: !!headline,
      value: value || 'NULL',
      optionElement: preview,
    }

    if (props.field.search) {
      mappedOption.text = text
    }

    return mappedOption
  })

  return (
    <CustomSelectField key={`custom-${props.field.key}`} {...props} options={mappedOptions} onChange={onChange} disabled={props.disabled || false} />
  )
}
