import React from 'react'
import ReactPaginate from 'react-paginate'

export default function Pagination(props) {
  const { pageCount, currentPage, onPageChange } = props

  const handlePageClick = (data) => {
    if (onPageChange) {
      onPageChange(data.selected + 1)
    }
  }

  return pageCount <= 1 ? null : (
    <ReactPaginate
      previousLabel="Previous"
      nextLabel="Next"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      pageCount={pageCount}
      pageRangeDisplayed={4}
      marginPagesDisplayed={2}
      onPageChange={handlePageClick}
      containerClassName="pagination"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      activeClassName="active"
      forcePage={currentPage - 1}
    />
  )
}
