import React, { Fragment, useState, useEffect } from "react"
import {
  Input,
  Label,
  Button,
  UncontrolledDropdown,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap"
import StickyBar from "../shared/StickyBar"
import Filter from "../shared/Filter"
import {
  ChevronDown,
  ChevronUp,
  Plus,
  Filter as FilterIcon,
} from "react-feather"

export default function ToolBar(props) {
  const {
    isAllSelected,
    selectAllRef,
    handleSelectAll,
    teams,
    canAdmin,
    toggleDataSource,
    isPast,
    basepath,
    handleFilterChange,
    filterText,
    selectedEvents,
    enableFilter,
    pagesWithSelectedItems,
    openGraphicModal,
    selectedEventObjects,
    openDeleteModal,
    isMinSm,
    isMaxSm,
    isMinMd,
    isMaxMd,
    isMinLg,
    isMaxLg,
  } = props

  const [mobileOptionsToggle, setMobileOptionsToggle] = useState(false)
  const [mobileControlsToggle, setMobileControlsToggle] = useState(false)

  const handleMobileOptionsToggle = () => {
    if (isMaxLg) {
      setMobileControlsToggle(false)
      setMobileOptionsToggle(!mobileOptionsToggle)
    } else {
      setMobileOptionsToggle(false)
    }
  }

  const handleMobileControlsToggle = () => {
    if (isMaxLg) {
      setMobileOptionsToggle(false)
      setMobileControlsToggle(!mobileControlsToggle)
    } else {
      setMobileControlsToggle(false)
    }
  }

  const handleSelectAllCheckbox = () => {
    handleSelectAll()
    setMobileControlsToggle(false)
  }

  useEffect(() => {
    if (isMaxLg) {
      setMobileOptionsToggle(false)
      setMobileControlsToggle(false)
    }
  }, [isMaxLg])

  const TeamDropdown = () => {
    if (!canAdmin) {
      return null
    }

    return (
      <UncontrolledDropdown className={isMaxLg ? "w-100" : null}>
        <DropdownToggle
          color="outline-secondary"
          className="w-100 text-start d-flex justify-content-between align-items-center"
        >
          {props.eventOwnerName} Events{" "}
          <ChevronDown size={16} className="dropdown-indicator" />
        </DropdownToggle>
        <DropdownMenu>
          {props.orgPath != basepath && (
            <DropdownItem href={`${props.orgPath}/events`}>
              All Organization Events
            </DropdownItem>
          )}
          {teams.map((team) => (
            <Fragment key={team.id}>
              {basepath != team.basepath && (
                <DropdownItem href={`${team.basepath}/events`}>
                  {team.name} Events
                </DropdownItem>
              )}
            </Fragment>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  const RangeDropdown = (props) => {
    return (
      <UncontrolledDropdown className={isMaxLg ? "w-100" : null}>
        <DropdownToggle
          color="outline-secondary"
          className="w-100 text-start d-flex justify-content-between align-items-center"
        >
          {isPast ? "Past Events" : "Upcoming"}{" "}
          <ChevronDown size={16} className="dropdown-indicator" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={toggleDataSource}>
            {isPast ? "Show Upcoming Events" : "Show Past Events"}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  const EventOptions = (props) => {
    return (
      <>
        <RangeDropdown {...props} />
        <TeamDropdown {...props} />
      </>
    )
  }

  const EventControllers = () => {
    return (
      <>
        <Button
          color="outline-primary"
          className="flex-grow-1 flex-shrink-1 w-100"
          onClick={() => {
            openGraphicModal(
              selectedEvents,
              selectedEventObjects[0].org_slug,
              selectedEventObjects[0].team_slug,
              selectedEventObjects[0].team_name,
              "multi"
            )
          }}
        >
          Create Multi-Event Graphic
        </Button>
        <Button
          color="outline-danger"
          className="flex-grow-1 flex-shrink-1 w-100"
          onClick={() => openDeleteModal(selectedEvents)}
        >
          Delete {selectedEvents.length} Events
        </Button>
      </>
    )
  }

  return (
    <StickyBar contentClasses="p-0 minh-unset" customGrid={true}>
      <div className="d-flex gap-1 gap-md-2 gap-lg-3 px-2 py-1 px-md-3 py-md-2">
        <div className="flex-shrink-1 d-flex align-items-center">
          <Label htmlFor="bulk_check" className="visually-hidden">
            Select All Events
          </Label>
          <Input
            className="m-0 me-2 me-md-0"
            type="checkbox"
            id="bulk_check"
            disabled={!enableFilter}
            checked={isAllSelected && selectedEvents.length > 0}
            innerRef={selectAllRef} // Attach the ref to the checkbox
            onChange={() => handleSelectAllCheckbox()}
          />
        </div>
        <div
          className="flex-grow-1 d-flex align-items-center"
          style={{ height: "calc(1.4em + 0.75rem + 2px)" }}
        >
          {isMinLg && (
            <>
              <div className={selectedEvents.length > 0 ? "" : "d-none"}>
                <div className="d-flex align-items-center gap-1">
                  <span className="text-muted">
                    {selectedEvents.length} selected
                  </span>
                  {pagesWithSelectedItems > 1 && (
                    <span className="text-muted">
                      on {pagesWithSelectedItems} pages
                    </span>
                  )}
                  {isMinLg && (
                    <>
                      <div id="create-graphic-multi">
                        <Button
                          color="link"
                          className="ms-3"
                          onClick={() => {
                            openGraphicModal(
                              selectedEvents,
                              selectedEventObjects[0].org_slug,
                              selectedEventObjects[0].team_slug,
                              selectedEventObjects[0].team_name,
                              "multi"
                            )
                          }}
                        >
                          Create Graphic
                        </Button>
                      </div>
                      <Button
                        color="link"
                        className="ms-3"
                        onClick={() => openDeleteModal(selectedEvents)}
                      >
                        <span className="text-danger">Delete Events</span>
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {selectedEvents.length === 0 && (
            <>
              {enableFilter ? (
                <Filter
                  title="Filter events…"
                  onChange={handleFilterChange}
                  query={filterText}
                />
              ) : (
                <div className="my-2 text-muted fst-italic">
                  No Events Found
                </div>
              )}
            </>
          )}

          {selectedEvents.length > 0 && (
            <>
              {isMaxLg && (
                <div className="d-flex align-items-center w-100">
                  <Button
                    color="outline-secondary"
                    className="w-100 w-sm-unset d-flex justify-content-between text-start align-items-center"
                    onClick={() => handleMobileControlsToggle()}
                  >
                    <span class="d-inline-block text-truncate">
                      {selectedEvents.length} Selected Events
                      {pagesWithSelectedItems > 1 && (
                        <>on {pagesWithSelectedItems} pages</>
                      )}
                    </span>
                    {mobileControlsToggle ? (
                      <ChevronUp size={16} />
                    ) : (
                      <ChevronDown size={16} />
                    )}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        {isMaxLg && (
          <div className="d-flex align-items-stretch">
            <Button
              color="outline-secondary"
              className="d-flex align-items-center gap-1"
              style={isMaxSm ? { paddingInline: "0.5rem" } : {}}
              onClick={() => handleMobileOptionsToggle()}
            >
              <span className={isMaxSm ? "visually-hidden" : ""}>Filter</span>
              {isMaxSm && <FilterIcon size={16} />}
              {isMinSm && !mobileOptionsToggle && <ChevronDown size={16} />}
              {isMinSm && mobileOptionsToggle && <ChevronUp size={16} />}
            </Button>
          </div>
        )}
        {isMinLg && <EventOptions />}
        <div className="d-flex align-items-stretch">
          <Button
            color="primary"
            href={`${props.basepath}/events/new`}
            className="d-flex align-items-center gap-1"
            style={isMaxSm ? { paddingInline: "0.5rem" } : {}}
          >
            {isMaxMd && <Plus size={20} />}
            <span className={isMaxMd ? "visually-hidden" : ""}>
              Create Event
            </span>
          </Button>
        </div>
      </div>

      {/* Mobile Only Dropdowns */}
      {isMaxLg && mobileOptionsToggle && (
        <div className="d-flex flex-column flex-sm-row p-2 gap-2">
          <EventOptions />
        </div>
      )}
      {isMaxLg && mobileControlsToggle && selectedEvents.length > 0 && (
        <div className="flex-grow-1 w-100 p-2 flex-column flex-sm-row d-flex gap-2">
          <EventControllers />
        </div>
      )}
    </StickyBar>
  )
}
