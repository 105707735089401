import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

export default function ProducerPreviewVideo(props) {
  const { generating, url, example } = props.format
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    // Keeping track of if the preview has been loaded
    // because Chrome fires the canplay event on every loop
    // of the video
    setLoaded(false)
  }, [url])

  const urlParams = new URLSearchParams(window.location.search);
  const hasDuplicate = urlParams.has('duplicate');

  const previewClasses = classNames({
    'producer-preview-image': true,
    'producer-preview-video': true,
    'producer-preview-image-generating': generating,
    'grayscale': example && !hasDuplicate,
  })

  const previewStyle = {
    maxHeight: props.maxHeight,
  }

  const handleCanPlay = () => {
    if (!loaded) {
      props.handlePreviewLoad()
      setLoaded(true)
    }
  }

  return (
    <div className="producer-preview-item" style={previewStyle}>
      {url ? (
        <video autoPlay loop muted playsInline
          controls={false}
          key={url}
          style={previewStyle}
          className={previewClasses}
          onCanPlay={handleCanPlay}>
          <source src={url} type="video/mp4" />
        </video>
      ) : null}
    </div>
  )
}

