import React, { forwardRef } from 'react'

const XLogo = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <polygon className="st0" points="21.3,21.1 9.9,2.9 2.7,2.9 14.1,21.1 "/>
      <line className="st0" x1="2.7" y1="21.1" x2="9.9" y2="14.5"/>
      <line className="st0" x1="14.1" y1="9.5" x2="21.3" y2="2.9"/>
    </svg>
  )
})

XLogo.displayName = 'XLogo'

export default XLogo
