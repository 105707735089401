import axios from '../../components/shared/axios'
import { actions } from '../uploads'
import { serialize } from 'object-to-formdata';

const objectId = (file) => {
  return `${file.lastModified}::${file.name}`
}

const sum = (arr) => (
  arr.reduce((a, b) => (a + b), 0)
)

const startUpload = (action, state) => {
  const { file, duplicateId } = action
  const oid = objectId(file)

  return {
    ...state,
    uploadingFiles: {
      ...state.uploadingFiles,
      [oid]: {
        file,
        category: state.category,
        folder: state.folder,
        progress: 0,
        duplicateId: duplicateId,
        status: 'Waiting',
      }
    }
  }
}

const updateUpload = (action, state) => {
  const { file, data } = action
  const oid = objectId(file)

  return {
    ...state,
    uploadingFiles: {
      ...state.uploadingFiles,
      [oid]: {
        ...state.uploadingFiles[oid],
        ...data,
      }
    }
  }
}

const processUpload = async (state, action, dispatch) => {
  const { file, info } = action
  const oid = objectId(file)
  const { category, folder, width, height, duration, duplicateId } = state.uploadingFiles[oid]
  const url = new URL(info.signedUrl)

  const formData = serialize({
    upload: {
      upload_path: url.pathname.replace(/^\/+/g, ''),
      category: category,
      upload_folder_id: folder,
      filename: file.name,
      filesize: file.size,
      width: width,
      height: height,
      duration: duration,
    },
    duplicate_id: duplicateId,
  })

  const response = await axios.post(`${state.basepath}/uploads.json`, formData)
  const data = response.data.data

  dispatch({
    type: actions.FINISH_UPLOAD,
    file,
    data,
    url,
  })
}

const finishUpload = (action, state) => {
  const { data, url, file } = action
  const oid = objectId(file)
  const allFinished =  Object.values(state.uploadingFiles).every(upload => (
    upload.status == 'Upload completed'
  ))

  return {
    ...state,
    uploads: [...state.uploads, data],
    uploadingFiles: allFinished ? {} : {
      ...state.uploadingFiles,
      [oid]: {
        ...state.uploadingFiles[oid],
        url,
      }
    }
  }
}

const uploadProgress = (state) => {
  const uploads = Object.values(state.uploadingFiles)
  const completed = uploads.filter(upload => upload.progress == 100).length
  const total = uploads.length
  const totalBytes = sum(uploads.map(u => u.file.size))
  const uploadedBytes = sum(uploads.map(u => u.progress * u.file.size / 100))
  const percentage = Math.round(totalBytes == 0 ? 0 : 100 * uploadedBytes / totalBytes)

  return {
    total,
    completed,
    percentage,
    uploadedBytes,
    totalBytes,
  }
}

export { startUpload, updateUpload, processUpload, finishUpload, uploadProgress }
