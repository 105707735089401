import React from 'react'
import { Mail } from 'react-feather'
import { Col, Row } from 'reactstrap'
import TimeAgo from 'react-timeago'
import { shareEmailActionText } from '../../../stores/share/email'

export default function ShareHistoryEmail(props) {
  const { share, hidePreviews } = props
  
  return (
    <Row className="mb-3">
      <Col xs="auto" className="pe-0">
        <Mail className="icon icon-md mt-n1" />
      </Col>
      <Col xs="auto" className="share-history-description">
        <strong>{share.user.name}</strong> emailed to {shareEmailActionText(share)}
        <small className="d-block text-muted"><TimeAgo date={share.performed_at} /></small>
        {hidePreviews ? null : (
          <div>
            {share.metadata.formats.map(format => {
              const url = share.graphic.attributes.preview_urls[format.id]
              return (
                <img key={url} src={url} alt={share.graphic.attributes.title} className="preview-sm me-2 mt-2 rounded" />
              )
            })}
          </div>
        )}
      </Col>
    </Row>
  )
}
