import React from 'react'
import { FacebookEmbed, InstagramEmbed, XEmbed } from 'react-social-media-embed';

const embedComponents = {
  XEmbed,
  InstagramEmbed,
  FacebookEmbed
}

function detectSocialService(url) {
  if (!url) return null

  try {
    const urlObj = new URL(url)
    const hostname = urlObj.hostname.toLowerCase()

    if (hostname.includes('twitter.com') || hostname.includes('x.com')) {
      return 'XEmbed'
    }
    
    if (hostname.includes('instagram.com')) {
      return 'InstagramEmbed'
    }
    
    if (hostname.includes('facebook.com')) {
      return 'FacebookEmbed'
    }

    return null
  } catch (e) {
    console.error('Invalid URL:', e)
    return null
  }
}

export default function SocialEmbed({url}) {
  const EmbedComponent = embedComponents[detectSocialService(url)]
  
  if (!EmbedComponent) { 
    return null
  }

  console.log("URL", url)

  return <EmbedComponent 
    url={url} 
    width="100%" 
  />
}
