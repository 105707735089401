import React, { useContext } from 'react'
import { ArrowUp, X } from 'react-feather'
import { store } from '../../stores/uploads'

export default function DropzoneNotifier(props) {
  const { currentFolderIsShared, canManageShared } = useContext(store)

  if (!props.active) return null
  return (
    <div className="dragging-notifier">
      <div className="dragging-notifier-content">
        <div className="dragging-notifier-icon">
        {currentFolderIsShared && !canManageShared ? <X color="white" size="64" /> : <ArrowUp color="white" size="64" />}
        </div>
        {currentFolderIsShared && !canManageShared ? 'Cannot upload to shared folders' : 'Drop files to upload'}
      </div>
    </div>
  )
}
