import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames';
import { X, Pause, Play } from 'react-feather'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'
import MultiformatIcon from "../shared/MultiformatIcon"
import { set } from 'lodash';


export default function PreviewModal(props) {
  
  const { isOpen, close, graphic } = props
  
  const videoRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  
  const attr = graphic.attributes || graphic
  const formats = attr.formats
  const fileExt = attr.formats[0].extension

  const [selectedGraphic, setSelectedGraphic] = useState(formats[0].id)


  const handleClose = (e) => {
    e.preventDefault()
    setPlaying(false)
    close()
  }

  const handlePlay = (e) => {
    e.preventDefault()
    setPlaying(!playing)
  }
  useEffect(() => {
    if (!videoRef.current) return

    if (playing) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [playing])

  const closeButton = (
    <Button color="transparent" onClick={handleClose} className="p-0 pe-auto">
      <X size={40} />
    </Button>
  )

  // Motion Graphic Modal Content
  // This checks that it is a video and expects one item in the array, then displays the video
  // A map loop is used though there should only be a single item in the array, this is done for two reasons:
  //   - Code consistency
  //   - Allows us to verify the id of the graphic matches with the selectedGraphic state
  const videoDisplay = (formats.length === 1 && fileExt === 'mp4') && formats.map((format, index) => {
    const graphicID = format.id
    const wrapperStyles = {
      "--width": format.width,
      "--height": format.height,
    }

    return (
      <div key={`${attr.id}-${index}`} className={`preview-modal__graphic-wrapper ${selectedGraphic === graphicID && 'preview-modal__graphic-wrapper--is-selected'}`} style={wrapperStyles}>
        <button onClick={handlePlay} className="preview-modal__overlay-action" key={`actionwrap-${attr.id}-${index}`}>
          <div className={`preview-modal__video-control preview-modal__video-control--start ${playing ? 'preview-modal__video-control--is-playing' : ''}`}>
            <Play className="preview-modal__control-icon non-scaling-stroke" />
          </div>
          <div className={`preview-modal__video-control preview-modal__video-control--pause ${playing ? 'preview-modal__video-control--is-playing' : ''}`}>
            <Pause className="preview-modal__control-icon non-scaling-stroke" />
          </div>
          <span className="visually-hidden">{ playing ? "Pause" : "Play"} Video</span>
        </button>
        <video loop muted
              playsInline={true}
              webkit-playsinline={true}
              tabIndex={-1}
              ref={videoRef}
              className="preview-modal__graphic modal-content-filter-shadow"
              controls={false}
              src={attr.full_urls[graphicID]}
              preload="auto" />
      </div>
    )
  })


  // Single-format Graphic Modal Content
  // This displays the graphic if there is only one format available and the file is not an mp4.
  // A map loop is used though there should only be a single item in the array, this is done for two reasons:
  //   - Code consistency
  //   - Allows us to verify the id of the graphic matches with the selectedGraphic state
  const graphicDisplay = (formats.length === 1 && fileExt != 'mp4') && formats.map((format, index) => {
    const graphicID = format.id
    const wrapperStyles = {
      "--width": format.width,
      "--height": format.height,
    }

    return (
      <div key={`${attr.id}-${index}`} className={`preview-modal__graphic-wrapper ${selectedGraphic === graphicID && 'preview-modal__graphic-wrapper--is-selected'}`} style={wrapperStyles}>
        <img src={attr.full_urls[graphicID]} alt="Graphic" className="preview-modal__graphic modal-content-filter-shadow" />
      </div>
    )
  })

  // Multi-format Graphic Modal Content
  // This loops through all the items in the formats arrays and creates an img tag for each.
  // Only the default, first item is shown, with the others set to opacity 0 and pointer-events none.
  const multiformatDisplay = (formats.length > 1 && fileExt != 'mp4') && formats.map((format, index) => {
    const graphicID = format.id
    const wrapperStyles = {
      "--width": format.width,
      "--height": format.height,
    }

    return (
      <div key={`${attr.id}-${format.id}-${index}`} className={`preview-modal__graphic-wrapper ${selectedGraphic === graphicID && 'preview-modal__graphic-wrapper--is-selected'}`} style={wrapperStyles}>
        <img src={attr.full_urls[graphicID]} alt="Graphic" className="preview-modal__graphic modal-content-filter-shadow" />
      </div>
    )
  })


  const multiformatControls = formats.map((format, index) => {
    const isSelectedClass = selectedGraphic === format.id ? "panel-button--selected" : null

    return (
      <button key={`control-${attr.id}-${index}`} className={`panel-button ${isSelectedClass} pe-auto text-white`} onClick={() => setSelectedGraphic(format.id)} aria-selected={selectedGraphic === format.id}>
        <span className="panel-button__title">Panel {index + 1}</span>
        <MultiformatIcon formats={[format]} size="60" className="panel-button__icon" />
        <span className="panel-button__meta">{format.width} &times; {format.height}</span>
      </button>
    )
  })

  const multiformatFooter = (
    <ModalFooter className="d-flex gap-1 gap-md-3 justify-content-center bg-transparent border-0 position-relative z-2 p-0">
      <div className="panel-button-group">{multiformatControls}</div>
    </ModalFooter>
  )

  return (
    <Modal isOpen={isOpen}
           toggle={handleClose}
           fullscreen={true}
           size="xl"
           onOpened={() => setPlaying(true)}
           fade={true}
           centered={true}
           className="p-0"
           contentClassName="d-flex bg-transparent modal-content-no-shadow pe-none h-100 mh-unset"
           modalClassName="modal-dark"
           backdropClassName="modal-backdrop-dark">
      <ModalHeader tag="div">
        {closeButton}
      </ModalHeader>
      <ModalBody className="p-0 m-0 lh-0 w-100 position-relative d-flex flex-grow-1 justify-content-center align-items-center overflow-visible">
        {videoDisplay}
        {graphicDisplay}
        {multiformatDisplay}
      </ModalBody>
      {formats.length > 1 && multiformatFooter }
    </Modal>
  )
}
