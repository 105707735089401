import React from 'react'
import { Row } from 'reactstrap'

export default function StickyBar(props) {
  const classes = `sticky-bar-content align-items-center mx-0 ${props.contentClasses || ''}`
  return (
    <div className={`sticky-bar ${props.className || ''}`}>
      { !props.customGrid ? (
        <Row className={classes}>
          { props.children }
        </Row>
      ) : (
        <div className={`${classes}`}>
          { props.children }
        </div>
      ) }
    </div>
  )
}
