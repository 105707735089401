import React from 'react'
import { generatePath, Link, useParams } from 'react-router-dom'

export default function TemplatesBreadcrumb(props) {
  const { templateCategories, query } = props
  const { plan, category, tag } = useParams()

  let categoryName
  if (tag) {
    categoryName = `Tagged: ${tag.replace('-', ' ')}`
  } else if (category != null) {
    categoryName = templateCategories.find(c => c.attributes.slug == category).attributes.name
  }
  const isFiltering = query.trim() != ''

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {plan ? (
          <li className="breadcrumb-item">
            {category || tag ? (
              <Link to={generatePath('/:plan', { plan: plan })} className="team-color btn btn-link text-capitalize">
                {plan} Templates
              </Link>
            ) : `${plan} Templates`}
          </li>
        ) : null}
        {category || tag ? (
          <li className="active breadcrumb-item text-capitalize" aria-current="page">
            {categoryName}
          </li>
        ) : null}
        {isFiltering ? (
          <li className="active breadcrumb-item text-transform-none" aria-current="page">
            &ldquo;{query}&rdquo;
          </li>
        ) : null}
      </ol>
    </nav>
  )
}
