import React, { useState } from 'react'
import { Button } from 'reactstrap'
import ShareEmail from './ShareEmail'
import ShareFacebook from './ShareFacebook'
import ShareInstagram from './ShareInstagram'
import ShareTwitter from './ShareTwitter'
import EditMultiShareForm from './EditMultiShareForm'

export default function ShareEditScheduled(props) {
  const { share } = props
  const [open, setOpen] = useState(false)

  let EditComponent = null

  if (share.action == 'email') {
    EditComponent = ShareEmail
  // TODO: remove this after Aug 2025, which is when the last old single-post share is scheduled in production
  } else if (share.action == 'post') {
    if (share.social_account.attributes.provider == 'facebook') {
      EditComponent = ShareFacebook
    } else if (share.social_account.attributes.provider == 'twitter') {
      EditComponent = ShareTwitter
    } else if (share.social_account.attributes.provider == 'instagram') {
      EditComponent = ShareInstagram
    }
  } else if (share.action == 'multipost') {
    EditComponent = EditMultiShareForm
  }

  return (
    <>
      <Button color="outline-secondary" size="sm" className={`me-2 ${props.className}`} onClick={() => setOpen(true)}>Edit</Button>
      {EditComponent ? <EditComponent share={share} isOpen={open} close={() => setOpen(false)} /> : null}
    </>
  )
}
