import React, { useRef, useState, Fragment, useEffect } from "react"
import { Button } from "reactstrap"
import FormFields from "./FormFields"
import ProducerActions from "./Producer/ProducerActions"
import ProducerPreview from "./Producer/ProducerPreview"
import ProducerPreviewFormats from "./Producer/ProducerPreviewFormats"
import DesignSystemSource from "./Producer/DesignSystemSource"
import ProducerStatus from "./Producer/ProducerStatus"
import classNames from "classnames"
import axios from "./shared/axios"
import Pusher from "pusher-js"
import ProducerXMLSelector from "./Producer/ProducerXMLSelector"
import useDimensions from "react-use-dimensions"
import ProducerHometownSelector from "./Producer/ProducerHometownSelector"
import { isEqual } from "lodash"
import ProducerEventSelector from "./Producer/ProducerEventSelector"
import ProducerTrialPrompt from "./Producer/ProducerTrialPrompt"

export default function Producer(props) {
  const formRef = useRef(null)

  const [fields, setFields] = useState(props.fields)
  const [pusherChannel, setPusherChannel] = useState(null)
  const [devChannel, setDevChannel] = useState(null)
  const [previewsRef, { height: previewsHeight }] = useDimensions()

  const [formState, setFormState] = useState({
    saving: false,
    uuid: null,
    formDataCheck: null,
    message: null,
    saveAfterGenerating: false,
    updatingFields: false,
    version: 1,
  })

  const [selectedFormat, setSelectedFormat] = useState(null)

  const [admin, setAdmin] = useState(props.admin || {})
  const adminRef = useRef(admin)

  const urls = props.urls || {}

  const [formats, setFormats] = useState(
    props.formats.map((format) => ({
      ...format,
      uuid: null,
      oldUrl: null,
      url: urls[format.id],
      generating: false,
      anyLoaded: false,
      loadedCurrentPreview: false,
      example: props.examplePreviews || false,
    }))
  )

  const formatsRef = useRef(formats)

  useEffect(() => {
    formatsRef.current = formats

    if (formats.every((format) => !format.generating)) {
      if (formState.saveAfterGenerating) {
        saveGraphic(
          formState.shareAfterGenerating,
          formState.saveAndUpdateAfterGenerating,
          formState.saveAndSubscribeAfterGenerating
        )
      }
    }
  }, [formats])

  useEffect(() => {
    adminRef.current = admin
  }, [admin])

  const [previewState, setPreviewState] = useState({
    mobilePreviewing: false,
    overTime: false,
    error: null,
    hideUpdatePreview: false,
    message: null,
  })

  const [selectedEnv, setSelectedEnv] = useState("prod")
  const handleEnvChange = (env) => {
    setSelectedEnv(env)
  }

  const calculateFormDataCheck = () => {
    const formData = new FormData(formRef.current)
    const formArray = [...formData.entries()]
    return formArray.filter(([key]) => key != "slim[]")
  }

  useEffect(() => {
    const socket = new Pusher(props.pusherAppKey, {
      cluster: props.pusherCluster,
    })
    setPusherChannel(socket.subscribe(props.teamChannel))

    if (admin && admin.dropboxTemplateId) {
      setDevChannel(socket.subscribe('devChannel'))
    }
  }, [])

  useEffect(() => {
    if (pusherChannel && formats.some((f) => !f.url)) {
      requestPreview()
    }
  }, [pusherChannel])

  useEffect(() => {
    if (devChannel && adminRef.current) {
      devChannel.bind('dropboxTemplateUpdate', dropboxTemplateId => {
        if (adminRef.current.devMode === true && dropboxTemplateId == adminRef.current.dropboxTemplateId) {
          requestPreview()
        }
      })
    }
  }, [devChannel])

  useEffect(() => {
    if (!props.newRecord) {
      setPreviewState((prev) => ({
        ...prev,
        formDataCheck: calculateFormDataCheck(),
      }))
    }
  }, [formRef])

  const handlePusherResponse = (data, formatId) => {
    const format = getFormatById(formatId)
    clearOverTimeClock(format.id)

    if (!format.generating) {
      return
    }

    setOverTimeClock(format.id, 20000)

    if (data.url) {
      updateFormat(format.id, {
        url: data.url,
        oldUrl: format.url,
        message: "Downloading…",
        loadedCurrentPreview: false,
      })

      setPreviewState((prev) => ({
        ...prev,
        overTime: false,
      }))
    } else {
      updateFormat(format.id, {
        message: data.status,
      })
    }
  }

  const clearOverTimeClock = (id) => {
    const format = getFormatById(id)

    if (format.overTimeTimer) {
      clearTimeout(format.overTimeTimer)
    }

    updateFormat(id, {
      overTimeTimer: null,
      overTime: false,
    })
  }

  const setOverTimeClock = (id, time) => {
    clearOverTimeClock(id)

    const timeout = setTimeout(() => {
      updateFormat(id, {
        overTime: true,
      })
    }, time)

    updateFormat(id, {
      overTimeTimer: timeout,
    })
  }

  const updateAllFormats = (newData) => {
    setFormats((prev) => {
      return prev.map((format) => ({
        ...format,
        ...newData,
      }))
    })
  }

  const updateFormat = (id, newData) => {
    setFormats((prev) => {
      return prev.map((format) => {
        if (format.id == id) {
          return {
            ...format,
            ...newData,
          }
        } else {
          return format
        }
      })
    })
  }

  const getFormatById = (id) => {
    return formatsRef.current.find((format) => format.id == id)
  }

  const requestPreview = async (event) => {
    if (event) event.preventDefault()
    if (formatsRef.current.some((format) => format.generating)) return

    const formData = new FormData(formRef.current)

    updateAllFormats({
      generating: true,
      message: "Updating…",
    })

    setFormState((prev) => ({
      ...prev,
      uuids: {},
    }))

    setPreviewState((prev) => ({
      ...prev,
      mobilePreviewing: true,
      overTime: false,
      formDataCheck: calculateFormDataCheck(),
    }))

    const response = await axios.put(props.requestPreviewUrl, formData)
    for (const [id, newData] of Object.entries(response.data.formats)) {
      const { uuid, error, message, backtrace } = newData

      setOverTimeClock(id, 20000) // 20 seconds for initial timeout

      updateFormat(id, {
        ...newData,
        message: "Generating…",
      })

      if (error) {
        clearOverTimeClock(id)
        updateFormat(id, {
          generating: false,
          url: null,
          error: {
            message,
            backtrace,
          },
        })
      } else {
        pusherChannel.bind(`preview:${uuid}`, (data) =>
          handlePusherResponse(data, id)
        )
        updateFormat(id, {
          error: null,
        })
      }
    }
  }

  const cancelPreview = () => {
    updateAllFormats({
      generating: false,
      message: null,
    })
    formats.forEach((format) => clearOverTimeClock(format.id))
    setPreviewState((prev) => ({
      ...prev,
      mobilePreviewing: false,
    }))
    setFormState((prev) => ({
      ...prev,
      formDataCheck: null,
    }))
  }

  const saveGraphic = async (
    share = false,
    saveAndUpdate = false,
    saveAndSubscribe = false
  ) => {
    const formDataCheck = calculateFormDataCheck()

    if (
      !formState.saveAfterGenerating &&
      !isEqual(formDataCheck, previewState.formDataCheck)
    ) {
      setFormState((prev) => ({
        ...prev,
        saveAfterGenerating: true,
        shareAfterGenerating: share,
        saveAndUpdateAfterGenerating: saveAndUpdate,
        saveAndSubscribeAfterGenerating: saveAndSubscribe,
      }))

      requestPreview()
    } else {
      const formData = new FormData(formRef.current)
      if (formState.uuids) {
        for (const [id, uuid] of Object.entries(formState.uuids)) {
          if (uuid) {
            formData.set(`rendered_uuids[${id}]`, uuid)
          }
        }
      }

      if (saveAndUpdate) {
        formData.set("save_and_update", 1)
      }

      if (share) {
        formData.set("share-graphic", 1)
      }

      if (saveAndSubscribe) {
        formData.set("save_and_sub", 1)
      }

      setFormState((prev) => ({
        ...prev,
        saving: true,
        message: "Saving…",
      }))

      const response = await axios({
        method: props.newRecord ? "post" : "put",
        url: props.saveUrl,
        data: formData,
      })

      const { redirect_now, redirect_to, uuid } = response.data

      if (redirect_now) {
        window.location = redirect_now
      } else {
        const handleSaveResponse = (data) => {
          if (data.generated) {
            pusherChannel.unbind(`saved:${uuid}`, handleSaveResponse)
            window.location = redirect_to
          } else {
            setFormState((prev) => ({
              ...prev,
              message: data.status + "…",
            }))
          }
        }

        pusherChannel.bind(`saved:${uuid}`, handleSaveResponse)
      }
    }
  }

  const updateFields = async (event) => {
    if (event) event.preventDefault()

    setFormState((prev) => ({
      ...prev,
      updatingFields: true,
    }))
    const formData = new FormData(formRef.current)
    const response = await axios.post(props.admin.updateFieldsPath, formData)
    setFields(response.data.fields)

    setFormState((prev) => ({
      ...prev,
      updatingFields: false,
      version: prev.version + 1,
    }))
  }

  const updateHometownFields = async () => {
    setFormState((prev) => ({
      ...prev,
      updatingFields: true,
    }))
    const formData = new FormData(formRef.current)
    const response = await axios.post(props.hometown.updateFieldsPath, formData)
    setFields(response.data.fields)

    setFormState((prev) => ({
      ...prev,
      updatingFields: false,
      version: prev.version + 1,
    }))
  }

  const updateEventFields = async () => {
    setFormState((prev) => ({
      ...prev,
      updatingFields: true,
    }))
    const formData = new FormData(formRef.current)
    const response = await axios.post(props.events.updateFieldsPath, formData)
    setFields(response.data.fields)

    setFormState((prev) => ({
      ...prev,
      updatingFields: false,
      version: prev.version + 1,
    }))
  }

  const sentProps = {
    selfSignUpTrial: props.selfSignUpTrial,
    formState,
    setFormState,
    previewState,
    setPreviewState,
    saveGraphic,
    requestPreview,
    cancelPreview,
    admin,
    setAdmin,
    updateFields,
    formats,
    updateAllFormats,
    updateFormat,
    clearOverTimeClock,
    selectedFormat,
    setSelectedFormat,
    setFields,
  }

  const formClasses = classNames({
    "producer-form": true,
    "mobile-previewing": previewState.mobilePreviewing,
  })

  const contentClasses = classNames({
    "producer-content": true,
    "mobile-previewing": previewState.mobilePreviewing,
    "is-placing": previewState.isPlacing,
  })

  const customCSSProps = {
    "--grid-columns": `${formats
      .map((format) => `${format.ratio}fr`)
      .toString()
      .replaceAll(",", " ")}`,
  }

  return (
    <Fragment>
      <div className={formClasses}>
        {props.trialTime && (
          <div className="producer-form-fields-header">
            <ProducerTrialPrompt {...props} {...sentProps} />
          </div>
        )}

        <form
          onSubmit={requestPreview}
          ref={formRef}
          className="producer-form-fields"
        >
          <Button className="form-control-hidden">Generate Preview</Button>
          <div className="producer-form-container">
            <div className="producer-form-mobile-header">Enter Your Data</div>
            {admin && admin.devMode ? (
              <>
                <Button
                  outline
                  block
                  color="secondary"
                  className="mb-3"
                  onClick={updateFields}
                  disabled={formState.updatingFields}
                >
                  Update Fields from YAML
                </Button>
                <DesignSystemSource onEnvChange={handleEnvChange} />
              </>
            ) : null}
            <input type="hidden" name="template_id" value={props.templateId} />
            {props.showXMLStream ? <ProducerXMLSelector {...props} /> : null}
            <ProducerHometownSelector
              {...props}
              formState={formState}
              updateHometownFields={updateHometownFields}
            />
            <ProducerEventSelector
              {...props}
              formState={formState}
              updateEventFields={updateEventFields}
            />
            <FormFields {...props} {...sentProps} fields={fields} />
          </div>
          <Button
            color="primary"
            size="lg"
            className="producer-mobile-action-button"
          >
            Update Preview
          </Button>
        </form>
      </div>
      <div className={contentClasses}>
        <ProducerActions {...props} {...sentProps} />
        <div
          className="producer-previews"
          ref={previewsRef}
          style={customCSSProps}
        >
          {formats.map((format) => (
            <ProducerPreview
              key={`format:${format.id}`}
              format={format}
              selectedFormat={selectedFormat}
              {...props}
              {...sentProps}
              style={{ flexBasis: `${previewState.width}px` }}
              previewsHeight={previewsHeight}
            />
          ))}
        </div>
        <ProducerPreviewFormats {...props} {...sentProps} />
        <ProducerStatus {...props} {...sentProps} />
        <Button
          color="primary"
          size="lg"
          onClick={cancelPreview}
          className="producer-mobile-action-button"
        >
          Continue Editing
        </Button>
      </div>
    </Fragment>
  )
}
