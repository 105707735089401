import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'

export default function TemplateFeatureModal(props) {
  const { players, opponents, uploads } = props.addedFeatures

  const addedFeaturesSentence = () => {
    let added = []
    if (players) added.push('players')
    if (opponents) added.push('opponents')
    if (uploads) added.push('uploads')

    if (added.length <= 2) {
      return added.join(' and ')
    } else {
      return added.slice(0, added.length - 1).join(', ') + ', and ' + added.slice(-1)
    }
  }

  const firstTemplateBody = (
    <ModalBody>
      <div className="display-1 my-3 text-center">
        🎉👍💥
      </div>
      <h1 className="text-center">
        Congrats on adding your first template!
      </h1>
      <p className="sublead px-3 text-center">
        Adding this template also unlocked management for {addedFeaturesSentence()},
        so once you&rsquo;re done adding templates, be sure to update that info
        to make creating your graphics even&nbsp;faster.
      </p>
    </ModalBody>
  )

  const addedFeaturesBody = (
    <ModalBody>
      <div className="display-1 my-3 text-center">
        {players ? '🏃' : null}
        {opponents ? '🎽' : null}
        {uploads ? '📸' : null}
      </div>
      <h1 className="text-center">
        This template added some features!
      </h1>
      <p className="sublead px-3 text-center">
        You can now manage {addedFeaturesSentence()}. Once you&rsquo;re done
        adding templates, be sure to update
        with your team&rsquo;s&nbsp;data.
      </p>
    </ModalBody>
  )

  return (
    <Modal isOpen={props.open} toggle={props.toggle} size="md">
      {props.firstTemplate ? firstTemplateBody : addedFeaturesBody}
      <ModalFooter>
        <Button size="lg" color="outline-secondary" onClick={props.toggle} block>
          Awesome, let&rsquo;s keep going!
        </Button>
      </ModalFooter>
    </Modal>
  )
}
