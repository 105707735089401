import axios from '../../components/shared/axios'
import { actions } from '../templates'

const addTemplateToTeams = async (state, action, dispatch) => {
  const { slug, owner_slug } = action.template.attributes

  const response = await axios.put(`${state.basename}/add/${owner_slug}/${slug}.json`, {
    teams: action.teams,
    addToNew: action.addToNew,
  }).then((response) => response).catch((error) => {
    dispatch({ type: actions.OPEN_ERROR_MODAL, header: "Error Adding Template", message: "An error occurred when adding this template, and the task cannot be completed. Please try again later. If the problem persists, please contact support." })
  })

  const newTemplate = response.data.template.data
  const { firstTemplate, addedFeatures } = response.data

  updateTeamNav(response.data.teamNavHTML)

  dispatch({ type: actions.REPLACE_TEMPLATE, template: newTemplate })

  if (firstTemplate || Object.values(addedFeatures).some((v) => v)) {
    dispatch({ type: actions.OPEN_FEATURES_MODAL, template: action.template, addedFeatures, firstTemplate })
  }
}

const removeTemplateFromTeams = async (state, action, dispatch) => {
  const { slug, owner_slug } = action.template.attributes
  const response = await axios.delete(`${state.basename}/remove/${owner_slug}/${slug}.json`, {
    data: {
      teams: action.teams,
    }
  }).then((response) => response).catch((error) => {
    dispatch({ type: actions.OPEN_ERROR_MODAL, header: "Error Removing Template", message: "An error occurred when removing this template, and the task cannot be completed. Please try again later. If the problem persists, please contact support." })
  })
  const newTemplate = response.data.template.data

  updateTeamNav(response.data.teamNavHTML)

  dispatch({ type: actions.REPLACE_TEMPLATE, template: newTemplate })
}

const removeTemplatesFromTeams = async (state, action, dispatch) => {
  const response = await axios.delete(`${state.basename}/bulk_remove.json`, {
    data: {
      bulk: {
        team_ids: action.teams,
        template_ids: action.templates
      }
    }
  }).then((response) => response).catch((error) => {
    dispatch({ type: actions.OPEN_ERROR_MODAL, header: "Error Removing Templates", message: "An error occurred when removing these templates. Please try again later. If the problem persists, please contact support." })
  })

  const { teamNavHTML, templates } = response.data
  updateTeamNav(teamNavHTML)

  templates.forEach(template => {
    dispatch({ type: actions.REPLACE_TEMPLATE, template: template.data })
  })
}

const updateTeamNav = (html) => {
  const nav = document.querySelector('.bo-nav-main')
  if (nav) nav.innerHTML = html
}

export {
  addTemplateToTeams,
  removeTemplateFromTeams,
  removeTemplatesFromTeams
}
