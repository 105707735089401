import React from 'react';
import { Untabbable } from 'react-untabbable'
export default function ProcessingOverlay (props) {

  return (
    <div className={`processing-overlay-container ${props.active ? 'processing-overlay-container--active' :  null}`} style={{'--delay':`${props.delay || 0 }ms`}}>
      <div className="processing-overlay">
        <div className="spinner"></div>
        {props.message || 'Processing…'}
      </div>
      <Untabbable disabled={!props.active}>
        <div className="processing-overlay-children">
          {props.children}
        </div>
      </Untabbable>
    </div>
  )
}