import axios from '../../components/shared/axios'
import { actions } from '../uploads'
import { folderNeedsLoading, foldersInFolderForCategory, foldersToLoad } from './folders'
import Fuse from 'fuse.js'

const fuseOptions = {
  keys: ['attributes.filename'],
  threshold: 0.3,
}

const loadFilesIfNeeded = async (state, action, dispatch) => {
  if (folderNeedsLoading(state, action)) {
    dispatch({ type: actions.SET_LOADING })
    const { folders, uploads, rootLoaded, opponentLogos } = await loadFilesForFolderAndSubfolders(state, action)
    dispatch({ type: actions.FILES_LOADED, folders, uploads, rootLoaded, opponentLogos })
  }
}

const replaceObjects = (original, updated) => {
  return original.map(item => updated.find(newItem => newItem.id == item.id) || item)
}

const newObjects = (original, updated) => {
  return updated.filter(obj => !original.find(oldObj => oldObj.id == obj.id))
}

const replaceOrAddObjects = (original, updated) => {
  const replacedObjects = replaceObjects(original, updated)
  return [...replacedObjects, ...newObjects(original, updated)]
}

const loadFilesForFolderAndSubfolders = async (state, action) => {
  const { category } = action
  const subfolders = foldersInFolderForCategory(action.id, state.folders, category).map(f => f.id)
  const folderIds = [...subfolders, action.id]
  const folderIdsToLoad = foldersToLoad(folderIds, state, action)

  if (folderIdsToLoad.length == 0) return state
  let { data } = await axios.get(`${state.basepath}/uploads.json?menu=${state.actsAsMenu ? 1 : 0}`, { params: { folder_ids: folderIds, category: category } })

  let newUploads = data.uploads.data

  while (data.nextPageUrl) {
    let response = await axios.get(data.nextPageUrl)
    data = response.data
    newUploads.push(...data.uploads.data)
  }

  const newFolderData = state.folders.map(f => {
    if (folderIds.includes(f.id)) {
      return {
        ...f,
        loaded: true,
      }
    } else {
      return f
    }
  })

  if (folderIdsToLoad.includes(null)) {
    state.rootLoaded = [...state.rootLoaded, action.category]
  }

  const newUploadsData = replaceOrAddObjects(state.uploads, newUploads)
  const newOpponentLogosData = data.opponentLogos ? data.opponentLogos.data : null

  return {
    folders: newFolderData,
    uploads: newUploadsData,
    rootLoaded: state.rootLoaded,
    opponentLogos: newOpponentLogosData,
  }
}

const previewFilesForFolder = (folder, files) => {
  return sortFiles(files.filter(f => f.attributes.upload_folder_id == folder.id)).slice(0, 9)
}

const displayFiles = ({ uploads, category, folder, filter }) => {
  const files = sortFiles(uploads.filter(f => f.attributes.upload_folder_id == folder && f.attributes.category == category))

  if (filter) {
    const fuse = new Fuse(files, fuseOptions)
    return fuse.search(filter).map(f => f.item)
  } else {
    return files
  }
}

const renameFile = async (state, action, dispatch) => {
  const response = await axios.put(`${action.shared ? state.orgBasename : state.basename}/${action.upload_id}.json`, action.formData)
    .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
    });
  const { data } = response;
  const upload = data.data
  dispatch({ type: actions.FILE_RENAMED, upload: upload })
}

const updateFile = (uploads, action) => {
  const { id, complete } = action
  const upload = action.upload.data
  upload.attributes.progress = complete

  return uploads.map(u => u.id == id ? upload : u)
}

const sortFiles = (files) => {
  return files.sort((a, b) => {
    const a_shared = a.attributes.shared ? 1 : 0
    const b_shared = b.attributes.shared ? 1 : 0
    return b_shared - a_shared || (
      (a.attributes.created_at < b.attributes.created_at) ? 1 : -1
    )
  })
}

const editVideo = async (state, action, dispatch) => {
  const { data } = await axios.put(`${state.basepath}/uploads/${action.id}/edit_video.json`, {
    image_state: action.imageState,
  })

  const upload = data.data
  dispatch({ type: actions.ADD_UPLOAD, upload: upload })
}

export { loadFilesForFolderAndSubfolders, loadFilesIfNeeded, previewFilesForFolder, displayFiles, renameFile, updateFile, editVideo }
