import React from 'react'

export default function HeaderField(props) {
  const { title, subtext } = props
  return (
    <div className="producer-form-header">
      {title}
      {subtext ? (
        <small className="text-muted d-block">{subtext}</small>
      ) : null}
    </div>
  )
}
