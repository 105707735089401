import React, { useState, useEffect } from 'react'
import { Input, Button } from 'reactstrap'
import classNames from 'classnames'
import UploadCropper from './UploadCropper'
import LoadingIndicator from '../shared/LoadingIndicator'
import axios from '../../components/shared/axios'

export default function UploadField(props) {
  const [upload, setUpload] = useState(props.field.upload)
  const [changed, setChanged] = useState(false)
  const attributes = upload ? upload.attributes : {}
  const { id, filename, thumb_url } = attributes
  const { category, field_name, key, can_crop, can_multi_crop, crops, subtext, usesClippings } = props.field
  const { open, openKey } = props.uploadMenuState
  const [clipping, setClipping] = useState(null)

  if (window.ClippingMagic) {
    window.ClippingMagic.initialize({ apiId: 14010 })
  }

  async function loadClipping() {
    if (!upload) return

    const { data } = await axios.get(`${props.basepath}/uploads/${upload.id}/clipping.json`)
    setClipping(data)

    if (data.incomplete) setTimeout(loadClipping, 1000)
  }

  useEffect(() => {
    setClipping(null)
    if (usesClippings) loadClipping()
  }, [upload])

  const onSelectUpload = (upload) => {
    setUpload(upload)
    setChanged(true)

    props.setUploadMenuState({
      open: false
    })
  }

  const handleEditClipping = (e) => {
    if (e) {
      e.preventDefault()
    }

    if (window.ClippingMagic) {
      window.ClippingMagic.edit({
        image: {
          id: Number(clipping.external_id),
          secret: clipping.external_secret
        },
        useStickySettings: true,
        hideBottomToolbar: true,
        locale: "en-US"
      }, opts => {
        switch (opts.event) {
          case "error":
            console.error(opts.error);
            break;
          case "result-generated":
            axios.post(`${props.basepath}/uploads/${upload.id}/reclip.json`).then(loadClipping)
            break;
        }
      });
    }
  }

  const openMenu = (e) => {
    e.preventDefault()

    props.setUploadMenuState({
      category: props.field.category,
      onSelectUpload,
      openKey: key,
      open: !open,
      selectedUpload: upload,
      sponsorLogo: props.sponsorLogo,
    })
  }

  const selectClasses = classNames({
    'form-control': true,
    'form-select': true,
    'form-select-upload': true,
    // 'text-truncate': true,
    'd-flex': true,
    'position-relative': true,
    'focus': open && openKey == key,
  })

  const manualUploadElements = (
    <>
      {props.field && props.field.value ? (
        <input type="hidden" name={`template_fields[${props.field.key}]`} value={props.field.value} />
      ) : null}
      {props.field.upload ? (
        <div className="mt-2">
          <img src={props.field.upload.attributes.thumb_url}
            alt={props.field.upload.attributes.filename}
            className="team-photo"
            height={50} />
        </div>
      ) : null}
      <Input type="file"
        id={`upload_for_${props.field.key}`}
        name={`upload_for_${props.field.key}`} />
    </>
  )

  const uploadPreview = (
    <>
      {can_crop ? null : (
        <div className="select-field-preview preview-checkered preview-checkered-sm">
          <img src={thumb_url} className="select-field-preview-image" />
        </div>
      )}
      <span className="fw-normal text-truncate">{filename}</span>
    </>
  )

  const emptySelection = (
    <span className="fw-normal text-truncate">Select a {category.replace(/^\w/, c => c.toUpperCase())}</span>
  )

  const uploadSelectorElements = (
    <button className={selectClasses} onClick={openMenu}>
      <input type="hidden" name={field_name} value={id || ''} />
      {upload ? uploadPreview : emptySelection}
    </button>
  )

  const cropFieldData = (data) => {
    return {
      cropperInputNames: data.cropperInputNames,
      result_width: data.result_width,
      result_height: data.result_height,
      crop_width: changed ? null : data.crop_width,
      crop_height: changed ? null : data.crop_height,
      crop_x: changed ? null : data.crop_x,
      crop_y: changed ? null : data.crop_y,
      image_width: changed ? null : data.image_width,
      image_height: changed ? null : data.image_height,
    }
  }

  const singleData = cropFieldData(props.field)

  const singleCropElements = can_crop ? (
    upload ? <UploadCropper key={`crop:${upload.id}`} {...singleData} upload={upload} clipping={clipping} onClipClick={handleEditClipping} /> : null
  ) : null

  const multiCropElements = can_multi_crop ? Object.entries(crops).map(([id, crop]) => {
    const data = cropFieldData(crop)
    return upload ? <UploadCropper key={`cropper:${upload.id}:${id}`} id={id} title={crop.title} {...data} upload={upload} clipping={clipping} onClipClick={handleEditClipping} /> : null
  }) : null

  const editClippingButton = clipping && !can_crop && !can_multi_crop && upload ? (
    <Button color="outline-secondary" className="w-100" onClick={handleEditClipping}>Edit Clipping</Button>
  ) : null

  const isLoading = usesClippings && upload && (!clipping || clipping.incomplete)

  return (
    <>
      {props.manualUploads ? manualUploadElements : uploadSelectorElements}
      {subtext ? (
        <small className="text-muted d-block mt-1">{subtext}</small>
      ) : null}
      <LoadingIndicator size="sm" loading={isLoading} title="Clipping the image…">
        {singleCropElements}
        {multiCropElements}
        {editClippingButton}
      </LoadingIndicator>
    </>
  )
}
