import React from 'react'

export default function LoadingIndicator(props) {
  if (props.loading) {
    return props.size == 'sm' ? (
      <div className={`text-muted mt-3 text-center ${props.className || ''}`}>
        <div className="spinner spinner-sm"></div>
        <span className="spinner-text">{props.title || 'Loading…'}</span>
      </div>
    ) : (
      <div className={`spinner-fill spinner-fill-${props.color || 'transparent'} ${props.className || ''}`}>
        <div className="spinner-center">
          <div className="spinner"></div>
          <div className="mt-3 text-muted">{props.title || 'Loading…'}</div>
        </div>
      </ div>
    )
  } else {
    return props.children || null
  }
}
