import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col } from 'reactstrap'
import { store } from '../../stores/uploads'

export default function UploadsSelectAll(props) {
  const { canManageShared, currentSelections, dispatch, actions } = useContext(store)
  const { currentPageItems } = props
  const [selectableItems, setSelectableItems] = useState([])
  const selectAllRef = useRef(null)

  useEffect(() => {
    setSelectableItems(currentPageItems.filter(item => (
      canManageShared || !item.attributes.shared
    )))
  }, [currentPageItems])

  useEffect(() => {
    const domElement = selectAllRef.current
    const itemKeys = selectableItems.map(item => `${item.type}:${item.id}`)

    const selectedPageItems = itemKeys.filter(key => (
      currentSelections.indexOf(key) != -1
    ))

    if (selectedPageItems.length == 0) {
      domElement.checked = false
      domElement.indeterminate = false
    } else if (selectedPageItems.length == itemKeys.length) {
      domElement.checked = true
      domElement.indeterminate = false
    } else {
      domElement.checked = true
      domElement.indeterminate = true
    }
  })

  const onSelectAllChange = (e) => {
    const checked = e.target.checked
    dispatch({ type: actions.SELECT_ITEMS, items: selectableItems, state: checked })
  }

  return (
    <Col xs="auto" className="select-all pe-0">
      <input
        type="checkbox"
        className="sticky-bar-checkbox"
        ref={selectAllRef}
        disabled={selectableItems.length == 0}
        onChange={onSelectAllChange} />
    </Col>
  )
}
