import React from 'react'
import { StateProvider } from '../stores/team_graphics'
import CampaignComponentsList from './Campaigns/CampaignComponentsList'

export default function CampaignComponents(props) {
  return (
    <StateProvider {...props}>
      <CampaignComponentsList />
    </StateProvider>
  )
}
