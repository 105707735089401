import S3Upload from 'react-s3-uploader/s3upload'

const beginUpload = ({ files, basepath, actions, dispatch, duplicateId }) => {
  const _URL = window.URL || window.webkitURL

  const handleProgress = (progress, status, file) => {
    dispatch({
      type: actions.UPDATE_UPLOAD,
      file,
      data: {
        progress,
        status,
      }
    })
  }

  const handleError = (err, file) => {
    //console.log(err, file)
  }

  const handleFinish = (info, file) => {
    dispatch({
      type: actions.PROCESS_UPLOAD,
      file,
      info,
    })
  }

  const uploadOptions = {
    files: files,
    signingUrl: `${basepath}/uploads/sign.json`,
    contentDisposition: 'auto',
    uploadRequestHeaders: {},
    onFinishS3Put: handleFinish,
    onProgress: handleProgress,
    onError: handleError,
  }

  files.forEach(file => {
    dispatch({ type: actions.START_UPLOAD, file, duplicateId })

    const objectUrl = _URL.createObjectURL(file)

    if (file.type.startsWith("image/")) {
      const img = new Image()
      
      img.onload = () => {
        dispatch({ 
          type: actions.UPDATE_UPLOAD, 
          file, 
          data: {
            width: img.width,
            height: img.height,
          },
        })
  
        _URL.revokeObjectURL(objectUrl);
      }
      img.src = objectUrl
    } else if (file.type == 'video/mp4') {
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.onloadedmetadata = () => {
        dispatch({ 
          type: actions.UPDATE_UPLOAD, 
          file, 
          data: {
            duration: video.duration,
          },
        })
      }
      video.src = objectUrl
    }
  })

  new S3Upload(uploadOptions)
}

export { beginUpload }


