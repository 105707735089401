import React, { createContext, useRef, useCallback } from 'react'
import { useReducer } from 'react'
import { addTemplateToTeams, removeTemplateFromTeams, removeTemplatesFromTeams } from './templates/add_remove'
import { fetchTemplates } from './templates/fetch'

const store = createContext({})

const { Provider } = store

const actions = {
  SET_LOADING_TEMPLATE: 'setLoadingTemplate',
  FETCH_TEMPLATES: 'fetchTemplates',
  SET_TEMPLATES: 'setTemplates',
  ADD_TEMPLATE: 'addTemplate',
  REMOVE_TEMPLATE: 'removeTemplate',
  REPLACE_TEMPLATE: 'replaceTemplate',
  BULK_REMOVE_TEMPLATES: 'bulkRemoveTemplates',
  OPEN_FEATURES_MODAL: 'openFeaturesModal',
  CLOSE_FEATURES_MODAL: 'closeFeaturesModal',
  OPEN_ADD_MULTIPLE_MODAL: 'openAddMultipleModal',
  CLOSE_ADD_MULTIPLE_MODAL: 'closeAddMultipleModal',
  OPEN_REMOVE_MULTIPLE_MODAL: 'openRemoveMultipleModal',
  CLOSE_REMOVE_MULTIPLE_MODAL: 'closeRemoveMultipleModal',
  SET_ERROR_HEADER: 'setErrorHeader',
  SET_ERROR_MESSAGE: 'setErrorMessage',
  OPEN_ERROR_MODAL: 'openErrorModal',
  CLOSE_ERROR_MODAL: 'closeErrorModal',
}

const useEnhancedReducer = (reducer, initState, initializer) => {
  const lastState = useRef(initState)
  const getState = useCallback(() => lastState.current, [])
  return [
    ...useReducer(
      (state, action) => lastState.current = reducer(state, action),
      initState,
      initializer
    ),
    getState,
  ]
}

const reduce = (state, action) => {
  switch (action.type) {
    case actions.SET_LOADING_TEMPLATE:
      return { ...state, loading: action.templateId }
    case actions.SET_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
        templateCategories: action.templateCategories
      }
    case actions.REPLACE_TEMPLATE:
      return {
        ...state,
        loading: null,
        addMultipleModal: null,
        removeMultipleModal: null,
        templates: state.templates.map(t => t.id == action.template.id ? action.template : t),
      }
    case actions.OPEN_FEATURES_MODAL:
      return {
        ...state,
        featureModalOpen: action.template.id,
        firstTemplate: action.firstTemplate,
        addedFeatures: action.addedFeatures,
      }
    case actions.OPEN_ADD_MULTIPLE_MODAL:
      return {
        ...state,
        addMultipleModal: action.template.id,
      }
    case actions.OPEN_REMOVE_MULTIPLE_MODAL:
      return {
        ...state,
        openModal: action.type,
        modalData: {
          templates: action.templates,
          teams: action.teams
        },
        removeMultipleModal: action.template.id,
      }
    case actions.CLOSE_FEATURES_MODAL:
      return {
        ...state,
        featureModalOpen: false,
      }
    case actions.CLOSE_ADD_MULTIPLE_MODAL:
      return {
        ...state,
        addMultipleModal: false,
      }
    case actions.CLOSE_REMOVE_MULTIPLE_MODAL:
      return {
        ...state,
        openModal: null,
        modalData: {},
        removeMultipleModal: false,
      }
    case actions.OPEN_ERROR_MODAL:
      return {
        ...state,
        errorModalOpen: true,
        errorMessageHeader: action.header || "Error",
        errorMessage: action.message,
      }
    case actions.CLOSE_ERROR_MODAL:
      return {
        ...state,
        errorModalOpen: false,
        errorMessageHeader: null,
        errorMessage: null,
      }
    default:
      return state
  }
}

const StateProvider = (props) => {
  const initialState = {
    ...props,
    templates: props.template ? [props.template.data] : null,
    templateCategories: props.templateCategories ? props.templateCategories.data : [],
    loading: !props.template,
    featureModalOpen: null,
    addedFeatures: {},
    firstTemplate: false,
    errorModalOpen: false,
    openModal: null,
    modalData: {}
  }

  const [state, dispatch, getState] = useEnhancedReducer(reduce, initialState)

  const asyncDispatch = (action) => {
    const state = getState()
    switch (action.type) {
      case actions.ADD_TEMPLATE:
        dispatch({ type: actions.SET_LOADING_TEMPLATE, templateId: action.template.id })
        return addTemplateToTeams(state, action, dispatch)
      case actions.REMOVE_TEMPLATE:
        dispatch({ type: actions.SET_LOADING_TEMPLATE, templateId: action.template.id })
        return removeTemplateFromTeams(state, action, dispatch)
      case actions.BULK_REMOVE_TEMPLATES:
        return removeTemplatesFromTeams(state, action, dispatch)
      case actions.FETCH_TEMPLATES:
        return fetchTemplates(state, dispatch)
      default:
        return dispatch(action)
    }
  }

  const usableData = {
    ...state,
    currentTemplates: [],
    loading: !state.templates,
    actions,
  }

  const value = {
    ...usableData,
    dispatch: asyncDispatch.bind(usableData)
  }

  return <Provider value={value}>{props.children}</Provider>
}

export { store, StateProvider, actions }
