import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { X } from 'react-feather';

export default function ProducerTrialPrompt(props) {
  const [modal, setModal] = useState(false);
  const generating = props.formats.some(format => format.generating)

  const open = () => setModal(true);
  const close = () => setModal(false);

  const saveAndContinue = () => {
    props.saveGraphic(false, false, true)
    setModal(false)
  }

  useEffect(() => {
    // Check if Plausible is loaded
    if (typeof plausibleListeners === 'function') {
      plausibleListeners();
    }
  }, []);

  const disabled = generating || props.formState.saving

  const closeButton = (
    <Button color="link" onClick={close} className="p-0">
      <X />
    </Button>
  )

  console.log('isAdmin', props.isAdmin)

  return (
    <div className="d-flex gap-3 align-items-center">
      <h2 className="m-0 h6 fst-normal flex-fill-md">
        You have {props.trialTime} left in your trial.
      </h2>
      {props.isAdmin && (
        <>
          <Button color="primary" size="md" onClick={open} disabled={disabled} className="plausible-event-name=Producer+Trial+Prompt">
            <span>Join <span className="d-none d-sm-inline">Box Out</span> Now</span>
          </Button>
          <Modal isOpen={modal} toggle={close}>
            <ModalHeader toggle={close} close={closeButton} tag="div">
              <h2>Continue to Subscribe to Box Out</h2>
            </ModalHeader>
            <ModalBody>
              <div>
                <p>You are about to leave the graphics producer. Do you want to save your progress before subscribing?</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={close}>Cancel <span aria-label="and">+</span> Stay</Button>
              <Button color="primary" onClick={saveAndContinue}>Save <span aria-label="and">+</span> Continue</Button>
              <Button color="primary" tag="a" href={props.subscribeUrl}>Continue</Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </div>
  );
}