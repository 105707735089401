import React, { useState, Fragment } from 'react'
import { Input } from 'reactstrap'

export default function DateTimeField(props) {
  const [value, setValue] = useState(props.field.value_for_input == null ? '' : props.field.value_for_input)
  const { field_name, field_id, input_type } = props.field

  const onChange = (e) => {
    const newValue = e.target.value
    setValue(newValue)

    if (props.onChange) {
      props.onChange(newValue)
    }
  }

  return (
    <Fragment>
      <Input
        type={input_type}
        id={field_id}
        name={field_name}
        value={value}
        onChange={onChange} />
    </Fragment>
  )
}
