import React, { useState } from 'react'
import {
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import axios from '../shared/axios'

export default function ProducerXMLSelector(props) {
  const [checked, setChecked] = useState(!!props.usingXMLStream)
  const [loading, setLoading] = useState(false)

  const handleChange = () => {
    setChecked(!checked)
    window.location = window.location.protocol + "//" + window.location.host + window.location.pathname + "?use_xml_stream=" + (checked ? 'false' : 'true')
  }

  const handleFileSelection = async (e) => {
    const file = e.target.files[0]

    if (file) {
      setLoading(true)
      const signResponse = await axios.get(`${props.basepath}/uploads/sign.json`, {
        params: {
          objectName: file.name
        }
      })

      const { signedUrl } = signResponse.data

      const path = new URL(signedUrl).pathname.replace(/^\/+/g, '')

      var options = {
        headers: {
          'Content-Type': 'xml'
        }
      }

      await axios.put(signedUrl, file, options)
      await axios.post(`${props.basepath}/settings/livestats.json`, {
        xml_update_path: path,
      })

      window.location = window.location.protocol + "//" + window.location.host + window.location.pathname
    }
  }

  return (
    <fieldset>
      <legend className="mb-2 h4">Use Live Stats?</legend>
      <FormGroup>
        <FormGroup check>
          <Input type="checkbox" id="use-xml-update" onChange={handleChange} checked={checked} disabled={!props.recentXMLStream} />{' '}
          <Label check htmlFor="use-xml-update">
            {props.recentXMLStream ? props.recentXMLStream.title : 'No live stats have been recorded'}
          </Label>
        </FormGroup>
        {loading ? (
          <div className="btn w-100 btn-outline-secondary btn-sm mt-2 disabled">
            Uploading…
          </div>
        ) : (
            <label className="btn w-100 btn-outline-secondary btn-sm mt-2">
              Upload a new XML file…
              <input type="file" hidden accept="text/xml" onChange={handleFileSelection} />
            </label>
          )}
      </FormGroup>
    </fieldset>
  )
}
