import React, { useState, Fragment, useEffect } from 'react'
import Cropper from '../Cropper'

export default function UploadCropper(props) {
  const { upload, cropperInputNames, result_width, result_height, title, clipping } = props
  const attributes = upload ? upload.attributes : {}
  const cropper_url = clipping ? clipping.cropper_url : attributes.cropper_url
  const [cropperValues, setCropperValues] = useState({
    crop_width: props.crop_width,
    crop_height: props.crop_height,
    crop_x: props.crop_x,
    crop_y: props.crop_y,
    image_width: props.image_width,
    image_height: props.image_height,
  })

  useEffect(() => {
    setCropperValues({
      crop_width: null,
      crop_height: null,
      crop_x: null,
      crop_y: null,
      image_width: null,
      image_height: null,
    })
  }, [upload])

  const updateCropperValues = (data) => {
    setCropperValues({
      crop_width: data.actions.crop.width,
      crop_height: data.actions.crop.height,
      crop_x: data.actions.crop.x,
      crop_y: data.actions.crop.y,
      image_width: data.input.width || data.input.image.width,
      image_height: data.input.height || data.input.image.height,
    })
  }

  const crop = cropperValues.crop_width ? {
    x: cropperValues.crop_x,
    y: cropperValues.crop_y,
    width: cropperValues.crop_width,
    height: cropperValues.crop_height,
  } : null

  const inputValue = (value) => {
    if (value == null || value == undefined) {
      return ''
    } else {
      return value
    }
  }

  const handleClipClick = () => {
    if (props.onClipClick) {
      props.onClipClick();
    }
  }

  return (
    <Fragment>
      {upload ? (
        <Fragment>
          {title ? <div className="mt-2 mb-n2"><strong>{title}</strong></div> : null}
          <Cropper
            crop={crop}
            clip={!!clipping}
            initialImage={cropper_url}
            ratio={`${result_width}:${result_height}`}
            didTransform={updateCropperValues}
            onClipClick={handleClipClick}
            key={upload.id} />
        </Fragment>
      ) : null}
      <input type="hidden" name={cropperInputNames.crop_width} value={inputValue(cropperValues.crop_width)} />
      <input type="hidden" name={cropperInputNames.crop_height} value={inputValue(cropperValues.crop_height)} />
      <input type="hidden" name={cropperInputNames.crop_x} value={inputValue(cropperValues.crop_x)} />
      <input type="hidden" name={cropperInputNames.crop_y} value={inputValue(cropperValues.crop_y)} />
      <input type="hidden" name={cropperInputNames.image_width} value={inputValue(cropperValues.image_width)} />
      <input type="hidden" name={cropperInputNames.image_height} value={inputValue(cropperValues.image_height)} />
    </Fragment>
  )
}
