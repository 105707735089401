import React from 'react'
import Field from './Field'

export default function GroupField(props) {
  const columns = props.columns || props.field.columns

  const renderColumn = (field) => {
    const span = field.span || 1
    return (
      <div key={field.key} className="producer-form-group-item" style={{"--span": span }}>
        <Field {...props} field={field} />
      </div>
    )
  }

  const fields = props.fields || props.field.fields

  return (
    <div className="producer-form-group" style={{'--columns': columns}}>
      {fields.map(field => renderColumn(field))}
    </div>
  )
}