import axios from '../../components/shared/axios'
import { actions } from '../team_graphics'

const updateComponent = async (state, action, dispatch) => {
  const response = await axios.post(`${state.components_basename}/components/${action.id}.json`, {
    campaign_component: {
      message: action.message,
    }
  })

  dispatch({ type: actions.REPLACE_COMPONENT, component: response.data.data })
}

export { updateComponent }
