import React from 'react'
import reactCSS from 'reactcss'

export const BoxOutPointer = () => {
  const styles = reactCSS({
    'default': {
      picker: {
        marginTop: '1px',
        width: '4px',
        borderRadius: '1px',
        height: '14px',
        boxShadow: '0 0 2px rgba(0, 0, 0, .6)',
        background: '#fff',
        transform: 'translateX(-2px)',
      },
    },
  })

  return (
    <div style={ styles.picker } />
  )
}

export default BoxOutPointer
