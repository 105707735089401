import React from "react"
import EventCard from "./EventCard"
import { format } from "date-fns"

const DaySection = (props) => {
  const { day, events, index } = props

  const date = new Date(day)

  return (
    <div className={`events-day ${index > 0 ? "border-top" : ""}`}>
      <div className="border-end text-center position-relative">
        <div className="mb-0 mb-md-3">
          <h3 className="all-unset" aria-label={format(date, "EEEE d")}>
            <div className="fs-5 fs-md-4 fw-bold lh-1 mt-2 mt-md-3">
              {format(date, "d")}
            </div>
            <div className="text-secondary fs-7 fs-md-6 mb-0 mb-md-2 text-uppercase">
              {format(date, "EEE")}
            </div>
          </h3>
        </div>
      </div>
      <div className="card-body p-0 d-flex align-items-stretch w-100 flex-wrap">
        {events.map((event, index) => (
          <EventCard key={event.id} event={event} index={index} {...props} />
        ))}
      </div>
    </div>
  )
}

export default DaySection
