import axios from "../../components/shared/axios"
import { actions, states } from "../share"
import { getScheduledPage, getSharePage } from "./pagination"

const createOrUpdateSharePost = async (type, state, action) => {
  const params = {
    share: {
      action: type,
      social_account_id: action.socialAccount && action.socialAccount.id,
      scheduled_at: action.scheduleAt ? `${action.scheduleAt.date} ${action.scheduleAt.time}` : null,
      prompt_log_id: action.promptLogId,
      metadata: metadataForAction(action),
    },
  }
  
  let response

  if (action.shareId) {
    response = await axios.patch(`${state.teampath}/shares/${action.shareId}`, params)
  } else {
    response = await axios.post(state.sharepath, params)
  }

  return response
}

const handleCompletedShare = async (data, state, dispatch, action) => {
  const { share, was_scheduled } = data

  if (share.scheduled_at) {
    await getScheduledPage(state.scheduled.currentPage, state, dispatch)
  } else {
    if (was_scheduled && state.shares) {
      // load both because the share may have been scheduled and then unscheduled
      await Promise.all([
        getSharePage(state.shares.currentPage, state, dispatch),
        getScheduledPage(state.scheduled.currentPage, state, dispatch),
      ])
    } else {
      await getSharePage(state.shares.currentPage, state, dispatch)
    }
  }

  dispatch({ type: action, payload: states.complete })
}

const metadataForAction = (action) => {
  if (action.type == actions.SHARE_MULTI) {
    return {
      facebook: action.facebook?.isEnabled && {
        social_account_id: action.facebook.post.id,
        repost: action.facebook.repost.map((r) => r.id),
        formats: action.facebook.formats,
      },
      twitter: action.twitter?.isEnabled && {
        social_account_id: action.twitter.post.id,
        repost: action.twitter.repost.map((r) => r.id),
        formats: action.twitter.formats,
      },
      instagram: action.instagram?.isEnabled && {
        social_account_id: action.instagram.post.id,
        formats: action.instagram.formats,
      },
      message: action.message,
    };
  } else {
    return {
      formats: action.formats,
      message: action.message,
    }
  }
}

// Creating these as different methods allows us to add additional logic
// to each social network's share method down the road

const shareMulti = async (state, action, dispatch) => {
  const response = await createOrUpdateSharePost("multipost", state, action);
  handleCompletedShare(
    response.data,
    state,
    dispatch,
    actions.SET_MULTI_SHARE_STATE
  )
}

const shareTwitter = async (state, action, dispatch) => {
  const response = await createOrUpdateSharePost("post", state, action)
  handleCompletedShare(response.data, state, dispatch, actions.SET_TWITTER_STATE)
}

const shareFacebook = async (state, action, dispatch) => {
  const response = await createOrUpdateSharePost("post", state, action);
  handleCompletedShare(response.data, state, dispatch, actions.SET_FACEBOOK_STATE)
}

const shareInstagram = async (state, action, dispatch) => {
  const response = await createOrUpdateSharePost("post", state, action);
  handleCompletedShare(response.data, state, dispatch, actions.SET_INSTAGRAM_STATE)
}

const updateShareResult = async (state, dispatch, action) => {
  const response = await axios.get(`${state.teampath}/shares/${action.share.id}/poll`)
  
  if (response.data.share.performed_at) {
    dispatch({ type: actions.UPDATE_SHARE, share: response.data.share })
  } else {
    setTimeout(() => updateShareResult(state, dispatch, action), 1000)
  }
}

const deleteSharePost = async (state, dispatch) => {
  await axios.delete(`${state.teampath}/shares/${state.postToDelete.id}/delete_post`)
  await getSharePage(state.shares.currentPage, state, dispatch)
  dispatch({ type: actions.CLOSE_DELETE_POST })
}

export { shareTwitter, shareFacebook, shareInstagram, shareMulti, deleteSharePost, updateShareResult }
