const KEY_TO_NAME = {
  'graphic': 'Logos',
  'photo': 'Photos',
  'video': 'Videos',
  'opponent_logo': 'Opponent Logos',
  'player': 'Head Shots',
  'background': 'Admin',
}

const categoryToTitle = (key) => {
  return KEY_TO_NAME[key] || key.replace('_', ' ') + 's'
}

export default categoryToTitle
