import React, { useContext, useState } from 'react'
import { X, FolderPlus } from 'react-feather'
import { store } from '../../stores/uploads'
import {
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip
} from 'reactstrap'

export default function AddFolder(props) {
  const { dispatch, actions, processing, openModal, category, folder, currentFolderIsShared, canManageShared } = useContext(store)
  const [name, setName] = useState('')
  const open = openModal == actions.ADD_FOLDER

  const toggle = () => {
    dispatch({ type: actions.TOGGLE_MODAL, modal: actions.ADD_FOLDER })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (processing) return
    const formData = new FormData(e.target)    
    dispatch({ type: actions.ADD_FOLDER, formData: formData })
  }

  const setFocus = () => {
    document.querySelector('#upload-folder-name').focus()
  }

  const closeButton = (
    <Button color="link" onClick={toggle} className="p-0 text-muted">
      <X />
    </Button>
  )

  const disabled = processing || name.trim() == ''

  return (
    <Col xs="auto" className={`ps-0 ms-auto ${props.className}`}>
      <span id="add-folder-button-wrapper">
        <Button
          id="add-folder-button"
          size="sm"
          color="link"
          disabled={currentFolderIsShared && !canManageShared}
          onClick={toggle}>
          <FolderPlus size={20} />
        </Button>
        <UncontrolledTooltip
          placement="top"
          target="add-folder-button-wrapper"
          fade={false}
          className="text-balance"
          delay={{ hide: 0, show: 0 }}>
          {currentFolderIsShared && !canManageShared ? 'New folders unavailable in shared folders' : 'Add a Folder'}
        </UncontrolledTooltip>
      </span>
      <Modal isOpen={open} toggle={toggle} size="sm" onOpened={setFocus}>
        <ModalHeader toggle={toggle} close={closeButton} tag="h3">
          Add a Folder
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <input
            type="hidden"
            name="upload_folder[category]"
            value={category} />
          {folder ? (
            <input
            type="hidden"
            name="upload_folder[parent_id]"
            value={folder} />
          ) : null}
          <ModalBody>
            <FormGroup>
              <Label for="upload-folder-name">Folder Name</Label>
              <Input
                type="text"
                id="upload-folder-name"
                name="upload_folder[name]"
                placeholder="Untitled"
                onChange={(e) => setName(e.target.value)}
                value={name} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={disabled}>
              Create Folder
            </Button>
            <Button color="outline-secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Col>
  )
}
