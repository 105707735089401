import React, { Fragment, useState, useEffect } from 'react'
import debounce from 'debounce'
import Filter from '../shared/Filter'
import StickyBar from '../shared/StickyBar'
import { ButtonGroup, Col } from 'reactstrap'
import { generatePath, useParams } from 'react-router'
import { Link } from 'react-router-dom'

export default function TemplatesFilter(props) {
  const { plans, isAdmin, subscribed, subscriptionLink, subscriptionTier, onChange } = props
  const [query, setQuery] = useState('')
  const debouncedSetQuery = debounce(setQuery, 200)
  const planNames = Object.keys(plans).reverse()
  const routeMatches = useParams()

  const currentPlan = routeMatches.plan

  useEffect(() => {
    if (onChange) {
      onChange(query)
    }
  }, [query])

  const subscribeButton = !subscribed && isAdmin ? (
    <Col xs="12" md="auto" className="mt-2 mt-md-0 px-2 ps-md-0">
      <a href={subscriptionLink} className="btn btn-primary d-block d-md-inline-block">Subscribe from $60/mo</a>
    </Col>
  ) : null

  const upgradeButton = subscribed && isAdmin && subscriptionTier < plans[currentPlan] ? (
    <Col xs="12" md="auto" className="mt-2 mt-md-0 px-2 ps-md-0">
      <a href={subscriptionLink} className="btn btn-primary d-block d-md-inline-block">Upgrade subscription</a>
    </Col>
  ) : null

  return (
    <Fragment>
      <StickyBar>
        <Filter
          query={query}
          onChange={debouncedSetQuery}
          title={`Filter templates (eg. gameday, birthday, instagram)…`} />
        {subscribeButton}
        {upgradeButton}
        <Col xs="12" md="auto" className="mt-2 mt-md-0 px-2 ps-md-0">
          <ButtonGroup className="d-flex">
            {planNames.map(plan => {
              const color = plan == currentPlan ? 'primary' : 'outline-secondary'
              const path = generatePath('/:plan/:category?/:template?', {
                ...routeMatches,
                plan,
              })
              return <Link key={plan} to={path} className={`btn btn-${color} text-capitalize w-100`}>{plan}</Link>
            })}
          </ButtonGroup>
        </Col>
      </StickyBar>
    </Fragment>
  )
}
