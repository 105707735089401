import React, { Fragment } from 'react'
import { Button, Row, Col } from 'reactstrap'
import DevModeToggle from '../DevModeToggle'

export default function ProducerActions(props) {
  const { devMode, openInEditor } = props.admin || {}
  const saveClass = props.showShare ? 'outline-secondary' : 'primary'
  const generating = props.formats.some(format => format.generating)

  const saveGraphic = () => {
    props.saveGraphic(false)
  }

  const shareGraphic = () => {
    props.saveGraphic(true)
  }

  const saveAndUpdateGraphic = () => {
    props.saveGraphic(false, true)
  }

  const toggleDevMode = (dev) => {
    props.setAdmin(prev => ({
      ...prev,
      devMode: dev,
    }))

    props.updateFields()
  }

  const disabled = generating || props.formState.saving

  const actionText = () => {
    if (props.hasSubscriptionAccess) {
      if (props.availableForSubscription) {
        if (props.canManageTemplates) {
          return (<Fragment>This is a <strong>demo</strong> of the template. Add to your team to create a graphic.</Fragment>)
        } else {
          return (<Fragment>This is a <strong>demo</strong> of the template. Contact your administrator to add it to your team.</Fragment>)
        }
      } else {
        return "Upgrade your subscription to add this template to your team"
      }
    } else {
      return "The watermark on the demo graphic will be removed after purchase"
    }
  }

  return (
    <Row className={`producer-content-actions ${props.noTemplate ? 'no-template' : null}`}>
      {Object.keys(props.admin).length > 0 ? (
        <Col xs="auto" className="me-auto mt-3 mt-md-0">
          <DevModeToggle devMode={devMode} onChange={toggleDevMode} />
        </Col>
      ) : null}
      {props.actionText ? (
        <Col xs className="text-center mt-3 mt-md-0">
          <span className="text-muted sublead">{actionText()}</span>
        </Col>
      ) : null}
      {props.admin && openInEditor ? (
        <Col xs md="auto" className="ps-0 d-none d-md-block mt-3 mt-md-0">
          <a href={openInEditor} className="btn btn-outline-secondary">Open in Editor</a>
        </Col>
      ) : null}
      {props.saveUrl ? (
        <Col xs md="auto" className="ps-0 mt-3 mt-md-0">
          <Button block color={saveClass} disabled={disabled} onClick={saveGraphic}>Save</Button>
        </Col>
      ) : null}
      {props.saveUrl && props.admin && props.admin.saveAndUpdate && devMode ? (
        <Col xs md="auto" className="ps-0 mt-3 mt-md-0">
          <Button block outline color="secondary" disabled={disabled} onClick={saveAndUpdateGraphic}>Save &amp; Update</Button>
        </Col>
      ) : null}
      {props.saveUrl && props.showShare ? (
        <Col xs md="auto" className="ps-0 mt-3 mt-md-0">
          <Button block color="primary" disabled={disabled} onClick={shareGraphic}>Share</Button>
        </Col>
      ) : null}
      {props.shareLink ? (
        <Col xs md="auto" className="ps-0 mt-3 mt-md-0">
          <a href={props.shareLink} className="btn w-100 btn-primary">Share</a>
        </Col>
      ) : null}
      {props.cancelLink ? (
        <Col xs md="auto" className="ps-0 mt-3 mt-md-0">
          <a href={props.cancelLink} className="btn w-100 btn-outline-secondary">Cancel</a>
        </Col>
      ) : null}
    </Row>
  )
}
