import React, { useContext } from 'react'
import StickyBar from '../shared/StickyBar'
import Filter from '../shared/Filter'
import debounce from 'debounce'
import { store } from '../../stores/team_graphics'

export default function TeamGraphicsFilter() {
  const { filter, page, dispatch, actions } = useContext(store)

  const setFilter = (value) => {
    dispatch({
      type: actions.FILTER,
      filter: value.trim(),
    })
  }

  const debouncedSetFilter = debounce(setFilter, 400)
  const title = page > 1 ? 'Filter graphics…' : 'Filter templates and graphics…'
  return (
    <StickyBar className="z-3">
      <Filter query={filter} onChange={debouncedSetFilter} title={title} refocusOnTitleChange={false} />
    </StickyBar>
  )
}
