import React from "react"
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import { X } from "react-feather"

const DeleteGraphicModal = (props) => {
  const { isOpen, toggle, handleDeleteGraphic } = props

  const closeButton = (
    <Button color="link" onClick={toggle} className="p-0 text-muted">
      <X />
    </Button>
  )

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        close={closeButton}
        toggle={toggle}
        className="modal-header-segmented d-flex align-items-center"
        tag="div"
      >
        <h2 className="fst-normal fs-5 m-0">
          Are you sure you want to delete this graphic?
        </h2>
      </ModalHeader>
      <ModalBody>
        <p className="fs-6">
          Deleting this graphic also deletes associated shares.
          <br />
          This action cannot be undone.
        </p>
        <div className="d-flex gap-3">
          <Button color="danger" onClick={() => handleDeleteGraphic()}>
            Delete Graphic
          </Button>
          <Button color="outline-secondary" onClick={() => toggle()}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteGraphicModal
