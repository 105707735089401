import React, { useState } from 'react'
import axios from '../shared/axios'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Card, CardBody, Button, Form, Row, Col, Input } from 'reactstrap'

export default function SubscriptionForm(props) {
  const stripe = useStripe()
  const elements = useElements()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(props.defaultPaymentMethod)
  const [subscriptionId, setSubscriptionId] = useState(null)

  const handleSubmit = async (event) => {
    const form = event.target
    event.preventDefault()
    if (loading) return
    setErrorMessage(null)
    setLoading(true)
    const { error, paymentMethod } = await getPaymentMethod()

    if (error) {
      showError(error.message)
    } else {
      const formData = new FormData(form)
      formData.set('payment_method_id', paymentMethod.id)
      const { error, redirect, confirm, subscriptionId } = await createSubscription(formData)

      if (error) {
        showError(error)
      } else if (redirect) {
        window.location = redirect
      } else if (confirm) {
        const { error } = await stripe.confirmCardPayment(confirm)

        if (error) {
          showError(error.message)
        } else {
          window.location = `${props.verifyPath}?subscription_id=${subscriptionId}`
        }
      }
    }
  }

  const getPaymentMethod = async () => {
    if (defaultPaymentMethod || props.amountDue <= 0) {
      return {
        paymentMethod: {
          id: 'default'
        }
      }
    } else {
      if (!stripe || !elements) return null
      const cardElement = elements.getElement(CardElement)
      return await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })
    }
  }

  const createSubscription = async (formData) => {
    const url = subscriptionId ? props.updatePaymentPath : props.subscribePath
    const response = await axios.post(url, formData)

    if (response.data.subscriptionId) {
      setSubscriptionId(response.data.subscriptionId)
    }

    return response.data
  }

  const showError = (message) => {
    setErrorMessage(message)
    setLoading(false)
  }

  const formatAmount = (amount) => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(amount / 100)
  }

  const buttonText = loading ? 'Processing' :
    (props.amountDue <= 0 ? 'Subscribe' :
      `Subscribe and Pay ${formatAmount(props.amountDue)}`)

  const options = {
    style: {
      base: {
        fontFamily: 'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        fontSize: '15px',
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} className="mb-4">
      <Input type="hidden" name="subscription_id"
        value={subscriptionId || ''} />
      {props.amountDue > 0 ? (
        <Card className="card-rounded mb-4">
          <CardBody className="p-3">
            {defaultPaymentMethod ? (
              <Row className="align-items-center">
                <Col>
                  <img
                    src={defaultPaymentMethod.image}
                    className="subscription-card-image"
                    alt={defaultPaymentMethod.name} />
                  <strong>
                    Charge to {defaultPaymentMethod.name} ending in {defaultPaymentMethod.last4}
                  </strong>
                </Col>
                <Col xs="auto">
                  <Button color="outline-secondary" size="sm"
                    onClick={() => setDefaultPaymentMethod(null)}>Change</Button>
                </Col>
              </Row>
            ) : (
                <CardElement options={options} />
              )}
          </CardBody>
        </Card>
      ) : null}
      {errorMessage ? (
        <div className="text-center text-danger fw-bold mb-4">
          {errorMessage}
        </div>
      ) : null}
      <Button size="lg" color="primary" block={true} type="submit" disabled={!stripe || loading}>
        {loading ? <div className="spinner spinner-sm spinner-white"></div> : null}
        {buttonText}
      </Button>
    </Form >
  )
}
