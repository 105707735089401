import React from 'react'
import classNames from 'classnames'
import ProducerPreviewImage from './ProducerPreviewImage'
import ProducerPreviewVideo from './ProducerPreviewVideo'
import ProducerPreviewStatus from './ProducerPreviewStatus'
import ProducerPreviewError from './ProducerPreviewError'

export default function ProducerPreview(props) {
  const { selectedFormat, format, updateFormat, clearOverTimeClock, setFormState, previewsHeight, previewState } = props
  const { id, uuid, url, oldUrl, video, generating, message, error, example, extension } = format

  const handlePreviewLoad = () => {
    if (updateFormat) {
      updateFormat(id, {
        generating: false,
        anyLoaded: true,
        loadedCurrentPreview: true,
        example: oldUrl ? false : example,
      })
    }

    if (clearOverTimeClock) {
      clearOverTimeClock(id)
    }

    if (setFormState) {
      setFormState(prev => ({
        ...prev,
        uuids: {
          ...prev.uuids,
          [id]: uuid,
        },
        formDataCheck: previewState.formDataCheck,
      }))
    }
  }
  const maxHeight = extension == 'png' ? `calc(${previewsHeight - 20}px - 4rem)` : `calc(${previewsHeight}px - 4rem)`
  const containerMaxHeight = `calc(${previewsHeight}px - 4rem)`

  const previewElement = () => {
    if (error) {
      return <ProducerPreviewError key="error" error={error} {...props} />
    } else if (!url) {
      return null
    } else if (video) {
      return <ProducerPreviewVideo key="videoPreview" {...props} handlePreviewLoad={handlePreviewLoad} maxHeight={maxHeight} />
    } else {
      return <ProducerPreviewImage key="imagePreview" {...props} handlePreviewLoad={handlePreviewLoad} maxHeight={maxHeight} containerMaxHeight={containerMaxHeight} aspectRatio={props.format.ratio} />
    }
  }

  const previewClasses = classNames({
    'producer-preview': true,
    'producer-preview-hidden': selectedFormat && selectedFormat != format.id,
  })

  return (
    <div className={previewClasses}>
      {previewElement()}
      <ProducerPreviewStatus generating={generating} message={message} />
    </div>
  )
}
