import React, { Fragment, useContext, useRef } from 'react'
import { Cloud, Facebook, Instagram, X } from 'react-feather'
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap'
import { store } from '../../stores/share'
import { shareEmailActionText } from '../../stores/share/email'
import LoadingIndicator from '../shared/LoadingIndicator'
import Pagination from '../shared/Pagination'
import ShareEditScheduled from './ShareEditSchedule'
import XLogo from '../shared/XLogo'

export default function ShareScheduled(props) {
  const { scheduled, sortedScheduled, scheduledLoading, deleteShareOpen, shareDeleting, actions, dispatch } = useContext(store)

  const { title } = props
  const elementRef = useRef()

  const months = Object.values(sortedScheduled.reduce((arr, share) => {
    const monthYearName = new Date(share.scheduled_at).toLocaleString('default', { month: 'long', year: 'numeric' })
    arr[monthYearName] = arr[monthYearName] || {
      monthYearName,
      shares: []
    }
    arr[monthYearName].shares.push(share)
    return arr
  }, {})).sort(function(a, b) {
    return new Date(a.shares[0].scheduled_at) - new Date(b.shares[0].scheduled_at)
  }).map(month => {
    const days = Object.values(month.shares.reduce((arr, share) => {
      const day = new Date(share.scheduled_at).getDate()
      arr[day] = arr[day] || {
        day,
        dayOfWeek: new Date(share.scheduled_at).toLocaleString('default', { weekday: 'short' }),
        shares: []
      }
      arr[day].shares.push(share)
      return arr
    }, {}))

    return {
      monthYearName: month.monthYearName,
      days: days,
    }
  })

  const iconComponent = (provider) => {
    switch (provider) {
      case 'facebook':
        return <Facebook className="icon icon-md" />
      case 'twitter':
        return <XLogo className="icon icon-md" />
      case 'instagram':
        return <Instagram className="icon icon-md" />
      default:
        return <Cloud className="icon icon-md" />
    }
  }

  const sharePreviews = (share) => {
    if (props.hidePreviews || !share.graphic) {
      return null
    }

    if (share.graphic.attributes.formats.length > 1) {
      return (
        <div className="preview-multiformat">
          {(share.metadata.formats || share.graphic.attributes.formats).map(format => {
            const url = share.graphic.attributes.preview_urls[format.id]
            return <img key={url} src={url} alt={share.graphic.attributes.title} className="preview-sm rounded" />
          })}
        </div>
      )
    }

    return (
      <img src={Object.values(share.graphic.attributes.preview_urls)[0]} alt={share.graphic.attributes.title} className="preview-sm rounded" />
    )
  }

  const hasStoryFormat = (formats) => {
    const storyRatio = 1080.0 / 1920.0
    return formats.some(format => {
      return format.ratio >= storyRatio - 0.1 && format.ratio <= storyRatio + 0.1
    })
  }

  const hasNonStoryFormat = (formats) => {
    const storyRatio = 1080.0 / 1920.0
    return formats.some(format => {
      return format.ratio < storyRatio - 0.1 || format.ratio > storyRatio + 0.1
    })
  }

  const selectedFormatImages = (share, formats) => {
    const previewUrls = share.graphic.attributes.preview_urls
    return formats.map((format, key) => ( 
      <Col xs="auto" key={key} className="ps-0 p-1">
        <div key={key} style={{aspectRatio: `${format.width} / ${format.height}`,height:'20px'}} className='rounded-2 overflow-hidden'>
          <img src={previewUrls[format.id]} key={format.id} 
          style={{
            objectFit: 'contain',
            height:'100%',
            width:'100%',
            verticalAlign:'unset',
          }} alt="" />
        </div>
      </Col>
    ))
  }

  const shareDetails = (share) => {
    switch (share.action) {
      case 'multipost':
        return (
          <div className="mt-md-n2">
            {share.facebook && (
              <Row className="align-items-center my-1 pe-2 pe-lg-0">
                <Col xs="auto" className="pe-0">
                  <Facebook className="icon icon-md" />
                </Col>
                <Col>
                  <strong>{share.facebook.social_account.attributes.name}</strong>
                  <small className="d-block text-muted">Post to 
                    {hasNonStoryFormat(share.facebook.formats) && ' Page '}
                    {hasStoryFormat(share.facebook.formats) && hasNonStoryFormat(share.facebook.formats) && ' and '}
                    {hasStoryFormat(share.facebook.formats) && ' Story '}
                  </small>
                </Col>
                {selectedFormatImages(share, share.facebook.formats)}
              </Row>
            )}
            {share.instagram && (
              <Row className="align-items-center my-1 pe-2 pe-lg-0">
                <Col xs="auto" className="pe-0">
                  <Instagram className="icon icon-md" />
                </Col>
                <Col>
                  <strong>{share.instagram.social_account.attributes.name}</strong>
                  <small className="d-block text-muted ">Post to 
                    {hasNonStoryFormat(share.instagram.formats) && ' Feed '}
                    {hasStoryFormat(share.instagram.formats) && hasNonStoryFormat(share.instagram.formats) && ' and '}
                    {hasStoryFormat(share.instagram.formats) && ' Story '}
                  </small>
                </Col>
                {selectedFormatImages(share, share.instagram.formats)}
              </Row>
            )}
            {share.twitter && (
              <Row className="align-items-center my-1 pe-2 pe-lg-0">
                <Col xs="auto" className="pe-0">
                  <XLogo className="icon icon-md" />
                </Col>
                <Col>
                  <strong>{share.twitter.social_account.attributes.name}</strong>
                  <small className="d-block text-muted">Post to Feed</small>
                </Col>
                {selectedFormatImages(share, share.twitter.formats)}
              </Row>
            )}
            <div className="rounded py-2 px-3 bg-light text-muted style-italic me-lg-n2">
              {share.metadata.message.trim() == '' ? <em>No post text entered</em> : share.metadata.message}
              <small className="text-muted d-block mt-1">Scheduled by {share.user.name}</small>
            </div>
          </div>
        )
      case 'post':
        return (
          <>
            <Row className="align-items-center mt-n1 mb-2">
              <Col xs="auto" className="pe-0">
                {iconComponent(share.social_account.attributes.provider)}
              </Col>
              <Col>
                <strong>{share.social_account.attributes.name}</strong>
                <small className="d-block text-muted">Post to 
                  {hasNonStoryFormat(share.metadata.formats) && ' Page '}
                  {hasStoryFormat(share.metadata.formats) && hasNonStoryFormat(share.metadata.formats) && ' and '}
                  {hasStoryFormat(share.metadata.formats) && ' Story '}
                </small>
              </Col>
              {selectedFormatImages(share, share.metadata.formats)}
            </Row>
            <div className="rounded py-2 px-3 bg-light text-muted style-italic me-n2">
              {share.metadata.message}
              <small className="text-muted d-block mt-1">Scheduled by {share.user.name}</small>
            </div>
          </>
        )
      case 'email':
        return (
          <>
            <div className="d-md-none my-2">
              {sharePreviews(share)}
            </div>
            Email to {shareEmailActionText(share)} <small className="text-muted d-block">by {share.user.name}</small>
          </>
        )
    }
  }

  const openDelete = (share) => {
    dispatch({
      type: actions.OPEN_DELETE_SHARE,
      share: share,
    })
  }

  const closeDelete = () => {
    if (!shareDeleting) {
      dispatch({
        type: actions.CLOSE_DELETE_SHARE,
      })
    }
  }

  const closeButton = (
    <Button color="link" onClick={closeDelete} className="p-0 text-muted">
      <X />
    </Button>
  )

  const handleDelete = () => {
    dispatch({
      type: actions.DELETE_SHARE,
    })
  }

  const handlePagination = (page) => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    dispatch({
      type: actions.LOAD_SCHEDULED_PAGE,
      payload: page,
    })
  }

  return (
    <div ref={elementRef} className="mt-n3 pt-3">
      {title ? <h1 className="mb-3">{title}</h1> : null}
      <div className="position-relative">
        {months.length == 0 ? (
          <div className="card card-empty mb-4">
            <div className="card-body">
              No shares have been scheduled for this graphic
            </div>
          </div>
        ) : months.map(month => (
          <Fragment key={month.monthYearName}>
            <Card className="rounded mb-4">
              <CardHeader>
                <h4 className="m-0">{month.monthYearName}</h4>
              </CardHeader>
              <CardBody className="p-0">
                <Table className="mb-0" borderless responsive>
                  <tbody>
                    {month.days.map(day => {
                      return day.shares.map((share, idx) => {
                        return (
                          <tr key={`share-${share.id}`} className="card-date-row">
                            {idx == 0 ? (
                              <td rowSpan={day.shares.length} className="card-date-column py-3">
                                <h4 className="m-0">{day.day}</h4>
                                <p className="m-0 text-muted text-uppercase">{day.dayOfWeek}</p>
                              </td>
                            ) : null}
                            <td className="text-muted py-3 pe-0 column-auto d-none d-md-table-cell align-top">
                              {new Date(share.scheduled_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}
                            </td>
                            <td>
                              <p className="d-md-none mb-1 text-muted">
                                {new Date(share.scheduled_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}
                              </p>
                              {shareDetails(share)}
                              <Row className="d-md-none mt-2">
                                <Col className="pe-0">
                                  <ShareEditScheduled share={share} className="w-100" />
                                </Col>
                                <Col>
                                  <Button onClick={() => openDelete(share)} block color="outline-subtle-danger" size="sm">Delete</Button>
                                </Col>
                              </Row>
                            </td>
                            <td className="table-actions d-none d-md-table-cell align-top">
                              <ShareEditScheduled share={share} />
                              <Button onClick={() => openDelete(share)} color="outline-subtle-danger" size="sm">Delete</Button>
                            </td>
                          </tr>
                        )
                      })
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Modal isOpen={deleteShareOpen} toggle={closeDelete}>
              <ModalHeader toggle={closeDelete} close={closeButton} tag="h4" className="modal-header-segmented">
                Delete this Scheduled Post?
              </ModalHeader>
              <ModalBody>
                Are you sure you want to delete this scheduled share?
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={handleDelete} disabled={shareDeleting}>{shareDeleting ? 'Deleting…' : 'Delete'}</Button>
                <Button color="outline-secondary" onClick={closeDelete} disabled={shareDeleting}>Cancel</Button>
              </ModalFooter>
            </Modal>
          </Fragment>
        ))}
        <LoadingIndicator color="gray" loading={scheduledLoading} />
      </div>
      <Pagination currentPage={scheduled.currentPage} pageCount={scheduled.pageCount} onPageChange={handlePagination} />
    </div>
  )
}
