const fetchFile = async (url, filename) => {
  const res = await fetch(url);
  const file = await res.blob();

  const shareData = {
    files: [
      new File([file], filename, {
        type: file.type,
      }),
    ],
  }

  if (navigator.canShare &&
      navigator.canShare(shareData) &&
      navigator.maxTouchPoints > 1 &&
      window.navigator.userAgent.indexOf("Mac") != -1) {
    return navigator.share(shareData);
  } else {
    const tempUrl = URL.createObjectURL(file);
    const element = document.createElement("a");
    element.href = tempUrl;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    URL.revokeObjectURL(tempUrl);
    element.remove();

    return Promise.resolve()
  }
}

export { fetchFile }
