import React from "react"
import { Button } from "reactstrap"
import { Play, Maximize2 } from "react-feather"
import PreviewModal from "../graphics/PreviewModal"
import MultiformatIcon from "../shared/MultiformatIcon"
import { Link } from "react-router-dom"

export default function GraphicImagePreview(props) {
  const {
    src,
    height,
    alt,
    loading,
    imageClasses,
    formats,
    link,
    graphic,
    open,
    close,
    previewIsOpen,
    ratio,
  } = props

  const previewImage = (
    <img
      src={src}
      height={height}
      alt={alt}
      loading={loading}
      className={imageClasses}
    />
  )

  const LinkTag = link.external ? "a" : Link

  const linkWrapper = link.href ? (
    <LinkTag
      href={link.href}
      to={!link.external ? link.href : null}
      className={link.class}
      style={{ aspectRatio: ratio }}
    >
      {previewImage}
    </LinkTag>
  ) : (
    <div style={{ aspectRatio: ratio }}>{previewImage}</div>
  )

  return (
    <>
      {linkWrapper}
      <Button
        color="player-control"
        className="graphic-preview-control p-1"
        style={{ aspectRatio: formats.length == 1 ? "1 / 1" : null }}
        onClick={open}
      >
        {formats.length > 1 && <MultiformatIcon formats={formats} />}
        {formats[0].extension == "mp4" && <Play size="20" />}
        {formats[0].extension != "mp4" && formats.length == 1 && (
          <Maximize2 size="20" />
        )}
      </Button>

      <PreviewModal graphic={graphic} isOpen={previewIsOpen} close={close} />
    </>
  )
}
