import React, { Fragment, useContext } from 'react'
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { store } from '../../stores/uploads'
import categoryToTitle from '../../lib/categoryToTitle'

export default function UploadsBreadcrumbs() {
  const { folder, category, breadcrumbs } = useContext(store)

  const navigate = useNavigate()

  const handleNavigation = (folder) => {
    if (folder) {
      navigate(`/${category}/${folder.id}`)
    } else {
      navigate(`/${category}`)
    }
  }

  const breadcrumbForFolder = (f) => {
    const active = f == folder || (f && folder == f.id)
    const name = f == null ? categoryToTitle(category) : f.attributes.name
    const key = f == null ? null : f.id
    return (
      <BreadcrumbItem
        key={key}
        active={active}>
        {active ? <Fragment>{name}</Fragment> : (
          <Button
            color="link"
            className="team-color"
            onClick={() => handleNavigation(f)}>
            {name}
          </Button>
        )}
      </BreadcrumbItem>
    )
  }

  const breadcrumbElements = breadcrumbs.map(f => breadcrumbForFolder(f))
  breadcrumbElements.unshift(breadcrumbForFolder(null))

  return (
    <Breadcrumb listClassName="mb-0">
      {breadcrumbElements}
    </Breadcrumb>
  )
}
