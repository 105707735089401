import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

export default function TextAreaField(props) {
  const [value, setValue] = useState(props.field.value == null ? '' : props.field.value)
  const { field_name, field_id, placeholder, maxlength } = props.field
  const minRows = props.field.min_rows || 2
  const maxRows = props.field.max_rows || 8

  const onChange = (e) => {
    const newValue = e.target.value
    setValue(newValue)

    if (props.onChange) {
      props.onChange(newValue)
    }
  }

  return (
    <TextareaAutosize
    className={`form-control producer-form-textarea ${props.className}`}
    id={field_id}
    name={field_name}
    placeholder={placeholder}
    maxRows={maxRows}
    minRows={minRows}
    maxLength={maxlength}
    value={value}
    onChange={onChange}
    />
  )
}
