import React, { useContext } from 'react'
import { Button, Col, Progress as ProgressBar, UncontrolledTooltip } from 'reactstrap'
import { UploadCloud } from 'react-feather'
import { store } from '../../stores/uploads'
import prettyBytes from 'pretty-bytes'
import categoryToTitle from '../../lib/categoryToTitle'

export default function UploadsProgress(props) {
  const { uploadProgress, category, currentFolderIsShared, canManageShared } = useContext(store)
  const { total, completed, percentage, totalBytes } = uploadProgress
  return (
    <>
      <Col xs="auto" className={`ps-0 ${props.className}`}>
        <span id="upload-button-wrapper">
          <Button
            size="sm"
            disabled={currentFolderIsShared && !canManageShared}
            onClick={props.openUploadDialog}
            color="link">
            <UploadCloud size={20} />
          </Button>
          <UncontrolledTooltip
            placement="top"
            target="upload-button-wrapper"
            className="text-balance"
            fade={false}
            delay={{hide: 0, show: 0}}>
              {currentFolderIsShared && !canManageShared ? 'Uploads unavailable in shared folders' : `Upload ${categoryToTitle(category)}…`}
          </UncontrolledTooltip>
        </span>
      </Col>
      { total == 0 ? null : (
        <>
          <Col xs={12} className="px-0 pt-2 order-10">
            <ProgressBar className="rounded-0 progress-sm" animated striped value={percentage} />
          </Col>
          <Col xs={12} className="position-relative bg-lightest mb-n2 rounded-bottom py-2 order-11">
            <strong>{percentage}%</strong> <span className="text-muted">of {prettyBytes(totalBytes)} uploaded: </span>
            <strong>{completed}</strong> of <strong>{total}</strong> complete
          </Col>
        </>
      )}
    </>
  )
}
