import classNames from 'classnames'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Facebook, Instagram } from 'react-feather'
import { Card,
         CardBody,
         CardHeader,
         Col,
         FormGroup,
         Input,
         Label,
         Row,
         InputGroup,
         InputGroupText } from 'reactstrap'
import { store } from '../../stores/share'
import SelectWithPhotoField from './SelectWithPhotoField'
import ShareFormatSelector from './ShareFormatSelector'
import AccountLockup from './AccountLockup'
import XLogo from '../shared/XLogo'

const accountTypes = {
  facebook: {
    label: 'Facebook',
    icon: Facebook,
    color: '#3b5998',
    max: 4000,
    supports_story: true,
    feed: 'Page',
  },
  twitter: {
    label: 'X (Twitter)',
    icon: XLogo,
    color: '#111111',
    max: 250,
    supports_story: false,
    feed: 'Feed',
  },
  instagram: {
    label: 'Instagram',
    icon: Instagram,
    color: '#e1306c',
    max: 500,
    supports_story: true,
    feed: 'Feed',
  },
}

const isStoryRatio = (ratio) => {
  const storyRatio = 1080.0 / 1920.0
  return ratio >= storyRatio - 0.1 && ratio <= storyRatio + 0.1
}

export default function SocialAccountsSelector({ type, onChange, repost = true, checked = true, error, share: incomingShare }) {
  const { graphic, shares, scheduled, facebookAccounts, twitterAccounts, instagramAccounts, socialAccountsNext} = useContext(store)
  const { formats } = graphic?.attributes ?? incomingShare?.graphic?.attributes ?? { formats: [] }

  const socialAccountsByType = {
    facebook: facebookAccounts,
    twitter: twitterAccounts,
    instagram: instagramAccounts,
  }

  const storyFormats = formats.filter(format => isStoryRatio(format.ratio))

  const defaultFormatsByType = {
    facebook: [...formats.filter(format => format.social.match(/facebook|all/)).slice(0, 4), ...storyFormats].filter(n => n),
    twitter: formats.filter(format => format.social.match(/twitter|all/)).slice(0, 4),
    instagram: [...formats.filter(format => format.social.match(/instagram|all/)).slice(0, 4), ...storyFormats].filter(n => n),
  }

  const multiFormat = formats.length > 1
  const [selectedFormatIds, setSelectedFormatIds] = useState(incomingShare && incomingShare[type] ? incomingShare[type].formats.map(format => format.id) : defaultFormatsByType[type].map(format => format.id))

  const socialAccounts = socialAccountsByType[type]

  const [enabled, setEnabled] = useState(checked)
  const [selectedAccountToPost, setSelectedAccountToPost] = useState(socialAccounts[0].id)
  const socialAccountsToRepost = socialAccounts.filter(account => account.id != selectedAccountToPost)
  const [selectedAccountsToRepost, setSelectedAccountsToRepost] = useState(repost ? socialAccountsToRepost.map(account => account.id) : null)

  const account = accountTypes[type]

  const share = incomingShare || {
    id: null,
    metadata: {
      formats: formats.filter(format => format.social.match(/twitter|all/)).slice(0, 4),
    },
    scheduled_at: null,
    graphic: graphic,
  }

  const headerClasses = classNames({
    'bg-white': enabled,
    'border-0': !enabled,
    'rounded-0': true,
    'p-0': true,
  })

  const accountToPostField = {
    name: 'account_to_post',
    options: socialAccounts.map(account => ({
      value: account.id,
      text: account.attributes.name,
      photo: account.attributes.avatar,
    })),
    value: selectedAccountToPost,
  }

  const showHeadersAndRepost = socialAccountsNext && repost && accountToPostField.options.length > 1

  const toggleSelectedAccountToRepost = (accountId, checked) => {
    setSelectedAccountsToRepost(
      checked
        ? [...selectedAccountsToRepost, accountId]
        : selectedAccountsToRepost.filter(id => id != accountId)
    );
  }

  const selectAllRef = useRef()

  useEffect(() => {
    if (repost) {
      setSelectedAccountsToRepost(socialAccountsToRepost.map(account => account.id))
    }
  }, [selectedAccountToPost])

  useEffect(() => {
    setEnabled(checked)
  }, [checked, shares, scheduled])

  const runRepost = (selectedAccountsToRepost) => {
    if (repost) {
      if (selectAllRef.current != undefined) { // Prevents error when the component is first rendered
        const domElement = selectAllRef.current;
        if (selectedAccountsToRepost.length == 0) {
          domElement.checked = false
          domElement.indeterminate = false
        } else if (selectedAccountsToRepost.length == socialAccountsToRepost.length) {
          domElement.checked = true
          domElement.indeterminate = false
        } else {
          domElement.checked = true
          domElement.indeterminate = -1
        }
      }
    }
  }

  const onSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectedAccountsToRepost(
      checked
        ? socialAccountsToRepost.map(account => account.id)
        : []
    );
  }

  useEffect(() => {
    // Initiates multiple account reposting checked state
    runRepost(selectedAccountsToRepost)

    if (onChange) {
      if (enabled) {
        // The runRepost is needed to update the selectAllRef.current.checked value
        runRepost(selectedAccountsToRepost)
        onChange({
          label: account.label,
          canPost: multiFormat ? selectedFormatIds.length > 0 : true,
          isEnabled: enabled,
          formats: formats.filter(format => selectedFormatIds.includes(format.id)),
          post: socialAccounts.filter(account => account.id == selectedAccountToPost)[0],
          repost: repost ? socialAccounts.filter(account => selectedAccountsToRepost.includes(account.id)) : null,
          maxLength: account.max,
        });
      } else {
        onChange({
          canPost: !enabled,
          label: account.label,
          isEnabled: enabled,
        });
      }
    }
  }, [enabled, selectedAccountToPost, selectedAccountsToRepost, selectedFormatIds]);

  const storyFormatSelected = selectedFormatIds.some(id => storyFormats.map(format => format.id).includes(id))
  const nonStoryFormatSelected = selectedFormatIds.some(id => !(storyFormats.map(format => format.id).includes(id)))

  return (
    <Card className="rounded-3 mb-3 overflow-hidden">
      <CardHeader className={headerClasses}>
        <Row className="align-items-stretch">
          <Col xs="12" md="auto" className="flex-grow-1">
            <InputGroup size="lg" className="h-100">
              <InputGroupText className={`border-0 border-end rounded-0`}>
                <Input type="checkbox" name={type} id={type} checked={enabled} className='m-auto' onChange={(e) => setEnabled(e.target.checked)} />
              </InputGroupText>
              <h2 className="all-unset">
                <Label htmlFor={type} className="m-0 ms-2 h-100 d-flex gap-2 align-items-center">
                  <account.icon size="20" color={enabled ? account.color : '#888'} />
                  <span className="rem-1 m-0 py-2">
                    { account.label }&nbsp;
                    {account.supports_story && storyFormatSelected && nonStoryFormatSelected && `${account.feed} and Story`}
                    {account.supports_story && storyFormatSelected && !nonStoryFormatSelected && 'Story'}
                    {(!account.supports_story || (!storyFormatSelected && nonStoryFormatSelected)) && account.feed}
                  </span>
                </Label>
              </h2>
            </InputGroup>
          </Col>
          { multiFormat && (
            <Col xs="12" md="auto" className="border-top border-top-md-0">
              <div className={`d-flex m-2 ${!enabled ? 'opacity-50 pe-none' : null}`}>
                <ShareFormatSelector
                  share={share}
                  selectedFormatIds={selectedFormatIds}
                  onChange={setSelectedFormatIds}
                  max={4}
                  accountType={type}
                  tabIndex={ (enabled ? 0 : -1 ) }
                  hasError={error} />
              </div>
            </Col>
          )}
        </Row>
      </CardHeader>
      {enabled && (
        <CardBody>
          {showHeadersAndRepost ?
            (
              <InputGroup size="lg">
                <InputGroupText htmlFor={`post-to-${type}`} tag="label" className="mb-0 rounded-start-1">
                  <span className="fs-6">Post to account</span>
                </InputGroupText>
                <div className="form-floating d-flex">
                  <SelectWithPhotoField dropdownId={`post-to-${type}`} field={accountToPostField} onChange={(value) => setSelectedAccountToPost(value)} />
                </div>
              </InputGroup>
            ) :
            <AccountLockup accountImage={accountToPostField.options[0].photo} accountText={accountToPostField.options[0].text} />
          }
          {showHeadersAndRepost && (
            <div className="mt-3">
              <FormGroup check>
              <input type="checkbox"
                      name={type}
                      className="form-check-input"
                      id={`post-to-accounts${type}`}
                      ref={selectAllRef}
                      onChange={onSelectAllChange} />
                <Label id={`post-to-${type}`} htmlFor={`post-to-accounts${type}`} aria-live="polite">
                  Repost from {selectedAccountsToRepost.length} {selectedAccountsToRepost.length == 1 ? 'account' : 'accounts'}
                </Label>
              </FormGroup>
              <Row>
                { socialAccountsToRepost.map((account, key) => (
                  <Col xs="12" sm="6" xxl="4" key={key}>
                    <FormGroup check key={`account-${account.id}`}>
                      <Input type="checkbox" name={type} id={`account-${account.id}`} checked={selectedAccountsToRepost.includes(account.id)} onChange={(e) => toggleSelectedAccountToRepost(account.id, e.target.checked)} />
                      <Label htmlFor={`account-${account.id}`} className="ms-2">
                        <AccountLockup accountImage={account.attributes.avatar} accountText={account.attributes.name} />
                      </Label>
                    </FormGroup>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </CardBody>
      )}
    </Card>
  )
}
