import React, { Fragment } from 'react'
import { Button } from 'reactstrap'

export default function ProducerStatus(props) {
  const { hideUpdatePreview } = props.previewState
  const { saving, message: savingMessage } = props.formState
  const generating = props.formats.some(format => format.generating)
  const overTime = props.formats.some(format => format.overTime)

  const updatePreviewButton = !saving && !hideUpdatePreview ? (
    <Button color="primary" className="producer-update-preview-button" onClick={props.requestPreview} disabled={generating}>
      Update Preview
    </Button>
  ) : null

  const overTimeWarning = generating && overTime ? (
    <div className="row text-center w-100 justify-content-center align-items-center">
      <div className="col-12 col-md-auto">
        Preview seems to be taking a while…
      </div>
      <div className="col-12 col-md-auto ps-md-0">
        <Button color="link" className="p-0 team-color d-inline" onClick={props.cancelPreview}>
          Cancel and try again?
        </Button>
      </div>
    </div>
  ) : null

  const savingStatus = saving ? (
    <Fragment>
      <div className="spinner spinner-sm me-2"></div>
      {savingMessage}
    </Fragment>
  ) : null

  return (
    <div className="producer-status">
      {overTimeWarning || savingStatus || updatePreviewButton}
    </div>
  )
}


