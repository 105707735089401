import React, { useState } from 'react'
import { Label, Badge, UncontrolledTooltip } from 'reactstrap'

import TextField from './TextField'
import TextAreaField from './TextAreaField'
import UploadField from './UploadField'
import ColorField from './ColorField'
import FieldNotImplemented from './FieldNotImplemented'
import GroupField from './GroupField'
import MultiLineGroupField from './MultiLineGroupField'
import SelectField from './SelectField'
import HeaderField from './HeaderField'
import SetField from './SetField'
import RepeatableField from './RepeatableField'
import PlacementField from './PlacementField'
import XmlStreamSelection from './XmlStreamSelection'
import SelectWithPhotoField from './SelectWithPhotoField'
import PlayerField from './PlayerField'
import OpponentField from './OpponentField'
import FontField from './FontField'
import DateTimeField from './DateTimeField'
import LimitedSponsor from './LimitedSponsor'

const hideSubtextForTypes = ['header', 'upload', 'set']

export default function Field(props) {
  const {
    show_title, title, field_id, key, admin, has_xml_stream_selector,
    admin_select, admin_option, admin_option_field_name, subtext, show_campaign_overrides, sponsorKeys
  } = props.field

  const [adminOptionOn, setAdminOptionOn] = useState(props.field.admin_option_on)
  const [campaignOverrideOn, setCampaignOverrideOn] = useState(props.field.campaign_override_on)

  const type = props.type || props.field.type

  const showSubtext = !hideSubtextForTypes.includes(type) && subtext


  const hasSponsorKey = sponsorKeys != undefined && sponsorKeys.includes(props.field.key);
  const isSelfSignUpTrialLogo = props.selfSignUpTrial && hasSponsorKey;

  const componentForField = (field) => {
    switch (type) {
      case 'hidden':
        return <input type="hidden" name={field.field_name} value={field.value} />
      case 'text':
        return <TextField {...props} />
      case 'text_area':
        return <TextAreaField {...props} />
      case 'upload':
        return isSelfSignUpTrialLogo ? <LimitedSponsor {...props} /> : <UploadField {...props} />
      case 'color':
        return <ColorField {...props} />
      case 'group':
        return <GroupField {...props} />
      case 'multiline_group':
        return <MultiLineGroupField {...props} />
      case 'set':
        return <SetField {...props} />
      case 'repeatable':
        return <RepeatableField {...props} />
      case 'select':
        return <SelectField {...props} options={field.options} />
      case 'font':
        return <FontField {...props} fonts={field.fonts} />
      case 'header':
        return <HeaderField {...props} title={field.title} subtext={field.subtext} />
      case 'placement':
        return <PlacementField {...props} />
      case 'player':
        return <PlayerField {...props} />
      case 'opponent':
        return <OpponentField {...props} />
      case 'select_with_photo':
        return <SelectWithPhotoField {...props} options={field.options} />
      case 'datetime':
        return <DateTimeField {...props} />
      default:
        return <FieldNotImplemented {...props} />
    }
  }

  const toggleAdminOption = () => {
    setAdminOptionOn(prev => !prev)
  }

  const toggleCampaignOverride = () => {
    setCampaignOverrideOn(prev => !prev)
  }

  const adminOptionElements = admin_option ? (
    <Badge
      size="sm"
      onClick={toggleAdminOption}
      color={adminOptionOn ? 'primary' : 'light'}
      className={`d-block mb-1 text-start cursor-pointer ${adminOptionOn ? 'text-white' : 'text-muted'}`}>
      {adminOptionOn ? 'Admin only' : 'Editable'}
      {adminOptionOn ? <input type="hidden" name={admin_option_field_name} value="yes" /> : null}
    </Badge>
  ) : null

  const campaignOverrideElements = show_campaign_overrides ? (
    <Badge
      size="sm"
      onClick={toggleCampaignOverride}
      color={campaignOverrideOn ? 'dark' : 'light'}
      className={`d-block mb-1 text-start cursor-pointer ${campaignOverrideOn ? 'text-white' : 'text-muted'}`}>
      Override HomeTown Autofill: {campaignOverrideOn ? 'Yes' : 'No'}
      <input type="hidden" name="campaign_component[campaign_overrides][]" value={campaignOverrideOn ? key : ''} />
    </Badge>
  ) : null

  const fieldElement = componentForField(props.field)

  return type == 'hidden' ? fieldElement : (
    <div className="form-group" key={`group-${key}`}>
      {show_title ? (
        <Label for={field_id} className="producer-label">
          {title}
          {admin ? <Badge size="sm" color="primary" className="ms-1">AD</Badge> : null}
          {admin_select ? <Badge size="sm" color="primary" className="ms-1">AS</Badge> : null}
          { isSelfSignUpTrialLogo ?
            <>
              <Badge size="sm" color="primary" className="ms-1 badge-primary" id={`field-${key}`}>UNLOCK</Badge>
              <UncontrolledTooltip placement="right" target={`field-${key}`}>
                <div className="popover-body">
                  This field is locked while in trial mode. To use your own sponsor logos, subscribe today!
                </div>
              </UncontrolledTooltip>
            </>
            : null}
        </Label>
      ) : null}
      {adminOptionElements}
      {campaignOverrideElements}
      {has_xml_stream_selector ? <XmlStreamSelection {...props} customField={fieldElement} /> : fieldElement}
      {showSubtext ? (
        <small className="text-muted d-block mt-1">{subtext}</small>
      ) : null}
    </div>
  )
}
