const selectItems = (action, state) => {
  const keys = action.items.map(item => `${item.type}:${item.id}`)
  const currentSelected = state.selected
  const categorySelections = currentSelected[state.category]
  const newSelections = action.state ? [...new Set([...categorySelections, ...keys])] : categorySelections.filter(key => keys.indexOf(key) === -1)
  
  return {
    ...state,
    selected: {
      ...currentSelected,
      [state.category]: newSelections,
    }
  }
}

export { selectItems }
