import React, { Fragment, useContext } from 'react'
import classNames from 'classnames'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap'
import { store } from '../../stores/templates'
import TemplateFeatureModal from './TemplateFeatureModal'
import TemplateAddMultipleModal from './TemplateAddMultipleModal'
import TemplateRemoveMultipleModal from './TemplateRemoveMultipleModal'

export default function TemplateManager(props) {  
  const { 
    isAdmin, 
    hasSubscriptionAccess, 
    canManageTemplates,
    availableForSubscription,
  } = props

  const { 
    loading: loadingId, 
    featureModalOpen,
    templates,
    addedFeatures,
    firstTemplate,
    addMultipleModal,
    removeMultipleModal,
    dispatch, 
    actions,
  } = useContext(store)

  const template = templates.find(t => t.id == props.template.id)
  const { active, activeForAny, activeForAll } = template.attributes

  const loading = loadingId == template.id

  const handleAdd = () => {
    dispatch({ type: actions.ADD_TEMPLATE, template: template })
  }

  const handleRemove = () => {
    dispatch({ type: actions.REMOVE_TEMPLATE, template: template })
  }

  const handleAddToMultiple = () => {
    dispatch({ type: actions.OPEN_ADD_MULTIPLE_MODAL, template: template })
  }

  const handleRemoveFromMultiple = () => {
    dispatch({ type: actions.OPEN_REMOVE_MULTIPLE_MODAL, template: template })
  }

  const closeFeatureModal = () => {
    dispatch({ type: actions.CLOSE_FEATURES_MODAL })
  }

  const closeAddMultipleModal = () => {
    dispatch({ type: actions.CLOSE_ADD_MULTIPLE_MODAL })
  }

  const closeRemoveMultipleModal = () => {
    dispatch({ type: actions.CLOSE_REMOVE_MULTIPLE_MODAL })
  }

  const buttonClasses = classNames([
    ...props.buttonClasses,
    { 'btn': true },
    { 'team-color': true },
    { 'dropdown-toggle': props.isAdmin },
    { 'text-muted': !availableForSubscription },
    { 'disabled': loading || !props.canManageTemplates || !props.hasSubscriptionAccess },
  ])

  const buttons = isAdmin ? (
    hasSubscriptionAccess ? (
      availableForSubscription ? (
        <UncontrolledDropdown>
          <DropdownToggle caret color={null} className={buttonClasses}>
            { activeForAll ? 'Remove' : (active || activeForAny ? 'Manage' : 'Add template') }
          </DropdownToggle>
          <DropdownMenu end={true}>
            {active ? <DropdownItem onClick={handleRemove}>Remove from this team</DropdownItem> : <DropdownItem onClick={handleAdd}>Add to this team</DropdownItem>}
            {activeForAll ? <DropdownItem onClick={handleRemoveFromMultiple}>Remove from multiple teams</DropdownItem> : <DropdownItem onClick={handleAddToMultiple}>Add to multiple teams</DropdownItem>}
            {activeForAny && !activeForAll ? <DropdownItem onClick={handleRemoveFromMultiple}>Remove from multiple teams</DropdownItem> : null}
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <Fragment>
          <div id={`add-template-button-${template.id}`}>
            <div className={buttonClasses}>Add template</div>
          </div>
          <UncontrolledTooltip fade={false} delay={{ hide: 0, show: 0 }} placement="bottom" target={`add-template-button-${template.id}`}>
            You must upgrade your subscription to add this template
          </UncontrolledTooltip>
        </Fragment>
      )
    ) : (
      <Fragment>
        <div id={`add-template-button-${template.id}`}>
          <div className={buttonClasses}>Add template</div>
        </div>
        <UncontrolledTooltip fade={false} delay={{ hide: 0, show: 0 }} placement="bottom" target={`add-template-button-${template.id}`}>
          You must upgrade your subscription to add this template
        </UncontrolledTooltip>
      </Fragment>
    )
  ) : (
    active ? (
      canManageTemplates ? (
        <button className={buttonClasses} onClick={handleRemove} disabled={loading}>Remove</button>
      ) : (
        <Fragment>
          <div id={`remove-button-${template.id}`}>
            <div className={buttonClasses}>Remove</div>
          </div>
          <UncontrolledTooltip fade={false} delay={{ hide: 0, show: 0 }} placement="bottom" target={`remove-button-${template.id}`}>
            Must be an organization admin or be given template permissions to remove a template
          </UncontrolledTooltip>
        </Fragment>
      )
    ) : (
      hasSubscriptionAccess ? (
        availableForSubscription ? (
          canManageTemplates ? (
            <button className={buttonClasses} onClick={handleAdd} disabled={loading}>Add to Team</button>
          ) : (
            <Fragment>
              <div id={`add-template-button-${template.id}`}>
                <div className={buttonClasses}>Add to Team</div>
              </div>
              <UncontrolledTooltip fade={false} delay={{ hide: 0, show: 0 }} placement="bottom" target={`add-template-button-${template.id}`}>
                Must be an organization admin or be given template permissions to add a template
              </UncontrolledTooltip>
            </Fragment>
          )
        ) : (
          <Fragment>
            <div id={`add-template-button-${template.id}`}>
              <div className={buttonClasses}>Add to Team</div>
            </div>
            <UncontrolledTooltip fade={false} delay={{ hide: 0, show: 0 }} placement="bottom" target={`add-template-button-${template.id}`}>
              You must upgrade your subscription to add this template
            </UncontrolledTooltip>
          </Fragment>
        )
      ) : (
        <Fragment>
          <div id={`add-template-button-${template.id}`}>
            <div className={buttonClasses}>Add to Team</div>
          </div>
          <UncontrolledTooltip fade={false} delay={{ hide: 0, show: 0 }} placement="bottom" target={`add-template-button-${template.id}`}>
            Subscribe to a plan to add a template and begin creating graphics
          </UncontrolledTooltip>
        </Fragment>
      )
    )
  )

  return (
    <Fragment>
      {buttons}
      <TemplateFeatureModal
        open={featureModalOpen == template.id}
        addedFeatures={addedFeatures}
        firstTemplate={firstTemplate}
        toggle={closeFeatureModal} />
      <TemplateAddMultipleModal
        template={template}
        open={addMultipleModal == template.id}
        toggle={closeAddMultipleModal} />
      <TemplateRemoveMultipleModal
        template={template}
        open={removeMultipleModal == template.id}
        toggle={closeRemoveMultipleModal} />
    </Fragment>
  )
}
