import React, { Fragment } from 'react'
import { useParams } from 'react-router'
import Template from '../Template'
import { templateLink } from '../../lib/templates'

export default function TemplatePreviews(props) {
  const {
    templates,
    hasSubscriptionAccess,
    canManageTemplates,
    basename,
    isAdmin,
    subscriptionTier,
    selectedTemplates,
    onSelect
  } = props

  const { plan: routedPlan } = useParams()

  return (
    <Fragment>
      {templates.length == 0 ? (
        <div className="card card-empty">
          <div className="card-body">
            No templates to display
          </div>
        </div>
      ) : (
        <div className="graphic-container">
          {templates
            .map(template => (
              <Template
                key={`template:${template.id}:${template.attributes.active}`}
                isAdmin={isAdmin}
                template={template}
                templateLink={templateLink(template, basename, routedPlan, hasSubscriptionAccess, subscriptionTier, canManageTemplates)}
                availableForSubscription={subscriptionTier >= template.attributes.tier}
                hasSubscriptionAccess={hasSubscriptionAccess}
                canManageTemplates={canManageTemplates}
                selected={selectedTemplates?.includes(template.id)}
                onSelect={onSelect} />
          ))}
        </div>
      )}
    </Fragment>
  )
}
