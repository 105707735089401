import React, { useState, useContext, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, Button, ModalHeader, Form, Label, FormGroup, Input, ListGroup, ListGroupItem } from 'reactstrap'
import { store } from '../../stores/templates'

export default function TemplateRemoveMultipleModal(props) {
  const { open, toggle, template } = props
  const { teams, loading: loadingId, actions, dispatch } = useContext(store)

  const loading = loadingId == template.id

  const [teamsToRemove, setTeamsToRemove] = useState([])
  
  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch({ 
      type: actions.REMOVE_TEMPLATE, 
      template: template, 
      teams: teamsToRemove, 
    })
  }

  useEffect(() => {
    setTeamsToRemove(
      teams.filter(t => template.attributes.activeTeamIds.includes(t.id)).map(t => t.id)
    )
  }, [open])

  const handleTeamCheck = (e) => {
    const id = parseInt(e.target.dataset.team)
    const checked = e.target.checked
    
    if (checked) {
      setTeamsToRemove(prev => [...prev, id])
    } else {
      setTeamsToRemove(prev => prev.filter(t => t != id))
    }
  }

  return (
    <Modal isOpen={open} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} tag="h3">
        Remove from multiple teams
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <ListGroup className="modal-list-group">
            {teams.map(t => {
              const added = template.attributes.activeTeamIds.includes(t.id)
              return added ? (
                <ListGroupItem key={`team:${t.id}`} className="position-relative">
                  <FormGroup className="position-static" check>
                    <Input id={`team-check-${t.id}`} data-team={t.id} type="checkbox" checked={teamsToRemove.includes(t.id)} onChange={handleTeamCheck} />
                    <Label for={`team-check-${t.id}`} className="d-block stretched-link cursor-pointer" check>
                      {t.name} 
                    </Label>
                  </FormGroup>
                </ListGroupItem>
              ) : null
            })}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={loading}>
            Remove from {teamsToRemove.length} team{teamsToRemove.length != 1 ? 's' : null}
          </Button>
          <Button color="outline-secondary" onClick={toggle} disabled={loading}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
