import React, { useState, useEffect } from "react"
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  UncontrolledTooltip,
} from "reactstrap"
import {
  Calendar,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Trash2,
} from "react-feather"
import HomeTownLogo from "../shared/HomeTownLogo"
import GraphicList from "./GraphicList"
import pluralize from "../../lib/pluralize"
import { format } from "date-fns"

const GraphicShares = ({ graphic, share, scheduled }) => {
  const graphicText =
    graphic > 0 ? (
      `${graphic} ${pluralize(graphic, "graphic")}`
    ) : (
      <em className="text-secondary">No graphics created</em>
    )
  const shareText =
    share > 0 ? (
      <>{share} shared</>
    ) : (
      <>
        <em>No shared graphics</em>
      </>
    )
  const scheduledText = scheduled > 0 ? <>, {scheduled} scheduled</> : null

  return (
    <div>
      <div>{graphicText}</div>
      {graphic > 0 && (
        <div className="text-secondary">
          {graphic > 0 && shareText}
          {graphic > 0 && scheduledText}
        </div>
      )}
    </div>
  )
}

const getTotalSharesCount = (graphics) => {
  if (!Array.isArray(graphics)) return { shared: 0, scheduled: 0 }

  // Filter out graphics with discarded_at not set to null
  const validGraphics = graphics.filter(
    (graphic) => graphic.discarded_at === null
  )

  const counts = validGraphics.reduce(
    (counts, graphic) => {
      if (graphic.shares) {
        // Collect all share IDs to be excluded
        const excludeIds = graphic.shares.reduce((ids, share) => {
          if (share.result && share.result.share_ids) {
            return ids.concat(share.result.share_ids)
          }
          return ids
        }, [])

        // Filter out shares with IDs in excludeIds
        const validShares = graphic.shares.filter(
          (share) => !excludeIds.includes(share.id)
        )

        counts.shared += validShares.length
      }
      if (graphic.assets && graphic.assets.attributes) {
        const attributes = graphic.assets.attributes
        if (attributes.scheduled_share_count) {
          counts.scheduled += attributes.scheduled_share_count
        }
      }
      return counts
    },
    { shared: 0, scheduled: 0 }
  )

  // Adjust the shared to be shared - scheduled
  counts.shared = counts.shared - counts.scheduled
  return counts
}

const EventCard = (props) => {
  const {
    event,
    selectedEvents,
    handleSelectEvent,
    openGraphicModal,
    openPreviewModal,
    closePreviewModal,
    previewOpen,
    openDeleteModal,
    isOrg,
    isMinLg,
    isMinSm,
    isMaxLg,
    isMaxSm,
  } = props

  const [isGraphicListOpen, setIsGraphicListOpen] = useState(false)
  const [mobileToggle, setMobileToggle] = useState(false)
  const graphicsCount = event.graphics ? event.graphics.length : 0
  const { shared: sharesCount, scheduled: scheduledCount } =
    getTotalSharesCount(event.graphics)

  const editPath = `/${event.org_slug}${event.team_slug ? `/${event.team_slug}` : '' }/events/${event.id}/edit`

  useEffect(() => {
    if (graphicsCount == 0) {
      setIsGraphicListOpen(false)
    }
  }, [graphicsCount])

  const graphicListProps = {
    orgSlug: event.org_slug,
    teamSlug: event.team_slug,
    graphics: event.graphics,
    openPreviewModal,
    closePreviewModal,
    previewOpen,
    getTotalSharesCount,
    openDeleteGraphicModal: props.openDeleteGraphicModal,
  }

  const eventTimeStatus = () => {
    switch (event.time_status) {
      case "to_be_determined":
        return "TBD"
      case "all_day":
        return "All Day"
      default:
        return event.starts_at
          ? format(new Date(event.starts_at), "h:mmaaa")
          : ""
    }
  }

  const handleMobileToggle = () => {
    if (isMaxLg) {
      setMobileToggle(!mobileToggle)
    } else {
      setMobileToggle(false)
    }
  }

  useEffect(() => {
    if (!isMinLg) {
      setIsGraphicListOpen(false)
    }
    if (!isMaxLg) {
      setMobileToggle(false)
    }
  }, [isMinLg, isMaxLg])

  const graphicSharesProps = {
    graphic: graphicsCount,
    share: sharesCount,
    scheduled: scheduledCount,
  }

  return (
    <div className="event-row">
      <div className={isOrg ? 'event-row__card with-org' : 'event-row__card'}>
        <div className="d-flex gap-2 align-items-start">
          <div>
            <Label htmlFor={`checkbox_${event.id}`} className="visually-hidden">
              Select {event.name} for bulk changes
            </Label>
            <Input
              type="checkbox"
              id={`checkbox_${event.id}`}
              className="mt-1"
              checked={selectedEvents.includes(event.id)}
              onChange={() => handleSelectEvent(event.id)}
            />
          </div>
          {isMinLg && (
            <div id={`graphic-toggle-${event.id}`}>
              <Button
                color="link"
                size="sm"
                style={{ width: "24px", aspectRatio: "1 / 1" }}
                className={`p-0 m-0${graphicsCount === 0 ? "opacity-50" : ""}`}
                onClick={() => setIsGraphicListOpen(!isGraphicListOpen)}
                disabled={graphicsCount === 0}
              >
                {isGraphicListOpen ? (
                  <ChevronDown size={20} />
                ) : (
                  <ChevronRight size={20} />
                )}
              </Button>
              {graphicsCount === 0 && (
                <UncontrolledTooltip
                  fade={false}
                  placement="bottom"
                  size="sm"
                  target={`graphic-toggle-${event.id}`}
                >
                  No graphics created
                </UncontrolledTooltip>
              )}
            </div>
          )}
          <div>
            <h4 className="all-unset">
              <span className="fs-6 fw-bold">{event.name}</span>
            </h4>
            <div className="fs-8 text-secondary">
              {eventTimeStatus()} <span aria-label="at">@</span> {event.venue ? event.venue : <em className="text-muted">No venue</em>}
            </div>
          </div>
        </div>
        {isMinSm && (
          <div className="d-flex align-items-center">
            <GraphicShares {...graphicSharesProps} />
          </div>
        )}
        {isMinLg && isOrg && (
          <div className="d-flex gap-3 align-items-center">
            {event.team_name ? event.team_name : <em className="text-muted">No team</em>}
          </div>
        )}
        {isMinLg && (
          <div className="d-flex gap-3 align-items-center justify-content-end">
            <div className="me-3 text-secondary">
              {event.source === "ical" && <Calendar size={20} alt="Imported from an iCal URL" />}
              {event.source === "hometown" && <HomeTownLogo size={20} />}
            </div>
            <div id={`create-graphic-${event.id}`}>
              <Button
                size="sm"
                color="primary"
                onClick={() =>
                  openGraphicModal(
                    [event.id],
                    event.org_slug,
                    event.team_slug,
                    event.team_name,
                    "single"
                  )
                }
              >
                Create Graphic
              </Button>
            </div>
            <UncontrolledDropdown group>
              <Button
                color="outline-secondary"
                href={editPath}
                size="sm"
                className="d-flex align-items-center"
              >
                Edit
              </Button>
              <DropdownToggle
                size="sm"
                color="outline-secondary"
                className="px-1 rounded-end-2"
              >
                <ChevronDown size={16} className="dropdown-indicator" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  className="text-danger d-flex gap-2 align-items-center"
                  onClick={() => openDeleteModal([event.id])}
                >
                  <Trash2 size={16} /> Delete Event
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
        {isMaxLg && (
          <Button
            color="transparent"
            size="sm"
            className="p-1"
            onClick={() => handleMobileToggle()}
          >
            <span className="visually-hidden">Options for {event.name}</span>
            {mobileToggle ? (
              <ChevronUp className="text-muted" size={20} />
            ) : (
              <ChevronDown className="text-muted" size={20} />
            )}
          </Button>
        )}
      </div>
      {isMinLg && isGraphicListOpen && event.graphics && (
        <GraphicList {...graphicListProps} />
      )}
      {isMaxLg && mobileToggle && (
        <div className="mt-2 mt-sm-3 ms-4 d-flex flex-column gap-2 gap-sm-3 flex-lg-row align-items-sm-start">
          <div className="d-flex gap-2 order-sm-1 justify-content-end">
            <Button
              color="primary"
              className="flex-grow-1 flex-sm-grow-0"
              aria-label={`Create graphic for ${event.name}`}
              onClick={() =>
                openGraphicModal(
                  [event.id],
                  event.org_slug,
                  event.team_slug,
                  event.team_name,
                  "single"
                )
              }
            >
              Create Graphic
            </Button>
            <UncontrolledDropdown
              group
              className="flex-grow-1 d-flex flex-sm-grow-0"
              >
              <Button
                color="outline-secondary"
                href={editPath}
                className="d-flex align-items-center flex-grow-1 flex-sm-grow-0 w-100 w-sm-unset"
                aria-label={`Edit ${event.name}`}
              >
                Edit Event
              </Button>
              <DropdownToggle
                color="outline-secondary"
                className="px-1 rounded-end-2 flex-shrink-1 flex-sm-shrink-0"
              >
                <ChevronDown size="20" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  className="text-danger d-flex gap-2 align-items-center"
                  onClick={() => openDeleteModal([event.id])}
                  aria-label={`Delete ${event.name}`}
                >
                  <Trash2 size={16} /> Delete Event
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          {isMaxSm && (
            <div className="flex-sm-grow-1">
              <GraphicShares {...graphicSharesProps} />
            </div>
          )}
          {isMaxLg && event.graphics && (
            <div className="order-1 w-sm-100">
              <GraphicList {...graphicListProps} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default EventCard
