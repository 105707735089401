import React, { Fragment, useState } from "react"
import {
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap"
import { format } from "date-fns"
import pluralize from "../../lib/pluralize"
import GraphicImagePreview from "../shared/GraphicImagePreview"
import { ChevronDown, Edit, Trash2 } from "react-feather"

const getFilteredDates = (shares) => {
  // Collect all share IDs to be excluded
  const excludeIds = shares.reduce((ids, share) => {
    if (share.result && share.result.share_ids) {
      return ids.concat(share.result.share_ids)
    }
    return ids
  }, [])

  // Filter out shares with IDs in excludeIds
  const validShares = shares.filter((share) => !excludeIds.includes(share.id))

  // Separate performed_at and scheduled_at dates
  const performedDates = validShares.filter((share) => share.performed_at)
  const scheduledDates = validShares.filter(
    (share) => share.scheduled_at && !share.performed_at
  )

  return { performedDates, scheduledDates }
}

const formatDate = (date) => {
  return `${format(new Date(date), "MMMM do")} @ ${format(new Date(date), "h:mmaaa")}`
}

const renderDates = (graphic, dates, showAll, setShowAll) => {
  return (
    <Fragment>
      {dates.slice(0, 1).map((share, index) => (
        <div key={`${share.id}-${index}`}>
          <span className="fs-8 text-secondary">
            {formatDate(share.scheduled_at || share.performed_at)}
          </span>
          {index === 0 && dates.length > 1 && !showAll[graphic.id] && (
            <span className="fs-8 text-secondary">
              {" "}
              and{" "}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  setShowAll({
                    ...showAll,
                    [graphic.id]: true,
                  })
                }}
              >
                {`${dates.length - 1} more`}
              </a>
            </span>
          )}
        </div>
      ))}
      {showAll[graphic.id] && (
        <div className="fs-8 text-secondary">
          {dates.slice(1).map((share, index) => (
            <div key={`${share.id}-${index}`}>
              {formatDate(share.scheduled_at || share.performed_at)}
            </div>
          ))}
        </div>
      )}
    </Fragment>
  )
}

const GraphicButtonText = (scheduled, shared) => {
  if (scheduled > 0) {
    return "Edit Shares"
  } else if (shared > 0) {
    return "View Shares"
  } else {
    return "Create Share"
  }
}

const GraphicList = (props) => {
  const {
    graphics,
    openPreviewModal,
    closePreviewModal,
    previewOpen,
    getTotalSharesCount,
    openDeleteGraphicModal,
  } = props

  const [showAllPerformed, setShowAllPerformed] = useState({})
  const [showAllScheduled, setShowAllScheduled] = useState({})

  return (
    <div className="graphic-list bg-light border mt-lg-3 rounded-1">
      {graphics.map((graphic, index) => {
        const sharesCount = getTotalSharesCount([graphic])
        const { shared, scheduled } = sharesCount

        const assets = graphic.assets.attributes || []

        const graphicPath = `/${props.orgSlug}/${props.teamSlug}/graphics/${graphic.id}`
        const sharePath = `${graphicPath}/shares`

        const { performedDates, scheduledDates } = getFilteredDates(
          graphic.shares || []
        )

        return (
          <div
            className={`event-graphic-list ${index > 0 ? "border-top" : ""}`}
            key={`${graphic.id}-${index}`}
          >
            {assets.formats.map(
              (format, index, arr) =>
                index === 0 && (
                  <div
                    className="position-relative graphic-preview-item graphic-preview-item-jpg"
                    key={`format:${format.id}`}
                    style={{
                      aspectRatio: `${format.width} / ${format.height}`,
                      width: "200px",
                    }}
                  >
                    <GraphicImagePreview
                      src={assets.preview_urls[format.id]}
                      height={`${(format.height / format.width) * 200}`}
                      alt={assets.title}
                      loading={index > 0 ? "lazy" : "eager"}
                      ratio={`${format.width} / ${format.height}`}
                      formats={arr}
                      graphic={assets}
                      imageClasses="w-100 h-100 object-cover rounded-2"
                      open={() => openPreviewModal(assets.id)} // Wrap in arrow function
                      close={closePreviewModal}
                      previewIsOpen={previewOpen === assets.id} // Check if the current graphic is open
                      link=""
                    />
                  </div>
                )
            )}
            <div className="my-2 d-flex flex-column justify-content-center">
              <div className="fw-bold fs-6">{assets.title}</div>
              {scheduled > 0 && (
                <div>
                  {scheduled} {pluralize(scheduled, "scheduled share")}
                </div>
              )}
              <div>
                {scheduledDates.length > 0 && (
                  <Fragment>
                    {renderDates(
                      graphic,
                      scheduledDates,
                      showAllScheduled,
                      setShowAllScheduled
                    )}
                  </Fragment>
                )}
                {shared > 0 && (
                  <div className={scheduled > 0 ? "mt-2" : ""}>
                    {shared} {pluralize(shared, "share")}
                  </div>
                )}
                {performedDates.length > 0 && (
                  <Fragment>
                    {renderDates(
                      graphic,
                      performedDates,
                      showAllPerformed,
                      setShowAllPerformed
                    )}
                  </Fragment>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <UncontrolledDropdown group className="w-100 d-flex w-lg-unset">
                <Button
                  size="sm"
                  color="outline-secondary"
                  href={`${sharePath}?return_url=${window.location.href}`}
                  className="flex-grow-1"
                >
                  {GraphicButtonText(scheduled, shared)}
                </Button>
                <DropdownToggle
                  size="sm"
                  color="outline-secondary"
                  className="px-1 rounded-end-2 flex-shrink-1 flex-grow-0"
                >
                  <ChevronDown size="16" />
                </DropdownToggle>
                <DropdownMenu>
                  {shared == 0 && (<DropdownItem
                    id={`graphic-edit-${graphic.id}`}
                    className="d-flex gap-2 align-items-center"
                    onClick={() => window.location = `${graphicPath}?return_url=${window.location.href}`}
                  >
                    <Edit size={16} /> Edit Graphic
                  </DropdownItem>
                  )}
                  <DropdownItem
                    className="text-danger d-flex gap-2 align-items-center"
                    onClick={() => openDeleteGraphicModal(graphic, graphicPath)}
                  >
                    <Trash2 size={16} /> Delete Graphic
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default GraphicList
