import axios from '../../components/shared/axios'
import { actions } from '../uploads'
import { previewFilesForFolder } from './files'
import Fuse from 'fuse.js'

const fuseOptions = {
  keys: ['attributes.name'],
  threshold: 0.3,
}

const folderNeedsLoading = (state, action) => {
  if (action.category == 'opponentLogo') {
    return false
  }
  
  const subfolders = foldersInFolderForCategory(action.id, state.folders, action.category).map(f => f.id)
  const ids = [...subfolders, action.id]
  const needingLoaded = foldersToLoad(ids, state, action)

  return needingLoaded.length > 0
}

const foldersInFolderForCategory = (id, folders, category) => {
  return folders.filter(f => f.attributes.parent_id == id && f.attributes.category == category)
}

const foldersToLoad = (ids, state, action) => {
  return ids.map(id => {
    if (id == null) {
      return state.rootLoaded.includes(action.category) ? false : null
    } else {
      const folder = state.folders.find(f => f.id == id)
      return folder.loaded ? false : folder.id
    }
  }).filter(id => id !== false)
}

const displayFolders = ({folder, folders, category, uploads, filter}) => {
  const foldersToDisplay = foldersInFolderForCategory(folder, folders, category).map(f => {
    const subfolderCount = foldersInFolderForCategory(f.id, folders, category).length
    const previewsToDisplay = subfolderCount >= 9 ? 0 : 9 - subfolderCount

    return {
      ...f,
      subfolderCount: subfolderCount,
      previews: previewFilesForFolder(f, uploads).slice(0, previewsToDisplay),
    }
  }).sort((a, b) => (
    (a.attributes.name > b.attributes.name) ? 1 : -1
  ))

  if (filter) {
    const fuse = new Fuse(foldersToDisplay, fuseOptions)
    return fuse.search(filter).map(f => f.item)
  } else {
    return foldersToDisplay
  }
}

const addFolder = async (state, action, dispatch) => {
  const { data } = await axios.post(`${state.basepath}/upload_folders.json`, action.formData)
  const folder = data.data
  dispatch({ type: actions.FOLDER_CREATED, folder: folder })
  dispatch({ type: actions.REDIRECT, url: `/${state.category}/${folder.id}` })
}

const renameFolder = async (state, action, dispatch) => {
  const response = await axios.put(`${action.shared ? state.orgBasepath : state.basepath}/upload_folders/${action.folder_id}.json`, action.formData)
    .then(response => {
        return response;
      })
      .catch(error => {
        throw error;
    });
  const { data } = response;
  const folder = data.data
  dispatch({ type: actions.FOLDER_RENAMED, folder: folder })
}

const loadParent = (folder, folders) => {
  return folders.find(f => f.id == folder.attributes.parent_id)
}

const currentFolder = (state) => {
  return state.folders.find(f => f.id == state.folder)
}

const currentFolderIsShared = (state) => {
  return (currentFolder(state) != undefined) ? currentFolder(state).attributes.shared : false
}

const displayBreadcrumbs = ({folder, folders}) => {
  if (folder == null) return []
  const folderObject = folders.find(f => f.id == folder)

  const breadcrumbs = [folderObject]
  let parent = loadParent(folderObject, folders)
  while (parent) {
    breadcrumbs.unshift(parent)
    parent = loadParent(parent, folders)
  }

  return breadcrumbs
}

export { 
  foldersInFolderForCategory, 
  foldersToLoad, 
  folderNeedsLoading, 
  displayFolders, 
  displayBreadcrumbs, 
  addFolder, 
  renameFolder,
  currentFolder,
  currentFolderIsShared,
}
