import React, { useState, useEffect } from 'react'
import useDimensions from 'react-use-dimensions'
import {
  Col,
  Label,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'

// TODO: remove this after Aug 2025, which is when the last old single-post share is scheduled in production
export default function ShareFormatSelectorLegacy(props) {
  const { max, share } = props
  const formats = share.graphic.attributes.formats
  const [selectedFormatIds, setSelectedFormatIds] = useState(props.selectedFormatIds)
  const [ref, { width }] = useDimensions()
  const previewUrls = share.graphic.attributes.preview_urls

  // TODO: Copy over key controls from CustomSelectField useEffect handleKeyDown

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedFormatIds)
    }
  }, [selectedFormatIds])

  const addFormatId = (id) => {
    setSelectedFormatIds(prev => {
      return formats.map(format => {
        return prev.includes(format.id) || format.id == id ? format.id : null
      }).filter(item => item != null)
    })
  }

  const removeFormatId = (id) => {
    setSelectedFormatIds(prev => prev.filter(pid => pid != id))
  }

  const updateFormatIds = (e) => {
    const input = e.target
    if (input.checked) {
      addFormatId(input.name)
    } else {
      removeFormatId(input.name)
    }
    if (ref.current) {
      ref.current.focus()
    }
  }

  const joinSentence = (array, oxford_comma = true) => {
    let lastWord
    if( array.length > 1 ){
      lastWord = " and " + array.pop()
      if( oxford_comma && array.length > 1 ) {
        lastWord = "," + lastWord
      }
    } else {
      lastWord = ""
    }
    return array.join(", ") + lastWord
  }

  const selectedFormats = selectedFormatIds.map(id => formats.find(f => f.id == id))
  let selectedFormatNames = joinSentence(selectedFormats.map(format => format.title))

  if (selectedFormatIds.length == 0) {
    selectedFormatNames = 'No formats selected'
  } else if (selectedFormatIds.length == formats.length) {
    selectedFormatNames = 'All formats'
  } else {
    selectedFormatNames = 'Only ' + selectedFormatNames
  }

  const selectedFormatImages = selectedFormats.map(format => (
    <img src={previewUrls[format.id]} key={format.id} className="share-format-image" />
  ))

  return (
    <>
      {formats.length > 1 ? (
        <UncontrolledDropdown a11y={false} className={selectedFormats.length == 1 ? 'mb-3' : ''}>
          <DropdownToggle innerRef={ref} tag="button" className="form-control form-select form-select-upload text-truncate py-2 form-control-height-auto mx-0 w-100">
            {selectedFormatImages} {selectedFormatNames}
          </DropdownToggle>
          <DropdownMenu container="body" className="pt-3" modifiers={[{
            name: 'setMaxHeight',
            enabled: true,
            phase: 'write',
            order: 890,
            fn: (data) => {
              return {
                ...data.state,
                styles: {
                  ...data.state.styles,
                  popper: {
                    ...data.state.styles.popper,
                    overflow: 'auto',
                    width: `${Math.round(width)}px`,
                    maxHeight: '50vh',
                    zIndex: 1100,
                  }
                }
              };
            },
          }]}>
            {formats.map(format => {
              const checked = selectedFormatIds.includes(format.id)
              const atMax = max ? selectedFormatIds.length >= max : false
              const disabled = !checked && atMax

              return (
                <Label check key={`format:${format.id}`} className="d-block position-relative text-overflow-ellipsis mb-2 px-3">
                  <Row className="align-items-center">
                    <Col xs="auto" className="pe-2">
                      <input type="checkbox" onChange={updateFormatIds} name={format.id} checked={checked} disabled={disabled} />
                    </Col>
                    <Col xs="auto" className="px-0 share-format-preview">
                      <img src={previewUrls[format.id]} alt={format.title} />
                    </Col>
                    <Col className="ps-1">{format.title}</Col>
                  </Row>
                </Label>
              )
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : null}
    </>
  )
}
