import React, { useContext } from 'react'
import { X } from 'react-feather'
import pluralize from 'pluralize'
import { store } from '../../stores/uploads'
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

export default function UploadsShare() {
  const { 
    processing, 
    openModal, 
    dispatch, 
    actions, 
    modalData,
  } = useContext(store)

  const items = modalData.items || []
  const isOpen = openModal == actions.SHARE_ITEMS
  const allShared = items.every(item => item.attributes.shared)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    if (processing) return
    const formData = new FormData(e.target)
    if (allShared) {
      dispatch({ type: actions.UNSHARE_ITEMS, formData: formData })
    } else {
      dispatch({ type: actions.SHARE_ITEMS, formData: formData })
    }
  }

  const close = () => {
    if (!processing) {
      dispatch({ type: actions.TOGGLE_MODAL, modal: actions.SHARE_ITEMS })
    }
  }

  const headlineDescription = () => {
    if (items.length == 0) {
      return `empty set`
    }

    const firstItemDescription = items[0].type == 'upload' ? 'upload' : 'folder'
    const uploads = items.filter(item => item.type == 'upload')
    const folders = items.filter(item => item.type == 'upload_folder')

    if (items.length == 1) {
      return `this ${firstItemDescription}`
    } else {
      if (items.every(item => item.type == 'upload') ||
        items.every(item => item.type == 'upload_folder')) {
        return `these ${pluralize(firstItemDescription, items.length, true)}`
      } else {
        return `${pluralize('folder', folders.length, true)} and
          ${pluralize('upload', uploads.length, true)}`
      }
    }
  }

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  const itemIds = items.map(i => `${i.type}:${i.id}`).join(',')

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close} close={closeButton} tag="h3">
        Are you sure?
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <input type="hidden" name="ids" value={itemIds} />
        <ModalBody>
          {allShared ?
            `Unsharing will move ${headlineDescription()} to only this team.` :
            `Are you sure you want to share
              ${headlineDescription()} with every team?`
          }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={processing}>
            {allShared ? 'Unshare' : 'Share'}
          </Button>
          <Button color="outline-secondary" onClick={close} disabled={processing}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
