import React, { useState, Fragment, useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Button } from 'reactstrap'

export default function PlacementField(props) {
  const { formats } = props
  const { button_text, value, field_id, field_name } = props.field
  const { generating } = props.previewState
  const [open, setOpen] = useState(false)
  const buttonRef = useRef(null)
  const placementRef = useRef(null)

  const max = props.field.max || 200 // Setting 200 as an arbitrary max

  const [placements, setPlacements] = useState(
    value ? value.split(':').map((v) => v.split(',')) : []
  )

  const [placementUpdates, setPlacementUpdates] = useState([])

  const handleToggle = (e) => {
    if (!open) {
      setPlacementUpdates(placements)
    }
    e.preventDefault()
    e.stopPropagation()
    setOpen(!open)
  }

  const handleFormClick = (e) => {
    if (open && e.target.closest('.producer-form') && !buttonRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  useLayoutEffect(() => {
    const form = document.querySelector('.producer-form')
    if (open) {
      props.setPreviewState(prev => ({
        ...prev,
        mobilePreviewing: true,
        hideUpdatePreview: true,
        isPlacing: true,
      }))
      form.addEventListener('click', handleFormClick, false)
      return () => form.removeEventListener('click', handleFormClick);
    } else {
      props.setPreviewState(prev => ({
        ...prev,
        mobilePreviewing: false,
        hideUpdatePreview: false,
        isPlacing: false,
      }))
    }
  }, [open])

  const addPlacement = (e) => {
    const placementPosition = placementRef.current.getBoundingClientRect()
    const left = (e.clientX - placementPosition.left) / placementPosition.width
    const top = (e.clientY - placementPosition.top) / placementPosition.height
    setPlacementUpdates(prev => [...prev, [left, top]])
  }

  const handleClear = () => {
    setPlacementUpdates([])
  }

  const handleUndo = () => {
    setPlacementUpdates(prev => prev.slice(0, -1))
  }

  const handleSave = () => {
    setPlacements(placementUpdates.slice(-max))
    setOpen(false)
  }

  const underlay = open ? ReactDOM.createPortal((
    <div className="producer-content-underlay"></div>
  ), document.querySelector('.producer-content')) : null

  const placementDisplay = open ? ReactDOM.createPortal((
    <div ref={placementRef} className="producer-preview-placement" onClick={addPlacement}>
      {placementUpdates.slice(-max).map((placement, idx) => (
        <div key={`placement-${idx}`} className="producer-preview-placement-item" style={{
          left: `${placement[0] * 100}%`,
          top: `${placement[1] * 100}%`,
        }}></div>
      ))}
    </div>
  ), document.querySelector('.producer-preview-item')) : null

  const placementActions = open ? ReactDOM.createPortal((
    <Fragment>
      <Button color="white" className="mx-1" onClick={handleClear} disabled={!placementUpdates.length}>Clear All</Button>
      <Button color="white" className="mx-1" onClick={handleUndo} disabled={!placementUpdates.length}>Undo</Button>
      <Button color="primary" className="mx-1" onClick={handleSave}>Save</Button>
      <Button color="white" className="mx-1" onClick={handleToggle}>Cancel</Button>
    </Fragment>
  ), document.querySelector('.producer-status')) : null

  const placementValue = placements.map((placement) => placement.join(',')).join(':')
  const disabled = generating || !formats[0].loadedCurrentPreview

  return (
    <Fragment>
      <input type="hidden" id={field_id} name={field_name} value={placementValue} />
      <Button innerRef={buttonRef} outline block color="secondary" onClick={handleToggle} disabled={disabled}>
        {open ? 'Click on the Preview to Place…' : button_text}
      </Button>
      {underlay}
      {placementDisplay}
      {placementActions}
    </Fragment>
  )
}
