import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap'
import { X } from 'react-feather'
import axios from '../shared/axios'

export default function RecentColorDelete(props) {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    setLoading(true)
    setError(false)

    try {
      await axios.delete(
        `${props.basePath}/recent_colors/${props.colorToDelete.id}.json`,
        { data: formData }
      )

      props.setColors(prev => prev.filter(c => c.id != props.colorToDelete.id))
      props.closeModal()
      setError(false)
    } catch (e) {
      setError(true)
    }

    setLoading(false)
  }

  const closeButton = (
    <Button color="link" onClick={props.closeModal} className="p-0 text-muted">
      <X />
    </Button>
  )

  return (
    <Modal isOpen={props.isOpen} toggle={props.closeModal}>
      <ModalHeader toggle={props.closeModal} close={closeButton} tag="h3">
        Delete a color
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={props.colorToDelete.id || ''} />
        <ModalBody>
          {error ? (
            <div className="alert alert-danger mb-3">Something went wrong, try again later…</div>
          ) : null}
          Are you sure you want to delete {props.colorToDelete.color}?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" type="submit" disabled={loading}>{loading ? 'Deleting…' : 'Delete'}</Button>
          <Button color="outline-secondary" onClick={props.closeModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
