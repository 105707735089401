import React, { useState, useRef } from 'react'
import { Row, Col, Button, Form } from 'reactstrap'
import axios from '../shared/axios'
import ColorField from '../Fields/ColorField'

export default function RecentColorsHeader(props) {
  const [color, setColor] = useState('')
  const [loading, setLoading] = useState(false)
  const colorFieldRef = useRef()

  const handleSubmit = async (e) => {
    e.preventDefault()
    colorFieldRef.current.close()
    const formData = new FormData(e.target)

    setLoading(true)
    const response = await axios.post(
      `${props.basePath}/recent_colors.json`,
      formData
    )
    props.setColors(prev => [response.data, ...prev])
    colorFieldRef.current.clear()
    setColor('')
    setLoading(false)
  }

  const disabled = color.trim() == '' || loading
  const field = {
    field_name: 'recent_color[color]',
    field_id: 'recent_color',
    swatches: []
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="align-items-center mb-3">
        <Col xs="12" md="auto" className="me-auto mb-3 mb-md-0">
          <h1 className="mb-0">Color swatches</h1>
        </Col>

        {props.admin ? (
          <React.Fragment>
            <Col xs="12" md="auto" className="pe-md-0 mb-3 mb-md-0">
              <ColorField
                onChange={(c) => setColor(c)}
                field={field}
                inInputGroup={true}
                disabled={loading}
                ref={colorFieldRef} />
            </Col>
            <Col xs="12" md="auto">
              <Button
                color="primary"
                type="submit"
                block={true}
                disabled={disabled}>
                Add color
              </Button>
            </Col>
          </React.Fragment>
        ) : null}
      </Row>
    </Form>
  )
}
