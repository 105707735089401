import React, { useState, useRef, Fragment } from 'react'
import ProducerActions from './Producer/ProducerActions'
import ProducerPreview from './Producer/ProducerPreview'
import useDimensions from 'react-use-dimensions'
import classNames from 'classnames'
import ProducerPreviewFormats from './Producer/ProducerPreviewFormats'

export default function ProducerWithoutTemplate(props) {
  const [previewState, setPreviewState] = useState({
    url: props.previewUrl,
    generating: false,
    loadedCurrentPreview: false,
    mobilePreviewing: true,
  })

  const [previewsRef, { height: previewsHeight }] = useDimensions()

  const [admin, setAdmin] = useState(props.admin || {})

  const [formState, setFormState] = useState({
    saving: false,
  })

  const [selectedFormat, setSelectedFormat] = useState(null)

  const urls = props.urls || {}

  const [formats, setFormats] = useState(props.formats.map(format => ({
    ...format,
    uuid: null,
    oldUrl: null,
    url: urls[format.id],
    generating: false,
    anyLoaded: false,
    loadedCurrentPreview: false,
  })))

  const updateAllFormats = (newData) => {
    setFormats(prev => {
      return prev.map(format => ({
        ...format,
        ...newData,
      }))
    })
  }

  const updateFormat = (id, newData) => {
    setFormats(prev => {
      return prev.map(format => {
        if (format.id == id) {
          return {
            ...format,
            ...newData,
          }
        } else {
          return format
        }
      })
    })
  }

  const formatsRef = useRef(formats)

  const getFormatById = (id) => {
    return formatsRef.current.find(format => format.id == id)
  }

  const formClasses = classNames({
    'producer-form': true,
    'mobile-previewing': true,
  })

  const contentClasses = classNames({
    'producer-content': true,
    'mobile-previewing': true,
  })

  const sentProps = {
    formState,
    setFormState,
    previewState,
    setPreviewState,
    admin,
    setAdmin,
    setSelectedFormat,
    setFormats,
    updateFormat,
    updateAllFormats,
  }

  return (
    <Fragment>
      <div className={formClasses}>
        <div className="producer-form-container">
          <h2 className="mb-3 mt-1">Template Unavailable</h2>
          <div className="text-muted">
            The template for this graphic has been removed from this
            team. You can still view and share the graphic, but are
            no longer able to make edits or changes.
          </div>
        </div>
      </div>
      <div className={contentClasses}>
        <ProducerActions {...props} {...sentProps} noTemplate={true} />
        <div className="producer-previews" ref={previewsRef}>
          {selectedFormat ? (
            <ProducerPreview key={`format:${selectedFormat}`} format={getFormatById(selectedFormat)} {...props} {...sentProps} style={{ flexBasis: `${previewState.width}px`}} previewsHeight={previewsHeight} />
          ) : formats.map(format => (
            <ProducerPreview key={`format:${format.id}`} format={format} {...props} {...sentProps} style={{ flexBasis: `${previewState.width}px`}} previewsHeight={previewsHeight} />
          )) }
        </div>
        <ProducerPreviewFormats {...props} {...sentProps} />
        <div className="producer-status">
          <div className="d-block d-md-none">
            Template unavailable, you can only view and share this graphic
          </div>
        </div>
      </div>
    </Fragment>
  )
}
