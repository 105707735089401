import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap'
import { store } from '../../stores/share'
import { useContext } from 'react'

export default function ShareScheduler(props) {
  const { tz } = useContext(store)
  const isDisabled = props.disabled || false
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const [isScheduled, setIsScheduled] = useState(!!props.scheduleAt)
  const [scheduleDate, setScheduleDate] = useState(props.scheduleAt ? props.scheduleAt.date : tomorrow.toJSON().slice(0,10))
  const [scheduleTime, setScheduleTime] = useState(props.scheduleAt ? props.scheduleAt.time : '09:00')
  
  useEffect(() => {
    if (props.onChange) {
      props.onChange(isScheduled ? {
        date: scheduleDate,
        time: scheduleTime,
      } : null)
    }
  }, [scheduleDate, scheduleTime, isScheduled])


  return (
    <>
      <FormGroup check noMargin={true}>
        <Input type="checkbox" id="schedule" disabled={isDisabled} checked={isScheduled} onChange={e => setIsScheduled(e.target.checked)} />
        <Label for="schedule" check>Schedule for later?</Label>
      </FormGroup>
      {isScheduled ? (
        <div className="mt-2">
          <Row>
            <Col>
              <InputGroup>
                <Input
                  type="date"
                  className="form-control"
                  placeholder={new Date().toLocaleDateString()}
                  min={new Date().toJSON().slice(0,10)}
                  value={scheduleDate}
                  onChange={e => setScheduleDate(e.target.value)}
                  disabled={isDisabled}
                />
                <Input
                  disabled={isDisabled}
                  type="time"
                  className="form-control"
                  placeholder="9:00 AM"
                  value={scheduleTime}
                  onChange={e => setScheduleTime(e.target.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <small className="text-muted d-block mt-1">
            Date and time are in {tz}.&nbsp;
            <a href="/users/edit" target="_blank" className="text-dark">Change your timezone in your profile</a>.
          </small>
        </div>
      ) : null}
    </>
  )
}
