import React, { useContext, useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap'
import { store } from '../../stores/team_graphics'

export default function CampaignComponentMessage({ component }) {
  const [message, setMessage] = useState(component.attributes.evaluated_message)
  const [updating, setUpdating] = useState(false)
  const updatingRef = useRef(updating)
  const [buttonText, setButtonText] = useState('Update Post Text')
  const { updatingComponent, dispatch, actions } = useContext(store)

  const submitMessage = () => {
    dispatch({
      type: actions.UPDATE_COMPONENT,
      id: component.id,
      message: message,
    })
  }

  useEffect(() => {
    updatingRef.current = updating
  }, [updating])

  useEffect(() => {
    if (updatingComponent && updatingComponent == component.id) {
      setUpdating(true)
      setButtonText('Updating...')
    } else {
      if (updatingRef.current == true) {
        setButtonText('Post Text Updated!')

        setTimeout(() => {
          setButtonText('Update Post Text')
        }, 2000)
      }

      setUpdating(false)
    }
  }, [updatingComponent])

  const disabled = component.attributes.evaluated_message == message || updating

  return (
    <Card>
      <CardHeader>{component.attributes.description}</CardHeader>
      <CardBody>
        <TextareaAutosize
          className="form-control producer-form-textarea"
          value={message}
          onChange={(e) => setMessage(e.target.value)} />
      </CardBody>
      <CardFooter>
        <Button color="outline-secondary w-100" disabled={disabled} onClick={submitMessage}>{buttonText}</Button>
      </CardFooter>
    </Card>
  )
}
