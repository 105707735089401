import React, { useState, useEffect, useRef } from "react"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { ChevronDown } from "react-feather"

const DesignSystemSource = ({ onEnvChange }) => {
  const [selectedEnv, setSelectedEnv] = useState("prod")
  const hiddenInputRef = useRef(null)

  const assetEnvOptions = [
    { value: "prod", text: "Production" },
    { value: "dev", text: "Development" },
  ]

  const handleSelect = (value) => {
    setSelectedEnv(value)
    onEnvChange(value)
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = value
    }
  }

  useEffect(() => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = selectedEnv
    }
  }, [selectedEnv])

  return (
    <div className="mb-3 d-flex align-items-center gap-3">
      <label htmlFor="envDropdown" className="visually-hidden">
        Select Environment
      </label>
      <UncontrolledDropdown id="envDropdown" className="flex-grow-1">
        <DropdownToggle
          color="outline-secondary"
          className="w-100 d-flex align-items-center"
        >
          <div className="flex-grow-1 text-start text-black">
            {assetEnvOptions.find((option) => option.value === selectedEnv)
              ?.text || "Select Environment"}{" "}
            <span className="fw-normal">Design System</span>
          </div>
          <ChevronDown size={16} />
        </DropdownToggle>
        <DropdownMenu className="w-100">
          {assetEnvOptions.map((option) => (
            <DropdownItem
              className="d-flex justify-content-between align-items-baseline"
              key={option.value}
              onClick={() => handleSelect(option.value)}
            >
              <span className="fw-bold">{option.text}</span>
              <span className="text-muted" style={{ fontSize: "12px" }}>
                e.g.{" "}
                <code style={{ fontSize: "10px", padding: "2px" }}>
                  utils
                  {option.text === "Development" && <>-dev</>}.js
                </code>
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <input
        type="hidden"
        id="template_fields-env"
        name="template_fields[env]"
        ref={hiddenInputRef}
      />
    </div>
  )
}

export default DesignSystemSource
