import React, { useState, Fragment } from 'react'
import { Input } from 'reactstrap'

export default function TextField(props) {
  const [value, setValue] = useState(props.field.value == null ? '' : props.field.value)
  const [error, setError] = useState(false)
  const disabled = props.field.disabled || false
  const { field_name, field_id, placeholder, maxlength, format, format_error } = props.field

  const onChange = (e) => {
    const newValue = e.target.value
    setValue(newValue)

    if (props.onChange) {
      props.onChange(newValue)
    }
  }

  const onBlur = () => {
    if (format && value != '' && !value.match(new RegExp(format))) {
      setError(true)
    } else {
      setError(false)
    }
  }

  return (
    <Fragment>
      <Input
        type="text"
        id={field_id}
        name={field_name}
        placeholder={placeholder}
        maxLength={maxlength}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        className={error ? 'is-invalid' : ''}
        onChange={onChange} />
      {error  && format_error ? (
        <div className="invalid-feedback">{format_error}</div>
      ) : null}
    </Fragment>
  )
}
