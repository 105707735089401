import React, { memo, useMemo, Fragment, useContext, useState, useRef } from 'react'
import classNames from 'classnames'
import {
  Menu,
  X,
  Check,
  Play,
  Pause,
  Trash2,
  Settings,
  Edit,
  Folder,
  Download,
} from 'react-feather'
import { store } from '../../stores/uploads'
import { PinturaEditorModal } from '@pqina/react-pintura'

import {
  setPlugins,
  getEditorDefaults,
  createMarkupEditorShapeStyleControls,
  createMarkupEditorFontFamilyControl,
  createDefaultFontFamilyOptions,
  createDefaultFontStyleOptions,
  createMarkupEditorToolStyles,
  createMarkupEditorToolStyle,
  createMarkupEditorColorOptions,
  colorStringToColorArray,
} from '@pqina/pintura'

import { plugin_trim, plugin_trim_locale_en_gb } from '@pqina/pintura-video'

import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap'
import { beginUpload } from '../../lib/upload'

const iconSize = 16
const dropdownItemClasses = classNames(['d-flex', 'align-center', 'gap-2', 'lh-sm'])

function WrappedUpload(props) {
  const { canManageShared, openModal, actsAsMenu, uploadMenuState, processing, limitedColors, limitedFonts, basepath, dispatch, actions } = useContext(store)
  const { upload, keyString, opponentLogo } = props
  const videoRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const addedFonts = [['Hurricane', 'Signature'], ['Permanent Marker', 'Marker']]

  let fontFamilies, fontStyleOptions, colorOptions

  if (limitedFonts) {
    fontFamilies = [...addedFonts, ...limitedFonts.map(f => [f, f])]
    fontStyleOptions = []
  } else {
    fontFamilies = [...addedFonts, ...createDefaultFontFamilyOptions()]
    fontStyleOptions = createDefaultFontStyleOptions()
  }

  let shapeStyleControls

  if (limitedColors) {
    const customColors = {}
    limitedColors.forEach(color => customColors[color] = colorStringToColorArray(color))
    colorOptions = createMarkupEditorColorOptions(customColors)

    shapeStyleControls = createMarkupEditorShapeStyleControls({
      fontStyleOptions,
      colorOptions,
    })
  } else {
    shapeStyleControls = createMarkupEditorShapeStyleControls({
      fontStyleOptions,
    })
  }

  shapeStyleControls.fontFamily = createMarkupEditorFontFamilyControl(
    fontFamilies,
    { defaultKey: false }
  )

  if (upload?.attributes.video) {
    setPlugins(plugin_trim)
  }

  const editorDefaults = upload?.attributes.video ? getEditorDefaults({
    utils: ["trim", "filter", "finetune"],
    util: "trim",
    locale: {
      ...plugin_trim_locale_en_gb,
    },
  }) : getEditorDefaults({
    stickers: ['😀', '👍', '💥', '☝️', '🙌', '🏀', '🏈', '⚽️', '⚾️', '🥎', '🎾', '🏐'],
    utils: ["crop", "filter", "finetune", "decorate", "sticker", "frame", "redact", "resize"],
    locale: {
      labelButtonExport: 'Save and Upload',
    },
    markupEditorShapeStyleControls: shapeStyleControls,
    markupEditorToolStyles: createMarkupEditorToolStyles({
      text: createMarkupEditorToolStyle('text', {
        fontFamily: fontFamilies[0][0],
      })
    }),
  })

  const [editing, setEditing] = useState(false)

  const [newName, setNewName] = useState(upload ? upload.attributes.filename : '')

  const renameModalId = `renameUpload:${keyString}`
  const renameOpen = openModal == renameModalId

  const handleMove = () => {
    dispatch({ type: actions.TOGGLE_MODAL, modal: actions.MOVE_ITEMS, modalData: { items: [upload] }})
  }

  const handleDelete = () => {
    dispatch({ type: actions.TOGGLE_MODAL, modal: actions.DELETE_ITEMS, modalData: { items: [upload] }})
  }

  const openEditor = () => {
    setEditing(true)
  }

  const uploadEditedFile = (args) => {
    const { dest, imageState } = args
    if (upload?.attributes.video) {
      dispatch({
        type: actions.EDIT_VIDEO,
        id: upload.id,
        imageState: imageState,
      })
    } else {
      const files = [dest]
      beginUpload({ files, dispatch, actions, basepath, duplicateId: upload.id })
    }
  }

  const isSelectable = uploadMenuState && (!upload || upload.attributes.status == 'processed')

  const handleClick = () => {
    if (actsAsMenu && isSelectable && !editing) {

      uploadMenuState.onSelectUpload(upload)
    }
  }

  const canEditUpload = canManageShared || (upload && !upload.attributes.shared)
  const uploadId = upload ? upload.id : null
  const selectedUpload = uploadMenuState ? uploadMenuState.selectedUpload : null
  const selectedUploadId = selectedUpload ? selectedUpload.id : null

  const toggleRename = (e) => {
    e.preventDefault()
    dispatch({ type: actions.TOGGLE_MODAL, modal: renameModalId })
  }

  const handleRename = (e) => {
    e.preventDefault()
    if (processing) return
    const formData = new FormData(e.target)
    dispatch({ type: actions.RENAME_FILE, formData: formData, upload_id: upload.id, shared: upload.attributes.shared })
  }

  const setFocus = () => {
    const input = document.querySelector('#upload-filename')
    input.focus()
    input.select()
  }

  const closeButton = (
    <Button color="link" onClick={toggleRename} className="p-0 text-muted">
      <X />
    </Button>
  )

  const previewClasses = classNames({
    'upload-preview': true,
    'preview-checkered': true,
    'position-relative': true,
    'fw-bold': true,
    'cursor-pointer': isSelectable,
    'team-inset-outline': uploadMenuState && uploadId == selectedUploadId,
  })

  if (!props.upload) {
    return (
      <div className="upload">
        <div className={previewClasses} onClick={handleClick}>
          <X size={96} color="#aaa" />
        </div>
        <Row>
          <Col className="upload-name">
            <label className="upload-label">
              No image
            </label>
          </Col>
        </Row>
      </div>
    )
  }

  const playVideo = () => {
    videoRef.current.play()
    setPlaying(true)
  }


  const pauseVideo = () => {
    videoRef.current.pause()
    setPlaying(false)
  }

  const previewOrStatus = useMemo(() => {
    switch (upload.attributes.status) {
      case 'uploaded':
        return (
          <Fragment>
            Processing
            <div className="progress upload-progress-container">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                style={{ width: `${upload.attributes.progress || 0}%` }}>
              </div>
            </div>
          </Fragment>
        )
      case 'processed':
        return upload.attributes.video ? (
          <>
            <video ref={videoRef} className="preview" loop poster={upload.attributes.thumb_url}>
              <source src={upload.attributes.full_url} type="video/mp4" />
            </video>
            {playing ? (
              <Pause size="16" className="video-controls icon-muted" onClick={pauseVideo} />
            ) : (
              <Play size="16" className="video-controls icon-muted" onClick={playVideo} />
            )}
          </>
        ) : (
          <img
            className="preview"
            alt={upload.attributes.filename}
            src={upload.attributes.thumb_url} />
        )
      default:
        return (
          <Fragment>
            {upload.attributes.status_message}
          </Fragment>
        )
    }
  }, [upload.attributes.status == 'processed', upload.attributes.progress, playing])

  const canAdminShared = canManageShared || !upload.attributes.shared

  const RenameButton = () => { 
    if (!canManageShared && upload.attributes.shared) {
      return (
        <DropdownItem id={`disabled-rename-${upload.id}`} disabled={true} className={`${dropdownItemClasses} pe-auto`}>
          <Edit size={iconSize}/> Rename
          <UncontrolledTooltip fade={false} placement="right" target={`disabled-rename-${upload.id}`}>
            Only admins can rename shared uploads
          </UncontrolledTooltip>
        </DropdownItem>
      )
    } else {
      return (
        <DropdownItem onClick={toggleRename} className={dropdownItemClasses}>
          <Edit size={iconSize}/> Rename
        </DropdownItem>
      )
    }
  }

  return (
    <div className="upload">
      <div className={previewClasses} onClick={handleClick}>
        {upload.attributes.shared ? (
          <div className="upload-badges">
            <div className="badge bg-dark">Shared</div>
          </div>
        ) : null}
        {previewOrStatus}
      </div>
      <Row>
        {actsAsMenu || !canEditUpload ? null : (
          <Col xs="auto" className="upload-selection">
            <input
              type="checkbox"
              value={props.keyString}
              className="sized-checkbox"
              id={`upload-${upload.id}`}
              onChange={(e) => {
                dispatch({ type: actions.SELECT_ITEMS, items: [upload], state: e.target.checked })
              }}
              checked={props.selected} />
          </Col>
        )}
        <Col className="upload-name">
          <label
            className="upload-label"
            id={`upload-label-${upload.id}`}
            htmlFor={`upload-${upload.id}`}>
            {upload.attributes.filename}
          </label>
          {canEditUpload ? null : (
            <UncontrolledTooltip
              placement="top"
              target={`upload-label-${upload.id}`}>
              Only administrators can edit shared uploads
            </UncontrolledTooltip>
          )}
        </Col>
        {upload.attributes.graphic_uploads_count > 0 ? (
          <Col xs="auto" className="ps-1 me-n1 text-muted">
            <Check size="16" id={`upload-${upload.id}-used`} />
            <UncontrolledTooltip
              placement="top"
              target={`upload-${upload.id}-used`}>
              Used in {upload.attributes.graphic_uploads_count} graphic{upload.attributes.graphic_uploads_count != 1 ? 's' : ''}
            </UncontrolledTooltip>
          </Col>
        ) : null}
        {upload ? (
          <Col xs="auto" className="upload-menu">
            <UncontrolledDropdown>
              <DropdownToggle
                color="link"
                size="sm"
                className="px-1 py-0 mx-n1 text-muted">
                <Menu size="16" />
              </DropdownToggle>
              <DropdownMenu end={true} className="p-0">
                <DropdownItem
                  href={upload.attributes.download_url}
                  className={dropdownItemClasses}
                  download>
                    <Download size={iconSize} /> Download
                  </DropdownItem>
                { !opponentLogo && <RenameButton /> }
                { (!opponentLogo && canAdminShared) && <DropdownItem onClick={openEditor} className={dropdownItemClasses}><Settings size={iconSize}/> Edit {upload.attributes.video ? 'Video' : 'Image'}</DropdownItem> }
                { (!actsAsMenu && canAdminShared) && <DropdownItem onClick={handleMove} className={dropdownItemClasses}><Folder size={iconSize}/> Move</DropdownItem> }
                { (!actsAsMenu && canAdminShared) && <DropdownItem onClick={handleDelete} className={`${dropdownItemClasses} dropdown-item--danger`}><Trash2 size={iconSize}/> Delete</DropdownItem> }
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        ) : null}
      </Row>
      <Modal isOpen={renameOpen} toggle={toggleRename} size="sm" onOpened={setFocus}>
        <ModalHeader toggle={toggleRename} close={closeButton} tag="h3">
          Rename File
        </ModalHeader>
        <Form onSubmit={handleRename}>
          <ModalBody>
            <FormGroup>
              <Label for="upload-filename">File Name</Label>
              <Input
                type="text"
                id="upload-filename"
                name="upload[display_filename]"
                placeholder="empty.png"
                onChange={(e) => setNewName(e.target.value)}
                value={newName} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={processing}>
              Save
            </Button>
            <Button color="outline-secondary" onClick={toggleRename}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      {editing && (
        <PinturaEditorModal
          {...editorDefaults}
          src={upload.attributes.full_url}
          onHide={() => setEditing(false)}
          onProcess={uploadEditedFile}
        />
      )}
    </div>
  )
}

const UploadsFile = memo(WrappedUpload, (oldProps, newProps) => {
  if (oldProps.selected != newProps.selected) return false
  if (oldProps.upload != newProps.upload) return false
  return true
})

export default UploadsFile
