import React, { useContext, useEffect } from 'react'
import { generatePath, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { store } from '../../stores/templates'
import ProducerPreview from '../Producer/ProducerPreview'
import StickyBar from '../shared/StickyBar'
import TemplateManager from '../shared/TemplateManager'
import useDimensions from 'react-use-dimensions'
import TemplatesNav from './TemplatesNav'
import { templateLink } from '../../lib/templates'

export default function TemplateDetails() {
  const {
    templates,
    templateCategories,
    basename,
    subscriptionTier,
    canManageTemplates,
    hasSubscriptionAccess,
    isAdmin,
  } = useContext(store)
  

  
  const routeMatches = useParams()
  const { plan, category: categorySlug, template: id } = routeMatches
  
  const [previewsRef, { height: previewsHeight }] = useDimensions()

  const category = templateCategories.find(c => c.attributes.slug == categorySlug)
  const template = templates.find(t => t.id === routeMatches.template)

  const title = template?.attributes?.title ?? '';
  const tags = template?.attributes?.tags ?? [];

  useEffect(() => {
    document.title = `${title} | Template Library | Box Out Sports`
  }, [])

  return (
    <>
      <TemplatesNav active="library" />
      <StickyBar>
        <Col>
          <Link to={generatePath("/:plan", { plan })} className="badge bg-secondary text-capitalize me-1">{plan}</Link>
          {tags.length > 0 && tags.map(tag => (
            <Link key={`tag:${tag}`} to={generatePath("/:plan/tag/:tag", { plan, tag: tag.replace(' ', '-') })} className="badge bg-primary me-1">{tag}</Link>
          ))}
        </Col>
      </StickyBar>
      <Row className="align-items-center">
        <Col>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={generatePath('/:plan/:category', { plan: plan, category: category.attributes.slug })} className="team-color btn btn-link text-capitalize">
                  {category.attributes.name}
                </Link>
              </li>
              <li className="active breadcrumb-item text-transform-none" aria-current="page">
                {title}
              </li>
            </ol>
          </nav>
        </Col>
        <Col xs="12" md="auto" className="pe-md-2 pb-3">
          {canManageTemplates ? (
            <a href={templateLink(template, basename, plan, hasSubscriptionAccess, subscriptionTier, canManageTemplates)} className="btn btn-outline-secondary w-100">Try Template</a>
          ) : (
            <a href={generatePath(`${basename}/:plan/demo/:category/:template`, {
              plan: plan,
              category: categorySlug,
              template: id,
            })} className="btn btn-outline-secondary w-100">Demo Template</a>
          )}
          
        </Col>
        <Col xs="12" md="auto" className="ps-md-2 pb-3">
          <TemplateManager
            isAdmin={isAdmin}
            template={template}
            buttonClasses={['btn-primary', 'w-100']}
            availableForSubscription={subscriptionTier >= template.attributes.tier}
            addPath={generatePath(`${basename}/:plan/add/:category/:template.json`, {
              ...routeMatches,
              category: template.attributes.owner_slug,
              template: template.attributes.slug,
            })}
            removePath={generatePath(`${basename}/:plan/remove/:category/:template.json`, {
              ...routeMatches,
              category: template.attributes.owner_slug,
              template: template.attributes.slug,
            })}
            hasSubscriptionAccess={hasSubscriptionAccess}
            canManageTemplates={canManageTemplates} />
        </Col>
      </Row>
      <div className="producer-previews template-library-formats" ref={previewsRef}>
        {template.attributes.formats.map(format => (
          <ProducerPreview key={format.id} previewsHeight={previewsHeight} format={{
            ...format,
            url: template.attributes.full_urls[format.id],
            loadedCurrentPreview: true,
          }} />
        ))}
      </div>
    </>
  )
}
