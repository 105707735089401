import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Play, Pause } from 'react-feather'
import { Button } from 'reactstrap'

export default function GraphicVideoPreview(props) {
  const videoRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const { full_url, preview_url, width, className } = props

  const handlePlay = (e) => {
    e.preventDefault()
    setPlaying(!playing)
  }

  useEffect(() => {
    if (!videoRef.current) return
    if (playing) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [playing])

  return (
    <Fragment>
      <video loop muted playsInline
        controls={false}
        ref={videoRef}
        src={full_url}
        width={width}
        poster={preview_url}
        className={className}
        preload="none" />
      <Button color="player-control" className="graphic-preview-control" onClick={handlePlay}>
        {playing ? <Pause size="20" /> : <Play size="20" />}
      </Button>
    </Fragment>
  )
}
