import React, { Fragment, useContext, useEffect, useRef } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { Button, Col, UncontrolledTooltip } from 'reactstrap'
import { store } from '../../stores/uploads'

export default function UploadsPagination() {
  const { page, perPage, displayFiles, displayFolders, dispatch, actions } = useContext(store)
  const totalItems = displayFiles.length + displayFolders.length
  const pageRef = useRef(page)

  const start = (page - 1) * perPage + 1
  const end = Math.min(start + perPage - 1, totalItems)

  const handleKeydown = (e) => {
    if (e.target.tagName.toUpperCase() != 'INPUT' && e.target.type != 'checkbox') {
      if (e.keyCode == 37) handlePreviousPage()
      if (e.keyCode == 39) handleNextPage()
    }
  }

  useEffect(() => {
    pageRef.current = page
  }, [page])

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, false);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const handlePreviousPage = () => {
    if (pageRef.current > 1) {
      dispatch({
        type: actions.PAGINATE,
        page: pageRef.current - 1,
      })
    }
  }

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalItems / perPage)
    if (pageRef.current < totalPages) {
      dispatch({
        type: actions.PAGINATE,
        page: pageRef.current + 1,
      })
    }
  }

  return (
    <Fragment>
      <Col xs="auto" className="order-6 order-md-5">
        <span className="text-muted">
          {totalItems > 0 ? start : null}{totalItems > 1 ? `-${end}` : null}
          {end < totalItems ? ` of ${totalItems}` : null}
        </span>
      </Col>
      <Col xs="auto" className="ps-1 ps-md-0 pe-2 me-auto me-md-0 order-5 order-md-6">
        <Button
          id="previous-page"
          size="sm"
          color="link"
          onClick={handlePreviousPage}
          disabled={page == 1}>
          <ChevronLeft />
        </Button>
        { page == 1 ? null : (
          <UncontrolledTooltip
            placement="top"
            target="previous-page"
            fade={false}
            delay={{hide: 0, show: 0}}>
            Previous page
          </UncontrolledTooltip>
        )}
        <Button
          id="next-page"
          size="sm"
          color="link"
          onClick={handleNextPage}
          disabled={end == totalItems}>
          <ChevronRight />
        </Button>
        { end == totalItems ? null : (
          <UncontrolledTooltip
            placement="top"
            target="next-page"
            fade={false}
            delay={{hide: 0, show: 0}}>
            Next page
          </UncontrolledTooltip>
        )}
      </Col>
    </Fragment>
  )
}
