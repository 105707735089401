import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

export default function ConfirmationModal({
  isOpen,
  onCancel,
  onConfirm,
  title = "Are you sure?",
  message,
  confirmText = "Confirm",
  cancelText = "Cancel"
}) {
  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      <ModalHeader toggle={onCancel} tag="h3">
        {title}
      </ModalHeader>
      <ModalBody>
        <p dangerouslySetInnerHTML={{ __html: message }} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button color="danger" onClick={onConfirm}>
          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
