import React, { useState, useEffect, forwardRef, useImperativeHandle, Fragment } from 'react'
import { Input, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import BoxOutPicker from '../BoxOutPicker/BoxOutPicker'

export function RecentColorSwatch(props) {
  const classes = ['color-swatch']

  if (props.active) {
    classes.push('focus')
  }

  return (
    <div className={classes.join(' ')} onClick={props.onClick}>
      <div
        className="color-swatch-foreground"
        style={{ backgroundColor: props.color }} />
    </div>
  )
}

const ColorField = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.field.value == null ? '' : props.field.value)
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false)
    },
    clear() {
      setValue('')
    }
  }));

  const defaults = {
    placeholder: 'Color eg. #04fa72'
  }

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.className == 'dropdown-backdrop') {
        handleClose()
      } else {
        return
      }
    }

    if (open) {
      document.body.classList.add('dropdown-open')
      document.addEventListener('mousedown', handleClick, false)

      return () => {
        document.removeEventListener('mousedown', handleClick)
        document.body.classList.remove('dropdown-open')
      }
    }
  }, [open])

  const handleChange = (event) => {
    let value = event.target.value.replace(/[^\dabcdefABCDEF]/g, '')

    if (value != '' && value.slice(0, 1) != '#') {
      value = `#${value}`
    }

    setValue(value)

    if (props.onChange) {
      props.onChange(value)
    }
  }

  const handlePickerChange = (color) => {
    const value = color.hex == 'transparent' ? '' : color.hex
    setValue(value)

    if (props.onChange) {
      props.onChange(value)
    }
  }

  const handleOpen = (e) => {
    e.preventDefault()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const {
    field_name,
    field_id,
    placeholder,
    swatches,
    limit_to_swatches
  } = props.field

  const limitedInput = (
    <React.Fragment>
      <input
        type="hidden"
        id={field_id}
        name={field_name}
        value={value}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        onChange={handleChange.bind(this)} />
      <div onClick={handleOpen}
        className={`form-control has-swatch ${open ? 'focus' : ''}`}>
        <RecentColorSwatch color={value} onClick={handleOpen} />
      </div>
    </React.Fragment>
  )

  const fullInput = (
    <div className="form-group has-swatch mb-0">
      <Input
        type="text"
        id={field_id}
        name={field_name}
        placeholder={placeholder || defaults.placeholder}
        maxLength={7}
        className={open ? 'focus' : ''}
        value={value}
        onChange={handleChange}
        onFocus={handleOpen} />
      <RecentColorSwatch
        color={value}
        onClick={handleOpen} />
    </div>
  )

  return (
    <Fragment>
      {open ? (
        <div className="dropdown-backdrop"></div>
      ) : null}
      <Dropdown isOpen={open} toggle={handleOpen}>
        <DropdownToggle tag="div" data-bs-toggle="dropdown" aria-expanded={open}>
          {limit_to_swatches ? limitedInput : fullInput}
        </DropdownToggle>
        <DropdownMenu className="p-0 my-1" container="body">
          <BoxOutPicker
            color={value}
            onChange={handlePickerChange}
            presetColors={swatches}
            handleClose={handleClose}
            limitToSwatches={limit_to_swatches} />
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  )
});

ColorField.displayName = 'ColorField'

export default ColorField
