export const sortItems = (items, sortBy) => {
  return [...items].sort((a, b) => {
    // Sort folders first, then files
    if (a.type === 'upload_folder' && b.type === 'upload') return -1;
    if (a.type === 'upload' && b.type === 'upload_folder') return 1;
    
    // If comparing items of the same type, apply the selected sort
    if (a.type === b.type) {
      let nameA, nameB, nameC, nameD, dateA, dateB, dateC, dateD;
      
      switch (sortBy) {
        case 'alphabetical_asc':
          nameA = a.type === 'upload_folder' 
            ? a.attributes.name 
            : a.attributes.filename;
          nameB = b.type === 'upload_folder' 
            ? b.attributes.name 
            : b.attributes.filename;
          return (nameA || '').toLowerCase().localeCompare((nameB || '').toLowerCase());
        
        case 'alphabetical_desc':
          nameC = a.type === 'upload_folder' 
            ? a.attributes.name 
            : a.attributes.filename;
          nameD = b.type === 'upload_folder' 
            ? b.attributes.name 
            : b.attributes.filename;
          return (nameD || '').toLowerCase().localeCompare((nameC || '').toLowerCase());
        
        case 'date_asc':
          dateA = a.attributes.created_at || '';
          dateB = b.attributes.created_at || '';
          return dateA.localeCompare(dateB);
        
        case 'date_desc':
          dateC = a.attributes.created_at || '';
          dateD = b.attributes.created_at || '';
          return dateD.localeCompare(dateC);
        
        default:
          return 0;
      }
    }
    
    return 0;
  });
}; 