import React from 'react'
import Template from './Template'

export default function PublicTemplates(props) {
  return props.templates.data.map(template => (
    <Template 
      key={`template:${template.id}`} 
      public={true}
      template={template} 
      hideActions={true}
      externalLink={true}
      templateLink={props.link} />
  ))
}
