import React from 'react'
import { Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { ArrowDown, ArrowUp, ChevronDown, ChevronUp } from 'react-feather'

const sortOptions = [
  { value: 'alphabetical_asc', label: 'Alphabetical (A-Z)', text: "A-Z" },
  { value: 'alphabetical_desc', label: 'Alphabetical (Z-A)', text: "Z-A" },
  { value: 'date_asc', label: 'Date Created (Oldest)', text: "Oldest" },
  { value: 'date_desc', label: 'Date Created (Newest)', text: "Newest" }
]

const Icon = (props) => {
  if (props.icon === 'alphabetical_asc' || props.icon === 'date_asc') return <ArrowUp {...props} />
  if (props.icon === 'alphabetical_desc' || props.icon === 'date_desc') return <ArrowDown {...props} />
}

export default function UploadsSortDropdown(props) {
  const { sortBy, onSortChange } = props
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)
  
  const currentSort = sortOptions.find(option => option.value === sortBy) || { label: 'Sort by...', value: null }

  const setSort = (value) => {
    onSortChange(value)
    setDropdownOpen(false)
  }

  return (
    <div className="d-flex align-items-center w-100">
      <Label className="me-2 mb-0 w-100 flex-grow-1 flex-shrink-1 text-nowrap" htmlFor="sort-dropdown-toggle">Sort by</Label>
      <Dropdown id="sort-dropdown" isOpen={dropdownOpen} toggle={toggle} className="w-100 flex-grow-1 flex-shrink-1">
        <DropdownToggle 
          id="sort-dropdown-toggle"
          aria-label={currentSort.label} 
          color="outline-secondary" 
          size="sm" 
          className="d-flex align-items-center w-100"
        >
          <Icon size={16} icon={currentSort.value} /> 
          <span className="ms-1 text-nowrap">{currentSort.text}</span>
          {dropdownOpen ? <ChevronUp size={16} className="ms-1" /> : <ChevronDown size={16} className="ms-1" />}
        </DropdownToggle>
        <DropdownMenu>
          {sortOptions.map(option => (
            <DropdownItem 
              key={option.value}
              onClick={() => setSort(option.value)}
              aria-label={option.label}
              className="d-flex align-items-center"
            >
              <Icon size={16} icon={option.value} />
              <span className="ms-1">{option.text}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
} 