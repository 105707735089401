import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import axios from "../shared/axios"
import MultiformatIcon from "../shared/MultiformatIcon"
import { Play, Pause, X } from "react-feather"
import LoadingIndicator from "../shared/LoadingIndicator"

/** @constant previewSize
 *  @type {number}
 *  @description Defines the size of the template previews and spacing for hiding extra templates
 */
const previewSize = 128

const GraphicModal = (props) => {
  const { isOpen, toggle, eventIds, isOrg, singleOrMulti, owner, teams } = props
  const [currentTeam, setCurrentTeam] = useState(
    owner.type == "team" ? owner.id : teams[0].id
  )
  const [templates, setTemplates] = useState({})
  const [loading, setLoading] = useState(true)

  const currentTeamObject = teams.find((team) => team.id == currentTeam)

  useEffect(() => {
    if (isOpen && eventIds.length > 0) {
      if (templates[currentTeam] === undefined) {
        setLoading(true)
        axios
          .get(`${currentTeamObject.basepath}/events/templates.json`)
          .then((response) => {
            const data = response.data
            console.log(data)
            setTemplates({
              ...templates,
              [currentTeam]: data.templates.map((template) => template.data),
            })
          })
          .catch((error) => {
            console.error("Error loading templates:", error)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [isOpen, eventIds, currentTeam])

  useEffect(() => {
    // console.log("Templates state updated:", templates) // Debug log
  }, [templates])

  const currentTeamTemplates = templates[currentTeam] || []
  const templatesToDisplay = currentTeamTemplates.filter(
    (template) => template.attributes.features.events == singleOrMulti
  )

  const closeButton = (
    <Button color="link" onClick={toggle} className="p-0 text-muted">
      <X />
    </Button>
  )

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        close={closeButton}
        toggle={toggle}
        className="modal-header-segmented d-flex align-items-center"
        tag="div"
      >
        <Row className="align-items-center me-2">
          <Col>
            <h2 className="fst-normal fs-5 m-0">Select a Template</h2>
          </Col>
          {isOrg && (
            <Col xs="auto">
              <UncontrolledDropdown className="ms-auto">
                <DropdownToggle color="outline-secondary" caret>
                  <span className="fw-normal text-muted">Create in:</span>{" "}
                  {currentTeamObject.name}
                </DropdownToggle>
                <DropdownMenu end={true}>
                  {teams.map((team) => (
                    <DropdownItem
                      active={team.id == currentTeam}
                      onClick={() => setCurrentTeam(team.id)}
                    >
                      {team.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          )}
        </Row>
      </ModalHeader>
      <ModalBody>
        <LoadingIndicator loading={loading} title="Loading templates…" size="sm" className="mt-4 mb-4">
          {templatesToDisplay.length > 0 ? (
            <div className="d-flex me-n3 mb-n3 flex-wrap">
              {templatesToDisplay.map((template, index) => {
                const size =
                  template.attributes.extension == "png"
                    ? previewSize * 0.875
                    : previewSize
                const ratio = template.attributes.formats[0].ratio
                const isMultiFormat = template.attributes.formats.length > 1
                const isVideo = template.attributes.extension === "mp4"
                const height = size
                const width = Math.round(ratio * size)
                const imageWidth = Math.round(ratio * height)

                return (
                  <div key={`${index}-${template.id}`}>
                    <a
                      href={`${currentTeamObject.basepath}/graphics/new/${template.attributes.slug}?event_ids=${eventIds.join(",")}&return_url=${window.location.href}`}
                    >
                      <figure
                        className="template-item mb-3 me-3 text-center"
                        style={{ width: `${width}px` }}
                      >
                        <div
                          className={`template-item-${template.attributes.extension} mb-2 position-relative`}
                        >
                          {isMultiFormat && (
                            <div className="badge bg-dark position-absolute z-2 p-1 bottom-1 start-1">
                              <MultiformatIcon
                                size="14"
                                formats={template.attributes.formats}
                                strokeWidth="1"
                              />
                            </div>
                          )}
                          {isVideo && (
                            <div className="badge bg-dark position-absolute z-2 p-1 bottom-1 start-1">
                              <Play size="14" strokeWidth="1" />
                              <Pause size="14" strokeWidth="1" />
                            </div>
                          )}
                          <img
                            alt={template.attributes.title}
                            height={height}
                            width={imageWidth}
                            className="graphic-template-preview"
                            src={
                              Object.values(template.attributes.preview_urls)[0]
                            }
                          />
                        </div>
                      </figure>
                    </a>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="my-4 text-center">
              <h3 className="fs-5 fw-bold mt-0 mb-3 text-black fst-normal">
                No {singleOrMulti == "single" ? "Single" : "Multiple"} Event
                Templates Added
              </h3>
              <p style={{ textWrap: "balance" }}>
                There are no templates in this team that currently support
                {singleOrMulti == "single" ? "single" : "multiple"} events. You
                can explore event templates to add them to your team.
              </p>
              <Button
                className="text-nowrap"
                color="primary"
                href={`${currentTeamObject.basepath}/template-library/events`}
              >
                Explore Event Templates
              </Button>
            </div>
          )}
        </LoadingIndicator>
      </ModalBody>
      {!loading && templatesToDisplay.length > 0 && (
        <ModalFooter>
          <div className="d-flex gap-3 align-items-center w-100">
            <div className="flex-grow-1">
              <p className="m-0">
                These templates have been added to your team and support
                connecting events.
              </p>
              <p className="m-0">
                You can also explore more event templates to add more.
              </p>
            </div>
            <div>
              <Button
                className="text-nowrap"
                color="primary"
                href={`${currentTeamObject.basepath}/template-library/events`}
              >
                Explore Event Templates
              </Button>
            </div>
          </div>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default GraphicModal
