import React, { useContext, useEffect, useState } from "react"
import GraphicImagePreview from "./shared/GraphicImagePreview"
import { store } from "../stores/team_graphics"
import { StateProvider } from "../stores/share"
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"

import classNames from "classnames"
import {
  Menu,
  Trash2,
  Download,
  Share,
  Copy,
  Edit,
  Settings,
  Tool,
  Maximize2,
} from "react-feather"
import DeleteModalNext from "./graphics/DeleteModalNext"
import RenameModalNext from "./graphics/RenameModalNext"
import LoadingIndicator from "./shared/LoadingIndicator"
import ShareDownload from "./Share/ShareDownload"

export default function GraphicNext(props) {
  const {
    basename,
    renaming,
    deleting,
    hideShare,
    hideDelete,
    hideRename,
    hideDuplicate,
    hideEdit,
    hideMenu,
    showPrompts,
  } = useContext(store)
  const { graphic, unlinkGraphic } = props
  const showTitleAndActions =
    props.showTitleAndActions == null ? true : props.showTitleAndActions
  const extraGraphicClasses = props.graphicClasses || []
  const linkGraphic = !unlinkGraphic

  const editLink = `${basename}/${graphic.id}/edit`
  const shareLink = `${basename}/${graphic.id}/shares`
  const duplicateLink = `${basename}/new/${graphic.attributes.template_slug}?duplicate=${graphic.id}`
  const formats =
    props.formats == null ? graphic.attributes.formats : props.formats
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [renameOpen, setRenameOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const { title, shared, preview_urls, generating } = graphic.attributes

  const graphicClasses = classNames(["graphic", ...extraGraphicClasses])
  const imageClasses = classNames(["graphic-preview-image"])

  // Get the sizes to enforce text truncation on the title
  const [isTitleOverflowing, setIsTitleOverflowing] = useState(false)

  useEffect(() => {
    const element = document.getElementById(`graphicTitleFor-${graphic.id}`)
    if (element) {
      setIsTitleOverflowing(element.scrollWidth > element.clientWidth)
    }
  }, [title])

  const firstFormat = formats[0]

  const textWidth = () => {
    const type = firstFormat.extension
    const width = firstFormat.width
    const height = firstFormat.height
    const typeHeight = type === "png" ? 180 : 200

    // Return a square if it is a portrait orientation
    const ratio = width < height ? 1 : width / height

    return `calc(${typeHeight * ratio}px - (${iconSize}px + (2 * var(--space-2))) )`
  }

  // const [selectedFormatIndex, setSelectedFormatIndex] = useState(0)

  const iconSize = 16
  const dropdownItemClasses = classNames([
    "d-flex",
    "align-center",
    "gap-2",
    "lh-sm",
  ])

  const openDeleteModal = () => {
    setDeleteOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteOpen(false)
  }

  const openRenameModal = () => {
    setRenameOpen(true)
  }

  const closeRenameModal = () => {
    setRenameOpen(false)
  }

  const openDownloadModal = () => {
    setDownloadOpen(true)
  }

  const closeDownloadModal = () => {
    setDownloadOpen(false)
  }

  const openPreviewModal = () => {
    setPreviewOpen(true)
  }

  const closePreviewModal = () => {
    setPreviewOpen(false)
  }

  useEffect(() => {
    if (!renaming) {
      closeRenameModal()
    }
  }, [renaming])

  useEffect(() => {
    if (!deleting) {
      closeDeleteModal()
    }
  }, [deleting])

  const editButton = shared ? (
    <DropdownItem
      id="disabled-edit"
      className={`${dropdownItemClasses} text-muted`}
    >
      <Settings size={iconSize} /> Edit
      <UncontrolledTooltip
        fade={false}
        delay={{ hide: 0, show: 0 }}
        placement="top"
        target="disabled-edit"
      >
        You cannot edit a shared graphic, duplicate instead
      </UncontrolledTooltip>
    </DropdownItem>
  ) : (
    <DropdownItem tag="a" href={editLink} className={dropdownItemClasses}>
      <Settings size={iconSize} /> Edit
    </DropdownItem>
  )

  const linkData = {
    external: true,
    href: linkGraphic ? (shared ? shareLink : editLink) : false,
    class: "graphic-preview",
  }

  const previewElementsNext = formats.map(
    (format, index, arr) =>
      index === 0 && (
        <div
          className={`graphic-preview-item graphic-preview-item-${format.extension}${generating ? " graphic-preview-item-generating" : ""}`}
          key={`format:${format.id}`}
        >
          <GraphicImagePreview
            src={preview_urls[format.id]}
            height={format.extension == "png" ? 180 : 200}
            alt={title}
            loading={index > 0 ? "lazy" : "eager"}
            imageClasses={imageClasses}
            ratio={`${format.width} / ${format.height}`}
            formats={arr}
            graphic={graphic}
            open={openPreviewModal}
            close={closePreviewModal}
            previewIsOpen={previewOpen}
            link={linkData}
          />
        </div>
      )
  )

  const previewContainer = (
    <>
      <LoadingIndicator
        title="Generating…"
        loading={generating}
        color="dark"
        textColor="text-white"
      />
      <div className="graphic-preview-container">{previewElementsNext}</div>
    </>
  )

  return (
    <div className="graphic-react-wrapper">
      <div className={graphicClasses}>
        {previewContainer}
        {showTitleAndActions ? (
          <div className="graphic-details">
            <div
              className="graphic-details__title"
              id={`graphicTitleFor-${graphic.id}`}
              style={{ "--width": textWidth() }}
            >
              {title}
            </div>
            {isTitleOverflowing && (
              <UncontrolledTooltip
                target={`graphicTitleFor-${graphic.id}`}
                placement="bottom"
              >
                {title}
              </UncontrolledTooltip>
            )}
            {!hideMenu && (
              <div className="graphic-details__controls">
                <UncontrolledDropdown direction="up" a11y={false}>
                  <DropdownToggle color="link" className="p-2 m-n2 text-muted">
                    <Menu size="16" />
                  </DropdownToggle>
                  <DropdownMenu end={true} className="mx-3 p-0">
                    {!hideEdit && editButton}
                    {!hideShare && (
                      <DropdownItem
                        tag="a"
                        href={shareLink}
                        className={dropdownItemClasses}
                      >
                        <Share size={iconSize} /> Share
                      </DropdownItem>
                    )}
                    {!hideDuplicate && (
                      <DropdownItem
                        tag="a"
                        href={duplicateLink}
                        className={dropdownItemClasses}
                      >
                        <Copy size={iconSize} /> Duplicate
                      </DropdownItem>
                    )}
                    {!hideRename && (
                      <DropdownItem
                        onClick={openRenameModal}
                        className={dropdownItemClasses}
                      >
                        <Edit size={iconSize} /> Rename
                      </DropdownItem>
                    )}
                    {!hideRename && (
                      <DropdownItem
                        onClick={openPreviewModal}
                        className={dropdownItemClasses}
                      >
                        <Maximize2 size={iconSize} /> Preview
                      </DropdownItem>
                    )}
                    <DropdownItem
                      onClick={openDownloadModal}
                      className={dropdownItemClasses}
                    >
                      <Download size={iconSize} /> Download
                    </DropdownItem>
                    {!hideDelete && (
                      <DropdownItem
                        onClick={openDeleteModal}
                        className={`${dropdownItemClasses} dropdown-item--danger`}
                      >
                        <Trash2 size={iconSize} /> Delete
                      </DropdownItem>
                    )}

                    {showPrompts && (
                      <DropdownItem
                        tag="a"
                        href={`${basename}/${graphic.id}/share_prompts`}
                        className={dropdownItemClasses}
                      >
                        <Tool size={iconSize} /> Prompts
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}
          </div>
        ) : null}
        <DeleteModalNext
          graphic={graphic}
          isOpen={deleteOpen}
          close={closeDeleteModal}
        />
        <RenameModalNext
          graphic={graphic}
          isOpen={renameOpen}
          close={closeRenameModal}
        />
        <StateProvider
          graphic={graphic}
          sharepath={`${basename}/${graphic.id}/shares.json`}
        >
          <ShareDownload isOpen={downloadOpen} close={closeDownloadModal} />
        </StateProvider>
      </div>
    </div>
  )
}
