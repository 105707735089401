import axios from '../../components/shared/axios'
import { actions } from '../uploads'

const queryOpponentLogos = async (state, action, dispatch) => {
  const tdQuery = action.query.trim()
  
  if (state.queriedOpponentLogos[tdQuery] == undefined) {
    const { data } = await axios.get(`${state.basepath}/uploads/search_library.json`, {
      params: {
        query: tdQuery,
      }
    })

    dispatch({ type: actions.SET_OPPONENT_LOGOS_FOR_QUERY, query: tdQuery, opponentLogos: data.data })
  }
}

export { queryOpponentLogos }
