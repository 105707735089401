import React, { useContext, useState } from 'react'
import { X } from 'react-feather'
import pluralize from 'pluralize'
import categoryToTitle from '../../lib/categoryToTitle'
import { store } from '../../stores/uploads'
import {
  Alert,
  Button,
  Input,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

export default function UploadsMove() {
  const { 
    processing, 
    openModal, 
    dispatch, 
    actions, 
    modalData, 
    categories, 
    canManageShared, 
    folder: currentFolder,
    category: currentCategory,
    folders, 
  } = useContext(store)

  const [emptyValue, setEmptyValue] = useState(true)

  const items = modalData.items || []
  const isOpen = openModal == actions.MOVE_ITEMS
  
  const handleSubmit = (e) => {
    e.preventDefault()
    if (processing) return
    const formData = new FormData(e.target)
    
    dispatch({ type: actions.MOVE_ITEMS, formData: formData })
  }

  const close = () => {
    if (!processing) {
      dispatch({ type: actions.TOGGLE_MODAL, modal: actions.MOVE_ITEMS })
    }
  }

  function headlineDescription() {
    if (items.length == 0) {
      return `empty set`
    }
    const firstItemDescription = items[0].type == 'upload' ?
      'upload' : 'folder'
    const uploads = items.filter(item => item.type == 'upload')
    const folders = items.filter(item => item.type == 'upload_folder')

    if (items.length == 1) {
      return `this ${firstItemDescription}`
    } else {
      if (items.every(item => item.type == 'upload') ||
        items.every(item => item.type == 'upload_folder')) {
        return `these ${pluralize(firstItemDescription, items.length,
          true)}`
      } else {
        const folderText = pluralize('folder', folders.length, true)
        const uploadText = pluralize('upload', uploads.length, true)
        return `${folderText} and ${uploadText}`
      }
    }
  }

  const folderOptions = (category, id = null, indent = 1) => {
    const options = []

    const currentFolders = folders.filter(f => f.attributes.category == category && f.attributes.parent_id == id)
      .sort((a, b) => (
        (a.attributes.name > b.attributes.name) ? 1 : -1)
      )

    currentFolders.forEach(folder => {
      const folderIsSelected = items.map(f => f.type == 'upload_folder' ? f.id : null).includes(folder.id)
      const disabled = (hasSharedItems && !folder.attributes.shared) ||
        (folder.attributes.shared && !canManageShared) || 
        (folder.id == currentFolder && folder.attributes.category == currentCategory) ||
        folderIsSelected

      options.push(
        <option
          key={`folder:${folder.id}`}
          value={folder.id}
          disabled={disabled}>
          {[...Array(indent)].map(() => '\u00A0\u00A0\u00A0\u00A0')}
          {folder.attributes.name}{folder.attributes.shared ? ' (shared)' : null}
        </option>
      )
      if (!folderIsSelected) {
        options.push(folderOptions(category, folder.id, indent + 1))
      }
    })

    return options
  }

  function moveLocationOptions() {
    const options = [
      (
        <option key="empty" value="">Select a folder or category</option>
      )
    ]

    categories.forEach(category => {
      if (currentCategory == 'video' && category == 'video' || currentCategory != 'video' && category != 'video') {
        options.push(
          <option key={`category:${category}`} value={category}>
            {categoryToTitle(category)}
          </option>
        )
        options.push(folderOptions(category))
      }
    })

    return options
  }

  const itemIds = items.map(i => `${i.type}:${i.id}`).join(',')
  const hasSharedItems = items.some(item => item.attributes.shared)

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  const sharedMoveWarning = hasSharedItems ? (
    <Alert color="primary" className="mb-2 mt-1">
      Because you have selected shared items, your folder options have been
      limited to shared folders, or a separate category.
    </Alert>
  ) : null

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close} close={closeButton} tag="h3">
        Move
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <input type="hidden" name="ids" value={itemIds} />
        <ModalBody>
          <Label for="folder_or_category">
            Where would you like to move {headlineDescription()}?
          </Label>
          {sharedMoveWarning}
          <Input
            type="select"
            id="folder_or_category"
            onChange={(e) => setEmptyValue(e.target.value == '')}
            name="folder_or_category">
            {moveLocationOptions()}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={processing || emptyValue}>
            Move
          </Button>
          <Button color="outline-secondary" onClick={close} disabled={processing}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
