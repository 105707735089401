import React, { useContext, useRef } from 'react'
import { X } from 'react-feather'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { store } from '../../stores/share'
import LoadingIndicator from '../shared/LoadingIndicator'
import Pagination from '../shared/Pagination'
import ShareHistoryDownload from './History/ShareHistoryDownload'
import ShareHistoryEmail from './History/ShareHistoryEmail'
import ShareHistoryPost from './History/ShareHistoryPost'

export default function ShareHistory(props) {
  const { shares, hidePreviews, facebookAppId, instagramToken, sharesLoading, deletePostOpen, postDeleting, actions, dispatch } = useContext(store)
  const { title } = props
  const elementRef = useRef()

  const shareComponent = (share) => {
    switch (share.action) {
      case 'download':
      case 'multi_format_download':
        return <ShareHistoryDownload key={`share-${share.id}`} share={share} hidePreviews={hidePreviews} />
      case 'email':
        return <ShareHistoryEmail key={`share-${share.id}`} share={share} hidePreviews={hidePreviews} />
      case 'post':
      case 'story':
        return <ShareHistoryPost key={`share-${share.id}`} share={share} facebookAppId={facebookAppId} instagramToken={instagramToken} />
    }
  }

  const handlePagination = (page) => {
    elementRef.current?.scrollIntoView({ behavior: 'smooth' })
    dispatch({
      type: actions.LOAD_SHARES_PAGE,
      payload: page,
    })
  }

  const closeDelete = () => {
    if (!postDeleting) {
      dispatch({
        type: actions.CLOSE_DELETE_POST,
      })
    }
  }

  const closeButton = (
    <Button color="link" onClick={closeDelete} className="p-0 text-muted">
      <X />
    </Button>
  )

  const handleDelete = () => {
    dispatch({
      type: actions.DELETE_POST,
    })
  }

  return (
    <div ref={elementRef} className="mt-n3 pt-3">
      {title ? <h1 className="mb-3">{title}</h1> : null}
      <div className="position-relative">
        {shares.data.length == 0 ? (
          <div className="card card-empty mb-4">
            <div className="card-body">
              No shares have been posted yet
            </div>
          </div>
        ) : shares.data.map(share => shareComponent(share))
        }
        <LoadingIndicator color="gray" loading={sharesLoading} />
      </div>
      <Pagination currentPage={shares.currentPage} pageCount={shares.pageCount} onPageChange={handlePagination} />
      <Modal isOpen={deletePostOpen} toggle={closeDelete}>
        <ModalHeader toggle={closeDelete} close={closeButton} tag="h4" className="modal-header-segmented">
          Delete this Post?
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this post? This action cannot be&nbsp;undone.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete} disabled={postDeleting}>{postDeleting ? 'Deleting…' : 'Delete'}</Button>
          <Button color="outline-secondary" onClick={closeDelete} disabled={postDeleting}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
