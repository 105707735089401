import React, { useContext } from 'react'
import { Nav, NavItem } from 'reactstrap'
import categoryToTitle from '../../lib/categoryToTitle'
import { generatePath, useParams } from 'react-router'
import { store } from '../../stores/uploads'
import { Link } from 'react-router-dom'
import { categoryForParam } from '../../stores/uploads/categories'

export default function UploadsTabs() {
  const { categories, actsAsMenu, showOpponentLogos } = useContext(store)
  const { category: currentParam } = useParams()
  const currentCategory = categoryForParam(currentParam)

  return (
    <div className="bo-content-nav">
      <Nav className="nav-toptab">
        {categories.map(category => {
          return (
            <NavItem key={category}>
              <Link to={generatePath('/:category', { category })} className={`btn btn-link btn nav-link text-capitalize ${!showOpponentLogos && category == currentCategory ? 'active' : ''}`}>
                {categoryToTitle(category)}
              </Link>
            </NavItem>
          )
        })}
        {actsAsMenu && categories.includes('graphic') ? (
          <NavItem>
            <Link to={generatePath('/:category', { category: 'opponentLogo' })} className={`btn btn-link btn nav-link text-capitalize ${showOpponentLogos ? 'active' : ''}`}>
              Opponent Logos
            </Link>
          </NavItem>
        ) : null}
      </Nav>
    </div>
  )
}
