import React, { useState, Fragment } from 'react'
import RecentColorsHeader from './RecentColors/RecentColorsHeader'
import RecentColorsList from './RecentColors/RecentColorsList'

export default function RecentColors(props) {
  const [colors, setColors] = useState(props.colors.sort((a, b) => {
    const ad = new Date(a.created_at)
    const bd = new Date(b.created_at)
    return bd - ad
  }))

  return (
    <Fragment>
      <RecentColorsHeader {...props} setColors={setColors} />
      <RecentColorsList {...props} colors={colors} setColors={setColors} />
    </Fragment>
  )
}
