import React, { useState, useEffect } from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import SubscriptionForm from './Subscription/SubscriptionForm'

export default function Subscription(props) {
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    setStripePromise(loadStripe(props.stripeKey))
  }, [])

  const options = {
    fonts: [{
      cssSrc: 'https://fonts.googleapis.com/css?family=Lato:400,700&display=swap',
    }]
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <SubscriptionForm {...props} />
    </Elements>
  )
}
