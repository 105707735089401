import axios from "../../components/shared/axios"
import { actions } from "../share"
import { getScheduledPage } from "./pagination"

const deleteShare = async (state, dispatch) => {
  await axios.delete(`${state.teampath}/shares/${state.shareToDelete.id}`)
  const pageToLoad = state.scheduled.data.length == 1 ? Math.max(state.scheduled.currentPage - 1, 1) : state.scheduled.currentPage
  await getScheduledPage(pageToLoad, state, dispatch)
  dispatch({ type: actions.CLOSE_DELETE_SHARE })
}

export { deleteShare }
