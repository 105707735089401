import React from 'react'
import Template from './Template'

export default function GraphicSample(props) {
  return (
    <div className="graphic-sample">
      <Template 
        template={props.template.data} 
        externalLink={true}
        hideActions={true} />
    </div>
  )
}
