import React, { useState, Fragment } from 'react'
import SelectField from './SelectField'
import Field from './Field'

export default function SetField(props) {
  const { sets, selector_options, show_set_selector, selected_set_fields, subtext } = props.field

  if (show_set_selector) {
    const [value, setValue] = useState(props.field.value || Object.keys(sets)[0])

    const selectedSetFields = sets[value] || []
    return (
      <Fragment>
        <SelectField {...props} field={props.field} options={selector_options} onChange={(value) => setValue(value)} />
        {subtext ? (
          <small className="text-muted d-block mt-1">{subtext}</small>
        ) : null}
        <div className="producer-form-set">
          {selectedSetFields.map((field) => <Field {...props} key={field.key} field={field} />)}
        </div>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        {selected_set_fields.map((field) => <Field {...props} key={field.key} field={field} />)}
      </Fragment>
    )
  }

}
