import React, { useContext, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { store } from '../../stores/share'
import ShareDownloadMultiFormat from './ShareDownloadMultiFormat'
import ShareDownloadSingleFormat from './ShareDownloadSingleFormat'
import ShareFormatSelectorLegacy from './ShareFormatSelectorLegacy'

export default function ShareDownload(props) {
  const { close, isOpen } = props
  const { graphic, formats, downloadState, states, dispatch, actions } = useContext(store)
  const { title } = graphic.attributes
  const [metadata, setMetadata] = useState({})
  const [formatError, setFormatError] = useState(false)
  const [selectedFormatIds, setSelectedFormatIds] = useState(formats.map(format => format.id))

  const selectedFormats = formats.filter(format => selectedFormatIds.includes(format.id))
  const noFormatSelected = selectedFormatIds.length == 0

  const share = {
    graphic: graphic,
  }

  const dispatchDownload = () => {
    dispatch({
      type: selectedFormats.length == 1 ? actions.SHARE_DOWNLOAD : actions.SHARE_MULTI_FORMAT_DOWNLOAD,
      metadata: metadata,
    })
  }

  const DownloadComponent = selectedFormats.length == 1 ? ShareDownloadSingleFormat : ShareDownloadMultiFormat

  const noSelectedFormatsComponent = selectedFormats.length == 0 ? (
    <div className="text-center fw-bold text-warning mt-3">Select at least one format to download</div>
  ) : null

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  useEffect(() => {
    if (downloadState == states.complete) {
      handleClose()
    }
  }, [downloadState])

  const handleClose = () => {
    if (downloadState != states.processing) {
      dispatch({
        type: actions.SET_DOWNLOAD_STATE,
        payload: states.ready,
      })
      close()
    }
  }

  const disabled = downloadState == states.processing || formatError || noFormatSelected

  const buttonText = () => {
    if (downloadState == states.processing) {
      if (selectedFormats.length == 1) {
        return 'Downloading…'
      } else {
        return 'Preparing Files…'
      }
    } else if (noFormatSelected) {
      return 'Select at least one format'
    } else if (downloadState == states.complete) {
      return 'Downloading…'
    } else {
      return 'Download'
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose} close={closeButton} tag="div" className="modal-header-segmented align-items-center">
        <h2 className="h4 m-0">Download {title}</h2>
      </ModalHeader>
      <ModalBody>
        <ShareFormatSelectorLegacy share={share} selectedFormatIds={selectedFormatIds} onChange={setSelectedFormatIds} />
        <DownloadComponent
          formats={selectedFormats}
          setMetadata={setMetadata}
          setFormatError={setFormatError}
          state={downloadState}
          disabled={disabled} />
        {noSelectedFormatsComponent}
      </ModalBody>
      <ModalFooter>
        <Button onClick={dispatchDownload} color="primary" size="lg" className="w-100" disabled={disabled}>
          {buttonText()}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
