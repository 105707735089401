import React from 'react'
import { generatePath, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { templatesForCategoryAndTier } from '../../lib/templates'

export default function TemplateCategories(props) {
  const { templateCategories, templates, plans } = props
  const { plan } = useParams()
  const tier = plans[plan]

  const templateCategoryElements = templateCategories.sort(category => category.attributes.position).map(category => {
    const { id, attributes } = category
    const { slug, name } = attributes
    const categoryTemplates = templatesForCategoryAndTier(templates, category, tier)
    const categoryPreviews = [categoryTemplates.slice(0, 3), categoryTemplates.slice(3, 6), categoryTemplates.slice(6, 9)]

    return categoryTemplates.length > 0 ? (
      <Link key={`templateCategory:${id}`} to={generatePath('/:plan/:category', { plan: plan, category: slug })} className="template-category">
        <div className="card card-bordered template-category-previews mb-2">
          <div className="card-body py-2">
            {categoryPreviews.map((row, idx) => (
              <div key={`categoryPreviewRow:${id}:${idx}`} className="row py-1 px-1">
                {row.map(template => {
                  const src = Object.values(template.attributes.preview_urls)[0]
                  return (
                    <div key={`categoryPreview:${id}:${template.id}`} className="col-4 template-category-preview p-2">
                      <img src={src} alt={template.attributes.title} />
                    </div>
                  )
                })}
              </div>
            ))}
          </div>
        </div>
        <h2 className="text-center text-dark mb-0">{name}</h2>
        <div className="text-center text-muted">{categoryTemplates.length} template{categoryTemplates == 1 ? null : 's'}</div>
      </Link>
    ) : null
  })

  return (
    <div className="d-flex flex-wrap mx-n2">
      {templateCategoryElements}
    </div>
  )
}
