import React, { useState, Fragment } from 'react'
import axios from '../shared/axios'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Card, CardBody, Button } from 'reactstrap'

export default function UpdatePaymentForm(props) {
  const stripe = useStripe()
  const elements = useElements()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleClick = async (event) => {
    event.preventDefault()
    if (!stripe || !elements || loading) return
    setErrorMessage(null)
    setLoading(true)

    const response = await axios.post(props.setupIntentPath)

    const cardElement = elements.getElement(CardElement)
    const { error, setupIntent } = await stripe.confirmCardSetup(response.data.clientSecret, {
      payment_method: {
        card: cardElement,
      }
    })

    if (error) {
      showError(error.message)
    } else {
      const response = await axios.post(props.updatePaymentPath, {
        payment_method_id: setupIntent.payment_method,
      })

      const { error, redirect, confirm } = response.data

      if (error) {
        showError(error)
      } else if (redirect) {
        window.location = redirect
      } else if (confirm) {
        const { error } = await stripe.confirmCardPayment(confirm)

        if (error) {
          showError(error.message)
        } else {
          window.location = `${props.verifyPath}`
        }
      }
    }
  }

  const showError = (message) => {
    setErrorMessage(message)
    setLoading(false)
  }

  const formatAmount = (amount) => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(amount / 100)
  }

  const buttonText = loading ? 'Processing' :
    (props.amountDue ?
      `Update and Pay ${formatAmount(props.amountDue)}` : 'Update')


  const options = {
    style: {
      base: {
        fontFamily: 'Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        fontSize: '15px',
      }
    }
  }

  return (
    <Fragment>
      <Card className="card-rounded mb-4">
        <CardBody className="p-3">
          <CardElement options={options} />
        </CardBody>
      </Card>
      {errorMessage ? (
        <div className="text-center text-danger fw-bold mb-4">
          {errorMessage}
        </div>
      ) : null}
      <Button onClick={handleClick} size="lg" color="primary" block={true} disabled={!stripe || loading}>
        {loading ? <div className="spinner spinner-sm spinner-white"></div> : null}
        {buttonText}
      </Button>
    </Fragment>
  )
}
