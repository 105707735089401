import axios from '../../components/shared/axios'
import { actions } from '../uploads'

const updateObjectAttributes = (original, updated) => {
  return original.map(item => {
    const newItem = updated.find(newItem => newItem.id == item.id)
    if (newItem) {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          ...newItem.attributes,
        }
      }
    } else {
      return item
    }
  })
}

const shareItems = async (state, action, dispatch) => {
  const response = await axios.put(`${state.basepath}/uploads/share.json`, action.formData)

  handleResponse(response, state, dispatch)
}

const unshareItems = async (state, action, dispatch) => {
  const response = await axios.put(`${state.basepath}/uploads/unshare.json`, action.formData)

  handleResponse(response, state, dispatch)
}

const handleResponse = (response, state, dispatch) => {
  const newFolders = updateObjectAttributes(state.folders, response.data.upload_folders.data)
  const newUploads = updateObjectAttributes(state.uploads, response.data.uploads.data)
  
  dispatch({ type: actions.SET_UPLOADS_AND_FOLDERS, folders: newFolders, uploads: newUploads })
}

export { shareItems, unshareItems }
