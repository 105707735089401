import React, { useContext } from 'react'
import UploadsAddFolder from './UploadsAddFolder'
import StickyBar from '../shared/StickyBar'
import UploadsSelectAll from './UploadsSelectAll'
import { store } from '../../stores/uploads'
import UploadsBulkActions from './UploadsBulkActions'
import UploadsPagination from './UploadsPagination'
import Filter from '../shared/Filter'
import categoryToTitle from '../../lib/categoryToTitle'
import debounce from 'debounce'
import UploadsProgress from './UploadsProgress'

export default function UploadsStickyBar(props) {
  const { currentSelections, category, filter, actsAsMenu, demo, dispatch, actions } = useContext(store)
  const { currentPageItems } = props

  const setFilter = (value) => {
    dispatch({
      type: actions.FILTER,
      filter: value.trim()
    })
  }

  const debouncedSetFilter = debounce(setFilter, 200)
  
  return (
    <StickyBar>
      { actsAsMenu ? null : (
        <UploadsSelectAll currentPageItems={currentPageItems} />
      )}
      { currentSelections.length > 0 && !actsAsMenu ? (
        <UploadsBulkActions />
      ) : (
        <Filter
          query={filter}
          onChange={debouncedSetFilter}
          title={`Filter ${categoryToTitle(category)}…`} />
      )}
      <div className="w-100 border-top my-2 d-md-none" />
      <UploadsPagination />
      { filter || demo ? null : (
        <div className="d-flex align-items-center flex-grow-1 order-2 w-auto">
          <UploadsAddFolder className="order-1" />
          <UploadsProgress className="order-2" openUploadDialog={props.openUploadDialog} />
        </div>
      )}
    </StickyBar>
  )
}
