import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import classNames from 'classnames'
import TemplateManager from './shared/TemplateManager'
import GraphicImagePreview from './shared/GraphicImagePreview'

export default function Template(props) {
  const [template, setTemplate] = useState(props.template)
  const [selectedFormatIndex, setSelectedFormatIndex] = useState(0)
  const [previewOpen, setPreviewOpen] = useState(false)

  const { title, active, tierName, id, is_new, slug, formats, preview_urls, full_urls, features } = template.attributes

  const {
    hideActions,
    onSelect,
    selected,
    templateLink,
  } = props

  const grayscale = props.grayscale !== undefined ? props.grayscale : active

  const graphicClasses = classNames(['graphic'])
  const imageClasses = classNames(['graphic-preview-image', { 'grayscale': grayscale }])
  const previewClasses = classNames(['graphic-preview', { 'team-inset-outline': active }])

  const currentID =[formats[selectedFormatIndex].id]

  const selectable = onSelect != null && !hideActions

  const openPreviewModal = () => {
    setPreviewOpen(true)
  }

  const closePreviewModal = () => {
    setPreviewOpen(false)
  }

  const linkData = {
    external: true,
    href: templateLink || `demo/${template.attributes.owner_slug}/${template.attributes.slug}`,
    class: previewClasses
  }

  const previewElementsNext = (
    <GraphicImagePreview
      src={preview_urls[currentID]}
      height={template.extension == 'png' ? 180: 200}
      alt={title}
      loading="eager"
      imageClasses={imageClasses}
      formats={formats}
      link={linkData}
      graphic={template}
      open={openPreviewModal}
      close={closePreviewModal}
      previewIsOpen={previewOpen}
      />
  )

  return (
    <div className="graphic-react-wrapper">
      <div id={`template-${slug}`} className={graphicClasses}>
        <div className="graphic-preview-container">
        {previewElementsNext}
        </div>
        <div className="graphic-badges">
          {is_new ? <Badge color="dark">New</Badge> : null}
          {features.events == 'single' && <Badge color="dark" className="ms-2">Event</Badge>}
          {features.events == 'multi' && <Badge color="dark" className="ms-2">Events</Badge>}
          {features.players && <Badge color="dark" className="ms-2">Players</Badge>}
          {features.opponents && <Badge color="dark" className="ms-2">Opponents</Badge>}
          <Badge color="dark" className="ms-2">{tierName}</Badge>
        </div>
        <div className="graphic-details">
          {selectable ? (
            <input type="checkbox"
              checked={selected}
              className="sized-checkbox"
              id={`template-${id}-checkbox`}
              onChange={() => onSelect(template.id)} />
          ) : null}
          <div className="graphic-details__title">
            {selectable ? (
              <label htmlFor={`template-${id}-checkbox`} className="mb-0">{title}</label>
            ) : title}
          </div>
          {hideActions ? null : (
            <div className="graphic-details__controls">
              <TemplateManager {...props}
                buttonClasses={['btn-link', 'p-0']} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}