import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { X } from 'react-feather'

export default function FileTypeInfoModal({ isOpen, toggle, category }) {
  const getFileTypes = () => {
    if (category === 'video') {
      return [
        { type: 'QuickTime', extension: '.mov' },
        { type: 'MP4', extension: '.mp4' }
      ]
    } else {
      return [
        { type: 'PNG', extension: '.png' },
        { type: 'JPEG', extension: '.jpg, .jpeg' },
        { type: 'HEIC', extension: '.heic' },
        { type: 'GIF', extension: '.gif' }
      ]
    }
  }

  const closeButton = (
    <Button color="link" onClick={toggle} className="p-0 text-muted">
      <X />
    </Button>
  )

  const formatExtensions = (extension) => {
    return extension.split(', ').map(ext => `<code>${ext}</code>`).join(', ')
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} close={closeButton} tag="div" className="modal-header-segmented">
        <h2 className="fst-normal fs-5 m-0">Unsupported File Type</h2>
      </ModalHeader>
      <ModalBody>
        <div>
          <p>The file you uploaded is not supported. Please upload a file of the following type:</p>
          <ul>
            {getFileTypes().map((fileType, index) => (
              <li key={index}>
                {fileType.type}: <span dangerouslySetInnerHTML={{ __html: formatExtensions(fileType.extension) }} />
              </li>
            ))}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>Close</Button>
        <Button color="outline-secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
} 