import React, { useContext, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { store } from '../../stores/share'
import MultiShareForm from './MultiShareForm';
import { X } from 'react-feather';

export default function EditMultiShareForm({ share, isOpen, close }) {
  const { multiShareState, states, actions, dispatch } = useContext(store)

  useEffect(() => {
    if (multiShareState == states.complete) {
      handleClose()
    }
  }, [multiShareState]);

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  const handleClose = () => {
    if (multiShareState != states.processing) {
      dispatch({
        type: actions.SET_MULTISHARE_STATE,
        payload: states.ready,
      })
      close()
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="lg">
      <ModalHeader toggle={handleClose} close={closeButton} tag="h4" className="modal-header-segmented">
        Edit Scheduled Share
      </ModalHeader>
      <ModalBody>
        <MultiShareForm share={share} />
      </ModalBody>
    </Modal>
  )
}
