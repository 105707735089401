import React, { Fragment, useLayoutEffect, useRef, useState } from 'react'
import { Button } from 'reactstrap'
import FormFields from '../FormFields'
import SelectWithPhotoField from './SelectWithPhotoField'
import axios from '../shared/axios'

export default function PlayerField(props) {
  const [addOpen, setAddOpen] = useState(false)
  const [adding, setAdding] = useState(false)
  const [value, setValue] = useState(props.field.value)
  const { key, prompt, playerFields } = props.field
  const formRef = useRef()
  
  const options = [
    { text: prompt || 'Select a player'},
    { headline: 'Only for this graphic' },
    { value: 'fill', text: 'Fill in manually…' },
    { headline: 'My players' },
    ...props.field.options,
  ]

  useLayoutEffect(() => {
    if (addOpen) {
      const stopEnter = (e) => {
        if (e.keyCode == 13) {
          e.preventDefault()
          e.stopPropagation()
  
          if (e.shiftKey) {
            handleSubmit()
          }
        }
      }
  
      formRef.current.addEventListener('keydown', stopEnter, false)
    }
  }, [addOpen])

  const onChange = (value) => {
    setValue(value)
  }

  const openAddForm = props.playerData.addUrl ? () => {
    setAddOpen(true)
    setTimeout(() => {
      formRef.current.querySelector('input[type=text]').focus()
    }, 10)
  } : null

  const closeAddForm = () => {
    setAddOpen(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    
    setAdding(true)
    const formData = new FormData(formRef.current.closest('form'))
    const response = await axios.post(props.playerData.addUrl, formData)
    props.setFields(response.data.fields)
    setAdding(false)
    closeAddForm()
  }

  const fillingIn = value == 'fill'

  return addOpen ? (
    <div className="bg-light p-3 rounded border" ref={formRef}>
      <input type="hidden" value={key} name="key" />
      <input type="hidden" value={props.playerData.templateId} name="template_id" />
      {props.playerData.graphicId ? <input type="hidden" value={props.playerData.graphicId} name="graphic_id" /> : null}
      <FormFields {...props} fields={props.playerData.fields} />
      <Button 
        color="primary"
        onClick={handleSubmit} 
        className="w-100 mb-3" 
        disabled={adding}>
          {adding ? 'Adding…' : 'Add player'}
      </Button>
      <Button color="outline-secondary" className="w-100" onClick={closeAddForm}>Cancel</Button>
    </div>
  ) : (
    <Fragment>
      <SelectWithPhotoField 
        key={`player-${key}`} 
        {...props} 
        connected={fillingIn}
        options={options} 
        onChange={onChange} 
        buttonAction={openAddForm}
        buttonText="+ Add" />
      {fillingIn && !addOpen ? <div className="connected-form bg-light mt-0 px-3 pt-3 rounded-bottom"><FormFields {...props} fields={playerFields} /></div> : null}
    </Fragment>
  )
}
