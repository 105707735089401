import React from 'react'
import { Row, Col } from 'reactstrap'
import { StateProvider } from '../stores/templates'
import TemplateManager from './shared/TemplateManager'

export default function DemoHeader(props) {
  const template = props.template.data
  const { title } = template.attributes

  return (
    <StateProvider {...props}>
      <Row className="align-items-center">
        <Col xs="12" md="auto" className="me-auto">
          <h2 className="fst-italic text-white text-truncate text-center mb-2 mt-n1 mt-md-0 mb-md-0">
            <span className="text-white-50">Demo: </span>
            {title}
          </h2>
        </Col>
        {template.attributes.active ? (
          <Col xs="12" md="auto" className="pe-md-0 mb-3 mb-md-0">
            <a href={props.newGraphicLink} className="btn btn-primary w-100">Create Graphic</a>
          </Col>
        ) : null}
        <Col xs md="auto">
          <TemplateManager {...props} template={template} buttonClasses={['btn-primary', 'w-100']} />
        </Col>
        <Col xs="auto" className="ps-0">
          <a href={props.backLink} className="btn btn-white">
            Back
          </a>
        </Col>
      </Row>
    </StateProvider>
  )
}
