import React from 'react'
import Graphic from './Graphic'

export default function Graphics(props) {
  const { basepath, graphics } = props
  return (
    <div className="graphic-container">
      {graphics.data.map(graphic => (
        <Graphic key={`graphic-${graphic.id}`} basepath={basepath} graphic={graphic} />
      ))}
    </div>
  )
}
