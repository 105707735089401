import React from 'react'
import GroupField from './GroupField'

export default function MultiLineGroupField(props) {
  const { columns, field_rows } = props.field

  const renderGroup = (fields, i) => (
    <div key={`group-${i}`} className="producer-form-multiline-item">
      <GroupField {...props} fields={fields} columns={columns} />
    </div>
  )

  return (
    <div className="producer-form-multiline">
      {field_rows.map((fields, i) => renderGroup(fields, i))}
    </div>
  )
}
