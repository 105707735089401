import React, { useContext, useState } from "react"
import { useEffect } from "react"
import { Clipboard, DownloadCloud, Mail } from "react-feather"
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap"
import { store } from "../../stores/share"
import { CopyToClipboard } from "react-copy-to-clipboard"
import GraphicNext from "../GraphicNext"
import ShareDownload from "./ShareDownload"
import ShareEmail from "./ShareEmail"
import MultiShareForm from "./MultiShareForm"
import ShareHistory from "./ShareHistory"
import ShareScheduled from "./ShareScheduled"

export default function ShareActions(props) {
  const {
    share,
    graphic,
    facebookAccounts,
    twitterAccounts,
    instagramAccounts,
    scheduleToAdd,
    teampath,
    sortedShares,
    hometownLink,
    eventLink,
    sortedScheduled,
    donePath,
  } = useContext(store)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [emailOpen, setEmailOpen] = useState(false)
  const [hometownButtonText, setHometownButtonText] =
    useState("Copy HomeTown Link")
  const [eventButtonText, setEventButtonText] = useState("Copy Event Link")

  const setHometownCopyText = () => {
    setHometownButtonText("Copied!")
    setTimeout(() => {
      setHometownButtonText("Copy HomeTown Link")
    }, 3000)
  }

  const setEventCopyText = () => {
    setEventButtonText("Copied!")
    setTimeout(() => {
      setEventButtonText("Copy Event Link")
    }, 3000)
  }

  const hasAnySocialAccounts =
    (facebookAccounts && facebookAccounts.length > 0) ||
    (twitterAccounts && twitterAccounts.length > 0) ||
    (instagramAccounts && instagramAccounts.length > 0)
      ? true
      : false

  useEffect(() => {
    if (scheduleToAdd && props.addScheduled) {
      props.addScheduled(scheduleToAdd)
    }
  }, [scheduleToAdd])

  const shared =
    sortedShares &&
    sortedShares.length > 0 &&
    sortedShares.some(
      (share) => share.action == "post" && share.deleted == false
    )

  return (
    <>
      {
        <>
          <Row>
            <Col
              xs="6"
              md="auto"
              className="pe-0 order-1 order-md-2 mb-3 mb-md-0"
            >
              {shared ? (
                <div
                  tabIndex={0}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="You cannot edit a shared graphic.">
                  <Button
                    type="button"
                    disabled={true}
                    id="disabled-edit"
                    color="outline-muted"
                    className="w-100 disabled"
                  >
                    Edit Graphic
                  </Button>
                </div>
              ) : (
                <a
                  href={`${teampath}/graphics/${graphic.id}/edit`}
                  className="btn btn-outline-muted w-100"
                >
                  Edit Graphic
                </a>
              )}
            </Col>
            <Col xs="6" md="auto" className="order-2 order-md-3 md-3 mb-md-0">
              <a href={donePath} className="btn btn-outline-muted w-100">
                Done Sharing
              </a>
            </Col>
            <Col xs="12" md="auto" className="me-auto order-3 order-md-1">
              <p className="text-muted m-0">Share a Graphic</p>
              <h2 className="mb-3 h1">{graphic.attributes.title}</h2>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              lg="4"
              xl="5"
              className="order-1 order-lg-2"
              style={{ position: "relative" }}
            >
              <div style={{ position: "sticky", insetBlockStart: "24px" }}>
                <GraphicNext
                  unlinkGraphic={true}
                  graphic={graphic}
                  ButtonGraphic={false}
                  showTitleAndActions={false}
                  graphicClasses={[
                    "graphic-singleton graphic-full-width",
                    "mx-0",
                    "mb-3",
                  ]}
                />
                <div className="bo-layout-graphic-actions">
                  <div className="bo-layout-graphic-actions-group">
                    {hometownLink && (
                      <CopyToClipboard
                        text={hometownLink.link}
                        onCopy={setHometownCopyText}
                      >
                        <Button
                          size="lg"
                          color="hometown"
                          className="w-100 mb-3 btn-lg-lg"
                        >
                          <Clipboard
                            size="20"
                            color="white"
                            className="me-2 icon-valign"
                          />{" "}
                          {hometownButtonText}
                        </Button>
                      </CopyToClipboard>
                    )}
                    {eventLink && (
                      <CopyToClipboard
                        text={eventLink.link}
                        onCopy={setEventCopyText}
                      >
                        <Button
                          size="lg"
                          color="primary"
                          className="w-100 mb-3 btn-lg-lg"
                        >
                          <Clipboard
                            size="20"
                            color="white"
                            className="me-2 icon-valign"
                          />{" "}
                          {eventButtonText}
                        </Button>
                      </CopyToClipboard>
                    )}
                    <Button
                      size="lg"
                      onClick={() => setEmailOpen(true)}
                      color="outline-secondary"
                      className="w-100 mb-3 btn-lg-lg"
                    >
                      <Mail size="20" className="me-2 icon-valign team-color" />{" "}
                      Send Graphic via Email
                    </Button>
                    <Button
                      onClick={() => setDownloadOpen(true)}
                      size="lg"
                      color="outline-secondary"
                      className="w-100 mb-3 btn-lg-lg"
                    >
                      <DownloadCloud
                        size="20"
                        className="me-2 icon-valign team-color"
                      />{" "}
                      Download Graphic
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="12" lg="8" xl="7" className="order-2 order-lg-1">
              <div className="mb-4">
                {hasAnySocialAccounts ? (
                  <MultiShareForm share={share} />
                ) : (
                  <div className="card card-empty mb-4">
                    <div className="card-body">
                      <p className="text-muted">
                        You have no social accounts connected to your account.
                      </p>
                      <Button
                        href={`${teampath}/settings/social`}
                        color="primary"
                      >
                        Connect Social Accounts
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {sortedScheduled.length ? (
                <ShareScheduled
                  title="Scheduled Shares"
                  hidePreviews={graphic.attributes.formats.length == 1}
                />
              ) : null}
              <ShareHistory title="Share History" hidePreviews={true} />
            </Col>
          </Row>
          <ShareDownload
            isOpen={downloadOpen}
            close={() => setDownloadOpen(false)}
          />
          <ShareEmail isOpen={emailOpen} close={() => setEmailOpen(false)} />
        </>
      }
    </>
  )
}
