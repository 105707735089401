import React, { Fragment } from 'react'
import classNames from 'classnames'

export default function ProducerPreviewImage(props) {
  const { generating, url, oldUrl, loadedCurrentPreview, example, extension } = props.format

  const urlParams = new URLSearchParams(window.location.search);
  const hasDuplicate = urlParams.has('duplicate');

  const previewClasses = classNames({
    'producer-preview-image': true,
    'producer-preview-image-positioned': !loadedCurrentPreview,
    'producer-preview-image-generating': generating,
    'grayscale': example && !hasDuplicate,
  })

  const backgroundClasses = classNames({
    'producer-preview-image': true,
    'producer-preview-image-positioned': loadedCurrentPreview,
    'producer-preview-image-generating': generating,
    'grayscale': example && !hasDuplicate,
  })

  const containerStyle = {
    maxHeight: props.containerMaxHeight,
    '--max-height': props.containerMaxHeight,
    '--aspect-ratio': props.aspectRatio,
  }

  const previewStyle = {
    maxHeight: props.maxHeight,
    '--max-height': props.maxHeight,
  }

  const currentElement = <img key={url} src={url} className={previewClasses} style={previewStyle} onLoad={props.handlePreviewLoad} alt="" />

  const backgroundElement = oldUrl ? (
    <img key={oldUrl} src={oldUrl} className={backgroundClasses} style={previewStyle} alt="" />
  ) : null

  return (
    <Fragment>
      <div className={`producer-preview-item producer-preview-item-${extension}`} style={containerStyle}>
        {backgroundElement}
        {currentElement}
      </div>
    </Fragment>
  )
}
