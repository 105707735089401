import React from 'react'
import {
  BrowserRouter as Router,
} from 'react-router-dom'

import { StateProvider } from '../stores/templates'

import ScrollToTop from './ScrollToTop'
import TemplatesRouter from './Templates/TemplatesRouter'

export default function Templates(props) {
  return (
    <StateProvider {...props}>
      <Router basename={props.basename}>
        <ScrollToTop />
        <TemplatesRouter />
      </Router>
    </StateProvider>
  )
}
