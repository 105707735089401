import axios from "../../components/shared/axios"
import React from 'react'
import { actions, states } from "../share"
import { getScheduledPage, getSharePage } from "./pagination"

const shareEmail = async (state, action, dispatch) => {
  const params = {
    share: {
      action: "email",
      scheduled_at: action.scheduleAt ? `${action.scheduleAt.date} ${action.scheduleAt.time}` : null,
      metadata: {
        emails: action.emails,
        formats: action.formats,
      },
    },
  }
  
  let response

  if (action.shareId) {
    response = await axios.patch(`${state.teampath}/shares/${action.shareId}`, params)
  } else {
    response = await axios.post(state.sharepath, params)
  }

  const { share, was_scheduled } = response.data

  if (share.scheduled_at) {
    await getScheduledPage(state.scheduled.currentPage, state, dispatch)
  } else {
    if (was_scheduled && state.shares) {
      await Promise.all([
        getSharePage(state.shares.currentPage, state, dispatch),
        getScheduledPage(state.scheduled.currentPage, state, dispatch),
      ])
    } else {
      await getSharePage(state.shares.currentPage, state, dispatch)
    }
  }

  dispatch({ type: actions.SET_EMAIL_STATE, payload: states.complete })
}

const shareEmailActionText = (share) => {
  if (share.metadata.emails.length == 1) {
    return <strong>{share.metadata.emails[0]}</strong>
  } else if (share.metadata.emails.length == 2) {
    return <><strong>{share.metadata.emails[0]}</strong> and <strong>{share.metadata.emails[1]}</strong></>
  } else {
    return <><strong>{share.metadata.emails[0]}</strong> and {share.metadata.emails.length - 1} others</>
  }
}
    

export { shareEmail, shareEmailActionText }
