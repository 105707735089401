import axios from '../../components/shared/axios'
import { actions } from '../share'

const getSharePage = async (page, state, dispatch) => {
  let response = await axios.get(state.shares.url, { params: { page: page } })

  if (response.data.currentPage > response.data.pageCount) {
    response = await axios.get(state.shares.url, { params: { page: response.data.pageCount } })
  }

  dispatch({ type: actions.SET_SHARES, payload: response.data })
  dispatch({ type: actions.SET_SHARES_LOADING, payload: false })
}

const getScheduledPage = async (page, state, dispatch) => {
  let response = await axios.get(state.scheduled.url, { params: { page: page } })

  if (response.data.currentPage > response.data.pageCount && response.data.pageCount > 0) {
    response = await axios.get(state.scheduled.url, { params: { page: response.data.pageCount } })
  }

  dispatch({ type: actions.SET_SCHEDULED, payload: response.data })
  dispatch({ type: actions.SET_SCHEDULED_LOADING, payload: false })
}

export { getSharePage, getScheduledPage }
