import React, { useEffect, useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router'
import { store } from '../../stores/templates'
import TemplateDetails from './TemplateDetails'
import TemplatesPlan from './TemplatesPlan'
import TemplatesPlanCategory from './TemplatesPlanCategory'
import TemplatesRecent from './TemplatesRecent'
import TemplatesTag from './TemplatesTag'
import TemplatesEvents from './TemplatesEvents'
import LoadingIndicator from '../shared/LoadingIndicator'
import TemplatesActive from './TemplatesActive'

export default function TemplatesRouter() {
  const {
    actions,
    dispatch,
    loading,
    defaultPlan,
  } = useContext(store)

  useEffect(() => {
    dispatch({ type: actions.FETCH_TEMPLATES })
  }, [])

  return (
    <LoadingIndicator loading={loading} title="Loading templates…">
      <Routes>
        <Route index element={<Navigate to="/active" />} />
        <Route path="active" element={<TemplatesActive />} />
        <Route path="events" element={<Navigate to={`/${defaultPlan}/events`} />} />
        <Route path=":plan">
          <Route index element={<TemplatesPlan />} />
          <Route path="recent" element={<TemplatesRecent />} />
          <Route path="events" element={<TemplatesEvents />} />
          <Route path="tag/:tag" element={<TemplatesTag />} />
          <Route path=":category">
            <Route index element={<TemplatesPlanCategory />} />
            <Route path=":template" element={<TemplateDetails />} />
          </Route>
        </Route>
      </Routes>
    </LoadingIndicator>
  )
}
