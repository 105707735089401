import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button, ButtonGroup, Col, Input, ListGroup, ListGroupItem, Row } from 'reactstrap'
import debounce from 'debounce'
import FormFields from '../FormFields'
import SelectWithPhotoField from './SelectWithPhotoField'
import axios from '../shared/axios'

export default function OpponentField(props) {
  const [addOpen, setAddOpen] = useState(false)
  const [viaLibrary, setViaLibrary] = useState(true)
  const [searching, setSearching] = useState(false)
  const [query, setQuery] = useState('')
  const [adding, setAdding] = useState(false)
  const [value, setValue] = useState(props.field.value)
  const [searchResults, setSearchResults] = useState({ items: [], groups: [] })
  const { key, opponentFields, prompt } = props.field
  const formRef = useRef()

  const options = [
    { text: prompt || 'Select an opponent'},
    { headline: 'Only for this graphic' },
    { value: 'fill', text: 'Fill in manually…' },
    { headline: 'My opponents' },
    ...props.field.options,
  ]

  useLayoutEffect(() => {
    if (addOpen) {
      const stopEnter = (e) => {
        if (e.keyCode == 13) {
          e.preventDefault()
          e.stopPropagation()
  
          if (e.shiftKey) {
            handleSubmit()
          }
        }
      }
  
      formRef.current.addEventListener('keydown', stopEnter, false)
    }
  }, [addOpen])

  const performSearch = () => {
    const trimmedQuery = query.trim()
    const controller = new AbortController()

    const fetchData = async () => {
      const response = await axios.get(props.opponentData.searchUrl, {
        signal: controller.signal,
        params: {
          query: trimmedQuery,
        }
      })

      setSearchResults(response.data)
      setSearching(false)
    }
    
    if (trimmedQuery.length > 2) {
      setSearching(true)
      fetchData()
      
      return () => {
        controller.abort()
      }
    } else {
      setSearching(false)
      setSearchResults({
        items: [],
        groups: [],
      })
    }
  }

  const debouncedSetQuery = debounce(setQuery, 300)

  useLayoutEffect(() => {
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.querySelector('input[type=text]').focus()
      }
    }, 10)
  }, [viaLibrary])

  useEffect(performSearch, [query])

  const onChange = (value) => {
    setValue(value)
  }

  const openAddForm = props.opponentData.addUrl ? () => {
    setAddOpen(true)
    setTimeout(() => {
      formRef.current.querySelector('input[type=text]').focus()
    }, 10)
  } : null

  const closeAddForm = () => {
    setAddOpen(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    
    setAdding(true)
    const formData = new FormData(formRef.current.closest('form'))
    const response = await axios.post(props.opponentData.addUrl, formData)
    props.setFields(response.data.fields)
    setAdding(false)
    closeAddForm()
  }

  const addLibraryItem = async (id) => {
    setAdding(true)
    const formData = new FormData(formRef.current.closest('form'))
    formData.append('id', id)
    const response = await axios.post(props.opponentData.linkUrl, formData)
    props.setFields(response.data.fields, response.data.id)
    setAdding(false)
    closeAddForm()
  }

  const rowForItem = (item) => (
    <ListGroupItem key={`item-${item.id}`} className="px-3">
      <Row className="mx-0 align-items-center">
        <Col xs="auto" className="p-0">
          <img src={item.preview_url} className="select-field-preview-image" alt="Logo" />
        </Col>
        <Col className="px-2">{item.name}</Col>
        <Col xs="auto" className="p-0">
          {item.added ? <em className="text-muted">Imported</em> : <Button size="sm" color="outline-secondary" onClick={() => addLibraryItem(item.id)} disabled={adding}>Import</Button> }
        </Col>
      </Row>
    </ListGroupItem>
  )

  const fillingIn = value == 'fill'

  const itemResults = searchResults.items.map(item => rowForItem(item))

  const groupResults = searchResults.groups.map(group => (
    <Fragment key={`group-${group.id}`}>
      <ListGroupItem className="bg-light text-muted fw-bold px-3">{group.name}</ListGroupItem>
      {group.items.map(item => rowForItem(item))}
    </Fragment>
  ))

  const totalResults = [...itemResults, ...groupResults]

  const results = searching || query.trim().length <= 2 ? null : (
    <Fragment>
      {totalResults.length > 0 ? <ListGroup className="mt-3">{totalResults}</ListGroup> : (
        <em className="d-block text-center text-muted mt-3">Nothing matched your search</em>
      )}
    </Fragment>
  ) 

  return addOpen ? (
    <div className="producer-add-form bg-light p-3 rounded border" ref={formRef}>
      <div className="mb-3">
        <ButtonGroup className="w-100">
          <Button color={viaLibrary ? 'outline-primary' : 'outline-muted'} active={viaLibrary} onClick={() => setViaLibrary(true)}>Search library</Button>
          <Button color={viaLibrary ? 'outline-muted' : 'outline-primary'} active={!viaLibrary} onClick={() => setViaLibrary(false)}>Enter manually</Button>
        </ButtonGroup>
      </div>
      <input type="hidden" value={key} name="key" />
      <input type="hidden" value={props.opponentData.templateId} name="template_id" />
      {props.opponentData.graphicId ? <input type="hidden" value={props.opponentData.graphicId} name="graphic_id" /> : null}
      {viaLibrary ? (
        <Fragment>
          <Input type="text" placeholder="Search the library..." onChange={(e) => debouncedSetQuery(e.target.value)} />
          {searching ? (
            <div className="text-muted mt-3 text-center">
              <div className="spinner spinner-sm"></div>
              <span className="spinner-text">Searching…</span>
            </div>
          ) : results}
          <Button color="outline-secondary" className="mt-3 w-100" onClick={closeAddForm} disabled={adding}>Cancel</Button>
        </Fragment>
      ) : (
        <Fragment>
          <FormFields {...props} fields={props.opponentData.fields} />
          <Button 
            color="primary"
            onClick={handleSubmit} 
            className="w-100 mb-3" 
            disabled={adding}>
              {adding ? 'Adding…' : 'Add opponent'}
          </Button>
          <Button color="outline-secondary" className="w-100" onClick={closeAddForm} disabled={adding}>Cancel</Button>
        </Fragment>
      )}
    </div>
  ) : (
    <Fragment>
      <SelectWithPhotoField 
        key={`opponent-${key}`} 
        {...props}
        connected={fillingIn}
        options={options} 
        onChange={onChange} 
        buttonAction={openAddForm}
        buttonText="+ Add" />
      {fillingIn && !addOpen ? <div className="connected-form bg-light mt-0 px-3 pt-3 rounded-bottom"><FormFields {...props} fields={opponentFields} /></div> : null}
    </Fragment>
  )
}
