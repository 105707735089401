import React, { memo, useState, useContext } from 'react'
import { Folder, Menu } from 'react-feather'
import { 
  X,
  Edit,
  Folder as FolderIcon,
  Trash2,
} from 'react-feather'
import { store } from '../../stores/uploads'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

const iconSize = 16
const dropdownItemClasses = classNames(['d-flex', 'align-center', 'gap-2', 'lh-sm'])

function WrappedFolder(props) {
  const { category, canManageShared, openModal, processing, actsAsMenu, dispatch, actions } = useContext(store)
  const { folder } = props
  const renameModalId = `renameFolder:${folder.id}`
  const renameOpen = openModal == renameModalId

  const [newName, setNewName] = useState(folder.attributes.name)

  const navigate = useNavigate()

  const subfolderIcons = [...Array(Math.min(folder.subfolderCount, 9))].map((n, i) => {
    const classes = classNames([
      'upload-folder-preview-item',
      'upload-folder-preview-item-subfolder',
    ])

    return (
      <Col
        key={`subfolderPreview:${i}`}
        xs={4}
        className="upload-folder-preview-col">
        <div className={classes}>
          <Folder size="40" />
        </div>
      </Col>
    )
  })

  const uploadPreviews = folder.previews.length + folder.subfolderCount > 0 ? folder.previews.map((upload) => {
    const classes = classNames([
      'upload-folder-preview-item',
      'preview-checkered',
      'preview-checkered-sm',
    ])

    return (
      <Col
        key={`preview:${upload.id}`}
        xs={4}
        className="upload-folder-preview-col">
        <div className={classes}>
          <img
            alt={upload.attributes.filename}
            src={upload.attributes.thumb_url}
            className="upload-folder-preview-image" />
        </div>
      </Col>
    )
  }) : (
    <div className="text-muted text-center d-flex align-items-center gap-2"><FolderIcon size={20} /> Empty</div>
  )

  const canEditFolder = canManageShared || !folder.attributes.shared

  const handleMove = () => {
    dispatch({ type: actions.TOGGLE_MODAL, modal: actions.MOVE_ITEMS, modalData: { items: [folder] } })
  }

  const handleDelete = () => {
    dispatch({ type: actions.TOGGLE_MODAL, modal: actions.DELETE_ITEMS, modalData: { items: [folder] } })
  }

  const folderPreviewsClasses = classNames({
    'upload-preview upload-folder-previews': true,
    'upload-folder-previews-empty': folder.previews.length + folder.subfolderCount == 0,
  })

  const toggleRename = (e) => {
    e.preventDefault()
    dispatch({ type: actions.TOGGLE_MODAL, modal: renameModalId })
  }

  const handleRename = (e) => {
    e.preventDefault()
    if (processing) return
    const formData = new FormData(e.target)
    dispatch({ type: actions.RENAME_FOLDER, formData: formData, folder_id: folder.id, shared: folder.attributes.shared })
  }

  const setFocus = () => {
    const input = document.querySelector('#upload-folder-name')
    input.focus()
    input.select()
  }

  const closeButton = (
    <Button color="link" onClick={toggleRename} className="p-0 text-muted">
      <X />
    </Button>
  )

  const selectFolder = (id) => {
    navigate(`/${category}/${id}`)
  }

  return (
    <div className="upload upload-folder" style={props.style}>
      <div
        className={folderPreviewsClasses}
        onClick={() => selectFolder(folder.id)}>
        {folder.attributes.shared ? (
          <div className="upload-badges">
            <div className="badge bg-dark">Shared</div>
          </div>
        ) : null}
        <div className="d-flex flex-wrap">
          {subfolderIcons}
          {uploadPreviews}
        </div>
      </div>
      <Row>
        {actsAsMenu || !canEditFolder ? null : (
          <Col xs="auto" className="upload-selection">
            <input
              type="checkbox"
              value={props.keyString}
              className="sized-checkbox"
              id={`upload-folder-${folder.id}`}
              onChange={(e) => {
                dispatch({ type: actions.SELECT_ITEMS, items: [folder], state: e.target.checked })
              }}
              checked={props.selected} />
          </Col>
        )}
        <Col className="upload-name">
          <label
            className="upload-label"
            id={`upload-folder-label-${folder.id}`}
            htmlFor={`upload-folder-${folder.id}`}>
            {folder.attributes.name}
          </label>
          {canEditFolder ? null : (
            <UncontrolledTooltip
              placement="top"
              target={`upload-folder-label-${folder.id}`}>
              Only administrators can edit shared folders
            </UncontrolledTooltip>
          )}
        </Col>
        {actsAsMenu || !canEditFolder ? null : (
          <Col xs="auto" className="upload-menu">
            <UncontrolledDropdown>
              <DropdownToggle
                color="link"
                size="sm"
                className="px-1 py-0 mx-n1 text-muted">
                <Menu size="16" />
              </DropdownToggle>
              <DropdownMenu end={true} className="p-0">
                <DropdownItem onClick={toggleRename} className={dropdownItemClasses}><Edit size={iconSize} /> Rename</DropdownItem>
                <DropdownItem onClick={handleMove} className={dropdownItemClasses}><FolderIcon size={iconSize} /> Move</DropdownItem>
                <DropdownItem onClick={handleDelete} className={`${dropdownItemClasses} dropdown-item--danger`}><Trash2 size={iconSize} /> Delete</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        )}
      </Row>
      <Modal isOpen={renameOpen} toggle={toggleRename} size="sm" onOpened={setFocus}>
        <ModalHeader toggle={toggleRename} close={closeButton} tag="h3">
          Rename Folder
        </ModalHeader>
        <Form onSubmit={handleRename}>
          <ModalBody>
            <FormGroup>
              <Label for="upload-folder-name">Folder Name</Label>
              <Input
                type="text"
                id="upload-folder-name"
                name="upload_folder[name]"
                placeholder="Untitled"
                onChange={(e) => setNewName(e.target.value)}
                value={newName} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" disabled={processing}>
              Save
            </Button>
            <Button color="outline-secondary" onClick={toggleRename}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  )
}

const UploadsFolder = memo(WrappedFolder)
export default UploadsFolder
