import React from 'react'

import { StateProvider } from '../stores/share'
import ShareHistory from './Share/ShareHistory'

export default function TeamShares(props) {
  return (
    <StateProvider {...props}>
      <ShareHistory title="All Posts & Shares" />
    </StateProvider>
  )
}
