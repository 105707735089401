import React from 'react'
import classNames from 'classnames'

export default function ProducerPreviewFormats(props) {
  const formatHeight = 40
  const { selectedFormat, setSelectedFormat, formats } = props

  const onFormatClick = (id) => {
    if (formats.length > 1) {
      setSelectedFormat(id)
    }
  }

  const formatPreviews = formats.map(format => {
    const { width, height, id, ratio, title } = format
    const formatWidth = formatHeight * ratio

    return (
      <button key={`formatPreview:${id}`} 
        className={classNames({'producer-preview-format': true, selected: id == selectedFormat})} 
        onClick={() => onFormatClick(id)}>
        {title && formats.length > 1 ? (
          <div className="producer-preview-format-title">{title}</div>
        ) : null}
        <div className="producer-preview-format-shape" style={{ width: formatWidth }} />
        <div className="producer-preview-format-size">
          {width} &times; {height}
        </div>
      </button>
    )
  })

  return (
    <div className="producer-preview-formats">
      {formats.length > 1 ? (
        <button className={classNames({'producer-preview-format': true, selected: !selectedFormat})} 
          onClick={() => onFormatClick(null)}>
          <div className="producer-preview-format-title">Show all</div>
          <div className="producer-preview-format-shape-all">
            <div className="producer-preview-format-shape" />
            <div className="producer-preview-format-shape" />
            <div className="producer-preview-format-shape" />
          </div>
          <div className="producer-preview-format-size">
            &nbsp;
          </div>
      </button>
      ) : null}
      {formatPreviews}
    </div>
  )
}
