import axios from "../../components/shared/axios"
import { fetchFile } from "../../lib/fetchFile"
import { actions, states } from "../share"
import { getSharePage } from "./pagination"

const beginDownload = async (state, action, dispatch) => {
  try {
    const response = await axios.post(state.sharepath, {
      share: {
        action: "download",
        graphic_id: state.graphic.id,
        metadata: action.metadata,
      },
    })
    const share = response.data.share
    
    if (state.shares) {
      await getSharePage(state.shares.currentPage, state, dispatch)
    }

    await fetchFile(share.result.url, share.result.filename);
    
    dispatch({ type: actions.SET_DOWNLOAD_STATE, payload: states.complete })
  } catch (error) {
    if (error.name === 'AbortError') {
      dispatch({ type: actions.SET_DOWNLOAD_STATE, payload: states.ready });
    } else {
      dispatch({ type: actions.SET_DOWNLOAD_STATE, payload: states.error });
    }
  }
}

const beginMultiFormatDownload = async (state, action, dispatch) => {
  try {
    const response = await axios.post(state.sharepath, {
      share: {
        action: "multi_format_download",
        graphic_id: state.graphic.id,
        metadata: action.metadata,
      },
    })
    handleMutliFormatDownloadResponse(response, dispatch, state)
  } catch (error) {
    dispatch({ type: actions.SET_DOWNLOAD_STATE, payload: states.error })
  }
}

const handleMutliFormatDownloadResponse = async (result, dispatch, state) => {
  try {
    let response
    const { share, poll } = result.data
    switch (share.result.status) {
      case 'success':
        await fetchFile(share.result.url, share.result.filename)
        if (state.shares && state.shares.currentPage) {
          await getSharePage(state.shares.currentPage, state, dispatch, actions)
        }
        dispatch({ type: actions.SET_DOWNLOAD_STATE, payload: states.complete })
        break
      case 'error':
        dispatch({ type: actions.SET_DOWNLOAD_STATE, payload: states.error })
        break
      default:
        setTimeout(async () => {
          response = await axios.get(poll)
          handleMutliFormatDownloadResponse(response, dispatch, state)
        }, 1000)
        break
    }
  }
  catch (error) {
    if (error.name === 'AbortError') {
      dispatch({ type: actions.SET_DOWNLOAD_STATE, payload: states.ready })
    } else {
      dispatch({ type: actions.SET_DOWNLOAD_STATE, payload: states.error })
    }
  }
}

export { beginDownload, beginMultiFormatDownload }
