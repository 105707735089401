import React, { useState } from 'react'
import { X } from 'react-feather'
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

export default function DeleteModal(props) {
  const token = document.querySelector('[name="csrf-token"]').getAttribute('content')
  const [deleting, setDeleting] = useState(false)
  const { deleteLink, close, scheduledShareCount } = props

  const handleSubmit = () => {
    setDeleting(true)
  }

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  return (
    <Modal isOpen={props.isOpen} toggle={close}>
      <ModalHeader toggle={close} close={closeButton} tag="h3">
        Delete this graphic?
      </ModalHeader>
      <Form onSubmit={handleSubmit} action={deleteLink} method="post">
        <input type="hidden" value="delete" name="_method" />
        <input type="hidden" name="authenticity_token" value={token} />
        <input type="hidden" name="return_to" value={window.location} />
        <ModalBody>
          {scheduledShareCount > 0 ? <p className="text-danger fw-bold mb-3">This graphic is scheduled to be shared {scheduledShareCount} time{scheduledShareCount == 1 ? '' : 's'}. Deleting it will cancel {scheduledShareCount == 1 ? 'that share' : 'those shares'}.</p> : null}
          Are you sure you want to delete this graphic?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" type="submit" disabled={deleting}>{deleting ? 'Deleting…' : 'Delete'}</Button>
          <Button color="outline-secondary" onClick={close}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
