import React, { useState, useEffect, useRef } from "react"
import axios from "./shared/axios"
import { Button } from "reactstrap"
import MonthSection from "./Events/MonthSection"
import ToolBar from "./Events/ToolBar"
import Fuse from "fuse.js"
import debounce from "debounce"
import GraphicModal from "./Events/GraphicModal"
import DeleteEventModal from "./Events/DeleteEventModal"
import DeleteGraphicModal from "./Events/DeleteGraphicModal"
import Pagination from "./shared/Pagination"
import LoadingIndicator from "./shared/LoadingIndicator"
import { useMediaQuery, mqKeys } from "../media-queries"
import { set } from "lodash"

export default function Events(props) {
  const { basepath, owner } = props
  const [data, setData] = useState([])
  const [previewOpen, setPreviewOpen] = useState(null)
  const [selectedEvents, setSelectedEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [isPast, setIsPast] = useState(false)
  const [filterText, setFilterText] = useState("")

  const [isGraphicModalOpen, setIsGraphicModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isDeleteGraphicModalOpen, setIsDeleteGraphicModalOpen] =
    useState(false)

  const [currentEventIds, setCurrentEventIds] = useState([])

  const [currentGraphic, setCurrentGraphic] = useState(null)
  const [currentGraphicPath, setCurrentGraphicPath] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)
  const [currentOrgId, setCurrentOrgId] = useState(null)
  const [currentTeamId, setCurrentTeamId] = useState(null)
  const [currentTeamName, setCurrentTeamName] = useState(null)
  const [singleOrMulti, setSingleOrMulti] = useState("single")
  const itemsPerPage = 20
  const selectAllRef = useRef(null)

  const mediaQueries = mqKeys.reduce((acc, key) => {
    acc[key] = useMediaQuery(key)
    return acc
  }, {})

  // ======================================
  // DATA FETCHING
  // ======================================
  async function loadData() {
    try {
      const endpoint = isPast
        ? `${basepath}/events.json?batch=past`
        : `${basepath}/events.json`
      const response = await axios.get(endpoint)
      setData(Array.isArray(response.data) ? response.data : [])
      setLoading(false)
    } catch (error) {
      console.error("Error loading data:", error)
      setData([])
      setLoading(false)
    }
  }

  // ======================================
  // TOGGLES & HANDLERS
  // ======================================

  // Toggle between past and upcoming events
  const toggleDataSource = () => {
    setIsPast((prevIsPast) => !prevIsPast)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  // Manage Selected Events
  const handleSelectEvent = (eventId) => {
    setSelectedEvents((prevSelected) =>
      prevSelected.includes(eventId)
        ? prevSelected.filter((id) => id !== eventId)
        : [...prevSelected, eventId]
    )
  }

  // Select All Events
  const handleSelectAll = () => {
    if (selectedEvents.length === allEventIds.length) {
      setSelectedEvents([])
    } else {
      setSelectedEvents(allEventIds)
    }
  }

  // Filter Events
  const handleFilterChange = (value) => {
    setFilterTextDebounced(value)
  }

  // Pagination
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  // Delete Event and Graphic
  const handleDeleteEvent = async () => {
    try {
      await axios
        .delete(
          `${basepath}/events/delete.json?event_ids=${currentEventIds.join(",")}`
        )
        .then(async () => {
          setSelectedEvents([])
          await loadData().then(() => {
            closeDeleteModal()
          })
        })
    } catch (error) {
      console.error("Error deleting event:", error)
    }
  }

  // Destroy Graphic and associated shares
  const handleDeleteGraphic = async () => {
    try {
      await axios.delete(`${currentGraphicPath}.json`).then(async () => {
        await loadData().then(() => {
          closeDeleteGraphicModal()
        })
      })
    } catch (error) {
      console.error("Error deleting graphic:", error)
    }
  }

  // ======================================
  // MODALS
  // ======================================

  // Graphic Preview Modal (Lightbox)
  const openPreviewModal = (id) => {
    setPreviewOpen(id)
  }

  const closePreviewModal = () => {
    setPreviewOpen(null)
  }

  // Graphic Modal (for Create Graphic)
  const openGraphicModal = (
    eventId,
    orgId,
    teamId,
    teamName,
    singleOrMulti
  ) => {
    setCurrentEventIds(eventId)
    setCurrentOrgId(orgId)
    setCurrentTeamId(teamId)
    setCurrentTeamName(teamName)
    setIsGraphicModalOpen(true)
    setSingleOrMulti(singleOrMulti)
  }

  const closeGraphicModal = () => {
    setIsGraphicModalOpen(false)
    setCurrentEventIds([])
    setCurrentOrgId(null)
    setCurrentTeamId(null)
    setCurrentTeamName(null)
  }

  // Delete Event Confirmation Modal
  const openDeleteModal = (eventIds, eventPath) => {
    setCurrentEventIds(eventIds)
    setIsDeleteModalOpen(true)
  }

  const closeDeleteModal = () => {
    setCurrentEventIds([])
    setIsDeleteModalOpen(false)
  }

  // Delete Graphic Confirmation Modal
  const openDeleteGraphicModal = (graphicProps, graphicPath) => {
    setCurrentGraphic(graphicProps)
    setCurrentGraphicPath(graphicPath)
    setIsDeleteGraphicModalOpen(true)
  }

  const closeDeleteGraphicModal = () => {
    setCurrentGraphic(null)
    setCurrentGraphicPath(null)
    setIsDeleteGraphicModalOpen(false)
  }

  // ======================================
  // FILTERING
  // ======================================

  // Debounce the filter text input
  const setFilterTextDebounced = debounce((value) => {
    setFilterText(value)
  }, 400)

  // Search Filter Terms
  const fuse = new Fuse(data, {
    keys: ["name", "venue"],
    threshold: 0.3,
  })

  // Filter the data based on the search term
  const filteredData = filterText
    ? fuse.search(filterText).map((result) => result.item)
    : data

  // Get all event ids
  const allEventIds = Array.isArray(filteredData)
    ? filteredData.map((event) => event.id)
    : []

  // Determine if all events are selected
  const isAllSelected = !loading && selectedEvents.length === allEventIds.length

  // Determine if the select all checkbox should be indeterminate (icon with a dash instead of a checkmark)
  const isIndeterminate =
    selectedEvents.length > 0 && selectedEvents.length < allEventIds.length

  // Group the data by month and day
  const groupedData = Array.isArray(filteredData)
    ? filteredData.reduce((acc, event) => {
        const date = new Date(event.starts_at)
        const month = date.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })
        const day = date.toLocaleDateString("default", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })

        if (!acc[month]) {
          acc[month] = {}
        }
        if (!acc[month][day]) {
          acc[month][day] = []
        }
        acc[month][day].push(event)
        return acc
      }, {})
    : {}

  // Flatten the grouped data into a list of events and filter out invalid graphics
  const flatData = Object.entries(groupedData).flatMap(([month, days]) =>
    Object.entries(days).flatMap(([day, events]) => {
      // Filter out graphics with discarded_at not set to null
      const filteredEvents = events.map((event) => {
        if (event.graphics) {
          event.graphics = event.graphics.filter(
            (graphic) => graphic.discarded_at === null
          )
        }
        return event
      })
      return filteredEvents
    })
  )

  // Paginate the flat list
  const paginatedData = flatData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  // Re-group the paginated data by month and day
  const paginatedGroupedData = paginatedData.reduce((acc, event) => {
    const date = new Date(event.starts_at)
    const month = date.toLocaleString("default", {
      month: "long",
      year: "numeric",
    })
    const day = date.toLocaleDateString("default", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })

    if (!acc[month]) {
      acc[month] = {}
    }
    if (!acc[month][day]) {
      acc[month][day] = []
    }
    acc[month][day].push(event)
    return acc
  }, {})

  // Calculate the number of pages with selected items
  const pagesWithSelectedItems = new Set(
    selectedEvents.map((eventId) => {
      const index = flatData.findIndex((event) => event.id === eventId)
      return Math.floor(index / itemsPerPage) + 1
    })
  ).size

  // ======================================
  // EFFECTS
  // ======================================
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("batch") === "past") {
      setIsPast(true)
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [basepath, isPast])

  useEffect(() => {
    if (selectAllRef.current) {
      selectAllRef.current.indeterminate = isIndeterminate
    }
  }, [isIndeterminate])

  // ======================================
  // SEND PROPS
  // ======================================
  const toolbarProps = {
    ...props,
    enableFilter: data.length > 0,
    isAllSelected,
    selectAllRef,
    handleSelectAll,
    toggleDataSource,
    isPast,
    handleFilterChange,
    filterText: filterText || "",
    selectedEvents,
    pagesWithSelectedItems,
    openGraphicModal,
    openDeleteModal,
    selectedEventObjects: flatData.filter((event) =>
      selectedEvents.includes(event.id)
    ),
    isMinLg: mediaQueries.lgUp,
    isMinMd: mediaQueries.mdUp,
    isMinSm: mediaQueries.smUp,
    isMaxLg: mediaQueries.lgDown,
    isMaxMd: mediaQueries.mdDown,
    isMaxSm: mediaQueries.smDown,
  }

  const monthProps = {
    ...props,
    openPreviewModal,
    closePreviewModal,
    previewOpen,
    selectedEvents,
    handleSelectEvent,
    openGraphicModal,
    openDeleteModal,
    openDeleteGraphicModal,
    isMinLg: mediaQueries.lgUp,
    isMinMd: mediaQueries.mdUp,
    isMinSm: mediaQueries.smUp,
    isMaxLg: mediaQueries.lgDown,
    isMaxMd: mediaQueries.mdDown,
    isMaxSm: mediaQueries.smDown,
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      <ToolBar {...toolbarProps} />

      <LoadingIndicator loading={loading} title="Loading Events…">
        {data.length > 0 &&
          Object.entries(paginatedGroupedData).map(([month, days]) => (
            <MonthSection
              key={month}
              month={month}
              days={days}
              {...monthProps}
            />
          ))}
        {data.length === 0 && (
          <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center h-100">
            <div className="text-center text-muted fs-2 mb-3">
              No Events Found
            </div>
            <Button size="lg" color="primary" href={`${basepath}/events/new`}>
              Create Event
            </Button>
          </div>
        )}
      </LoadingIndicator>

      <Pagination
        currentPage={currentPage}
        pageCount={Math.ceil(flatData.length / itemsPerPage)}
        onPageChange={handlePageChange}
      />

      <GraphicModal
        {...props}
        isOpen={isGraphicModalOpen}
        toggle={closeGraphicModal}
        eventIds={currentEventIds}
        teamId={currentTeamId}
        orgId={currentOrgId}
        teamName={currentTeamName}
        singleOrMulti={singleOrMulti}
      />

      <DeleteEventModal
        {...props}
        isOpen={isDeleteModalOpen}
        toggle={closeDeleteModal}
        multiple={currentEventIds.length > 1}
        handleDeleteEvent={handleDeleteEvent}
      />

      <DeleteGraphicModal
        {...props}
        graphic={currentGraphic}
        isOpen={isDeleteGraphicModalOpen}
        toggle={closeDeleteGraphicModal}
        handleDeleteGraphic={handleDeleteGraphic}
      />
    </div>
  )
}
