import Pusher from 'pusher-js'
import React, { useContext, useEffect } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { store } from '../../stores/team_graphics'
import { StateProvider } from '../../stores/share'
import GraphicNext from '../GraphicNext'
import CampaignComponentMessage from './CampaignComponentMessage'
import { Cloud, Facebook, Instagram } from 'react-feather'
import ShareScheduled from '../Share/ShareScheduled'
import ShareHistory from '../Share/ShareHistory'
import XLogo from '../shared/XLogo'

export default function CampaignComponentsList() {
  const {
    status,
    graphics,
    socialAccounts,
    components,
    pusherAppKey,
    pusherChannel,
    pusherCluster,
    shares,
    scheduled,
    teampath,
    canAdmin,
    tz,
    dispatch,
    actions
  } = useContext(store)

  const mappedComponents = components.map(component => ({
    ...component,
    graphic: graphics.find(graphic => graphic.id.toString() === component.attributes.graphic_id.toString())
  }))

  if (pusherAppKey && pusherChannel) {
    useEffect(() => {
      const socket = new Pusher(pusherAppKey, {
        cluster: pusherCluster,
      })
      socket.subscribe(pusherChannel).bind(`automatedPreview`, (data) => dispatch({
        type: actions.REPLACE_GRAPHIC,
        graphic: data.data,
      }))
    }, [])
  }

  const shareComponents = mappedComponents.filter(component => component.attributes.kind == 'share').sort((a, b) => (a.attributes.share_datetime > b.attributes.share_datetime) ? 1 : -1)
  const utilityComponents = mappedComponents.filter(component => component.attributes.kind == 'utility').sort((a, b) => (a.id > b.id) ? 1 : -1).map(component => (
    <GraphicNext graphic={component.graphic} key={component.id} />
  ))

  const iconComponent = (provider) => {
    switch (provider) {
      case 'facebook':
        return <Facebook className="icon icon-md icon-valign me-1" />
      case 'twitter':
        return <XLogo className="icon icon-md icon-valign me-1" />
      case 'instagram':
        return <Instagram className="icon icon-md icon-valign me-1" />
      default:
        return <Cloud className="icon icon-md icon-valign me-1" />
    }
  }

  const socialAccountComponents = socialAccounts && socialAccounts.map(socialAccount => (
    <strong key={socialAccount.id}>
      {socialAccount.attributes.avatar ? (
        <img src={socialAccount.attributes.avatar} alt={socialAccount.attributes.name} className="avatar avatar-sm avatar-valign mx-1" />
      ) : iconComponent(socialAccount.attributes.provider)}
      {socialAccount.attributes.name}
    </strong>
  ))

  const socialAccountList = socialAccountComponents?.length > 0 && socialAccountComponents.reduce((prev, curr, currentIndex) => [prev, currentIndex == socialAccountComponents.length - 1 ? ', and ' : ', ', curr])

  return components.length > 0 ? (
    <>

      {status == 'draft' ? (
        <>
          <h2 className="mb-0">Posts to Social Media</h2>
          {socialAccounts.length > 0 ? (
            <p className="text-muted">These posts will be sent to {socialAccountList}.</p>
          ) : (
            <p className="alert alert-bold-danger mt-2 mb-3">
              You have no social accounts added to this team.
              {canAdmin ? (
                <> <a href={`${teampath}/settings/social`}>Add social accounts to your team</a> to automatically schedule posts.</>
              ) : (
                <> Contact your administrator to add social accounts to your team.</>
              )}
            </p>
          )}

          {shareComponents.map(component => (
            <Row key={component.id}>
              <Col xs="12" md="auto" className="graphics-container ps-1">
                <GraphicNext graphic={component.graphic} />
              </Col>
              <Col className="ps-md-0">
                <CampaignComponentMessage component={component} />
              </Col>
            </Row>
          ))}

          <h2>Utility Graphics</h2>
          <div className="graphic-container">
            {utilityComponents}
          </div>
        </>
      ) : (
        <>
          <h1 className="mb-3">Graphics for Posting</h1>
          <div className="graphic-container">
            {shareComponents.map(component => (
              <GraphicNext key={component.id} graphic={component.graphic} />
            ))}
          </div>
          {utilityComponents.length > 0 && (
            <>
              <h1 className="mb-3">Utility Graphics</h1>
              <div className="graphic-container">
                {utilityComponents}
              </div>
            </>
          )}
          {(scheduled?.data?.length || shares?.data?.length) && (
            <StateProvider shares={shares} scheduled={scheduled} tz={tz} teampath={teampath}>
              {scheduled?.data.length && <ShareScheduled title="Scheduled Shares" hidePreviews={false} />}
              {shares?.data.length && <ShareHistory title="Share History" />}
            </StateProvider>
          )}
        </>
      )}
    </>
  ) : (
    <Card className="card-empty">
      <CardBody>There are no components in this campaign.</CardBody >
    </Card >
  )
}
