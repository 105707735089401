import React from 'react'

export default function AccountLockup(props) {
  const {accountImage, accountText} = props;

  return (
    <div className="px-0" style={{display:'grid',gridTemplateColumns:'1.5rem auto',gap:'0.5rem',alignItems:'center'}}>
        <div style={{borderRadius:'50%',overflow:'hidden'}}>
          { accountImage ? <img src={ accountImage } className="select-field-preview-image" alt="" /> : <div className="bg-dark-subtle" style={{height:'24px',width:'24px'}}></div> }
        </div>
      <div>
        <p className="m-0 fw-normal">{ accountText }</p>
      </div>
    </div>
  )
}