import axios from '../../components/shared/axios'
import { actions } from '../uploads'

const replaceObjects = (original, updated) => {
  return original.map(item => updated.find(newItem => newItem.id == item.id) || item)
}

const moveItems = async (state, action, dispatch) => {
  const response = await axios.put(`${state.basepath}/uploads/move.json`, action.formData)

  const newFolders = replaceObjects(state.folders, response.data.upload_folders.data)
  const newUploads = replaceObjects(state.uploads, response.data.uploads.data)
  
  dispatch({ type: actions.SET_UPLOADS_AND_FOLDERS, folders: newFolders, uploads: newUploads })
}

export { moveItems }
