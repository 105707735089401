import React from 'react'
import { DownloadCloud } from 'react-feather'
import { Button, Col, Row } from 'reactstrap'
import TimeAgo from 'react-timeago'
import { fetchFile } from '../../../lib/fetchFile'

export default function ShareHistoryDownload(props) {
  const { share, hidePreviews } = props
  
  const rawFormats = share.metadata.formats || [share.metadata.format]

  // If the format information is missing, generate a fallback for React to work
  const formats = rawFormats.map(fmt => {
    if (fmt?.id) {
      return fmt
    } else {
      return {
        ...fmt,
        id: Math.random().toString(36).substring(2, 11) // Generate a random number for missing IDs to ensure unique keys work
      }
    }
  })

  const handleClick = () => {
    fetchFile(share.result.url, share.result.filename)
  }

  const actionText = () => {
    let formatName = null
    const formatNames = formats.map(format => <strong key={`format-name-${format.id}`}>{format.title}</strong>)

    switch (share.action) {
      case 'download':
        if (share.graphic.attributes.formats.length > 1) {
          formatName = (<>of <strong>{share.metadata.format.title}</strong></>)
        }

        if (share.metadata.resize) {
          return <>a resized version ({share.metadata.width}x{share.metadata.height}) {formatName}</>
        } else if (share.metadata.crop) {
          return <>a cropped version ({share.metadata.width}x{share.metadata.height}) {formatName}</>
        } else {
          return <>the original version {formatName}</>
        }
      case 'multi_format_download':
        if (formats.length == share.graphic.attributes.formats.length) {
          return <>downloaded a ZIP file containing <strong>all formats</strong></>
        } else {
          return <>downloaded a ZIP file containing {formatNames.map((fn, i) => {
            if (i === 0) {
              return fn
            } else if (i === formatNames.length - 1) {
              return <> and {fn}</>
            } else {
              return <>, {fn}</>
            }
          })}</>
        }
    }
  }

  return (
    <Row className="mb-3">
      <Col xs="auto" className="pe-0">
        <DownloadCloud className="icon icon-md mt-n1" />
      </Col>
      <Col md="auto" className="social-history-description">
        <strong>{share.user.name}</strong> downloaded {actionText()}
        <small className="d-block text-muted"><TimeAgo date={share.performed_at} /></small>
        {hidePreviews ? null : (
          <div>
            {formats.map(format => {
              const url = share.graphic.attributes.preview_urls[format.id]
              return <img key={format.id} src={url} alt={share.graphic.attributes.title} className="preview-sm me-2 mt-2 rounded" />
            })}
          </div>
        )}
      </Col>
      <Col className="ps-0 d-none d-md-block">
        <hr className="mt-2" />
      </Col>
      <Col xs="12" md="auto" className="ps-md-0 order-md-4 social-history-info">
        <Button color="outline-secondary" onClick={handleClick} className="mt-3 mt-md-n2 w-100">
          Download
        </Button>
      </Col>
    </Row>
  )
}
