import React, { forwardRef, Fragment } from 'react'

const MultiformatIcon = forwardRef(({ color = 'currentColor', size = 24, strokeWidth = 1.5, formats = [], ...rest }, ref) => {

  const totalCount = formats.length
  const multiPanelLimit = 3
  const multiPanelDisplay = rest.multiPanelDisplay || 3
  const hasMultiPanel = totalCount > multiPanelLimit
  const multiPanelRatio = multiPanelDisplay > 1 ? 0.8 : 1

  const constSize = 24
  const offset = constSize * 0.125 // 3
  const rectHeight = constSize - (offset * 2)
  const spacing = offset * 2

  const diffRatio =  constSize / size

  const widthsArray = []
  if(hasMultiPanel) {
    for (let i = 0; i < multiPanelDisplay; i++) {
      widthsArray.push(Math.round(multiPanelRatio * rectHeight))
    }
  }
  else {
    formats.map(format => widthsArray.push(Math.round(format.ratio * rectHeight)))
  }

  const xOffset = (index, arr) => {
    if (index === 0) {
      return offset
    } else {
      const previousWidth = arr.slice(0, index).reduce((total, width) => total + width, offset)
      return previousWidth + (spacing * index)
    }
  }

  
  let totalWidth = 0
  widthsArray.forEach(width => totalWidth += width)

  if(hasMultiPanel) {
    totalWidth = totalWidth + (spacing * multiPanelDisplay)
  } else {
    totalWidth = totalWidth + (spacing * totalCount)
  }

  const svgWidth = totalWidth / diffRatio

  const vbX = 0
  const vbY = 0
  const vbW = totalWidth
  const vbH = constSize

  const multiformatIcon = widthsArray.map((width, index, arr) => {
    const plusOffset = 1
    const multiPanelWidth = hasMultiPanel ?totalWidth - (offset * 2) : null
    return (
      <Fragment key={index}>
        {(hasMultiPanel && index === multiPanelDisplay - 1) && 
          <mask id={`mask-${index}`}>
            <rect x={0} y={0} height={constSize} width={totalWidth} fill="white" />
            <rect x={multiPanelWidth - (offset * 2)} y={(rectHeight * 0.5) + offset} height={constSize} width={width} fill="black" />
          </mask>
        }

        <rect key={index} rx={2} x={xOffset(index, arr)} y={offset} width={width} height={rectHeight} mask={(hasMultiPanel && index === multiPanelDisplay - 1) ? `url(#mask-${index})` : ''} />
        
        {(hasMultiPanel && index === multiPanelDisplay - 1) && <path d={`
          M${multiPanelWidth + offset} ${(rectHeight * plusOffset)} v${offset * 2}
          M${multiPanelWidth} ${rectHeight + offset} h${offset * 2}
        `} />}
      </Fragment>
    )
  })

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={Math.round(svgWidth)}
      height={size}
      overflow="visible"
      viewBox={`${vbX} ${vbY} ${vbW} ${vbH}`}
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
      >
      {multiformatIcon}
    </svg>
  )
})

MultiformatIcon.displayName = 'MultiformatIcon'

export default MultiformatIcon
