import React from 'react'
import { Row, Col } from 'reactstrap'
import AccountLockup from './AccountLockup'
import CustomSelectField from '../Fields/CustomSelectField'

export default function SelectWithPhotoField(props) {
  const photoPreview = (photo) => {
    if (photo) {
      return (
        <Col xs="auto" className="pe-0 ps-2 me-n2">
          <img src={photo} className="select-field-preview-image" />
        </Col>
      )
    } else {
      return null
    }
  }

  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const selectClasses = props.selectClasses || ''

  const options = props.options || props.field.options

  const mappedOptions = options.map(option => {
    const { value, text, photo, headline } = option

    const preview = headline ? headline : (
      <AccountLockup accountImage={photo} accountText={text} />
    )

    const mappedOption = {
      headline: !!headline,
      value: value || 'NULL',
      optionElement: preview,
    }

    if (props.field.search) {
      mappedOption.text = text
    }

    return mappedOption
  })

  return (
    <CustomSelectField key={`custom-${props.field.key}`} {...props} options={mappedOptions} onChange={onChange} selectClasses={`bo-h-unset bo-minh-unset ${selectClasses}`} />
  )
}
