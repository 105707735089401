import React, { useContext, useState } from 'react'
import { X } from 'react-feather'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'

import { store } from '../../stores/team_graphics'

export default function RenameModalNext(props) {
  const { graphic, close, isOpen } = props
  const [newTitle, setNewTitle] = useState(graphic ? graphic.attributes.title : '')
  const { renaming, dispatch, actions } = useContext(store)

  const setFocus = () => {
    const input = document.querySelector('#graphic-title')
    input.focus()
    input.select()
  }

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  const handleRename = async (e) => {
    e.preventDefault()
    dispatch({
      type: actions.RENAME_GRAPHIC,
      id: graphic.id,
      title: newTitle,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={close} size="sm" onOpened={setFocus}>
      <ModalHeader toggle={close} close={closeButton} tag="h3">
        Rename Graphic
      </ModalHeader>
      <Form onSubmit={handleRename}>
        <ModalBody>
          <FormGroup>
            <Label for="upload-filename">Graphic Name</Label>
            <Input
              type="text"
              id="graphic-title"
              name="graphic[display_title]"
              placeholder="Default title"
              onChange={(e) => setNewTitle(e.target.value)}
              value={newTitle} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={renaming}>
            {renaming ? 'Saving…' : 'Save'}
          </Button>
          <Button color="outline-secondary" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
