import React, { useState, useEffect, Fragment, useLayoutEffect } from 'react'
import LoadingIndicator from '../shared/LoadingIndicator'
import StickyBar from '../shared/StickyBar'
import Filter from '../shared/Filter'
import Fuse from 'fuse.js'
import axios from '../shared/axios'
import debounce from 'debounce'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
  UncontrolledTooltip,
  CardHeader,
} from 'reactstrap'
import { Check } from 'react-feather'

export default function ProducerEventSelector(props) {
  const { updateEventFields, formState, saved } = props
  const { graphicEnabled, orgEnabled, eventsUrl, eventMax, currentTeam } = props.events || {}
  const [events, setEvents] = useState([])
  const [loadedEvents, setLoadedEvents] = useState(false)
  const [open, setOpen] = useState(false)
  const [scope, setScope] = useState(true)
  const [query, setQuery] = useState('')

  const multi = graphicEnabled == 'multi'

  const addEventData = (events) => {
    return events.map(event => {
      return {
        ...event,
        date: new Date(event.starts_at),
      }
    })
  }

  const [linkedEvents, setLinkedEvents] = useState(props.events && props.events.events ? addEventData(props.events.events) : [])
  const [updatedLinkedEvents, setUpdatedLinkedEvents] = useState(linkedEvents)

  const debouncedSetQuery = debounce(setQuery, 200)

  const loadEvents = async () => {
    const response = await axios.get(eventsUrl)
    setEvents(addEventData(response.data.events).sort((a, b) => a.date >= b.date))
    setLoadedEvents(true)
  }

  useEffect(() => {
    if (open && !loadedEvents) {
      loadEvents()
    }
  }, [open])

  useLayoutEffect(() => {
    if (!open && events.length && linkedEvents.length) {
      updateEventFields()
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      setUpdatedLinkedEvents(linkedEvents)
    }
  }, [open])

  const scopedEvents = (scope ? events.filter(event => {
    return event.owner_type == 'Team' && event.owner_id == currentTeam.id
  }) : events)

  const selectEvent = (event) => {
    if (multi) {
      setUpdatedLinkedEvents([...updatedLinkedEvents, event])
    } else {
      setSingleEvent(event)
    }
  }

  const deselectEvent = (event) => {
    setUpdatedLinkedEvents(updatedLinkedEvents.filter(e => e.id != event.id))
  }

  const setSingleEvent = (event) => {
    setLinkedEvents([event])
    setOpen(false)
  }

  const setMultiEvents = () => {
    setLinkedEvents(updatedLinkedEvents)
    setOpen(false)
  }

  const fuseOptions = {
    keys: ['name', 'venue'],
    threshold: 0.5,
    ignoreLocation: true,
  }

  const fuse = new Fuse(scopedEvents, fuseOptions)
  const queriedEvents = query ? fuse.search(query).map(result => result.item) : scopedEvents
  const showSelectorButton = graphicEnabled && orgEnabled

  const displayForEvent = (event, showTeam = false) => {
    return (
      <>
        <strong className="d-block">{event.name}</strong>
        {showTeam && event.team_name && (
          <div>
            {event.team_name && (
              <span className="d-block">{event.team_name}</span>
            )}
          </div>
        )}
        <small className="text-muted">
          {event.date.toLocaleString("en-us", {
            weekday: "long",
            timeZone: event.timezone || "America/Indianapolis",
          })}&nbsp;
          {event.date.toLocaleString("en-us", {
            dateStyle: "medium",
            timeZone: event.timezone || "America/Indianapolis",
          })} @&nbsp;
          {event.date.toLocaleString("en-us", {
            timeStyle: "short",
            timeZone: event.timezone || "America/Indianapolis",
          })}
        </small>
      </>
    )
  }

  const eventComponent = (event, showTeam = false) => {
    const selected = updatedLinkedEvents.some(e => e.id == event.id)
    return (
      <ListGroupItem key={event.id} className={selected ? 'bg-lightest' : ''}>
        <Row className="align-items-center">
          <Col>
            {displayForEvent(event, showTeam)}
          </Col>
          {!saved && (
            <Col xs="auto">
              {selected ? (
                <Button color="outline-success" size="sm" onClick={() => deselectEvent(event)}><Check size="16" className="icon-valign" /> Selected</Button>
              ) : (eventMax && updatedLinkedEvents.length >= eventMax ? (
                <>
                  <Button color="outline-secondary disabled" size="sm" id={`max-reached-${event.id}`}>
                    Select
                  </Button>
                  <UncontrolledTooltip fade={false} delay={{ hide: 0, show: 0 }} placement="top" target={`max-reached-${event.id}`}>
                    Maximum of {eventMax} events for this graphic have been selected.
                  </UncontrolledTooltip>
                </>
              ) : (
                <Button color="outline-secondary" size="sm" onClick={() => selectEvent(event)}>Select</Button>
              ))}
            </Col>
          )}
        </Row>
      </ListGroupItem>
    )
  }

  return showSelectorButton ? (
    <>
      {linkedEvents.length == 0 && (
        <Button block color="primary" className="mb-3" onClick={() => setOpen(true)} disabled={formState.updatingFields}>
          Connect Event{multi ? 's' : ''}…
        </Button>
      )}
      {!multi && linkedEvents.length > 0 && (
        <>
          <h5 className="mb-1 text-dark">Connected Event{linkedEvents.length > 1 ? 's' : ''}</h5>
          <Card className="rounded bg-gray mb-3">
            <CardBody className="card-body-sm">
              <Row className="align-items-center">
                <Col>{displayForEvent(linkedEvents[0])}</Col>
                <Col xs="auto">
                  {saved ? (
                    <Button color="outline-secondary" size="sm" onClick={() => setOpen(true)}>
                      View
                    </Button>
                  ) : (
                    <Button color="outline-secondary" size="sm" onClick={() => { setLinkedEvents([]); setUpdatedLinkedEvents([]) }}>
                      Clear
                    </Button>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )}
      {
        multi && linkedEvents.length > 0 && (
          <>
            <Card className="rounded bg-gray mb-3">
              <CardBody className="card-body-sm">
                <Row className="align-items-center">
                  <Col><strong>{linkedEvents.length} Event{linkedEvents.length > 1 ? 's' : ''} linked</strong></Col>
                  <Col xs="auto">
                    <Button color="outline-secondary" size="sm" onClick={() => setOpen(true)}>
                      {saved ? 'View' : 'Edit'}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
        )
      }
      {
        linkedEvents.map(event => (
          <input key={`linked-${event.id}`} type="hidden" name="graphic[event_ids][]" value={event.id} />
        ))
      }
      <Modal size="lg" className="h-100" contentClassName="h-100" isOpen={open} toggle={() => setOpen(!open)}>
        <ModalHeader toggle={() => setOpen(!open)} tag="h4" className="modal-header-segmented">
          {saved ? `Linked Event${linkedEvents.length == 1 ? '' : 's'}` : (
            multi ? `Select ${eventMax ? `up to ${eventMax}` : ''} Events` : 'Select an Event'
          )}
        </ModalHeader>
        <ModalBody className={`position-relative pt-0 bg-gray ${loadedEvents ? '' : 'modal-body-minheight'}`}>
          <LoadingIndicator loading={!loadedEvents} title="Loading events…" className="spinner-fill-sm">
            {saved ? null : (
              <StickyBar className="mb-2rem" contentClasses="p-2 gap-2 grid d-lg-flex" customGrid={true}>
                <Filter title="Filter events…" query={query} onChange={debouncedSetQuery} className="g-col-12 flex-grow-1 ps-2" />
                <div className="g-col-6">
                  <UncontrolledDropdown>
                    <DropdownToggle color="link" className="d-flex align-items-center mw-100" caret>
                      <div className="text-truncate mw-100 d-inline-block">
                        {scope ? `${currentTeam.name} Events` : 'All Organization Events'}
                      </div>
                    </DropdownToggle>
                    <DropdownMenu end={true}>
                      <DropdownItem onClick={() => setScope(false)}>All Organization Events</DropdownItem>
                      <DropdownItem onClick={() => setScope(true)}>{currentTeam.name} Events</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </StickyBar>
            )}
            {saved ? (
              <Card className="mt-3 rounded">
                <CardHeader className="bg-warning text-warning rounded-top">
                  Once a graphic has been saved, you cannot edit the linked events. Create a new graphic to link different events.
                </CardHeader>
                <ListGroup flush>
                  {linkedEvents.map(event => eventComponent(event, false))}
                </ListGroup>
              </Card>
            ) : (
              queriedEvents.length ? (
                <Fragment>
                  <h4 className="mt-4">Upcoming Events</h4>
                  <Card className="mt-3 rounded">
                    <ListGroup flush>
                      {queriedEvents.map(event => eventComponent(event, !scope))}
                    </ListGroup>
                  </Card>
                </Fragment>
              ) : (
                <div className="card card-empty">
                  <div className="card-body">
                    { query ? 'No events match your filter.' : 'No future published events found.'}
                  </div>
                </div>
              ))}
          </LoadingIndicator>
        </ModalBody>
        {multi && !saved && (
          <ModalFooter>
            <Row className="w-100 px-0">
              <Col className="px-0">
                <Button color="primary" className="w-100" onClick={setMultiEvents}>Link these Events</Button>
              </Col>
              <Col xs="auto" className="pe-0">
                <Button color="outline-secondary" onClick={() => setOpen(false)}>Cancel</Button>
              </Col>
            </Row>
          </ModalFooter>
        )}
      </Modal >
    </>
  ) : null
}
