import React, { useState, useEffect, Fragment } from 'react'
import { Input } from 'reactstrap'

export default function XmlStreamSelection(props) {
  const {
    key, has_xml_stream_selected_from, xml_fill_field_names,
    xml_stream_selections, xml_stream_selected_from, field_id,
  } = props.field

  const xml_stream_default_index = props.field.xml_stream_default_index || 0

  const [index, setIndex] = useState(xml_stream_default_index)

  const selectOptions = () => {
    let selectOptions
    if (has_xml_stream_selected_from) {
      const selectedFrom = props.selectValues[xml_stream_selected_from]
      const selectedValues = xml_stream_selections.find((o) => o.for == selectedFrom) || {}
      selectOptions = selectedValues.fill || []
    } else {
      selectOptions = xml_stream_selections || []
    }

    return selectOptions.filter(option => option[0] != '')
  }

  useEffect(() => {
    setIndex(xml_stream_default_index)
  }, [props.selectValues[xml_stream_selected_from]])

  const onChange = (e) => {
    setIndex(e.target.selectedIndex)
  }

  const optionsAndValues = selectOptions()
  const optionNames = optionsAndValues.map(option => option[0])
  const optionValues = optionsAndValues.map(option => option.slice(1))

  const optionElements = (
    <Fragment>
      {optionNames.map(option => (
        <option key={option}>
          {option}
        </option>
      ))}
      <option>Custom</option>
    </Fragment>
  )

  const showCustomField = index >= optionNames.length

  const hiddenInputs = showCustomField ? null : xml_fill_field_names.map((name, namesIndex) => (
    <input type="hidden" key={name} name={name} value={optionValues[index][namesIndex]} />
  ))

  const customField = (
    <div className="producer-form-xml-custom-input">
      {props.customField}
    </div>
  )

  const selectKey = `xml:${key}`

  const value = optionNames[index] || 'Custom'

  return (
    <Fragment>
      <Input type="select" key={selectKey} id={`xml-${field_id}`} value={value} onChange={onChange}>
        {optionElements}
      </Input>
      {showCustomField ? customField : hiddenInputs}
    </Fragment>
  )
}


