import axios from '../../components/shared/axios'
import { actions } from '../team_graphics'

const loadGraphicsPage = async (state, dispatch) => {
  const response = await axios.get(`${state.basename}.json`, {
    params: {
      page: state.page,
      filter: state.filter,
    }
  })

  if (state.page > response.data.total_pages) {
    dispatch({ type: actions.PAGINATE, page: response.data.total_pages })
  } else {
    dispatch({
      type: actions.GRAPHICS_LOADED,
      graphics: response.data.graphics.data,
      page: state.page,
      perPage: response.data.per_page,
      totalPages: response.data.total_pages,
      totalEntries: response.data.total_entries,
    })
  }
}

const deleteGraphic = async (state, action, dispatch) => {
  await axios.delete(`${state.basename}/${action.id}.json`)
  dispatch({ type: actions.SET_LOADING_GRAPHICS })
  loadGraphicsPage(state, dispatch)
}

const renameGraphic = async (state, action, dispatch) => {
  const response = await axios.post(`${state.basename}/${action.id}/rename.json`, {
    graphic: {
      display_title: action.title,
    }
  })

  dispatch({ type: actions.REPLACE_GRAPHIC, graphic: response.data.data })
}

export { loadGraphicsPage, deleteGraphic, renameGraphic }
