import axios from '../../components/shared/axios'
import { actions } from '../uploads'

const uploadsInFolder = (folder, state) => {
  return state.uploads.filter(item => item.attributes.upload_folder_id == folder.id)
}

const subfolders = (folder, state) => {
  const folders = state.folders.filter(item => item.attributes.parent_id == folder.id)
  folders.forEach(subfolder => folders.push(...subfolders(subfolder, state)))
  return folders
}

const deleteFiles = async (state, action, dispatch) => {
  await axios.delete(`${state.basepath}/uploads/delete.json`, { data: action.formData })
  const uploadsToDelete = action.items.filter(item => item.type == 'upload')
  const foldersToDelete = action.items.filter(item => item.type == 'upload_folder').map(folder => {
    return [folder, ...subfolders(folder, state)]
  }).flat()

  foldersToDelete.forEach(folder => uploadsToDelete.push(...uploadsInFolder(folder, state)))

  const uploadIdsToDelete = uploadsToDelete.map(u => u.id)
  const folderIdsToDelete = foldersToDelete.map(f => f.id)

  let newUploads = state.uploads.filter(item => !uploadIdsToDelete.includes(item.id))
  let newFolders = state.folders.filter(item => !folderIdsToDelete.includes(item.id))

  dispatch({ type: actions.SET_UPLOADS_AND_FOLDERS, folders: newFolders, uploads: newUploads })
}

export { deleteFiles }
