import ReactRailsUJS from "react_ujs"
import TextareaAutosize from 'react-textarea-autosize'

const modules = import.meta.glob('../javascripts/components/*.{js,jsx}', { eager: true, import: 'default' })
const components = Object.fromEntries(Object.entries(modules).map(([path, module]) => {
  const name = path.match(/([^/]+)\.jsx?/)[1]
  return [name, module]
}))

// Add TextareaAutosize component for rendering with react_component view helper
components['TextareaAutosize'] = TextareaAutosize

ReactRailsUJS.components = components

ReactRailsUJS.getConstructor = function (name) {
  return components[name]
}
