import React, { useContext, useEffect, useState } from 'react'
import { Label, Button, Form, FormGroup } from 'reactstrap'
import ProcessingOverlay from '../ProcessingOverlay'
import ShareTextarea from './ShareTextarea'
import SocialAccountsSelector from './SocialAccountsSelector'
import ShareSchedulerNext from './ShareSchedulerNext'
import { store } from '../../stores/share'

export default function MultiShareForm({ share, onChange }) {
  const {
    shares,
    scheduled,
    multiShareState,
    graphic,
    dispatch,
    actions,
    states,
    facebookAccounts,
    twitterAccounts,
    instagramAccounts,
    eventLink,
    hometownLink } = useContext(store)

  const [facebookData, setFacebookData] = useState(null)
  const [twitterData, setTwitterData] = useState(null)
  const [instagramData, setInstagramData] = useState(null)
  const [message, setMessage] = useState(share && share.id ? share.metadata.message : eventLink?.message || hometownLink?.message || '')
  const [promptLogId, setPromptLogId] = useState(null)
  const [scheduleAt, setScheduleAt] = useState(share && share.scheduled_at ? {
    date: new Date(share.scheduled_at).toJSON().slice(0, 10),
    time: new Date(share.scheduled_at).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
  } : null)
  const isAnyEnabled = (facebookData?.isEnabled || twitterData?.isEnabled || instagramData?.isEnabled) ? true : false

  const whichEnabled = [
    facebookData?.isEnabled ? facebookData : null,
    twitterData?.isEnabled ? twitterData : null,
    instagramData?.isEnabled ? instagramData : null,
  ].filter(Boolean);

  const [whichEnabledButtonText, setWhichEnabledButtonText] = useState(null)

  const canFacebookPost = (facebookData?.canPost || facebookData?.canPost === undefined) ? true : false
  const canTwitterPost = (twitterData?.canPost || twitterData?.canPost === undefined) ? true : false
  const canInstagramPost = (instagramData?.canPost || instagramData?.canPost === undefined) ? true : false

  const [messageError, setMessageError] = useState('')
  const [socialAccountError, setSocialAccountError] = useState('')
  const [facebookMultiError, setFacebookMultiError] = useState(false)
  const [twitterMultiError, setTwitterMultiError] = useState(false)
  const [instagramMultiError, setInstagramMultiError] = useState(false)

  const sharesAndScheduled = shares ? shares.data.concat(scheduled.data) : scheduled.data
  const postedToFacebook = facebookAccounts.length == 0 || sharesAndScheduled.some(share => (share.social_account && share.social_account.attributes.provider === 'facebook') || share?.facebook)
  const postedToTwitter = twitterAccounts.length == 0 || sharesAndScheduled.some(share => (share.social_account && share.social_account.attributes.provider === 'twitter') || share?.twitter)
  const postedToInstagram = instagramAccounts.length == 0 || sharesAndScheduled.some(share => (share.social_account && share.social_account.attributes.provider === 'instagram') || share?.instagram)
  const postedToAll = postedToFacebook && postedToTwitter && postedToInstagram
  const [showForm, setShowForm] = useState(!postedToAll || share?.id !== null)
  const [postButtonText, setPostButtonText] = useState('Post Graphic')

  const canSelectedPost = (
    canFacebookPost &&
    canTwitterPost &&
    canInstagramPost
  ) ? true : false

  const lengthArray = [facebookData?.maxLength, twitterData?.maxLength, instagramData?.maxLength].filter(Boolean)

  const maxLength = Math.min(...lengthArray);

  const multiFormat = graphic?.attributes?.formats?.length > 1 || false

  // Provide a warning if the user tries to navigate away from the page with a message in the textarea
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (message && message.trim() !== '') {
        e.preventDefault()
      }
    }

    // Only add the event listener if the form is shown
    if (showForm) {
      // Add the event listener
      window.addEventListener('beforeunload', handleBeforeUnload)

      // Cleanup the event listener on unmount
      return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [message, showForm])

  const getAccountName = (num) => {
    switch(num) {
      case facebookData?.maxLength:
        return facebookData.label;
      case twitterData?.maxLength:
        return twitterData.label;
      case instagramData?.maxLength:
        return instagramData.label;
      default:
        return '';
    }
  }

  useEffect(() => {
    setShowForm(!postedToAll || share?.id !== null)
  }, [postedToAll, shares, scheduled])

  useEffect(() => {
    const preposition = scheduleAt ? ' for' : ' to'
    if (whichEnabled.length === 1) {
      setWhichEnabledButtonText(`${preposition} ${whichEnabled[0].label}`)
    }
    else if (whichEnabled.length > 1 ) {
      setWhichEnabledButtonText(`${preposition} ${whichEnabled.length} Accounts`)
    }
    else {
      setWhichEnabledButtonText(null)
    }
  }, [whichEnabled])

useEffect(() => {
  if (scheduleAt) {
    if (multiShareState == states.processing) {
      setPostButtonText('Scheduling Graphic')
    }
    else {
      setPostButtonText('Schedule Graphic')
    }
  }
  else {
    if (multiShareState == states.processing) {
      setPostButtonText('Posting Graphic')
    }
    else {
      setPostButtonText('Post Graphic')
    }
  }
}, [postButtonText, scheduleAt, multiShareState, states])

  const displayCount = () => {
    return (
      (lengthArray.length > 0) ?
      <>
        <div className="mt-1">
          <span>Character Count: </span>
          <strong className={(message.length > maxLength ? ' text-danger' : null)}>
            {maxLength - message.length}/{maxLength}
          </strong>
        </div>
        <div className="text-muted">Set to the maximum character count of {getAccountName(maxLength)}</div>
      </>
        : null
    )
  }

  useEffect(() => {
    if (onChange) {
      onChange({
        facebook: facebookData,
        twitter: twitterData,
        instagram: instagramData,
      })
    }
  }, [facebookData, twitterData, instagramData])

  const validationCheck = (
    !isAnyEnabled || // Make sure at least one social media account is selected
    !canSelectedPost || // Verify that the selected social media accounts can post
    message.length > maxLength || // Verify that the message length is within the character limit
    (
      multiFormat && // Conditions if there are multiple formats
      (
        // Check that the social media account is enabled and that there are no formats selected
        (facebookData?.isEnabled && facebookData?.formats.length === 0) ||
        (twitterData?.isEnabled && twitterData?.formats.length === 0) ||
        (instagramData?.isEnabled && instagramData?.formats.length === 0)
      )
    )
  )

  const validateForm = () => {
    if (validationCheck) {
      if (message.length > maxLength) {
        setMessageError(
          <>
            Please edit the length of your post message.<br />
            The post message is currently {message.length} characters, but the maximum allowed for your social media account selection is {maxLength}.
          </>
        )
      }
      else {
        setMessageError(null);
      }

      if (!isAnyEnabled) {
        setSocialAccountError(
          <>Please select at least one social media account to post to.</>
        )
      } else {
        setSocialAccountError(null);
      }

      if (multiFormat) {
        if (facebookData?.isEnabled && facebookData?.formats.length === 0) {
          setFacebookMultiError(<>Please select at least one graphic format to post to Facebook</>);
        } else {
          setFacebookMultiError(false);
        }

        if (twitterData?.isEnabled && twitterData?.formats.length === 0) {
          setTwitterMultiError(<>Please select at least one graphic format to post to Twitter</>);
        } else {
          setTwitterMultiError(false);
        }

        if (instagramData?.isEnabled && instagramData?.formats.length === 0) {
          setInstagramMultiError(<>Please select at least one graphic format to post to Instagram</>);
        } else {
          setInstagramMultiError(false);
        }
      }
    } else {
      dispatchShare()
    }
  }
  const dispatchShare = async () => {
    dispatch({
      type: actions.SHARE_MULTI,
      multi: true,
      facebook: facebookData,
      twitter: twitterData,
      instagram: instagramData,
      message,
      promptLogId,
      scheduleAt,
      shareId: share ? share.id : null
    })
  }

  useEffect(() => {
    if (messageError && (message.length <= maxLength)) {
      setMessageError(null)
    }
    if (socialAccountError && isAnyEnabled) {
      setSocialAccountError(null)
    }
    if (facebookMultiError && (!facebookData?.hasOwnProperty('formats') || facebookData?.formats.length > 0)) {
      setFacebookMultiError(false)
    }
    if (twitterMultiError && (!twitterData?.hasOwnProperty('formats') || twitterData?.formats.length > 0)) {
      setTwitterMultiError(false)
    }
    if (instagramMultiError && (!instagramData?.hasOwnProperty('formats') || instagramData?.formats.length > 0)) {
      setInstagramMultiError(false)
    }
  }, [messageError, message, maxLength, socialAccountError, isAnyEnabled, facebookMultiError, facebookData, twitterMultiError, twitterData, instagramMultiError, instagramData])

  return showForm ? (
    <ProcessingOverlay message="Processing…" active={multiShareState == states.processing} delay={300}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
          <ShareTextarea
            message={message}
            onChange={setMessage}
            onPromptLogIdChange={setPromptLogId}
            platforms={[
              facebookData?.isEnabled && 'facebook',
              twitterData?.isEnabled && 'twitter',
              instagramData?.isEnabled && 'instagram'
            ].filter(Boolean)}
            error={messageError}
          />
          { displayCount() }
        </FormGroup>
        <fieldset>
          <legend className="visually-hidden">Social Media Accounts</legend>
          <div aria-live="polite" id="socialAccountError">{socialAccountError ? (<p className='fw-bold text-danger'>{socialAccountError}</p>) : null}</div>
          { facebookAccounts?.length > 0 ? (
            <>
              { multiFormat ? (
                <div aria-live="polite" id="facebookMultiError">{facebookMultiError ? (<p className='fw-bold text-danger'>{facebookMultiError}</p>) : null }</div>
              ) : null }
              <SocialAccountsSelector share={share} type="facebook" checked={!postedToFacebook} onChange={(value) => setFacebookData(value) } error={facebookMultiError} />
            </>
          ) :null }
          { twitterAccounts?.length > 0 ? (
            <>
            { multiFormat ? (
              <div aria-live="polite" id="twitterMultiError">{twitterMultiError ? (
                <p className='fw-bold text-danger'>{twitterMultiError}</p>) : null }
              </div>
            ) : null }
            <SocialAccountsSelector share={share} type="twitter" checked={!postedToTwitter} onChange={(value) => setTwitterData(value)} error={twitterMultiError} />
          </>
          ) :null }
          { instagramAccounts?.length > 0 ? (
            <>
              { multiFormat ? (
                <div aria-live="polite" id="instagramMultiError">{instagramMultiError ? (
                  <p className='fw-bold text-danger'>{instagramMultiError}</p>) : null }
                </div>
              ) : null }
              <SocialAccountsSelector share={share} type="instagram" checked={!postedToInstagram} repost={false} onChange={(value) => setInstagramData(value) } error={instagramMultiError} />
            </>
            ) :null }
        </fieldset>
        <div>
          <div className="mb-3">
            <ShareSchedulerNext scheduleAt={scheduleAt} onChange={(schedule) => setScheduleAt(schedule)} />
          </div>

          <div aria-live="polite" >
            {(messageError || socialAccountError || facebookMultiError || twitterMultiError || instagramMultiError) ? (
            <>
              <h2 className="m-0 h6 fst-normal"><strong>Please correct the following errors and try again:</strong></h2>
              <ul className="text-danger fw-bold ps-3">
                {messageError ? <li className="mb-1">{messageError} <a href="#message" className='fw-normal'>Fix message error</a></li> : null}
                {socialAccountError ? <li>{socialAccountError} <a href="#socialAccountError" className='fw-normal'>Fix social account selection error</a></li> : null}
                {facebookMultiError ? <li className="mb-1">{facebookMultiError} <a href="#facebookMultiError" className='fw-normal'>Fix Facebook format error</a></li> : null }
                {twitterMultiError ? <li className="mb-1">{twitterMultiError} <a href="#twitterMultiError" className='fw-normal'>Fix Twitter format error</a></li> : null }
                {instagramMultiError ? <li>{instagramMultiError} <a href="#instagramMultiError" className='fw-normal'>Fix Instagram format error</a></li> : null }
              </ul>
            </>) : null}
          </div>

          <Button color="primary" size="lg" className="w-100" type="submit" onClick={validateForm} disabled={multiShareState == states.processing}>
            { postButtonText }{ whichEnabledButtonText }
          </Button>
        </div>
      </Form>
    </ProcessingOverlay>
  ) : (
    <div className="d-md-flex">
      <div className="border rounded-top-2 rounded-md-start-2 rounded-md-top-end-0 text-bg-light p-2 flex-grow-1">
        <p className="m-0 fs-6 lh-sm text-center text-md-start">You have posted or scheduled to all your social media accounts.</p>
      </div>

      <Button color="outline-secondary border-top-0 rounded-0 rounded-bottom-2 border-top-md border-start-md-0 rounded-end-md-2 rounded-bottom-start-md-0 w-100 w-md-unset" onClick={() => setShowForm(true)}>
        Show form to post again
      </Button>
    </div>
  )
}
