import React, { createContext, useRef, useCallback } from 'react'
import { useReducer } from 'react'
import { deleteFiles } from './uploads/delete'
import { displayFiles, loadFilesIfNeeded, renameFile, updateFile, editVideo } from './uploads/files'
import { addFolder, displayBreadcrumbs, displayFolders, renameFolder, currentFolder, currentFolderIsShared } from './uploads/folders'
import { moveItems } from './uploads/move'
import { queryOpponentLogos } from './uploads/opponent_logos'
import { selectItems } from './uploads/selections'
import { shareItems, unshareItems } from './uploads/share'
import { finishUpload, processUpload, startUpload, updateUpload, uploadProgress } from './uploads/upload'

const store = createContext({})

const { Provider } = store

const actions = {
  SET_LOADING: 'setLoading',
  LOAD_FILES: 'loadFiles',
  FILES_LOADED: 'filesLoaded',
  SELECT_FOLDER_AND_CATEGORY: 'selectFolderAndCategory',
  SET_PROCESSING: 'setProcessing',
  ADD_FOLDER: 'addFolder',
  RENAME_FOLDER: 'renameFolder',
  RENAME_FILE: 'renameFile',
  FOLDER_UPDATED: 'folderUpdated',
  FOLDER_CREATED: 'folderCreated',
  FOLDER_RENAMED: 'folderRenamed',
  FILE_RENAMED: 'fileRenamed',
  TOGGLE_MODAL: 'toggleModal',
  REDIRECT: 'redirect',
  CLEAR_REDIRECT: 'clearRedirect',
  MOVE_ITEMS: 'moveItems',
  DELETE_ITEMS: 'deleteItems',
  SELECT_ITEMS: 'selectItems',
  PAGINATE: 'paginate',
  FILTER: 'filter',
  SHARE_ITEMS: 'shareItems',
  UNSHARE_ITEMS: 'unshareItems',
  SET_UPLOADS_AND_FOLDERS: 'setUploadsAndFolders',
  START_UPLOAD: 'startUpload',
  UPDATE_UPLOAD: 'updateUpload',
  PROCESS_UPLOAD: 'processUpload',
  FINISH_UPLOAD: 'finishUpload',
  GET_STATE: 'getState',
  QUERY_OPPONENT_LOGO: 'queryOpponentLogo',
  SET_OPPONENT_LOGOS_FOR_QUERY: 'setOpponentLogosForQuery',
  UPDATE_FILE: 'updateFile',
  EDIT_VIDEO: 'editVideo',
  ADD_UPLOAD: 'addUpload',
  SET_SORT: 'setSort',
}

const STORAGE_KEY_PREFIX = 'boxout_uploads_sort_'

const getStoredSort = (userId) => {
  if (!userId) return 'date_desc';
  try {
    const stored = localStorage.getItem(`${STORAGE_KEY_PREFIX}${userId}`);
    return stored || 'date_desc';
  } catch (e) {
    console.warn('Failed to read sort preference from localStorage:', e);
    return 'date_desc';
  }
}

const storeSort = (userId, sortBy) => {
  if (!userId) return;
  try {
    localStorage.setItem(`${STORAGE_KEY_PREFIX}${userId}`, sortBy);
  } catch (e) {
    console.warn('Failed to save sort preference to localStorage:', e);
  }
}

const useEnhancedReducer = (reducer, initState, initializer) => {
  const lastState = useRef(initState)
  const getState = useCallback(() => lastState.current, [])
  return [
    ...useReducer(
      (state, action) => lastState.current = reducer(state, action),
      initState,
      initializer
    ),
    getState
  ]
}

const reduce = (state, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      return { ...state, loading: true }
    case actions.SET_SORT:
      storeSort(state.userId, action.sortBy);
      return { ...state, sortBy: action.sortBy }
    case actions.SELECT_FOLDER_AND_CATEGORY:
      return {
        ...state,
        folder: action.folder,
        category: action.category,
        showOpponentLogos: false,
        page: 1,
        filter: '',
        selected: {
          ...state.selected,
          [action.category]: [],
        }
      }
    case actions.REDIRECT:
      return { ...state, redirectTo: action.url, page: 1 }
    case actions.CLEAR_REDIRECT:
      return { ...state, redirectTo: null }
    case actions.FOLDER_CREATED:
      return {
        ...state,
        processing: false,
        openModal: false,
        folders: [...state.folders, action.folder],
      }
    case actions.FOLDER_RENAMED:
      return {
        ...state,
        processing: false,
        openModal: false,
        folders: state.folders.map(f => f.id == action.folder.id ? action.folder : f),
      }
    case actions.FILE_RENAMED:
      return {
        ...state,
        processing: false,
        openModal: false,
        uploads: state.uploads.map(f => f.id == action.upload.id ? action.upload : f),
      }
    case actions.SET_PROCESSING:
      return { ...state, processing: true }
    case actions.FILTER:
      return { ...state, filter: action.filter, page: 1 }
    case actions.FILES_LOADED:
      return {
        ...state,
        folders: action.folders,
        uploads: action.uploads,
        rootLoaded: action.rootLoaded,
        opponentLogos: action.opponentLogos ? action.opponentLogos : state.opponentLogos,
        loading: false,
      }
    case actions.PAGINATE:
      return {
        ...state,
        page: action.page,
      }
    case actions.SET_UPLOADS_AND_FOLDERS:
      return {
        ...state,
        processing: false,
        openModal: false,
        selected: {
          ...state.selected,
          [state.category]: [],
        },
        uploads: action.uploads,
        folders: action.folders,
      }
    case actions.UPDATE_FILE:
      return {
        ...state,
        uploads: updateFile(state.uploads, action)
      }
    case actions.TOGGLE_MODAL:
      if (action.modal == state.openModal) {
        return { ...state, openModal: false, modalData: {} }
      } else {
        return { ...state, openModal: action.modal, modalData: action.modalData || {} }
      }
    case actions.SELECT_ITEMS:
      return selectItems(action, state)
    case actions.START_UPLOAD:
      return startUpload(action, state)
    case actions.UPDATE_UPLOAD:
      return updateUpload(action, state)
    case actions.FINISH_UPLOAD:
      return finishUpload(action, state)
    case actions.SET_OPPONENT_LOGOS_FOR_QUERY:
      return {
        ...state,
        queriedOpponentLogos: {
          ...state.queriedOpponentLogos,
          [action.query]: action.opponentLogos,
        },
      }
    case actions.GET_STATE:
      return state
    case actions.ADD_UPLOAD:
      return {
        ...state,
        uploads: [...state.uploads, action.upload],
      }
    default:
      return state
  }
}

const StateProvider = (props) => {
  const selected = {}

  props.categories.forEach(c => selected[c] = [])

  const initialState = {
    ...props,
    uploads: [],
    folders: props.folders.data,
    canManageShared: props.canManageShared,
    rootLoaded: [],
    currentFolder: null,
    currentFolderIsShared: false,
    loading: true,
    uploadingFiles: [],
    redirectTo: null,
    modalData: {},
    page: 1,
    perPage: 100,
    opponentLogos: [],
    queriedOpponentLogos: {},
    filter: '',
    selected: selected,
    sortBy: getStoredSort(props.userId),
  }

  const [state, dispatch, getState] = useEnhancedReducer(reduce, initialState)

  const asyncDispatch = (action) => {
    const state = getState()
    switch (action.type) {
      case actions.LOAD_FILES:
        dispatch({ type: actions.SELECT_FOLDER_AND_CATEGORY, folder: action.id, category: action.category})
        return loadFilesIfNeeded(state, action, dispatch)
      case actions.ADD_FOLDER:
        dispatch({ type: actions.SET_PROCESSING})
        return addFolder(state, action, dispatch)
      case actions.RENAME_FOLDER:
        dispatch({ type: actions.SET_PROCESSING})
        return renameFolder(state, action, dispatch)
      case actions.RENAME_FILE:
        dispatch({ type: actions.SET_PROCESSING})
        return renameFile(state, action, dispatch)
      case actions.DELETE_ITEMS:
        dispatch({ type: actions.SET_PROCESSING})
        return deleteFiles(state, action, dispatch)
      case actions.MOVE_ITEMS:
        dispatch({ type: actions.SET_PROCESSING})
        return moveItems(state, action, dispatch)
      case actions.SHARE_ITEMS:
        dispatch({ type: actions.SET_PROCESSING})
        return shareItems(state, action, dispatch)
      case actions.UNSHARE_ITEMS:
        dispatch({ type: actions.SET_PROCESSING})
        return unshareItems(state, action, dispatch)
      case actions.QUERY_OPPONENT_LOGO:
        return queryOpponentLogos(state, action, dispatch)
      case actions.PROCESS_UPLOAD:
        return processUpload(state, action, dispatch)
      case actions.EDIT_VIDEO:
        return editVideo(state, action, dispatch)
      default:
        return dispatch(action)
    }
  }

  const usableData = {
    ...state,
    displayFolders: displayFolders(state),
    displayFiles: displayFiles(state),
    breadcrumbs: displayBreadcrumbs(state),
    currentSelections: state.selected[state.category] || [],
    currentFolder: currentFolder(state),
    currentFolderIsShared: currentFolderIsShared(state),
    uploadProgress: uploadProgress(state),
    showOpponentLogos: state.category == 'opponentLogo',
    actions,
  }

  const value = {
    ...usableData,
    dispatch: asyncDispatch.bind(usableData)
  }

  return <Provider value={value}>{props.children}</Provider>
}

export { store, StateProvider, actions }