import React, { forwardRef } from 'react'

const HomeTownLogo = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path fillRule="evenodd" d="M14 2v3h2v5H8V5h2V2H1v3h2v14H1v3h9v-3H8v-5h8v5h-2v3h9v-3h-2V5h2V2z"/>
    </svg>
  )
})

HomeTownLogo.displayName = 'HomeTownLogo'

export default HomeTownLogo
