import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { allTemplatesForTier } from '../../lib/templates'
import { store } from '../../stores/templates'
import Fuse from 'fuse.js'
import TemplateCategories from './TemplateCategories'
import TemplatesBreadcrumb from './TemplatesBreadcrumb'
import TemplatePreviews from './TemplatePreviews'
import TemplatesFilter from './TemplatesFilter'
import TemplatesNav from './TemplatesNav'

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function TemplatesPlan() {
  const [query, setQuery] = useState('')
  const isFiltering = query.trim() != ''

  const {
    templateCategories,
    templates,
    plans,
    hasSubscriptionAccess,
    canManageTemplates,
    basename,
    subscriptionTier,
    isAdmin,
    subscribed,
    subscriptionLink,
  } = useContext(store)

  const { plan } = useParams()

  const displayTemplates = allTemplatesForTier(templates, plans[plan])

  const fuse = new Fuse(displayTemplates, {
    keys: ['attributes.title', 'attributes.tags'],
    threshold: 0.3,
  })

  useEffect(() => {
    document.title = `${capitalizeFirstLetter(plan)} Templates | Template Library | Box Out Sports`
  }, [plan])

  const filteredTemplates = isFiltering ? fuse.search(query.trim()).map(f => f.item) : null

  const filterResults = isFiltering ? (
    <TemplatePreviews
      isAdmin={isAdmin}
      subscribed={subscribed}
      subscriptionTier={subscriptionTier}
      hasSubscriptionAccess={hasSubscriptionAccess}
      canManageTemplates={canManageTemplates}
      templates={filteredTemplates}
      templateCategories={templateCategories}
      basename={basename}
      templateLink={`/${plan}/:category/:slug`} />
  ) : null

  const normalResults = isFiltering ? null : (
    <TemplateCategories templateCategories={templateCategories} templates={templates} plans={plans} />
  )

  return (
    <>
      <TemplatesNav active="library" />
      <TemplatesFilter
        subscriptionTier={subscriptionTier}
        subscribed={subscribed}
        subscriptionLink={subscriptionLink}
        isAdmin={isAdmin}
        plans={plans}
        templates={templates}
        templateCategories={templateCategories}
        onChange={setQuery} />
      <TemplatesBreadcrumb templateCategories={templateCategories} query={query} />
      {filterResults}
      {normalResults}
    </>
  )
}
