import React, { useEffect, useContext } from 'react'
import Pusher from 'pusher-js'
import { Fragment } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { store } from '../../stores/uploads'
import { categoryForParam } from '../../stores/uploads/categories'
import { sortItems } from '../../stores/uploads/sort'
import LoadingIndicator from '../shared/LoadingIndicator'
import UploadsBreadcrumbs from './UploadsBreadcrumbs'
import UploadsDropzone from './UploadsDropzone'
import UploadsFile from './UploadsFile'
import UploadsFolder from './UploadsFolder'
import UploadsEmpty from './UploadsEmpty'
import UploadsStickyBar from './UploadsStickyBar'
import UploadsTabs from './UploadsTabs'
import UploadsMove from './UploadsMove'
import UploadsDelete from './UploadsDelete'
import UploadsShare from './UploadsShare'
import UploadsOpponentLogos from './UploadsOpponentLogos'
import UploadsSortDropdown from './UploadsSortDropdown'

export default function UploadsCategory(props) {
  const routeMatches = useParams()
  const category = categoryForParam(props.category || routeMatches.category)
  const folderId = props.folder || routeMatches.folder || null
  const {
    showOpponentLogos,
    displayFolders,
    displayFiles,
    actsAsMenu,
    currentSelections,
    loading,
    page,
    perPage,
    filter,
    pusherAppKey,
    pusherCluster,
    teamChannel,
    orgChannel,
    dispatch,
    redirectTo,
    actions,
    sponsorLogo,
    selfSignUpTrial,
    sortBy,
    canSort,
  } = useContext(store)

  useEffect(() => {
    const socket = new Pusher(pusherAppKey, {
      cluster: pusherCluster,
    })
    socket.subscribe(teamChannel).bind('upload', (data) => dispatch({ ...data, type: actions.UPDATE_FILE }))

    if (orgChannel) {
      socket.subscribe(orgChannel).bind('upload', (data) => dispatch({ ...data, type: actions.UPDATE_FILE }))
    }
  }, [])

  const navigate = useNavigate()

  const startIndex = (page - 1) * perPage
  const sortedItems = sortItems([...displayFolders, ...displayFiles], sortBy)
  const currentPageItems = sortedItems.slice(startIndex, startIndex + perPage)

  useEffect(() => {
    if (redirectTo) {
      navigate(redirectTo)
    }
  }, [redirectTo])

  useEffect(() => {
    dispatch({
      type: actions.LOAD_FILES,
      id: folderId,
      category: category,
    })
  }, [category, folderId])

  const folderElement = (folder) => {
    const key = `upload_folder:${folder.id}`
    const selected = currentSelections.includes(key)
    return (
      <UploadsFolder
        key={key}
        keyString={key}
        folder={folder}
        selected={selected} />
    )
  }

  const fileElement = (upload) => {
    const key = `upload:${upload.id}`
    const selected = currentSelections.includes(key)
    return (
      <UploadsFile
        key={key}
        keyString={key}
        selected={selected}
        upload={upload} />
    )
  }

  const noUploadElement = actsAsMenu && folderId == null ? (
    <UploadsFile
      upload={null}
      key="noUpload"
      selected={false}
      keyString="noUpload" />
  ) : null

  const displayElements = currentPageItems.map(item => {
    switch (item.type) {
      case 'upload_folder':
        return folderElement(item)
      case 'upload':
        return fileElement(item)
    }
  })


  const emptyElement = displayFiles.length + displayFolders.length == 0 ? (
    <UploadsEmpty />
  ) : null

  const defaultUploadView = (
    <UploadsDropzone>
      {({ uploadProgress, open: openUploadDialog }) => (
        <Fragment>
          <UploadsTabs />
          {showOpponentLogos ? (
            <UploadsOpponentLogos uploadMenuState={props.uploadMenuState} />
          ) : (
            <Fragment>
              <LoadingIndicator loading={loading} title="Loading uploads…">
                <UploadsStickyBar
                  uploadProgress={uploadProgress}
                  openUploadDialog={openUploadDialog}
                  currentPageItems={currentPageItems}
                  setUpload />
                {filter ? (
                  <h2 className="mb-3">Search for &lsquo;{filter}&rsquo;</h2>
                ) : (
                  <div className="d-flex flex-wrap align-items-center mb-3 gap-2">
                    <div className="flex-grow-1 d-flex align-items-center">
                      <UploadsBreadcrumbs />
                    </div>
                    {canSort ? (
                      <div className="flex-shrink-1 d-flex justify-content-end align-items-center">
                        <UploadsSortDropdown 
                          sortBy={sortBy}
                          onSortChange={(value) => dispatch({ type: actions.SET_SORT, sortBy: value })}
                          />
                      </div>
                    ) : null}
                  </div>
                )}
                <div className="upload-container">
                  {noUploadElement}
                  {displayElements}
                </div>
                {emptyElement}
              </LoadingIndicator>
              <UploadsMove />
              <UploadsDelete />
              <UploadsShare />
            </Fragment>
          )}
        </Fragment>
      )}
    </UploadsDropzone>
  )

  return (
    <>
      { defaultUploadView }
    </>
  )
}