import React, { useContext, useEffect, useState } from 'react'
import { Instagram, X } from 'react-feather'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { store } from '../../stores/share'
import Field from '../Fields/Field'
import ShareFormatSelectorLegacy from './ShareFormatSelectorLegacy'
import ShareScheduler from './ShareScheduler'

// TODO: remove this after Aug 2025, which is when the last old single-post share is scheduled in production
export default function ShareInstagram(props) {
  const { close, isOpen } = props
  const { graphic, instagramState, instagramAccounts, hometownLink, states, actions, dispatch } = useContext(store)

  const share = props.share || {
    id: null,
    metadata: {
      message: hometownLink ? `Tickets at ${hometownLink}` : '',
      formats: graphic.attributes.formats.filter(format => format.social.match(/instagram|all/)).slice(0, 1),
    },
    scheduled_at: null,
    graphic: graphic,
  }

  const formats = share.graphic.attributes.formats

  const [message, setMessage] = useState(share.metadata.message)
  const [selectedFormatIds, setSelectedFormatIds] = useState(share.metadata.formats.map(format => format.id))
  const [scheduleAt, setScheduleAt] = useState(share.scheduled_at ? {
    date: new Date(share.scheduled_at).toJSON().slice(0, 10),
    time: new Date(share.scheduled_at).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
  } : null)

  const selectedFormats = formats.filter(format => selectedFormatIds.includes(format.id))
  const instagramAccount = share.social_account || instagramAccounts[0]

  if (!instagramAccount) {
    return null
  }

  useEffect(() => {
    if (instagramState == states.complete) {
      handleClose()
    }
  }, [instagramState]);

  const postField = {
    type: 'text_area',
    show_title: false,
    field_name: 'fbpost',
    field_id: 'fbpost',
    min_rows: 2,
    value: message,
    placeholder: 'Enter text to post along with the graphic…',
  }

  const noFormatSelected = selectedFormatIds.length == 0
  const moreThanOneFormatSelected = selectedFormatIds.length > 1

  let limitation = false
  const selectedFormat = selectedFormats[0] || {}
  const maxRatio = selectedFormat.video ? 16 / 9 : 1.91

  if (!moreThanOneFormatSelected && !noFormatSelected && (selectedFormat.ratio < 4 / 5 || selectedFormat.ratio > maxRatio)) {
    limitation = "Sorry, Instagram does not allow posting graphics of this height/width ratio."
  }

  const disabled = moreThanOneFormatSelected ||
    !!limitation ||
    noFormatSelected ||
    instagramState == states.processing ||
    message.length == 0 ||
    (scheduleAt && (!scheduleAt.date || !scheduleAt.time))

  const stateNotifiers = {
    [states.error]: (
      <div className="text-danger text-center fw-bold mt-2">
        Something went wrong, try again
      </div>
    ),
  }

  const handleSend = async () => {
    dispatch({
      type: actions.SHARE_INSTAGRAM,
      shareId: share.id,
      message,
      formats: selectedFormats,
      socialAccount: instagramAccount,
      scheduleAt,
    })
  }

  const buttonText = () => {
    if (instagramState == states.processing) {
      if (scheduleAt) {
        return 'Scheduling…'
      } else {
        return 'Posting…'
      }
    } else if (noFormatSelected) {
      return 'Select at least one format'
    } else {
      if (scheduleAt) {
        return 'Schedule Post'
      } else {
        return 'Post to Instagram'
      }
    }
  }

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  useEffect(() => {
    if (instagramState == states.complete) {
      handleClose()
    }
  }, [instagramState])

  const handleClose = () => {
    if (instagramState != states.processing) {
      dispatch({
        type: actions.SET_INSTAGRAM_STATE,
        payload: states.ready,
      })
      close()
    }
  }

  const handleScheduleChange = (newScheduleAt) => {
    setScheduleAt(newScheduleAt)
  }

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose} close={closeButton} tag="h4" className="modal-header-segmented">
        Post to Instagram
      </ModalHeader>
      <ModalBody>
        <Row className="align-items-center mb-2">
          <Col xs>
            <Instagram size={24} className="text-dark me-1 icon-valign" />
            <strong className="text-dark">{instagramAccount.attributes.name}</strong>
          </Col>
        </Row>
        <Field field={postField} onChange={(value) => setMessage(value)} />
        <div className="mb-3">
          <ShareFormatSelectorLegacy share={share} selectedFormatIds={selectedFormatIds} onChange={setSelectedFormatIds} max={4} />
          {limitation ? (
            <div className="text-muted my-3">
              {limitation}
            </div>
          ) : null}
          {moreThanOneFormatSelected ? (
            <div className="text-muted my-3">
              Unfortunately, <strong>the Instagram API does not allow</strong> applications like Box Out Sports to create multi-image posts.
              To post these graphics to Instagram as one post, <a href="https://youtu.be/Babn0aXHubk?t=76">follow the directions on our support website</a>.
            </div>
          ) : null}
        </div>
        <div className="my-n2">
          <ShareScheduler scheduleAt={scheduleAt} onChange={handleScheduleChange} />
        </div>
        {stateNotifiers[instagramState]}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" className="w-100" onClick={handleSend} disabled={disabled}>
          {buttonText()}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
