import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Input } from 'reactstrap'
import { Maximize2, X } from 'react-feather'
import { ReactSortable } from "react-sortablejs"
import Field from './Field'

const replaceIndex = (str, index) => {
  return str ? str.replace(/__\d+/, `__${index}`) : str
}

const createRepeatableFieldObject = (field, index, clearValues = true) => {
  const clone = JSON.parse(JSON.stringify(field))
  if (!field.cloned) clone.key = replaceIndex(field.key, index)
  clone.field_id = replaceIndex(field.field_id, index)
  clone.field_name = replaceIndex(field.field_name, index)
  clone.cloned = true

  if (clone.cropperInputNames) {
    Object.keys(clone.cropperInputNames).forEach(key => {
      clone.cropperInputNames[key] = replaceIndex(clone.cropperInputNames[key], index)
    });
  }
  if (clone.playerFields) {
    clone.playerFields = clone.playerFields.map(playerField => {
      return createRepeatableFieldObject(playerField, index, clearValues)
    })
  }
  if (clone.opponentFields) {
    clone.opponentFields = clone.opponentFields.map(opponentField => {
      return createRepeatableFieldObject(opponentField, index, clearValues)
    })
  }
  if (clearValues) clone.value = null

  switch (field.type) {
    case 'group':
      clone.fields = field.fields.map(f => createRepeatableFieldObject(f, index, clearValues))
      break
    case 'multiline_group':
      clone.field_rows = field.field_rows.map(row => (
        row.map(f => createRepeatableFieldObject(f, index, clearValues))
      ))
      break
  }

  return clone
}



export default function RepeatableField(props) {
  

  const { max, min, field_name, field_id } = props.field
  
  const createClonedValues = (values) => {
    const unclonedValue = values[0]
    const clonedValues = []
    const total = values.length > min ? values.length : min
    for (let i = 0; i < total; i++) {
      const field = values[i] || unclonedValue
      clonedValues.push(createRepeatableFieldObject(field, i, !field))
    }

    return clonedValues
  }

  const [fields, setFields] = useState(createClonedValues(props.field.value))

  useEffect(() => {
    setFields(createClonedValues(props.field.value))
  }, [props.field.value])

  const addField = () => {
    const unclonedValue = props.field.value[0]

    setFields(prev => [
      ...prev,
      createRepeatableFieldObject(unclonedValue, prev.length)
    ])
  }

  const removeField = (key) => {
    setFields(prev => (
      prev.filter(f => f.key != key)
        .map((f, index) => (
          createRepeatableFieldObject(f, index, false)
        ))
    ))
  }

  const updateFields = (newFields) => {
    setFields(newFields.map((f, index) => createRepeatableFieldObject(f, index, false)))
  }

  return (
    <div className="producer-form-repeatable">
      <Input type="hidden" name={field_name} id={field_id} value={fields.length} />
      <ReactSortable list={fields} setList={updateFields} handle=".drag-handle" animation={150} forceFallback={true} delay={0}>
        {fields.map(field => (
          <Row key={field.key} className="drag-item align-items-start flex-nowrap">
            <Button className="drag-handle">
              <Maximize2 size={16} className="text-muted" />
            </Button>
            <Col style={{ maxWidth: fields.length > 1 ? 'calc(100% - 24px)' : null }}>
              <Field {...props} field={field} />
            </Col>
            {fields.length > min ? (
              <Col xs="auto" className="position-absolute top-0 end-0">
                <Button
                  color="link"
                  className="p-1 me-n3 mb-1 text-muted"
                  onClick={() => removeField(field.key)}>
                  <X></X>
                </Button>
              </Col>
            ) : null}
          </Row>
        ))}
      </ReactSortable>
      {fields.length < max ? (
        <div className="mt-3">
          <Button color="link" className="team-color p-0" onClick={addField}>+ Add another</Button>
        </div>
      ) : null}
    </div>
  )
}