import React from 'react'
import PropTypes from 'prop-types'
import reactCSS from 'reactcss'
import merge from 'lodash/merge'

import { ColorWrap, Saturation, Hue } from 'react-color/lib/components/common'
import BoxOutPresetColors from './BoxOutPresetColors'
import BoxOutPointer from './BoxOutPointer'

export const BoxOut = ({ width, rgb, hsv, hsl, onChange, onSwatchHover,
  presetColors, limitToSwatches, styles: passedStyles = {}, className = '', handleClose }) => {
  const styles = reactCSS(merge({
    'default': {
      picker: {
        width,
        padding: '10px 10px 0',
        boxSizing: 'initial',
        background: 'transparent',
        borderRadius: '4px',
      },
      saturation: {
        width: '100%',
        paddingBottom: '75%',
        position: 'relative',
        overflow: 'hidden',
      },
      Saturation: {
        radius: '3px',
        shadow: 'inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)',
      },
      controls: {
        display: 'flex',
      },
      sliders: {
        padding: '10px 0',
        flex: '1',
      },
      color: {
        width: '24px',
        height: '16px',
        position: 'relative',
        marginTop: '10px',
        marginLeft: '9px',
        borderRadius: '3px',
      },
      activeColor: {
        absolute: '0px 0px 0px 0px',
        borderRadius: '2px',
        background: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`,
        boxShadow: 'inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)',
      },
      hue: {
        position: 'relative',
        height: '16px',
        overflow: 'hidden',
      },
      Hue: {
        radius: '2px',
        height: '16px',
        shadow: 'inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)',
        userSelect: 'none',
        cursor: 'default',
      },
      ...passedStyles,
    },
  }, passedStyles))

  return (
    <div style={styles.picker} className={`box-out-picker ${className}`}>
      {limitToSwatches ? null : (
        <React.Fragment>
          <div style={styles.saturation}>
            <Saturation
              style={styles.Saturation}
              hsl={hsl}
              hsv={hsv}
              onChange={onChange}
            />
          </div>
          <div style={styles.controls} className="flexbox-fix">
            <div style={styles.sliders}>
              <div style={styles.hue}>
                <Hue
                  style={styles.Hue}
                  hsl={hsl}
                  onChange={onChange}
                  pointer={BoxOutPointer}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      <BoxOutPresetColors
        colors={limitToSwatches ? ['transparent', ...presetColors] : presetColors}
        onClick={onChange}
        onSwatchHover={onSwatchHover}
        handleClose={handleClose}
        limitToSwatches={limitToSwatches}
      />
    </div>
  )
}

BoxOut.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.object,
  limitToSwatches: PropTypes.bool,
  rgb: PropTypes.object,
  hsv: PropTypes.object,
  hsl: PropTypes.object,
  onChange: PropTypes.func,
  onSwatchHover: PropTypes.func,
  presetColors: PropTypes.array,
  className: PropTypes.string,
}

BoxOut.defaultProps = {
  width: 200,
  styles: {},
  limitToSwatches: false,
  presetColors: ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505',
    '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000',
    '#4A4A4A', '#9B9B9B', '#FFFFFF'],
}

export default ColorWrap(BoxOut)
