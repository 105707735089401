import React, { useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { beginUpload } from '../../lib/upload'
import { store } from '../../stores/uploads'
import FileTypeInfoModal from './FileTypeInfoModal'

import DropzoneNotifier from '../shared/DropzoneNotifier'

const OPPONENT_LOGO_KEY = 'opponent_logo'

export default function UploadsDropzone(props) {
  const {
    disableUploads,
    basepath,
    dispatch,
    actions,
    currentFolderIsShared,
    canManageShared,
    category: currentCategory,
  } = useContext(store)

  const [showFileTypeModal, setShowFileTypeModal] = useState(false)

  const acceptedFormats = currentCategory == 'video' ? {
    'video/quicktime': ['.mov'],
    'video/mp4': ['.mp4'],
   } : {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/heic': ['.heic'],
    'image/gif': ['.gif'],
   }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (currentFolderIsShared && !canManageShared) return
    if (rejectedFiles.length > 0) {
      setShowFileTypeModal(true)
      return
    }
    beginUpload({ basepath, dispatch, actions, files: acceptedFiles })
  }

  const disabled = disableUploads || currentCategory == OPPONENT_LOGO_KEY

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {...acceptedFormats},
    noClick: true,
    noKeyboard: true,
    disabled: disabled,
  })

  return (
    <div className="upload-dropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      {props.children({ open })}
      <DropzoneNotifier active={isDragActive} />
      <FileTypeInfoModal 
        isOpen={showFileTypeModal} 
        toggle={() => setShowFileTypeModal(false)}
        category={currentCategory}
      />
    </div>
  )
}
