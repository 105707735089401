import React from "react"
import DaySection from "./DaySection"
import slug from "../../lib/slugify"

const MonthSection = (props) => {
  const { month, days } = props

  return (
    <section
      className="card rounded-1 mb-3 mb-md-4 position-relative"
      key={slug(month)}
      aria-labelledby={slug(month)}
    >
      <div className="card-header card-header-month rounded-top-1 d-flex px-2 py-1 px-sm-3 py-sm-2">
        <h2 className="all-unset" id={slug(month)}>
          <span className="d-block fs-6 fs-md-5 fw-bold">{month}</span>
        </h2>
      </div>
      <div>
        {Object.keys(days).map((day, index) => (
          <DaySection
            key={day}
            day={day}
            events={days[day]}
            index={index}
            {...props}
          />
        ))}
      </div>
    </section>
  )
}

export default MonthSection
