import React from 'react'
import {
  BrowserRouter,
  MemoryRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import { StateProvider } from '../stores/uploads'
import UploadsCategory from './Uploads/UploadsCategory'

export default function Uploads(props) {
  const updatedProps = {
    ...props,
    categories: props.uploadMenuState ? [props.uploadMenuState.category] : props.categories,
  }

  const defaultCategory = updatedProps.categories[0]
  const Router = props.actsAsMenu ? MemoryRouter : BrowserRouter
  const routerBase = props.actsAsMenu ? '' : props.basename

  return defaultCategory ? (
    <StateProvider {...updatedProps}>
      <Router basename={routerBase}>
        <Routes>
          <Route index element={<Navigate to={updatedProps.categories[0]} />} />
          <Route path=":category">
            <Route index element={<UploadsCategory />} />
            <Route path=":folder" element={<UploadsCategory />} />
          </Route>
        </Routes>
      </Router>
    </StateProvider >
  ) : null
}
