  import React, { useContext, useEffect, useState, useRef } from 'react'
import { store } from '../../stores/templates'
import { teamTemplatesMatching, templatesLastUsedBetween, templateLink } from '../../lib/templates'
import StickyBar from '../shared/StickyBar'
import Filter from '../shared/Filter'
import TemplatesNav from './TemplatesNav'
import Template from '../Template'
import ConfirmationModal from '../shared/ConfirmationModal'
import debounce from 'debounce'
import pluralize from 'pluralize'
import { daysAgo } from '../../lib/daysAgo'
import { Link, useParams } from "react-router-dom"

import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap"

const SORT_OPTIONS = {
  recent: "Recently Used",
  name: "Template Name",
};

const DEFAULT_SORT = Object.keys(SORT_OPTIONS)[0]

const RECENCY_SORT_GROUPS = [
  {
    title: "Used in the Last Week",
    start: daysAgo(7),
    end: daysAgo(0),
    allowBulkRemove: false
  },
  {
    title: "Used in the Last Month",
    start: daysAgo(30),
    end: daysAgo(7),
    allowBulkRemove: false
  },
  {
    title: "Used in the Last 3 Months",
    start: daysAgo(90),
    end: daysAgo(30),
    allowBulkRemove: false
  },
  {
    title: "Used in the Last 6 Months",
    start: daysAgo(180),
    end: daysAgo(90),
    allowBulkRemove: true
  },
  {
    title: "Used more than 6 Months Ago",
    start: daysAgo(Infinity),
    end: daysAgo(180),
    allowBulkRemove: true
  },
  {
    title: "Never Used",
    start: null,
    end: null,
    allowBulkRemove: true
  }
]

const BulkActions = ({
  selectedCount,
  onRemoveFromTeam,
  onRemoveFromAllTeams
}) => (
  <Col>
    <Row className="align-items-center">
      <Col xs="auto text-muted me-auto me-md-0">
        {selectedCount} selected
      </Col>
      <Col xs="auto" className="ps-0 d-none d-lg-block">
        <Button color="link" onClick={onRemoveFromTeam}>
          Remove From This Team
        </Button>
      </Col>
      <Col xs="auto" className="ps-0 d-none d-lg-block">
        <Button color="link" onClick={onRemoveFromAllTeams}>
          Remove From ALL Teams
        </Button>
      </Col>
    </Row>
  </Col>
)

const SortDropdown = ({ sortBy, setSortBy }) => (
  <Col xs="auto" className="px-2">
    <UncontrolledDropdown>
      <DropdownToggle caret color="link" className="p-2 m-n2 text-muted">
        {SORT_OPTIONS[sortBy]}
      </DropdownToggle>
      <DropdownMenu end={true}>
        {Object.keys(SORT_OPTIONS).map((key) => (
          <DropdownItem key={key} onClick={() => setSortBy(key)}>
            Sort By {SORT_OPTIONS[key]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  </Col>
)

const SelectAllInput = ({ selectedTemplates, templates, toggleAll }) => {
  const ref = useRef(null)

  useEffect(() => {
    ref.current.indeterminate = selectedTemplates.length !== templates.length && templates.length > 0 && selectedTemplates.length !== 0
  }, [selectedTemplates, templates])

  return (
    <Col xs="auto" className="pe-0">
      <input
        ref={ref}
        type="checkbox"
        className="sticky-bar-checkbox"
        checked={selectedTemplates.length === templates.length && templates.length > 0}
        disabled={templates.length === 0}
        onChange={toggleAll} />
    </Col>
  )
}

const Templates = ({ templates, selectedTemplates, onSelect }) => {
  const {
    basename,
    subscriptionTier,
    canManageTemplates,
    hasSubscriptionAccess,
    isAdmin,
  } = useContext(store)

  const { plan: routedPlan } = useParams()

  return (
    <div className="graphic-container">
      {templates
        .map(template => (
          <Template
            key={`template:${template.id}:${template.attributes.active}`}
            isAdmin={isAdmin}
            template={template}
            templateLink={templateLink(template, basename, routedPlan, hasSubscriptionAccess, subscriptionTier, canManageTemplates)}
            availableForSubscription={subscriptionTier >= template.attributes.tier}
            hasSubscriptionAccess={hasSubscriptionAccess}
            canManageTemplates={canManageTemplates}
            selected={selectedTemplates?.includes(template.id)}
            grayscale={false}
            onSelect={onSelect} />
      ))}
    </div>
  )
}

const RecentTemplatesGroup = ({title, templates, selectedTemplates, onSelect, selectGroup, deselectGroup, allowBulkRemove}) => {
  return (
    <div key={title}>
      <Row className="mb-3 align-items-center">
        <Col xs="auto">
          <h2 className="mb-0 fs-4 fst-normal text-capitalize">{title}</h2>
        </Col>
        {allowBulkRemove && (
          <Col xs="auto" className="mt-1">
            { templates.every(template => selectedTemplates.includes(template.id)) ? (
              <Button color="link" className="p-0 team-color" onClick={deselectGroup}>
                Deselect All
              </Button>
            ) : (
              <Button color="link" className="p-0 team-color" onClick={selectGroup}>
                Select All
              </Button>
            )}
          </Col>
        )}
      </Row>
      <div className="mb-4">
        <Templates
          templates={templates}
          selectedTemplates={selectedTemplates}
          onSelect={onSelect} />
      </div>
    </div>
  )
}

export default function TemplatesActive() {
  const {
    actions,
    dispatch,
    templates,
    currentTeam,
    defaultPlan,
    teams,
  } = useContext(store)

  const [query, setQuery] = useState('')
  const [sortBy, setSortBy] = useState(DEFAULT_SORT)
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const [selectedTeams, setSelectedTeams] = useState([])
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const hideModal = () => setIsDeleteModalOpen(false)
  const showModal = () => setIsDeleteModalOpen(true)

  const setQueryDebounced = debounce(value => setQuery(value.trim().toLowerCase()), 100)

  const filteredAndSortedTemplates = teamTemplatesMatching(templates, currentTeam.id, query)
    .sort((a, b) => {
      if (sortBy === 'name') {
        return a.attributes.title.localeCompare(b.attributes.title)
      } else {
        return new Date(b.attributes.last_used_at || 0) - new Date(a.attributes.last_used_at || 0)
      }
    })

  useEffect(() => {
    document.title = 'My Templates | Box Out Sports'
  }, [])

  const toggleTemplate = (id) => {
    setSelectedTemplates(prev => {
      if (prev.includes(id)) {
        return prev.filter(pid => pid != id)
      } else {
        return [...prev, id]
      }
    })
  }

  const toggleAll = () => {
    setSelectedTemplates(prev =>
      prev.length === filteredAndSortedTemplates.length
        ? []
        : filteredAndSortedTemplates.map(template => template.id)
    )
  }

  const selectTemplates = (ids) => {
    setSelectedTemplates(prev => prev.concat(ids))
  }

  const deselectTemplates = (ids) => {
    setSelectedTemplates(prev => prev.filter(id => !ids.includes(id)))
  }

  const removeSelected = () => {
    dispatch({
      type: actions.BULK_REMOVE_TEMPLATES,
      templates: selectedTemplates,
      teams: selectedTeams,
    })
    setSelectedTemplates([])
    setSelectedTeams([])
    setQuery('')
    hideModal()
  }

  const handleBulkRemove = (teams) => {
    setSelectedTeams(teams.map(team => team.id))
    showModal()
  }

  if (filteredAndSortedTemplates.length === 0 && query.trim() === '') {
    return (
      <>
        <TemplatesNav active="my-templates" />
        <div className="card card-empty">
            <div className="card-body">
              <p>You haven't added any templates yet.</p>
              <Link to={`/${defaultPlan}`} className="btn btn-primary">
                View Template Library
              </Link>
            </div>
          </div>
      </>
    )
  } else {
    return (
      <>
        <TemplatesNav active="my-templates" />
        <StickyBar>
          <SelectAllInput
            selectedTemplates={selectedTemplates}
            templates={filteredAndSortedTemplates}
            toggleAll={toggleAll} />
          { selectedTemplates.length == 0 ? (
            <Filter
              query={query}
              onChange={setQueryDebounced}
              title="Filter templates..." />
          ) : (
            <BulkActions
              selectedCount={selectedTemplates.length}
              onRemoveFromTeam={() => handleBulkRemove([currentTeam])}
              onRemoveFromAllTeams={() => handleBulkRemove(teams)}
            />
          )}
          <SortDropdown
            sortBy={sortBy}
            setSortBy={setSortBy} />
        </StickyBar>
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          onCancel={hideModal}
          onConfirm={removeSelected}
          message={`Are you sure you want to remove <strong>${pluralize('template', selectedTemplates.length, true)}</strong> from <strong>${selectedTeams.length > 1 ? 'all teams' : 'this team'}</strong>?`}
        />
        { filteredAndSortedTemplates.length == 0 && (
            <div className="card card-empty">
              <div className="card-body">
                <p>No templates match your filter.</p>
              </div>
            </div>
          )
        }
        { sortBy === "recent" ? (
          RECENCY_SORT_GROUPS.map(({ title, start, end, allowBulkRemove }) => {
            const recentTemplates = templatesLastUsedBetween(filteredAndSortedTemplates, start, end)
            return recentTemplates.length > 0 && (
              <RecentTemplatesGroup
                key={title}
                title={title}
                templates={recentTemplates}
                selectedTemplates={selectedTemplates}
                onSelect={toggleTemplate}
                selectGroup={() => selectTemplates(recentTemplates.map(template => template.id))}
                deselectGroup={() => deselectTemplates(recentTemplates.map(template => template.id))}
                allowBulkRemove={allowBulkRemove} />
            )
          })
        ) : (
          <Templates
            templates={filteredAndSortedTemplates}
            selectedTemplates={selectedTemplates}
            onSelect={toggleTemplate} />
        )
        }
      </>
    )
  }

}
