import React, { useContext, useEffect, useRef } from 'react'
import { store } from '../../stores/team_graphics'
import Pagination from '../shared/Pagination'
import LoadingIndicator from '../shared/LoadingIndicator'
import GraphicNext from '../GraphicNext'
import Pusher from 'pusher-js'

export default function TeamGraphicsList() {
  const {
    page,
    totalPages,
    filter,
    graphics,
    loadingGraphics,
    pusherAppKey,
    pusherChannel,
    pusherCluster,
    dispatch,
    actions,
  } = useContext(store)

  const graphicsRef = useRef()

  if (pusherAppKey && pusherChannel) {
    useEffect(() => {
      const socket = new Pusher(pusherAppKey, {
        cluster: pusherCluster,
      })
      socket.subscribe(pusherChannel).bind(`automatedPreview`, (data) => dispatch({
        type: actions.REPLACE_GRAPHIC,
        graphic: data.data,
      }))
    }, [])
  }

  useEffect(() => {
    dispatch({ type: actions.LOAD_GRAPHICS })

    if (graphicsRef.current) {
      graphicsRef.current.scrollIntoView()
    }
  }, [page, filter])

  const handlePagination = (page) => {
    dispatch({
      type: actions.PAGINATE,
      page: page,
    })
  }

  return (
    <>
      <h2 className="fs-4 fst-normal text-capitalize mb-3">Recently Created Graphics</h2>
      {graphics.length > 0 ? (
        <div className="position-relative min-height-200" ref={graphicsRef}>
          <LoadingIndicator loading={loadingGraphics} title="Loading graphics…">
            <div className="graphic-container">
              {graphics && graphics.map(graphic => (
                <GraphicNext key={graphic.id} graphic={graphic} />
              ))}
            </div>
            <Pagination currentPage={page} pageCount={totalPages} onPageChange={handlePagination} />
          </LoadingIndicator>
        </div>
      ) : (
        <div className="card card-empty mb-4">
          <div className="card-body">
            {filter == '' ? "No graphics have been created yet." : "No graphics match your filter."}
          </div>
        </div>
      )}
    </>
  )
}
