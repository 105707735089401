import React, { Fragment, useState } from 'react'
import GraphicVideoPreview from './shared/GraphicVideoPreview'
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap'

import classNames from 'classnames'
import { ChevronLeft, ChevronRight, Menu } from 'react-feather'
import DeleteModal from './graphics/DeleteModal'
import RenameModal from './graphics/RenameModal'

export default function Graphic(props) {
  const [graphic, setGraphic] = useState(props.graphic.data ? props.graphic.data : props.graphic)
  const showTitleAndActions = props.showTitleAndActions == null ? true : props.showTitleAndActions
  const extraGraphicClasses = props.graphicClasses || []
  const linkGraphic = props.linkGraphic == null ? true : props.linkGraphic
  const { deleteLink, editLink, shareLink, duplicateLink, renameLink } = props
  const formats = props.formats == null ? graphic.attributes.formats : props.formats

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [renameOpen, setRenameOpen] = useState(false)
  const { title, shared, preview_urls, full_urls, scheduled_share_count } = graphic.attributes

  const max_ratio = Math.max(...formats.map(format => format.ratio))
  const width = Math.round(max_ratio * 200)
  const containerWidth = Math.max(width, 200)

  const graphicClasses = classNames(['graphic', ...extraGraphicClasses])
  const imageClasses = classNames(['graphic-preview-image'])
  const previewClasses = classNames(['graphic-preview'])

  const [selectedFormatIndex, setSelectedFormatIndex] = useState(0)

  const openDeleteModal = () => {
    setDeleteOpen(true)
  }

  const closeDeleteModal = () => {
    setDeleteOpen(false)
  }

  const openRenameModal = () => {
    setRenameOpen(true)
  }

  const closeRenameModal = () => {
    setRenameOpen(false)
  }

  const editButton = shared ? (
    <DropdownItem id="disabled-edit" className="text-muted">
      Edit
      <UncontrolledTooltip fade={false} delay={{ hide: 0, show: 0 }} placement="top" target="disabled-edit">
        You cannot edit a shared graphic, duplicate instead
      </UncontrolledTooltip>
    </DropdownItem>
  ) : <DropdownItem tag="a" href={editLink}>Edit</DropdownItem>

  const nextFormat = () => {
    if (selectedFormatIndex == formats.length - 1) {
      setSelectedFormatIndex(0)
    } else {
      setSelectedFormatIndex(prev => prev + 1)
    }
  }

  const previousFormat = () => {
    if (selectedFormatIndex == 0) {
      setSelectedFormatIndex(formats.length - 1)
    } else {
      setSelectedFormatIndex(prev => prev - 1)
    }
  }

  const previewElements = formats.map((format, index) => (
    <div className={`graphic-preview-item graphic-preview-item-${format.extension}`} key={`format:${format.id}`} style={{ width: `${containerWidth}px` }}>
      {format.extension == 'mp4' ? (
        <GraphicVideoPreview
          full_url={full_urls[format.id]}
          height={200}
          preview_url={preview_urls[format.id]}
          className={imageClasses} />
      ) : (
        <img
          src={preview_urls[format.id]}
          height={200}
          alt={title}
          loading = {(index > 0) ? 'lazy' : 'eager'}
          className={imageClasses} />
      )}
    </div>
  ))

  const previews = (
    <div className="graphic-preview-container" style={{ left: `-${selectedFormatIndex * 100}%` }}>
      {previewElements}
    </div>
  )

  return (
    <div className="graphic-react-wrapper">
      <div className={graphicClasses} style={{ width: `${containerWidth}px` }}>
        {linkGraphic ? (
          <a href={shared ? shareLink : editLink} className={previewClasses}>{previews}</a>
        ) : (
          <div className={previewClasses}>{previews}</div>
        )}
        {formats.length > 1 ? (
          <Fragment>
            <div onClick={nextFormat} className="graphic-preview-switcher-next">
              <ChevronRight color="#ffffff" />
            </div>
            <div onClick={previousFormat} className="graphic-preview-switcher-previous">
              <ChevronLeft color="#ffffff" />
            </div>
            <div className="graphic-preview-switcher">
              {formats.map((format, i) => (
                <div
                  key={`format-switcher-${i}`}
                  className={`graphic-preview-switcher-item ${selectedFormatIndex == i ? 'selected' : ''}`}
                  onClick={() => setSelectedFormatIndex(i)} />
              ))}
            </div>
          </Fragment>
        ) : null}
        {showTitleAndActions ? (
          <Row className="align-items-center">
            <Col className="text-truncate pe-0">
              <strong>{title}</strong>
            </Col>
            <Col xs="auto">
              <UncontrolledDropdown direction="up" a11y={false}>
                <DropdownToggle color="link" className="p-2 m-n2 text-muted">
                  <Menu size="16" />
                </DropdownToggle>
                <DropdownMenu end={true} className="mx-3">
                  {editButton}
                  <DropdownItem tag="a" href={shareLink}>Share</DropdownItem>
                  <DropdownItem tag="a" href={duplicateLink}>Duplicate</DropdownItem>
                  <DropdownItem onClick={openRenameModal}>Rename</DropdownItem>
                  <DropdownItem onClick={openDeleteModal}>Delete</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        ) : null}
        <DeleteModal isOpen={deleteOpen} close={closeDeleteModal} deleteLink={deleteLink} scheduledShareCount={scheduled_share_count} />
        <RenameModal graphic={graphic} isOpen={renameOpen} close={closeRenameModal} renameLink={renameLink} setGraphic={setGraphic} />
      </div>
    </div>
  )
}
