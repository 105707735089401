import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { allTemplatesForTier, templatesForTag } from '../../lib/templates'
import { store } from '../../stores/templates'
import Fuse from 'fuse.js'
import TemplatePreviews from './TemplatePreviews'
import TemplatesFilter from './TemplatesFilter'
import TemplatesBreadcrumb from './TemplatesBreadcrumb'
import TemplatesNav from './TemplatesNav'


export default function TemplatesTag() {
  const [query, setQuery] = useState('')
  const isFiltering = query.trim() != ''

  const { tag: routedTag, plan: routedPlan } = useParams()
  const tag = routedTag.replace('-', ' ')

  const {
    templateCategories,
    templates,
    plans,
    hasSubscriptionAccess,
    canManageTemplates,
    basename,
    subscriptionTier,
    isAdmin,
    subscribed,
    subscriptionLink,
  } = useContext(store)

  const tieredTemplates = allTemplatesForTier(templates, plans[routedPlan])
  const tagTemplates = templatesForTag(tieredTemplates, tag)

  const fuse = new Fuse(tagTemplates, {
    keys: ['attributes.title', 'attributes.tags'],
    threshold: 0.3,
  })

  const displayTemplates = isFiltering ? fuse.search(query.trim()).map(f => f.item) : tagTemplates

  useEffect(() => {
    document.title = `Tagged ${tag} | Template Library | Box Out Sports`
  }, [])

  return (
    <>
      <TemplatesNav active="library" />
      <TemplatesFilter
        subscriptionTier={subscriptionTier}
        subscribed={subscribed}
        subscriptionLink={subscriptionLink}
        isAdmin={isAdmin}
        plans={plans}
        templates={templates}
        templateCategories={templateCategories.data}
        onChange={setQuery} />
      <TemplatesBreadcrumb templateCategories={templateCategories.data} query={query} />
      <TemplatePreviews
        subscriptionTier={subscriptionTier}
        hasSubscriptionAccess={hasSubscriptionAccess}
        canManageTemplates={canManageTemplates}
        templates={displayTemplates}
        templateCategories={templateCategories.data}
        basename={basename} />
    </>
  )
}
