import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { X } from 'react-feather'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { store } from '../../stores/share'
import Field from '../Fields/Field'
import ShareFormatSelectorLegacy from './ShareFormatSelectorLegacy'
import ShareScheduler from './ShareScheduler'

export default function ShareEmail(props) {
  const { close, isOpen } = props
  const { graphic, emailState, states, actions, dispatch } = useContext(store)
  
  const share = props.share || {
    id: null,
    metadata: {
      emails: [],
      formats: graphic.attributes.formats,
    },
    scheduled_at: null,
    graphic: graphic,
  }

  const formats = share.graphic.attributes.formats

  const [emails, setEmails] = useState(share.metadata.emails)
  const [selectedFormatIds, setSelectedFormatIds] = useState(share.metadata.formats.map(format => format.id))
  const [scheduleAt, setScheduleAt] = useState(share.scheduled_at ? {
    date: new Date(share.scheduled_at).toJSON().slice(0,10),
    time: new Date(share.scheduled_at).toLocaleTimeString([], {hour12: false, hour: '2-digit', minute:'2-digit'})
  } : null)
  const inputRef = useRef(null)

  const selectedFormats = formats.filter(format => selectedFormatIds.includes(format.id))
  const noFormatSelected = selectedFormatIds.length == 0

  useEffect(() => {
    if (emailState == states.complete) {
      handleClose()
    }
  }, [emailState]);

  useLayoutEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current.emailInputRef.current
      input.setAttribute('type', 'email')
      input.setAttribute('autocapitalize', 'none')
    }
  }, [emails, isOpen])

  const stateNotifiers = {
    [states.error]: (
      <div className="text-danger text-center fw-bold mt-2">
        Something went wrong, try again
      </div>
    ),
  }

  const handleSend = async () => {
    dispatch({
      type: actions.SHARE_EMAIL,
      shareId: share.id,
      emails,
      formats: selectedFormats,
      scheduleAt,
    })
  }

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  useEffect(() => {
    if (emailState == states.complete) {
      handleClose()
    }
  }, [emailState])

  const handleClose = () => {
    if (emailState != states.processing) {
      dispatch({
        type: actions.SET_EMAIL_STATE,
        payload: states.ready,
      })
      close()
    }
  }

  const handleScheduleChange = (newScheduleAt) => {
    setScheduleAt(newScheduleAt)
  }

  const disabled = noFormatSelected || 
    emailState == states.processing || 
    emails.length == 0 || 
    !isEmail(emails[0]) || 
    (scheduleAt && (!scheduleAt.date || !scheduleAt.time))

  const buttonText = () => {
    if (emailState == states.processing) {
      if (scheduleAt) {
        return 'Scheduling…'
      } else {
        return 'Emailing…'
      }
    } else if (noFormatSelected) {
      return 'Select at least one format'
    } else {
      if (scheduleAt) {
        return 'Schedule Email'
      } else {
        return 'Send Email'
      }
    }
  } 

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose} close={closeButton} tag="h4" className="modal-header-segmented">
        Send via Email
      </ModalHeader>
      <ModalBody>
        <div className="form-group">
          <ReactMultiEmail
            placeholder="Enter one or more email addresses…"
            emails={emails}
            ref={inputRef}
            disabled={emailState == states.processing}
            id="share-email-input"
            onChange={_emails => {
              setEmails(_emails)
            }}
            validateEmail={email => {
              return isEmail(email)
            }}
            getLabel={(
              email,
              index,
              removeEmail,
            ) => {
              return (
                <div data-tag key={index}>
                  <span data-tag-email>{email}</span>
                  <button data-tag-handle onClick={() => removeEmail(index)} aria-label={`Remove {email} from list`} type="button">
                    ×
                  </button>
                </div>
              )
            }}
          />
        </div>
        <div className="mb-3">
          <ShareFormatSelectorLegacy share={share} selectedFormatIds={selectedFormatIds} onChange={setSelectedFormatIds} />
        </div>
        <div className="my-n2">
          <ShareScheduler scheduleAt={scheduleAt} onChange={handleScheduleChange} />
        </div>
        {stateNotifiers[emailState]}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="w-100" onClick={handleSend} disabled={disabled}>
          {buttonText()}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
