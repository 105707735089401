import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import Uploads from './Uploads'

import classNames from 'classnames'
import Field from './Fields/Field'

export default function FormFields(props) {
  const { formState, selfSignUpTrial } = props
  const { version } = formState || { version: 1 }
  const [uploadMenuState, setUploadMenuState] = useState({
    open: false,
    category: null,
    selectedUpload: null,
    onSelectUpload: () => { },
  })

  // We use these for autofilling xml values that are based on a select
  const [selectValues, setSelectValues] = useState({})

  const uploadMenuRef = useRef()

  useEffect(() => {
    if (uploadMenuState.open) {
      const handleKeyDown = (e) => {
        if (e.keyCode == 27) { // esc
          setUploadMenuState({
            open: false
          })
        }
      }

      const handleMouseDown = (e) => {
        if (uploadMenuState.open &&
          uploadMenuRef.current &&
          !uploadMenuRef.current.contains(e.target) &&
          !e.target.closest('.form-select-upload') &&
          !e.target.closest('.pintura-editor') &&
          !e.target.closest('.modal')) {
          setUploadMenuState({
            open: false
          })
        }
      }
      window.addEventListener("keydown", handleKeyDown, false)
      window.addEventListener("mousedown", handleMouseDown, false)

      return () => {
        window.removeEventListener("keydown", handleKeyDown)
        window.removeEventListener("mousedown", handleMouseDown)
      }
    }
  }, [uploadMenuState.open])

  const uploadMenuClasses = classNames({
    'uploads-menu': true,
    'open': uploadMenuState.open,
    'uploads-menu-demo': props.demo,
  })

  const uploadsMenu = ReactDOM.createPortal((
    <div className={uploadMenuClasses} ref={uploadMenuRef}>
      <Uploads
        {...props}
        selfSignUpTrial={selfSignUpTrial}
        actsAsMenu={true}
        demo={props.demo}
        key={uploadMenuState.category}
        uploadMenuState={uploadMenuState} />
    </div>
  ), document.body)

  return (
    <Fragment>
      {props.fields.map(field => (
        <Field
          selfSignUpTrial={props.selfSignUpTrial}
          key={`${field.key}:${version}`}
          field={field}
          formats={props.formats}
          manualUploads={props.manualUploads}
          previewState={props.previewState}
          setPreviewState={props.setPreviewState}
          uploadMenuState={uploadMenuState}
          setUploadMenuState={setUploadMenuState}
          selectValues={selectValues}
          setSelectValues={setSelectValues}
          playerData={props.playerData}
          opponentData={props.opponentData}
          pusherAppKey={props.pusherAppKey}
          pusherCluster={props.pusherCluster}
          setFields={props.setFields}
          teamChannel={props.teamChannel}
          allowSharing={props.allowSharing}
          folders={props.folders}
          categories={props.categories}
          canManageShared={props.canManageShared}
          basename={props.basename}
          basepath={props.basepath}
          updateFields={props.updateFields}
        />
      ))}
      {uploadsMenu}
    </Fragment>
  )
}
