import React, { useState, useEffect } from 'react'
import useDimensions from 'react-use-dimensions'
import classNames from 'classnames'
import {
  Col,
  Label,
  Row,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap'

export default function ShareFormatSelector(props) {
  const { max, share, tabIndex } = props
  const formats = share.graphic.attributes.formats
  const [selectedFormatIds, setSelectedFormatIds] = useState(props.selectedFormatIds)
  const [ref] = useDimensions()
  const previewUrls = share.graphic.attributes.preview_urls

  // TODO: Copy over key controls from CustomSelectField useEffect handleKeyDown

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedFormatIds)
    }
  }, [selectedFormatIds])

  const addFormatId = (id) => {
    setSelectedFormatIds(prev => {
      return formats.map(format => {
        return prev.includes(format.id) || format.id == id ? format.id : null
      }).filter(item => item != null)
    })
  }

  const removeFormatId = (id) => {
    setSelectedFormatIds(prev => prev.filter(pid => pid != id))
  }

  const updateFormatIds = (e) => {
    const input = e.target
    if (input.checked) {
      addFormatId(input.name)
    } else {
      removeFormatId(input.name)
    }
    if (ref.current) {
      ref.current.focus()
    }
  }

  const joinSentence = (array, oxford_comma = true) => {
    let lastWord
    if( array.length > 1 ){
      lastWord = " and " + array.pop()
      if( oxford_comma && array.length > 1 ) {
        lastWord = "," + lastWord
      }
    } else {
      lastWord = ""
    }
    return array.join(", ") + lastWord
  }

  const selectedFormats = selectedFormatIds.map(id => formats.find(f => f.id == id))
  let selectedFormatNames = joinSentence(selectedFormats.map(format => format.title))

  if (selectedFormatIds.length == 0) {
    selectedFormatNames = 'No formats selected'
  } else if (selectedFormatIds.length == formats.length) {
    selectedFormatNames = 'All formats'
  } else {
    selectedFormatNames = 'Only ' + selectedFormatNames
  }

  const selectedFormatImages = formats.map((format, key) => (
    <div key={key} style={{aspectRatio: `${format.width} / ${format.height}`,height:'20px'}} className='rounded-2 overflow-hidden'>
      <img src={previewUrls[format.id]} key={format.id}
      style={{
        objectFit: 'contain',
        height:'100%',
        width:'100%',
        verticalAlign:'unset',
        filter: (selectedFormatIds.includes(format.id) ? '' :'grayscale(100%)'),
        opacity: (selectedFormatIds.includes(format.id) ? '1' :'0.1')
      }} alt="" />
    </div>
  ))

  const dropdownToggleClasses = classNames({
    'form-control': true,
    'form-select': true,
    'form-select-upload': true,
    'text-truncate': true,
    'py-1': true,
    'form-control-height-auto': true,
    'mx-0': true,
    'w-100': true,
    'is-invalid': props.hasError,
  })

  return (
    <div className="d-flex gap-3">
      {formats.length > 1 ? (
        <UncontrolledDropdown a11y={false} style={{display:'flex',gap:'1rem'}}>
          <div style={{display:'flex',alignItems:'center',gap:'4px'}}>
            {selectedFormatImages}
          </div>
          <DropdownToggle aria-label={selectedFormatNames} aria-live="polite" innerRef={ref} tag="button" tabIndex={tabIndex ? tabIndex : 0 } style={{display:'flex',alignItems:'center'}} className={dropdownToggleClasses}>
            Select Formats
          </DropdownToggle>
          <DropdownMenu container="body" className="pt-3" modifiers={[{
            name: 'setMaxHeight',
            enabled: true,
            phase: 'write',
            order: 890,
            fn: (data) => {
              return {
                ...data.state,
                styles: {
                  ...data.state.styles,
                  popper: {
                    ...data.state.styles.popper,
                    overflow: 'auto',
                    maxHeight: '50vh',
                    width:'max-content',
                    zIndex: 1100,
                  }
                }
              };
            },
          }]}>
            {formats.map(format => {
              const checked = selectedFormatIds.includes(format.id)
              const atMax = max ? selectedFormatIds.length >= max : false
              const disabled = !checked && atMax
              const largestWidth = Math.max(...formats.map(format => format.width));
              const widestFormat = formats.find(format => format.width === largestWidth);

              return (
                <Label check key={`format:${format.id}`} className="d-block position-relative text-overflow-ellipsis mb-2 px-3">
                  <Row className="align-items-center">
                    <Col xs="auto" className="pe-2 d-flex">
                      <Input type="checkbox"
                             className="m-auto"
                             onChange={updateFormatIds}
                             name={format.id}
                             checked={checked}
                             disabled={disabled} />
                    </Col>
                    <Col xs="auto" className="px-0">
                      <div style={{aspectRatio: `${widestFormat.width} / ${widestFormat.height}`,height:'20px',display:'flex',justifyContent:'center'}}>
                        <div style={{aspectRatio: `${format.width} / ${format.height}`,height:'100%'}} className='rounded-2 overflow-hidden'>
                          <img src={previewUrls[format.id]} alt={format.title}
                              style={{
                                objectFit: 'contain',
                                height:'100%',
                                width:'100%',
                                verticalAlign:'unset',
                              }} />
                        </div>
                      </div>
                    </Col>
                    <Col className="ps-2">
                      {format.title}
                      <small className="text-muted fw-normal d-block mt-n2">{format.width} x {format.height}</small>
                    </Col>
                  </Row>
                </Label>
              )
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : null}
    </div>
  )
}
