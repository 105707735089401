import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import useDimensions from 'react-use-dimensions'
import Fuse from 'fuse.js'
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Row, Col, Button,
} from 'reactstrap'

export default function CustomSelectField(props) {
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [activeIndex, setActiveIndex] = useState(-1)
  const [currentOptions, setCurrentOptions] = useState(props.options)
  const { key, field_name, field_id, search } = props.field
  const searchRef = useRef(null)
  const currentOptionsRef = useRef(currentOptions)
  
  // Get selected values from props
  const selectedValues = props.selectValues ? Object.values(props.selectValues) : []
  
  // Find an available option
  let availableOptions = props.field.unique_defaults
    ? props.options.filter(option => !selectedValues.includes(option.value)) 
    : props.options;

  // Pick a random option if available; otherwise, default to the first option
  let firstAvailableOption = props.field.unique_defaults && availableOptions.length > 0
    ? availableOptions[Math.floor(Math.random() * availableOptions.length)] 
    : props.options[0]

  const [value, setValue] = useState(props.field.value == null ? firstAvailableOption.value : props.field.value)
  const [ref, { width }] = useDimensions()

  useEffect(() => {
    if (props.setSelectValues) {
      props.setSelectValues(prev => ({
        ...prev,
        [key]: value
      }))
    }

    if (props.onChange) {
      props.onChange(value)
    }
  }, [value])

  const toggleOpen = (e) => {
    e.preventDefault()
    setOpen(!open)
  }

  const fuseOptions = {
    keys: ['text'],
    threshold: 0.3,
  }
  const fuse = new Fuse(props.options, fuseOptions)

  useEffect(() => {
    if (props.setSelectValues) {
      props.setSelectValues(prev => ({
        ...prev,
        [key]: value
      }))
    }

    if (props.onChange) {
      props.onChange(value)
    }
  }, [value])

  useEffect(() => { currentOptionsRef.current = currentOptions },
    [currentOptions]
  )

  useEffect(() => {
    if (open) {
      setActiveIndex(props.options.findIndex(option => option.value == value))

      if (searchRef.current) searchRef.current.focus()

      const handleKeyDown = (e) => {
        const options = currentOptionsRef.current
        switch (e.keyCode) {
          case 40: // Down
            setActiveIndex(prev => Math.min(prev + 1, options.length - 1))
            e.preventDefault()
            break
          case 38: // Up
            setActiveIndex(prev => Math.max(prev - 1, -1))
            e.preventDefault()
            break
          case 13: // Enter
            setActiveIndex(prevIndex => {
              if (prevIndex >= 0) {
                setValue(options[prevIndex].value)
                e.preventDefault()
                setOpen(false)
              }
            })
            break
          case 27: // esc
            setSearchTerm(prev => {
              if (prev.trim() == '') setOpen(false)
              return ''
            })
        }
      }
      document.addEventListener('keydown', handleKeyDown, false)
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    } else {
      setSearchTerm('')
    }
  }, [open])

  useEffect(() => {
    if (searchTerm.trim() != '') {
      setActiveIndex(0)
      setCurrentOptions(fuse.search(searchTerm.trim()).map(f => f.item))
    } else {
      setCurrentOptions(props.options)
    }
  }, [searchTerm])

  const OptionFormatting = ({ text }) => {
    const parseOptionContent = (text) => {
      return text
        .replace(/\*(.*?)\*/g, '<strong style="color:inherit;">$1</strong>') // Bold
        .replace(/_(.*?)_/g, '<em style="color:inherit;">$1</em>') // Italics
    }
  
    const htmlContent = parseOptionContent(text)
    return <div className="p-0 text-truncate" dangerouslySetInnerHTML={{ __html: htmlContent }} />
  }

  const selectedOption = props.options.find(option => option.value == value) || props.options[0]

  const selectClasses = classNames({
    'connected-field': props.connected,
    'form-control': true,
    'form-select': true,
    'form-select-upload': true,
    'py-0': true,
    'form-control-height': true,
    'row': true,
    'mx-0': true,
    'align-items-center': true,
    'focus': open && !search,
  })

  const onButtonClick = (e) => {
    toggleOpen(e)
    if (props.buttonAction) {
      props.buttonAction()
    }
  }

  const searchOptions = search ? (
    <div className="px-3 pb-3 pt-2">
      <Row>
        <Col><Input value={searchTerm} placeholder="Filter…" innerRef={searchRef} onChange={(e) => setSearchTerm(e.target.value)} /></Col>
        {props.buttonText && props.buttonAction ? <Col xs="auto" className="ps-0">
          <Button color="outline-secondary" onClick={onButtonClick}>{props.buttonText}</Button>
        </Col> : null}
      </Row>
    </div>
  ) : null

  const options = currentOptions.map((option, idx) => {
    return option.headline ? (
      <DropdownItem key={`option-headline-${idx}`} header={true}>
        {typeof option.optionElement === 'string' ? <OptionFormatting text={option.optionElement} /> : option.optionElement}
      </DropdownItem>
    ) : (
      <DropdownItem className={idx == activeIndex && option.value != 'NULL' ? 'active' : null} key={`option-${option.value}`} onClick={() => setValue(option.value)}>
        {typeof option.optionElement === 'string' ? <OptionFormatting text={option.optionElement} /> : option.optionElement}
      </DropdownItem>
    )
  })

  return (
    <Dropdown isOpen={open} toggle={toggleOpen}>
      <input type="hidden" name={field_name} id={field_id} value={value || ''} />
      <DropdownToggle id={props.dropdownId} className={`${selectClasses} ${props.selectClasses}`} tag="button" innerRef={ref} disabled={props.disabled || false}>
        { selectedOption.optionElement && typeof selectedOption.optionElement === 'string' ? <OptionFormatting text={selectedOption.optionElement} /> : selectedOption.selectedElement || selectedOption.optionElement }
      </DropdownToggle>
      <DropdownMenu modifiers={[{
        name: 'setMaxHeight',
        enabled: true,
        phase: 'write',
        order: 890,
        fn: (data) => {
          return {
            ...data.state,
            styles: {
              ...data.state.styles,
              popper: {
                ...data.state.styles.popper,
                overflow: 'auto',
                minWidth: `${width}px`,
                maxHeight: '50vh',
              }
            }
          };
        },
      }]}>
        {searchOptions}
        {options}
      </DropdownMenu>
    </Dropdown>
  )
}
