import React, { useContext, useState, useEffect, Fragment, useRef } from 'react'
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import { Lock, Unlock } from 'react-feather'
import Cropper from '../Cropper'
import useDebounce from '../shared/useDebounce'
import { store } from '../../stores/share'

export default function ShareDownloadFormat(props) {
  const { formats, maxResizePixels, graphic, states } = useContext(store)
  const { formats: selectedFormats, state } = props
  const format = selectedFormats[0]
  const { width, height, ratio, video, extension } = format
  const [value, setValue] = useState('original')
  const [resizeWidth, setResizeWidth] = useState(width)
  const [cropWidth, setCropWidth] = useState(width)
  const [cropHeight, setCropHeight] = useState(height)
  const [error, setError] = useState(null)
  const [cropRatio, setCropRatio] = useState(`${cropWidth}:${cropHeight}`)
  const [metadata, setMetaData] = useState({})
  const cropperRef = useRef(null)
  const debouncedCropRatio = useDebounce(cropRatio, 700)
  const url = graphic.attributes.full_urls[format.id]

  const [cropperValues, setCropperValues] = useState({
    crop_width: null,
    crop_height: null,
    crop_x: null,
    crop_y: null,
    image_width: null,
    image_height: null,
  })

  const updateCropperValues = (data) => {
    setCropperValues({
      crop_width: data.actions.crop.width,
      crop_height: data.actions.crop.height,
      crop_x: data.actions.crop.x,
      crop_y: data.actions.crop.y,
      image_width: data.input.image.width,
      image_height: data.input.image.height,
    })
  }

  const changeResizeWidth = (e) => {
    const newWidth = parseInt(e.target.value) || ''
    setResizeWidth(newWidth)
  }

  const changeResizeHeight = (e) => {
    const newWidth = Math.round(parseInt(e.target.value) * ratio) || ''
    setResizeWidth(newWidth)
  }

  const changeCropWidth = (e) => {
    const newWidth = parseInt(e.target.value) || ''
    setCropWidth(newWidth)
    setCropRatio(`${newWidth}:${cropHeight}`)
  }

  const changeCropHeight = (e) => {
    const newHeight = parseInt(e.target.value) || ''
    setCropHeight(newHeight)
    setCropRatio(`${cropWidth}:${newHeight}`)
  }

  const resizeHeight = resizeWidth == '' ? '' : Math.round(resizeWidth / ratio)

  useEffect(() => {
    let filename = graphic.attributes.title
    let metadata = {
      format: format,
    }

    if (formats.length > 1) {
      filename += ` - ${format.title}`
    }
  
    switch (value) {
      case 'resize':
        metadata = {
          ...metadata,
          resize: true,
          width: resizeWidth,
          height: resizeHeight,
        }
        filename += ` ${resizeWidth}x${resizeHeight}`
        break;
      case 'crop':
        metadata = {
          ...metadata,
          crop: true,
          width: cropWidth,
          height: cropHeight,
          crop_width: cropperValues.crop_width,
          crop_height: cropperValues.crop_height,
          crop_x: cropperValues.crop_x,
          crop_y: cropperValues.crop_y,
        }
        filename += ` ${cropWidth}x${cropHeight}`
        break;
    }
  
    filename += `.${extension}`

    metadata.filename = filename

    setMetaData(metadata)

    switch (value) {
      case 'resize':
        if (Math.max(resizeWidth, resizeHeight) > maxResizePixels) {
          setError(`Maximum dimension of ${maxResizePixels} pixels`)
        } else {
          setError(null)
        }
        break;
      case 'crop':
        if (Math.max(cropWidth, cropHeight) > maxResizePixels) {
          setError(`Maximum dimension of ${maxResizePixels} pixels`)
        } else {
          setError(null)
        }
        break;
      default:
        setError(null)
    }
  }, [cropWidth, cropHeight, resizeWidth, resizeHeight, value, cropperValues])

  useEffect(() => {
    if (props.setMetadata) {
      props.setMetadata(metadata)
    }
  }, [metadata])

  useEffect(() => {
    if (props.setFormatError) {
      props.setFormatError(error)
    }
  }, [error])

  let disabled = state == states.processing || !!error

  switch (value) {
    case 'resize':
      disabled = disabled || resizeWidth == '' || resizeWidth == 0
      break;
    case 'crop':
      disabled = disabled || cropWidth == '' || cropWidth == 0 || cropHeight == '' || cropHeight == 0
      break;
  }

  const resizeElements = value == 'resize' ? (
    <Row className="align-items-center mt-3">
      <Col className="pe-2">
        <InputGroup>
          <InputGroupText>Width</InputGroupText>
          <Input type="number" id="resize_width" placeholder="Width" value={resizeWidth} onChange={changeResizeWidth} />
        </InputGroup>
      </Col>
      <Col xs="auto" className="px-0"><Lock size="18" /></Col>
      <Col className="ps-2">
        <InputGroup>
          <InputGroupText>Height</InputGroupText>
          <Input type="number" id="resize_height" placeholder="Height" value={resizeHeight} onChange={changeResizeHeight} />
        </InputGroup>
      </Col>
    </Row>
  ) : null

  const cropElements = value == 'crop' ? (
    <Row className="align-items-center mt-3">
      <Col className="pe-2">
        <InputGroup>
          <InputGroupText>Width</InputGroupText>
          <Input id="crop_width" placeholder="Width" value={cropWidth} onChange={changeCropWidth} />
        </InputGroup>
      </Col>
      <Col xs="auto" className="px-0"><Unlock size="18" /></Col>
      <Col className="ps-2">
        <InputGroup>
          <InputGroupText>Height</InputGroupText>
          <Input id="crop_height" placeholder="Height" value={cropHeight} onChange={changeCropHeight} />
        </InputGroup>
      </Col>
      {disabled ? null : (
        <Col xs="12" className="pt-2 mb-n2">
          <Cropper
            ref={cropperRef}
            initialImage={url}
            ratio={debouncedCropRatio}
            forceSize={`${cropWidth},${cropHeight}`}
            didTransform={updateCropperValues}
            key={`cropper:${debouncedCropRatio}`} />
        </Col>
      )}
    </Row>
  ) : null

  const errorElements = error ? (
    <div className="mb-3 text-danger fw-bold text-center mt-3">
      {error}
    </div>
  ) : null

  const stateNotifiers = {
    [states.error]: (
      <div className="text-danger fw-bold mt-2 text-center">
        Something went wrong, try again
      </div>
    ),
  }

  return (
    <Fragment>
      {video ? 'Download the video to your phone or computer.' : (
        <Fragment>
          <ButtonGroup className="w-100">
            <Button color={value == 'original' ? 'outline-primary active' : 'outline-secondary'} onClick={() => setValue('original')}>
              Original
              <small className="d-block text-faded">{width}x{height}</small>
            </Button>
            <Button color={value == 'resize' ? 'outline-primary active' : 'outline-secondary'} onClick={() => setValue('resize')}>
              Resize
              <small className="d-block text-faded">Same ratio</small>
            </Button>
            <Button color={value == 'crop' ? 'outline-primary active' : 'outline-secondary'} onClick={() => setValue('crop')}>
              Crop
              <small className="d-block text-faded">Trim to fit</small>
            </Button>
          </ButtonGroup>
          {resizeElements}
          {cropElements}
          {errorElements}
        </Fragment>
      )}
      {stateNotifiers[state]}
    </Fragment>
  )
}
