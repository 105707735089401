import React, { useState, useEffect, Fragment } from 'react'
import LoadingIndicator from './shared/LoadingIndicator'
import StickyBar from './shared/StickyBar'
import Filter from './shared/Filter'
import Fuse from 'fuse.js'
import axios from './shared/axios'
import debounce from 'debounce'
import {
  Button,
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap'
import { Check } from 'react-feather'

export default function HometownCampaignSelector(props) {
  const { basepath, hometown } = props
  const { eventsUrl, eventMax, updateFiltersPath } = hometown || {}
  const [filters, setFilters] = useState(hometown && hometown.filters ? hometown.filters : {
    filter: null,
    query: '',
    display: 'department',
  })
  const [events, setEvents] = useState([])
  const [loadedEvents, setLoadedEvents] = useState(false)
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = useState(filters.filter)
  const [query, setQuery] = useState(filters.query)
  const [display, setDisplay] = useState(filters.display)
  const [settingFitlerDefaults, setSettingFilterDefaults] = useState(false)

  const filterMatches = JSON.stringify({
    filter: filter,
    query: query,
    display: display,
  }) == JSON.stringify(filters)

  const addEventData = (events) => {
    return events.map(event => {
      return {
        ...event,
        date: new Date(event.timestamp_start * 1000),
        timezone: event.venue.tz,
      }
    })
  }

  const [linkedEvents, setLinkedEvents] = useState(hometown && hometown.events ? addEventData(hometown.events) : [])
  const [updatedLinkedEvents, setUpdatedLinkedEvents] = useState(linkedEvents)

  const debouncedSetQuery = debounce(setQuery, 200)

  const loadEvents = async () => {
    const response = await axios.get(eventsUrl)
    setEvents(addEventData(response.data.events).sort((a, b) => a.date >= b.date))
    setLoadedEvents(true)
  }

  useEffect(() => {
    loadEvents()
  }, [])

  useEffect(() => {
    if (!open) {
      setUpdatedLinkedEvents(linkedEvents)
    }
  }, [open])

  const filteredEvents = (filter ? events.filter(event => {
    if (filter.type == 'department') {
      return filter.name == event.department_name
    }

    if (filter.type == 'team') {
      return filter.name == event.team
    }

    return false
  }) : events)

  const selectEvent = (event) => {
    window.location = `${basepath}?event_id=${event.hometown_id}`
  }

  const deselectEvent = (event) => {
    setUpdatedLinkedEvents(updatedLinkedEvents.filter(e => e.id != event.id))
  }

  const setFilterDefaults = async () => {
    setSettingFilterDefaults(true)
    const response = await axios.post(updateFiltersPath, {
      filters: {
        filter: filter,
        query: query,
        display: display,
      }
    })

    setFilters(response.data)
    setSettingFilterDefaults(false)
  }

  const fuseOptions = {
    keys: ['name'],
    threshold: 0.5,
    ignoreLocation: true,
  }

  const fuse = new Fuse(filteredEvents, fuseOptions)
  const queriedEvents = query ? fuse.search(query).map(result => result.item) : filteredEvents
  const grouped_events = queriedEvents.reduce(function(r, a) {
    r[a.department_name] = r[a.department_name] || []
    r[a.department_name].push(a)
    return r
  }, Object.create(null))

  const teamed_events = {}

  Object.keys(grouped_events).forEach(key => {
    teamed_events[key] = grouped_events[key].reduce(function(r, a) {
      r[a.team] = r[a.team] || []
      r[a.team].push(a)
      return r
    }, Object.create(null))
  })

  const departments = [...new Set(events.map(event => event.department_name))]
  const teams = [...new Set(events.map(event => event.team))]

  const displayForEvent = (event, showDepartmentAndTeam = false) => {
    return (
      <>
        <strong className="d-block">{event.name}</strong>
        {showDepartmentAndTeam && (
          <div>
            {event.department_name}
            {event.team && (
              <>&nbsp;&mdash; {event.team}</>
            )}
          </div>
        )}
        <small className="text-muted">
          {event.date.toLocaleString("en-us", {
            weekday: "long",
            timeZone: event.timezone || "America/Indianapolis",
          })}&nbsp;
          {event.date.toLocaleString("en-us", {
            dateStyle: "medium",
            timeZone: event.timezone || "America/Indianapolis",
          })} @&nbsp;
          {event.date.toLocaleString("en-us", {
            timeStyle: "short",
            timeZone: event.timezone || "America/Indianapolis",
          })}
        </small>
      </>
    )
  }

  const eventComponent = (event, showDepartmentAndTeam = false) => {
    const selected = updatedLinkedEvents.some(e => e.id == event.id)
    return (
      <ListGroupItem key={event.id} className={selected ? 'bg-lightest' : ''}>
        <Row className="align-items-center">
          <Col>
            {displayForEvent(event, showDepartmentAndTeam)}
          </Col>
          <Col xs="12" md="auto" className="mt-2 mt-md-0">
            {selected ? (
              <Button color="outline-success" className="btn-sm-block" onClick={() => deselectEvent(event)}><Check size="16" className="icon-valign" /> Selected</Button>
            ) : (eventMax && updatedLinkedEvents.length >= eventMax ? (
              <>
                <Button color="outline-secondary disabled" className="btn-sm-block" id={`max-reached-${event.id}`}>
                  Select Event
                </Button>
                <UncontrolledTooltip fade={false} delay={{ hide: 0, show: 0 }} placement="top" target={`max-reached-${event.id}`}>
                  Maximum of {eventMax} events for this graphic have been selected.
                </UncontrolledTooltip>
              </>
            ) : (
              <Button color="primary" className="btn-sm-block" onClick={() => selectEvent(event)}>Select Event</Button>
            ))}
          </Col>
        </Row>
      </ListGroupItem>
    )
  }

  return (
    <>
      <LoadingIndicator loading={!loadedEvents} title="Loading events…" className="spinner-fill-sm">
        <StickyBar className="mb-4">
          <Filter title="Filter events…" query={query} onChange={debouncedSetQuery} className="col-12 col-md" />
          <Col xs="auto" className="ps-md-0 mt-1 mt-md-0">
            <UncontrolledDropdown>
              <DropdownToggle color="link" caret>{filter ? filter.name : 'All Events'}</DropdownToggle>
              <DropdownMenu end={true}>
                <DropdownItem onClick={() => setFilter(null)}>All Events</DropdownItem>
                <DropdownItem header={true}>Departments</DropdownItem>
                {departments.sort().map(department => (
                  <DropdownItem key={department} onClick={() => setFilter({ type: 'department', name: department })}>
                    {department}
                  </DropdownItem>
                ))}
                <DropdownItem header={true}>Teams</DropdownItem>
                {teams.sort().map(team => (
                  <DropdownItem key={team} onClick={() => setFilter({ type: 'team', name: team })}>
                    {team}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs="auto" className="ps-0 mt-1 mt-md-0">
            <UncontrolledDropdown>
              <DropdownToggle color="link" caret>{display == 'department' ? 'By Department' : 'By Date'}</DropdownToggle>
              <DropdownMenu end={true}>
                <DropdownItem onClick={() => setDisplay('department')}>By Department</DropdownItem>
                <DropdownItem onClick={() => setDisplay('date')}>By Date</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs="12" md="auto" className="ps-md-0 mt-1 mt-md-0 mb-2 mb-md-0">
            <Button color="outline-secondary btn-sm-block" size="sm" onClick={setFilterDefaults} disabled={filterMatches || settingFitlerDefaults}>
              {settingFitlerDefaults ? 'Saving…' : 'Set as default'}
            </Button>
          </Col>
        </StickyBar>
        {queriedEvents.length ? (
          <Fragment>
            {display == 'department' ? (teamed_events ? Object.keys(teamed_events).sort().map(name => (
              <div key={name}>
                <h4 className="mt-4">{name}</h4>
                <Card className="mt-3 rounded mb-4">
                  <ListGroup flush>
                    {Object.keys(teamed_events[name]).map(team => (
                      <Fragment key={team}>
                        {team != 'null' ? (
                          <ListGroupItem className="bg-gray"><strong>{team}</strong></ListGroupItem>
                        ) : null}
                        {teamed_events[name][team].map(event => eventComponent(event))}
                      </Fragment>
                    ))}
                  </ListGroup>
                </Card>
              </div>
            )) : null) : (
              <Fragment>
                <h4 className="mt-4">Upcoming Events</h4>
                <Card className="mt-3 rounded mb-4">
                  <ListGroup flush>
                    {queriedEvents.map(event => eventComponent(event, true))}
                  </ListGroup>
                </Card>
              </Fragment>
            )}
          </Fragment>
        ) : (
          <div className="card card-empty mb-4">
            <div className="card-body">
              {filter || query ? 'No events match your filter.' : 'No future published events found.'}
            </div>
          </div>
        )}
      </LoadingIndicator>
    </>
  )
}
