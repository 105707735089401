import React, { createContext, useRef, useCallback } from 'react'
import { useReducer } from 'react'
import { beginDownload, beginMultiFormatDownload } from './share/download'
import { shareEmail } from './share/email'
import { getScheduledPage, getSharePage } from './share/pagination'
import { deleteSharePost, shareFacebook, shareInstagram, shareMulti, shareTwitter, updateShareResult } from './share/post'
import { deleteShare } from './share/scheduled'

const store = createContext({})

const { Provider } = store

const actions = {
  SHARE_DOWNLOAD: 'shareDownload',
  SHARE_MULTI_FORMAT_DOWNLOAD: 'shareMultiFormatDownload',
  SHARE_EMAIL: 'shareEmail',
  SHARE_TWITTER: 'shareTwitter',
  SHARE_FACEBOOK: 'shareFacebook',
  SHARE_INSTAGRAM: 'shareInstagram',
  SET_DOWNLOAD_STATE: 'setDownloadState',
  SET_EMAIL_STATE: 'setEmailState',
  SET_TWITTER_STATE: 'setTwitterState',
  SET_FACEBOOK_STATE: 'setFacebookState',
  SET_INSTAGRAM_STATE: 'setInstagramState',
  SET_MULTI_SHARE_STATE: 'setMultiShareState',
  SET_SHARES: 'setShares',
  SET_SCHEDULED: 'setScheduled',
  SET_SHARES_LOADING: 'setSharesLoading',
  SET_SCHEDULED_LOADING: 'setScheduledLoading',
  LOAD_SHARES_PAGE: 'loadSharesPage',
  LOAD_SCHEDULED_PAGE: 'loadScheduledPage',
  OPEN_DELETE_POST: 'openDeletePost',
  CLOSE_DELETE_POST: 'closeDeletePost',
  DELETE_POST: 'deletePost',
  SET_POST_DELETING: 'setPostDeleting',
  OPEN_DELETE_SHARE: 'openDeleteShare',
  CLOSE_DELETE_SHARE: 'closeDeleteShare',
  DELETE_SHARE: 'deleteShare',
  SET_SHARE_DELETING: 'setShareDeleting',
  SET_FACEBOOK_REPOST_COUNT: 'setFacebookRepostCount',
  SET_TWITTER_REPOST_COUNT: 'setTwitterRepostCount',
  GET_SHARE_RESULT: 'getShareResult',
  UPDATE_SHARE: 'updateShare',
  SHARE_MULTI: 'shareMulti',
}

const states = {
  ready: 0,
  processing: 1,
  complete: 2,
  error: 3,
}

const useEnhancedReducer = (reducer, initState, initializer) => {
  const lastState = useRef(initState)
  const getState = useCallback(() => lastState.current, [])
  return [
    ...useReducer(
      (state, action) => lastState.current = reducer(state, action),
      initState,
      initializer
    ),
    getState
  ]
}

const reduce = (state, action) => {
  switch (action.type) {
    case actions.SET_DOWNLOAD_STATE:
      return { ...state, downloadState: action.payload }
    case actions.SET_EMAIL_STATE:
      return { ...state, emailState: action.payload }
    case actions.SET_TWITTER_STATE:
      return { ...state, twitterState: action.payload }
    case actions.SET_FACEBOOK_STATE:
      return { ...state, facebookState: action.payload }
    case actions.SET_INSTAGRAM_STATE:
      return { ...state, instagramState: action.payload }
    case actions.SET_MULTI_SHARE_STATE:
      return { ...state, multiShareState: action.payload }
    case actions.SET_SHARES_LOADING:
      return { ...state, sharesLoading: action.payload }
    case actions.SET_SCHEDULED_LOADING:
      return { ...state, scheduledLoading: action.payload }
    case actions.SET_SHARES:
      return {
        ...state,
        shares: {
          ...state.shares,
          ...action.payload,
        }
      }
    case actions.SET_SCHEDULED:
      return {
        ...state,
        scheduled: {
          ...state.scheduled,
          ...action.payload,
        }
      }
    case actions.OPEN_DELETE_POST:
      return { ...state, postToDelete: action.share, deletePostOpen: true }
    case actions.CLOSE_DELETE_POST:
      return { ...state, postToDelete: null, deletePostOpen: false, postDeleting: false }
    case actions.SET_POST_DELETING:
      return { ...state, postDeleting: action.payload }
    case actions.OPEN_DELETE_SHARE:
      return { ...state, shareToDelete: action.share, deleteShareOpen: true }
    case actions.CLOSE_DELETE_SHARE:
      return { ...state, shareToDelete: null, deleteShareOpen: false, shareDeleting: false }
    case actions.SET_SHARE_DELETING:
      return { ...state, shareDeleting: action.payload }
    case actions.SET_FACEBOOK_REPOST_COUNT:
      return { ...state, facebookRepostCount: action.payload }
    case actions.SET_TWITTER_REPOST_COUNT:
      return { ...state, twitterRepostCount: action.payload }
    case actions.UPDATE_SHARE:
      return {
        ...state,
        shares: {
          ...state.shares,
          data: state.shares.data.map(share => {
            return share.id === action.share.id ? action.share : share
          })
        }
      }
    default:
      return state
  }
}

const StateProvider = (props) => {
  const initialState = {
    ...props,
    loading: false,
    redirectTo: null,
    downloadState: states.ready,
    emailState: states.ready,
    twitterState: states.ready,
    facebookState: states.ready,
    instagramState: states.ready,
    multiShareState: states.ready,
    sharesLoading: false,
    schedulesLoading: false,
    deletePostOpen: false,
    postDeleting: false,
    postToDelete: null,
    deleteShareOpen: false,
    shareDeleting: false,
    shareToDelete: null,
    facebookRepostCount: null,
    twitterRepostCount: null,
    states: states,
  }

  const [state, dispatch, getState] = useEnhancedReducer(reduce, initialState)

  const asyncDispatch = (action) => {
    const state = getState()
    switch (action.type) {
      case actions.SHARE_DOWNLOAD:
        beginDownload(state, action, dispatch)
        return dispatch({
          type: actions.SET_DOWNLOAD_STATE,
          payload: states.processing,
        })
      case actions.SHARE_MULTI_FORMAT_DOWNLOAD:
        beginMultiFormatDownload(state, action, dispatch)
        return dispatch({
          type: actions.SET_DOWNLOAD_STATE,
          payload: states.processing,
        })
      case actions.SHARE_EMAIL:
        shareEmail(state, action, dispatch)
        return dispatch({
          type: actions.SET_EMAIL_STATE,
          payload: states.processing,
        })
      case actions.SHARE_TWITTER:
        shareTwitter(state, action, dispatch)
        return dispatch({
          type: actions.SET_TWITTER_STATE,
          payload: states.processing,
        })
      case actions.SHARE_FACEBOOK:
        shareFacebook(state, action, dispatch)
        return dispatch({
          type: actions.SET_FACEBOOK_STATE,
          payload: states.processing,
        })
      case actions.SHARE_INSTAGRAM:
        shareInstagram(state, action, dispatch)
        return dispatch({
          type: actions.SET_INSTAGRAM_STATE,
          payload: states.processing,
        })
      case actions.SHARE_MULTI:
        shareMulti(state, action, dispatch)
        return dispatch({
          type: actions.SET_MULTI_SHARE_STATE,
          payload: states.processing,
        })
      case actions.LOAD_SHARES_PAGE:
        getSharePage(action.payload, state, dispatch)
        return dispatch({
          type: actions.SET_SHARES_LOADING,
          payload: true,
        })
      case actions.LOAD_SCHEDULED_PAGE:
        getScheduledPage(action.payload, state, dispatch)
        return dispatch({
          type: actions.SET_SCHEDULED_LOADING,
          payload: true,
        })
      case actions.DELETE_POST:
        deleteSharePost(state, dispatch)
        return dispatch({
          type: actions.SET_POST_DELETING,
          payload: true,
        })
      case actions.DELETE_SHARE:
        deleteShare(state, dispatch)
        return dispatch({
          type: actions.SET_SHARE_DELETING,
          payload: true,
        })
      case actions.GET_SHARE_RESULT:
        updateShareResult(state, dispatch, action)
        return dispatch({
          type: actions.SET_SHARE_POLLING,
          id: action.share.id,
        })
      default:
        return dispatch(action)
    }
  }

  const usableData = {
    ...state,
    donePath: state.returnUrl || `${state.teampath}/graphics`,
    formats: state.graphic?.attributes.formats,
    twitterAccounts: state.socialAccounts?.filter(account => account.attributes.provider === 'twitter'),
    instagramAccounts: state.socialAccounts?.filter(account => account.attributes.provider === 'instagram'),
    facebookAccounts: state.socialAccounts?.filter(account => account.attributes.provider === 'facebook'),
    sortedScheduled: state.scheduled?.data.sort(function(a, b) {
      const dateDiff = new Date(a.scheduled_at) - new Date(b.scheduled_at)
      return dateDiff === 0 ? b.id - a.id : dateDiff
    }),
    sortedShares: state.shares?.data.sort(function(a, b) {
      const dateDiff = new Date(b.processed_at) - new Date(a.processed_at)
      return dateDiff === 0 ? a.id - b.id : dateDiff
    }),
    actions,
  }

  const value = {
    ...usableData,
    dispatch: asyncDispatch.bind(usableData)
  }

  return <Provider value={value}>{props.children}</Provider>
}

export { store, StateProvider, actions, states }
