import axios from "../../components/shared/axios"
import { actions } from "../templates";

const fetchTemplates = async (state, dispatch) => {
  const result = await axios.get(`${state.basename}/templates.json`)
  dispatch({ 
    type: actions.SET_TEMPLATES, 
    templates: result.data.templates.data,
    templateCategories: result.data.template_categories.data,
  })
}

export { fetchTemplates }
