import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { AlertOctagon } from 'react-feather'

export default function ErrorModal(props) {
  const { isOpen, message, header, toggle } = props

  return (
    <>
    <Modal isOpen={isOpen} size="sm" toggle={toggle}>
      <ModalHeader tag="div">
        <h2 className="remove-default fs-4 d-flex align-items-center gap-2">
          <AlertOctagon size={20} stroke="var(--bs-danger)" /> {header}
        </h2>
      </ModalHeader>
      <ModalBody aria-live="passive">
        {message}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" className="w-100" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
    </>
  )
}