import React, { createContext, useRef, useCallback } from 'react'
import { useReducer } from 'react'
import { deleteGraphic, loadGraphicsPage, renameGraphic } from './team_graphics/graphics'
import { updateComponent } from './team_graphics/components'

const store = createContext({})

const { Provider } = store

const actions = {
  FILTER: 'filter',
  LOAD_GRAPHICS: 'loadGraphics',
  SET_LOADING_GRAPHICS: 'setLoadingGraphics',
  GRAPHICS_LOADED: 'graphicsLoaded',
  PAGINATE: 'paginate',
  DELETE_GRAPHIC: 'deleteGraphic',
  SET_DELETING: 'setDeleting',
  RENAME_GRAPHIC: 'renameGraphic',
  SET_RENAMING: 'setRenaming',
  REPLACE_GRAPHIC: 'replaceGraphic',
  UPDATE_COMPONENT: 'updateComponent',
  SET_UPDATING_COMPONENT: 'setUpdatingComponent',
}

const useEnhancedReducer = (reducer, initState, initializer) => {
  const lastState = useRef(initState)
  const getState = useCallback(() => lastState.current, [])
  return [
    ...useReducer(
      (state, action) => lastState.current = reducer(state, action),
      initState,
      initializer
    ),
    getState,
  ]
}

const reduce = (state, action) => {
  switch (action.type) {
    case actions.SET_LOADING_GRAPHICS:
      return { ...state, loadingGraphics: true }
    case actions.SET_DELETING:
      return { ...state, deleting: true }
    case actions.SET_RENAMING:
      return { ...state, renaming: true }
    case actions.FILTER:
      return { ...state, filter: action.filter }
    case actions.PAGINATE:
      return {
        ...state,
        page: action.page,
      }
    case actions.GRAPHICS_LOADED:
      return {
        ...state,
        graphics: action.graphics,
        page: action.page,
        perPage: action.perPage,
        totalPages: action.totalPages,
        totalEntries: action.totalEntries,
        loadingGraphics: false,
        deleting: false,
      }
    case actions.REPLACE_GRAPHIC:
      return {
        ...state,
        graphics: state.graphics.map((graphic) => {
          if (graphic.id === action.graphic.id) {
            return action.graphic
          } else {
            return graphic
          }
        }),
        renaming: false,
      }
    case actions.SET_UPDATING_COMPONENT:
      return {
        ...state,
        updatingComponent: action.id,
      }
    case actions.REPLACE_COMPONENT:
      return {
        ...state,
        components: state.components.map((component) => {
          if (component.id === action.component.id) {
            return action.component
          } else {
            return component
          }
        }),
        updatingComponent: null,
      }
    default:
      return state
  }
}

const StateProvider = (props) => {
  const initialState = {
    ...props,
    filter: '',
    loadingGraphics: props.graphics ? false : true,
    graphics: props.graphics || [],
    components: props.components || [],
    templates: props.templates || { data: [] },
    per_page: null,
    total_pages: null,
    total_entries: null,
    deleting: false,
    renaming: false,
  }

  const [state, dispatch, getState] = useEnhancedReducer(reduce, initialState)

  const asyncDispatch = (action) => {
    const state = getState()
    switch (action.type) {
      case actions.LOAD_GRAPHICS:
        dispatch({ type: actions.SET_LOADING_GRAPHICS })
        return loadGraphicsPage(state, dispatch)
      case actions.DELETE_GRAPHIC:
        dispatch({ type: actions.SET_DELETING })
        return deleteGraphic(state, action, dispatch)
      case actions.RENAME_GRAPHIC:
        dispatch({ type: actions.SET_RENAMING })
        return renameGraphic(state, action, dispatch)
      case actions.UPDATE_COMPONENT:
        dispatch({ type: actions.SET_UPDATING_COMPONENT, id: action.id })
        return updateComponent(state, action, dispatch)
      default:
        return dispatch(action)
    }
  }

  const usableData = {
    ...state,
    templates: state.templates.data,
    actions,
  }

  const value = {
    ...usableData,
    dispatch: asyncDispatch.bind(usableData)
  }

  return <Provider value={value}>{props.children}</Provider>
}

export { store, StateProvider, actions }
