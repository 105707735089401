import React, { useContext } from 'react'
import { X } from 'react-feather'
import pluralize from 'pluralize'
import { store } from '../../stores/uploads'
import {
  Alert,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

export default function UploadsDelete() {
  const { 
    processing, 
    openModal, 
    dispatch, 
    actions, 
    modalData,
  } = useContext(store)

  const items = modalData.items || []
  const isOpen = openModal == actions.DELETE_ITEMS

  const handleSubmit = (e) => {
    e.preventDefault()
    if (processing) return
    const formData = new FormData(e.target)
    dispatch({ type: actions.DELETE_ITEMS, formData: formData, items: items })
  }

  const headlineDescription = () => {
    if (items.length == 0) {
      return `empty set`
    }

    const firstItemDescription = items[0].type == 'upload' ? 'upload' : 'folder'
    const uploads = items.filter(item => item.type == 'upload')
    const folders = items.filter(item => item.type == 'upload_folder')

    if (items.length == 1) {
      return `this ${firstItemDescription}`
    } else {
      if (items.every(item => item.type == 'upload') ||
        items.every(item => item.type == 'upload_folder')) {
        return `these ${pluralize(firstItemDescription, items.length, true)}`
      } else {
        return `${pluralize('folder', folders.length, true)} and
          ${pluralize('upload', uploads.length, true)}`
      }
    }
  }

  const close = () => {
    if (!processing) {
      dispatch({ type: actions.TOGGLE_MODAL, modal: actions.DELETE_ITEMS })
    }
  }

  const hasSharedItems = items.some(item => (
    item.attributes.shared
  ))

  const folders = items.filter(item => (
    item.type == 'upload_folder'
  ))

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  const folderWarning = folders.length == 0 ? null : (
    <Alert color="danger" className="mt-3">
      Deleting a folder will delete all the uploads inside of it!
    </Alert>
  )

  const sharedWarning = !hasSharedItems ? null : (
    <Alert color="danger" className="mt-3">
      Deleting a shared folder or file will delete it from every
      team in your organization! Be sure this is what you want to do.
    </Alert>
  )

  const itemIds = items.map(item => `${item.type}:${item.id}`).join(',')

  return (
    <Modal isOpen={isOpen} toggle={close}>
      <ModalHeader toggle={close} close={closeButton} tag="h3">
        Are you sure?
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <input type="hidden" name="ids" value={itemIds} />
        <ModalBody>
          Are you sure you want to delete {headlineDescription()}?
          This is permanent.
          {folderWarning}
          {sharedWarning}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" type="submit" disabled={processing}>
            Delete
          </Button>
          <Button color="outline-secondary" onClick={close} disabled={processing}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
