import React, { useRef, useState, useEffect, useContext, Fragment } from 'react'
import StickyBar from '../shared/StickyBar'
import UploadsFile from './UploadsFile'
import LoadingIndicator from '../shared/LoadingIndicator'
import useDebounce from '../shared/useDebounce'
import { Col, Input } from 'reactstrap'
import { store } from '../../stores/uploads'

export default function UploadsOpponentLogos() {
  const { 
    opponentLogos,
    queriedOpponentLogos,
    dispatch,
    actions 
  } = useContext(store)

  const [query, setQuery] = useState('')
  
  const debouncedQuery = useDebounce(query, 300)

  useEffect(() => {
    dispatch({ type: actions.QUERY_OPPONENT_LOGO, query })
  }, [debouncedQuery])

  const inputRef = useRef()
  const sortedLogos = opponentLogos.sort((ao, bo) => {
    const a = ao.attributes.filename
    const b = bo.attributes.filename
    if (a.toLowerCase() < b.toLowerCase()) return -1
    if (a.toLowerCase() > b.toLowerCase()) return 1
    return 0
  })
  const trimmedQuery = query.trim()

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const uploadComponent = (upload) => {
    const key = `upload:${upload.id}`
    return (
      <UploadsFile
        key={key}
        keyString={key}
        actsAsMenu={true}
        selected={false}
        opponentLogo={true}
        upload={upload} />
    )
  }

  const renderResults = (uploads) => {
    if (!Array.isArray(uploads)) return null

    if (uploads.length) {
      return (
        <div className="upload-container">
          {uploads.map(uploadComponent)}
        </div>
      )
    } else {
      return (
        <div className="rounded bg-darken p-5 text-center text-muted">
          Search the opponent library using the form above.
        </div>
      )
    }
  }

  return (
    <Fragment>
      <StickyBar>
        <Col className="px-3">
          <Input type="search"
            placeholder="Search opponent library…"
            value={query}
            innerRef={inputRef}
            onChange={(e) => setQuery(e.target.value)}
            className="form-control-flush"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false" />
        </Col>
      </StickyBar>
      {trimmedQuery == '' ? (
        <Fragment>
          <h2 className="mb-3">Imported Opponents</h2>
          {renderResults(sortedLogos)}
        </Fragment>
      ) : (
          <Fragment>
            <h2 className="mb-3">Search for &lsquo;{query}&rsquo;</h2>
            <LoadingIndicator loading={queriedOpponentLogos[trimmedQuery] == undefined}>
              {renderResults(queriedOpponentLogos[trimmedQuery])}
            </LoadingIndicator>
          </Fragment>
        )}
    </Fragment>
  )
}
