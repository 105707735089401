import React, { useState, useRef, useEffect, useLayoutEffect } from "react"
import { Col, Input } from "reactstrap"

export default function Filter(props) {
  const [query, setQuery] = useState(props.query || "")
  const inputRef = useRef()
  const refocusOnTitleChange =
    "refocusOnTitleChange" in props ? props.refocusOnTitleChange : true

  const onChange = (e) => {
    setQuery(e.target.value)
    props.onChange(e.target.value)
  }

  useEffect(() => {
    if (inputRef.current && refocusOnTitleChange) {
      inputRef.current.focus()
    }
  }, [props.title])

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  return (
    <Col className={props.className}>
      <Input
        type="search"
        placeholder={props.title}
        value={query}
        innerRef={inputRef}
        onChange={onChange}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        className="form-control-flush"
      />
    </Col>
  )
}
