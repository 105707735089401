import React, { useContext, useState } from "react";
import { store } from "../../stores/team_graphics";
import useDimensions from "react-use-dimensions";
import Fuse from "fuse.js";
import { Play, Pause } from 'react-feather'
import MultiformatIcon from "../shared/MultiformatIcon"

import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

const SORT_OPTIONS = {
  recent: "Recently Added",
  name: "Template Name",
};

function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

/** @constant previewSize
  *  @type {number}
  *  @description Defines the size of the template previews and spacing for hiding extra templates
*/
const previewSize = 128

const templatesForWidth = (templates, width) => {
  if (width === undefined) {
    return [];
  }

  const displayTemplates = [];
  let currentWidth = 0;

  for (let i = 0; i < templates.length; i++) {
    let template = templates[i];
    const templateWidth =
      Math.round(template.attributes.formats[0].ratio * previewSize) + convertRemToPixels(1);

    if (templateWidth + currentWidth <= width) {
      displayTemplates.push(template);
      currentWidth += templateWidth;
    } else {
      return displayTemplates;
    }
  }

  return displayTemplates;
};

export default function TeamGraphicsTemplates() {
  const { filter, templates, basename, page } = useContext(store);
  const [sortBy, setSortBy] = useState(Object.keys(SORT_OPTIONS)[0]);
  const [hideExtra, setHideExtra] = useState(true);
  const [containerRef, { width: containerWidth }] = useDimensions();

  const fuseOptions = {
    keys: ["attributes.title", "attributes.tags"],
    threshold: 0.5,
    ignoreLocation: true,
  };

  const sortedTemplates = templates.sort((a, b) => {
    switch (sortBy) {
      case "recent":
        return (
          new Date(b.attributes.activated_at) -
          new Date(a.attributes.activated_at)
        );
      case "name":
        return a.attributes.title.localeCompare(b.attributes.title);
      default:
        return a.id - b.id;
    }
  });

  const fuse = new Fuse(sortedTemplates, fuseOptions);
  const filtering = filter != "";
  const displayTemplates = hideExtra
    ? templatesForWidth(sortedTemplates, containerWidth || 0)
    : sortedTemplates;
  const queriedTemplates = filtering
    ? fuse.search(filter).map((result) => result.item)
    : displayTemplates;
  const hiddenTemplateCount = sortedTemplates.length - displayTemplates.length;

  return (
    page == 1 && (
      <>
        <Row className="align-items-center mb-3">
          <Col>
            <h2 className="fs-4 fst-normal text-capitalize mb-0">Create a New Graphic</h2>
          </Col>
          <Col xs="auto">
            <UncontrolledDropdown>
              <DropdownToggle
                caret
                color="link"
                className="m-n2 text-muted p-2"
              >
                {SORT_OPTIONS[sortBy]}
              </DropdownToggle>
              <DropdownMenu end={true}>
                {Object.keys(SORT_OPTIONS).map((key) => (
                  <DropdownItem key={key} onClick={() => setSortBy(key)}>
                    {SORT_OPTIONS[key]}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        {queriedTemplates.length == 0 ? (
          <div className="card card-empty mb-4" ref={containerRef}>
            <div className="card-body">
              {filtering ? (
                "No templates match your filter."
              ) : (
                <>
                  No templates available.{" "}
                  <a href={`${basename}/../templates`}>Add some templates</a> to
                  get started.
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="d-flex me-n3 flex-wrap" ref={containerRef}>
            {queriedTemplates.map((template) => {
              const size = template.attributes.extension == 'png' ? previewSize * 0.875 : previewSize
              const ratio = template.attributes.formats[0].ratio
              const isMultiFormat = template.attributes.formats.length > 1
              const isVideo = template.attributes.extension === 'mp4'
              const height = size
              const width = Math.round(ratio * size);
              const imageWidth = Math.round(ratio * height);

              return (
                <a
                  key={template.id}
                  href={`${basename}/new/${template.attributes.slug}`}
                >
                  <figure
                    className="template-item mb-3 me-3 text-center"
                    style={{ width: `${width}px` }}
                  >
                    <div className={`template-item-${template.attributes.extension} mb-2 position-relative`}>
                      {isMultiFormat && <div className="badge bg-dark position-absolute z-2 p-1 bottom-1 start-1"><MultiformatIcon size="14" formats={template.attributes.formats} strokeWidth="1" /></div>}
                      {isVideo && <div className="badge bg-dark position-absolute z-2 p-1 bottom-1 start-1"><Play size="14" strokeWidth="1" /><Pause size="14" strokeWidth="1" /></div>}
                      <img
                        alt={template.attributes.title}
                        height={height}
                        width={imageWidth}
                        className={`graphic-template-preview ${
                          !template.attributes.has_latest_graphic && "grayscale grayscale-remove-on-hover"
                        }`}
                        src={Object.values(template.attributes.preview_urls)[0]}
                      />
                    </div>
                    <figcaption className="meta text-dark lh-sm fs-7 fw-bold">
                      {template.attributes.title} 
                    </figcaption>
                  </figure>
                </a>
              );
            })}
          </div>
        )}
        {!filtering && hiddenTemplateCount > 0 && (
          <div className="zigzag text-center">
            <button
              type="button"
              className="btn btn-outline-muted btn-on-line"
              onClick={() => setHideExtra(false)}
            >
              Show {hiddenTemplateCount} More Template
              {hiddenTemplateCount == 1 ? "" : "s"}
            </button>
          </div>
        )}
      </>
    )
  );
}
