import Fuse from 'fuse.js'

export function templatesForCategoryAndTier(templates, category, tier) {
  const { id } = category

  return sortByActivation(templates.filter(template => template.attributes.owner_id == id && template.attributes.tier <= tier))
}

export function allTemplatesForTier(templates, tier) {
  return templates.filter(template => template.attributes.tier <= tier)
}

export function recentTemplates(templates) {
  const comparisonDate = new Date()
  comparisonDate.setMonth(comparisonDate.getMonth() - 6)

  return deduplicateTemplates(
    sortByActivation(
      templates.filter(template => new Date(template.attributes.activated_at) >= comparisonDate)
    )
  )
}

export function eventTemplates(templates) {
  const etemplates = sortByActivation(templates.filter(template => ['single', 'multi'].includes(template.attributes.features.events)))
  return deduplicateTemplates(
    etemplates.filter((value, index, self) => {
      return self.findIndex(v => v.attributes.dropbox_template_id === value.attributes.dropbox_template_id) === index;
    })
  )
}

export function templatesForTag(templates, tag) {
  return deduplicateTemplates(
    sortByActivation(templates.filter(template => template.attributes.tags.includes(tag)))
  )
}

export function sortByActivation(templates) {
  return templates.sort((a, b) => new Date(a.attributes.activated_at) > new Date(b.attributes.activated_at) ? -1 : 1)
}

export function teamTemplates(templates, teamId) {
  return deduplicateTemplates(
    templates.filter(template => template.attributes.activeTeamIds.includes(teamId))
  )
}

export function deduplicateTemplates(templates) {
  return templates.filter((template, index, self) =>
    index === self.findIndex((t) => t.attributes.dropbox_template_id === template.attributes.dropbox_template_id)
  )
}

export function templatesMatching(templates, query) {
  if (!query?.trim()) return templates

  const fuse = new Fuse(templates, {
    keys: ['attributes.title', 'attributes.tags'],
    threshold: 0.3,
  })

  return fuse.search(query.trim()).map(f => f.item)
}

export function teamTemplatesMatching(templates, teamId, query) {
  return templatesMatching(teamTemplates(templates, teamId), query)
}

export function templatesLastUsedBetween(templates, start, end) {
  if(start == null && end == null) {
    return templates.filter(template => template.attributes.last_used_at === null)
  }

  return templates.filter(template => {
    if( template.attributes.last_used_at == null ) return false
    const lastUsed = new Date(template.attributes.last_used_at)
    return lastUsed >= start && lastUsed <= end
  })
}

export function templateLink(template, basename, planOverride, hasSubscriptionAccess, subscriptionTier, canManageTemplates) {
  const { active, owner_slug, slug, tierName } = template.attributes
  const plan = planOverride || tierName.toLowerCase() || 'basic'

  if (active) {
    return `${basename}/${plan}/demo/${owner_slug}/${slug}/new_graphic`
  } else {
    if (hasSubscriptionAccess && template.attributes.tier <= subscriptionTier && canManageTemplates) {
      return `${basename.replace(/\/template-library$/, '')}/graphics/new/${owner_slug}/${slug}?return_url=${encodeURIComponent(window.location.href)}`
    } else {
      return `${basename}/${plan}/demo/${owner_slug}/${slug}`
    }
  }
}
