import React, { useContext, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { store } from '../../stores/share'
import Field from '../Fields/Field'
import ShareFormatSelectorLegacy from './ShareFormatSelectorLegacy'
import ShareScheduler from './ShareScheduler'

const MAX_LENGTH = 257

// TODO: remove this after Aug 2025, which is when the last old single-post share is scheduled in production
export default function ShareTwitter(props) {
  const { close, isOpen } = props
  const { graphic, twitterState, twitterAccounts, hometownLink, states, actions, dispatch } = useContext(store)

  const share = props.share || {
    id: null,
    metadata: {
      message: hometownLink ? `Tickets at ${hometownLink}` : '',
      formats: graphic.attributes.formats.filter(format => format.social.match(/twitter|all/)).slice(0, 4),
    },
    scheduled_at: null,
    graphic: graphic,
  }

  const formats = share.graphic.attributes.formats

  const [message, setMessage] = useState(share.metadata.message)
  const [selectedFormatIds, setSelectedFormatIds] = useState(share.metadata.formats.map(format => format.id))
  const [scheduleAt, setScheduleAt] = useState(share.scheduled_at ? {
    date: new Date(share.scheduled_at).toJSON().slice(0, 10),
    time: new Date(share.scheduled_at).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })
  } : null)

  const selectedFormats = formats.filter(format => selectedFormatIds.includes(format.id))
  const twitterAccount = share.social_account || twitterAccounts[0]

  if (!twitterAccount) {
    return null
  }

  useEffect(() => {
    if (twitterState == states.complete) {
      handleClose()
    }
  }, [twitterState]);

  const tweetField = {
    type: 'text_area',
    show_title: false,
    field_name: 'tweet',
    field_id: 'tweet',
    min_rows: 2,
    maxlength: MAX_LENGTH,
    value: message,
    placeholder: 'Enter text to tweet along with the graphic…',
  }

  const noFormatSelected = selectedFormatIds.length == 0
  const remaining = MAX_LENGTH - message.length
  const disabled = noFormatSelected ||
    twitterState == states.processing ||
    message.length == 0 ||
    (scheduleAt && (!scheduleAt.date || !scheduleAt.time))

  const remainingColorClass = () => {
    if (remaining > 15) {
      return 'text-success'
    } else {
      return 'text-danger'
    }
  }

  const remainingElement = message.length == 0 ? null : (
    <div className={`font- weight - bold ${remainingColorClass()
      } `}>{remaining}</div>
  )

  const stateNotifiers = {
    [states.error]: (
      <div className="text-danger text-center fw-bold mt-2">
        Something went wrong, try again
      </div>
    ),
  }

  const handleSend = async () => {
    dispatch({
      type: actions.SHARE_TWITTER,
      shareId: share.id,
      message,
      formats: selectedFormats,
      socialAccount: twitterAccount,
      scheduleAt,
    })
  }

  const closeButton = (
    <Button color="link" onClick={close} className="p-0 text-muted">
      <X />
    </Button>
  )

  useEffect(() => {
    if (twitterState == states.complete) {
      handleClose()
    }
  }, [twitterState])

  const handleClose = () => {
    if (twitterState != states.processing) {
      dispatch({
        type: actions.SET_TWITTER_STATE,
        payload: states.ready,
      })
      close()
    }
  }

  const handleScheduleChange = (newScheduleAt) => {
    setScheduleAt(newScheduleAt)
  }

  const buttonText = () => {
    if (twitterState == states.processing) {
      if (scheduleAt) {
        return 'Scheduling…'
      } else {
        return 'Tweeting…'
      }
    } else if (noFormatSelected) {
      return 'Select at least one format'
    } else {
      if (scheduleAt) {
        return 'Schedule Post'
      } else {
        return 'Post to X (Twitter)'
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose} close={closeButton} tag="h4" className="modal-header-segmented">
        Post to X (Twitter)
      </ModalHeader>
      <ModalBody>
        <Row className="align-items-center mb-2">
          <Col xs>
            <img src={twitterAccount.attributes.avatar} alt={twitterAccount.attributes.name} className="avatar avatar-sm avatar-valign me-2" />
            <strong className="text-dark">{twitterAccount.attributes.name}</strong>
          </Col>
          <Col xs="auto">
            {remainingElement}
          </Col>
        </Row>
        <Field field={tweetField} onChange={(value) => setMessage(value)} />
        <div className="form-group">
          <ShareFormatSelectorLegacy share={share} selectedFormatIds={selectedFormatIds} onChange={setSelectedFormatIds} max={4} />
        </div>
        <div className="my-n2">
          <ShareScheduler scheduleAt={scheduleAt} onChange={handleScheduleChange} />
        </div>
        {stateNotifiers[twitterState]}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" className="w-100" onClick={handleSend} disabled={disabled}>
          {buttonText()}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
