import React from 'react'

import { StateProvider } from '../stores/share'
import ShareActions from './Share/ShareActions'

export default function Share(props) {
  return (
    <StateProvider {...props}>
      <div className="bo-content-section bo-content-section-full">
        <ShareActions />
      </div>
    </StateProvider>
  )
}
