import React, { Fragment, useContext } from 'react'
import {
  Button,
  Col,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap'
import { Menu } from 'react-feather'
import { store } from '../../stores/uploads'

export default function UploadsBulkActions(props) {
  const { 
    currentSelections, 
    currentFolder,
    canManageShared, 
    displayFiles, 
    displayFolders,
    perPage,
    dispatch, 
    actions,
    isTeam
  } = useContext(store)

  const allItems = [...displayFolders, ...displayFiles]
  const selectedObjects = allItems.filter(item => currentSelections.includes(`${item.type}:${item.id}`))

  const selectAll = () => {
    dispatch({
      type: actions.SELECT_ITEMS,
      items: allItems,
      state: true,
    })
  }

  const deselectAll = () => {
    dispatch({
      type: actions.SELECT_ITEMS,
      items: allItems,
      state: false,
    })
  }

  const handleMove = () => {
    dispatch({ type: actions.TOGGLE_MODAL, modal: actions.MOVE_ITEMS, modalData: { items: selectedObjects }})
  }

  const handleDelete = () => {
    dispatch({ type: actions.TOGGLE_MODAL, modal: actions.DELETE_ITEMS, modalData: { items: selectedObjects }})
  }

  const handleShare = () => {
    dispatch({ type: actions.TOGGLE_MODAL, modal: actions.SHARE_ITEMS, modalData: { items: selectedObjects }})
  }

  const inRoot = currentFolder == null

  const inUnsharedFolder = !!(currentFolder &&
   !currentFolder.attributes.shared)

  const inSharedFolder = !inUnsharedFolder

  const hasSelectedAllSharedItems = selectedObjects.length && selectedObjects.every(item => (
    item.attributes.shared
  ))

  const generateShareButton = () => {
    if (canManageShared && isTeam) {
      if (inRoot) {
        return (
          <Button color="link" onClick={handleShare}>
            {hasSelectedAllSharedItems ? 'Unshare' : 'Share'}
          </Button>
        )
      } else {
        return (
          <Fragment>
            <div
              id="unsharing-disabled"
              className="text-muted fw-bold cursor-default">
              {hasSelectedAllSharedItems ? 'Unshare' : 'Share'}
            </div>
            <UncontrolledTooltip
              placement="top"
              target="unsharing-disabled"
              fade={false}
              delay={{hide: 0, show: 0}}>
              Only top level files and folders can be shared.
            </UncontrolledTooltip>
          </Fragment>
        )
      }
    } else {
      return null
    }
  }

  const shareButton = generateShareButton()

  return (
    <Col>
      <Row className="align-items-center">
        <Col xs="auto text-muted me-auto me-md-0">
          { currentSelections.length } selected
        </Col>
        <Col xs="auto" className="ps-0 d-none d-lg-block">
          <Button color="link" onClick={handleMove}>Move</Button>
        </Col>
        { shareButton ? (
          <Col xs="auto" className="ps-0 d-none d-lg-block">
            {shareButton}
          </Col>
        ) : null }
        <Col xs="auto" className="ps-0 d-none d-lg-block">
          <Button color="link" onClick={handleDelete}>Delete</Button>
        </Col>
        { allItems.length > perPage ? (
          <Fragment>
            { currentSelections.length < allItems.length ? (
              <Col xs="auto" className="ps-0 d-none d-lg-block">
                <Button color="link" onClick={selectAll}>
                  Select all {allItems.length}
                </Button>
              </Col>
            ) : (
              <Col xs="auto" className="ps-0 d-none d-lg-block">
                <Button color="link" onClick={deselectAll}>
                  Deselect all {allItems.length}
                </Button>
              </Col>
            )}
          </Fragment>
        ) : null }
        <Col xs="auto" className="d-lg-none ps-0">
          <UncontrolledDropdown>
            <DropdownToggle
              color="link"
              size="sm"
              className="text-muted">
              <Menu size="20" />
            </DropdownToggle>
            <DropdownMenu end={true}>
              <DropdownItem onClick={handleMove}>Move</DropdownItem>
              { canManageShared && isTeam ? (
                inRoot ? (
                  <Fragment>
                    { hasSelectedAllSharedItems ? (
                      <DropdownItem onClick={handleShare}>Unshare</DropdownItem>
                    ) : (
                      <DropdownItem onClick={handleShare}>Share</DropdownItem>
                    )}
                  </Fragment>
                ) : null
              ) : null }
              <DropdownItem onClick={handleDelete}>Delete</DropdownItem>
              { allItems.length > props.perPage ? (
                <Fragment>
                  { currentSelections.length < allItems.length ? (
                    <DropdownItem onClick={selectAll}>
                      Select all {allItems.length}
                    </DropdownItem>
                  ) : (
                    <DropdownItem onClick={deselectAll}>
                      Deselect all {allItems.length}
                    </DropdownItem>
                  )}
                </Fragment>
              ) : null }
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
    </Col>
  )
}
