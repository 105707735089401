import React, { useContext, useEffect } from 'react'
import { Cloud, Facebook, Instagram } from 'react-feather'
import { Button, Col, Row } from 'reactstrap'
import { InstagramEmbed, FacebookEmbed, XEmbed } from 'react-social-media-embed'
import TimeAgo from 'react-timeago'
import { store } from '../../../stores/share'
import XLogo from '../../shared/XLogo'

export default function ShareHistoryPost(props) {
  const { share } = props
  const { provider, name } = share.social_account && share.social_account.attributes || {}
  const { dispatch, actions } = useContext(store)
  const { deleted } = share

  const iconComponent = () => {
    switch (provider) {
      case 'facebook':
        return <Facebook className="icon icon-md mt-n1" />
      case 'twitter':
        return <XLogo className="icon icon-md mt-n1" />
      case 'instagram':
        return <Instagram className="icon icon-md mt-n1" />
      default:
        return <Cloud className="icon icon-md mt-n1" />
    }
  }

  useEffect(() => {
    if (!share.performed_at) {
      dispatch({
        type: actions.GET_SHARE_RESULT,
        share: share,
      })
    }
  }, [share.performed_at]);

  const embedComponent = () => {
    if (!share.result.post_url) {
      return null
    }

    if (share.action == 'story') {
      const format = share.metadata.formats[0]
      const url = share.graphic.attributes.preview_urls[format.id]

      return (
        <div className="mt-n2">
          <img src={url} alt={share.graphic.attributes.title} className="preview-sm me-2 mt-2 rounded" />
        </div>
      )
    }

    switch (provider) {
      case 'facebook':
        return <FacebookEmbed url={share.result.post_url} style={{ maxWidth: 550 }} width="100%" />
      case 'twitter':
        return <XEmbed url={share.result.post_url} style={{ maxWidth: 550 }} width="100%" />
      case 'instagram':
        return <InstagramEmbed url={share.result.post_url} style={{ maxWidth: 550 }} width="100%" />
      default:
        return null
    }
  }

  const openDelete = () => {
    dispatch({
      type: actions.OPEN_DELETE_POST,
      share: share,
    })
  }

  const postLocation = share.action == 'story' ? 'Story' : provider == 'facebook' ? 'Page' : 'Feed'

  return (
    <Row className="mb-3">
      <Col xs="auto" className="pe-0">
        {iconComponent()}
      </Col>
      {share.performed_at ? (
        <>
          <Col className="social-history-description" xs="auto">
            <strong>{share.user.name}</strong> posted to <strong>{name}</strong> {postLocation}
            <small className="d-block text-muted"><TimeAgo date={share.performed_at} /></small>
          </Col>
          <Col xs="12" className="order-md-5 social-history-info pt-2">
            {deleted ? (
              <div className="text-muted d-md-inline-block py-2 px-3 rounded bg-gray-darker">
                <small className="fst-italic">This post has been deleted.</small>
              </div>
            ) : share.result.error ? (
              <div className="text-danger fw-bold d-md-inline-block py-2 px-3 rounded bg-gray-darker">
                <strong className="text-danger">Something went wrong while posting this share.</strong>
              </div>
            ) : embedComponent()}
          </Col>
          {!deleted ? (
            <>
              <Col className="ps-0 order-md-3 d-none d-md-block">
                <hr className="mt-2" />
              </Col>
              <Col xs="12" md="auto" className="ps-md-0 order-md-4 social-history-info">
                <Row>
                  {share.result.post_url && <Col><a href={share.result.post_url} className="btn btn-outline-secondary w-100 mt-2 mt-md-n2">View</a></Col>}
                  {share.action != 'story' && provider != 'instagram' && <Col className={share.result.post_url && 'ps-0'}><Button onClick={openDelete} color="outline-subtle-danger" className="w-100 mt-2 mt-md-n2">Delete</Button></Col>}
                </Row>
              </Col>
            </>
          ) : null}
        </>
      ) : (
        <>
          <Col className="social-history-description" xs="auto">
            <div className="spinner spinner-sm"></div>
            <span className="spinner-text">Posting…</span>
          </Col>
        </>
      )}
    </Row>
  )
}
