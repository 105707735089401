import React, { useEffect, useState } from 'react'
import { Input, InputGroup, InputGroupText, Label } from 'reactstrap'
import { store } from '../../stores/share'
import { useContext } from 'react'

export default function ShareScheduler(props) {
  const { tz } = useContext(store)
  const isDisabled = props.disabled || false
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  const [isScheduled, setIsScheduled] = useState(!!props.scheduleAt)
  const [scheduleDate, setScheduleDate] = useState(props.scheduleAt ? props.scheduleAt.date : tomorrow.toJSON().slice(0,10))
  const [scheduleTime, setScheduleTime] = useState(props.scheduleAt ? props.scheduleAt.time : '09:00')
  
  useEffect(() => {
    if (props.onChange) {
      props.onChange(isScheduled ? {
        date: scheduleDate,
        time: scheduleTime,
      } : null)
    }
  }, [scheduleDate, scheduleTime, isScheduled])


  return (
    <div>
      <InputGroup check="true" size="lg">
        <InputGroupText>
          <Input type="checkbox" id="schedule" className="m-auto" checked={isScheduled} onChange={e => setIsScheduled(e.target.checked)} />
        </InputGroupText>
        <div className={isScheduled ? 'form-control p-0' : 'form-control'}>
          <Label for="schedule" check className={isScheduled ? 'visually-hidden' : 'lh-sm rem-1'}>Schedule post for later</Label>
          {isScheduled ? (
          <div className="d-flex align-items-stretch h-100">
            <div className="w-100 d-flex align-items-stretch">
               <Label htmlFor="scheduleDate" className="visually-hidden">Date</Label>
               <div className="form-floating d-flex w-100">
                <Input
                  id="scheduleDate"
                  type="date"
                  className="form-control py-2 minh-unset h-unset border-0 rounded-0"
                  placeholder={new Date().toLocaleDateString()}
                  min={new Date().toJSON().slice(0,10)}
                  value={scheduleDate}
                  onChange={e => setScheduleDate(e.target.value)}
                  disabled={isDisabled}
                />
              </div>
            </div>

            <div className="w-100 border-start d-flex align-items-stretch ">
              <Label htmlFor="scheduleTime" className="visually-hidden">Time</Label>
              <div className="form-floating d-flex w-100">
                <Input
                  id='scheduleTime'
                  disabled={isDisabled}
                  type="time"
                  className="form-control py-2 minh-unset h-unset rounded-end-3 rounded-start-0 border-0"
                  placeholder="9:00 AM"
                  value={scheduleTime}
                  onChange={e => setScheduleTime(e.target.value)}
                  />
              </div>
            </div>
          </div>
       ) : null} 
        </div>
      </InputGroup>
      {isScheduled ? (
        <span className="text-muted d-block mt-1">
          Date and time are in {tz}.&nbsp;
          <a href="/users/edit" target="_blank">Change your timezone in your profile</a>.
        </span>
      ) : null } 
    </div>
  )
}
