import React from 'react'
import { AlertTriangle } from 'react-feather'

export default function ProducerPreviewError(props) {
  const { error } = props

  if (error.message) {
    return (
      <div className="rounded bg-faded p-4">
        <h1 className="display-5 text-muted mb-3">Error generating graphic</h1>
        <div><code className="text-dark fw-bold">{error.message}</code></div>
        <div><code className="text-muted">{error.backtrace}</code></div>
      </div>
    )
  } else {
    return (
      <h1 className="display-5 faded text-muted m-0 text-center px-4">
        <AlertTriangle size="64" />
        <div className="mt-3">Something went wrong generating your graphic</div>
      </h1>
    )
  }
}


