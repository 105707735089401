import React, { useState } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import axios from './shared/axios'

export default function DevModeToggle(props) {
  const [devMode, setDevMode] = useState(props.devMode)

  const handleChange = async () => {
    const newDevMode = !devMode
    setDevMode(newDevMode)
    await axios.post('/admin/dev.json')

    if (props.reloadAfterChange) {
      setTimeout(() => {
        // eslint-disable-next-line no-self-assign
        window.location = window.location
      }, 100)
    }

    if (props.onChange) {
      props.onChange(newDevMode)
    }
  }

  return (
    <FormGroup switch className="form-group" noMargin={true}>
      <Input
      type="switch"
      id="devModeSwitch"
      name="devMode"
      checked={devMode}
      onChange={handleChange} />
      <Label htmlFor="devModeSwitch" check>Dev Mode</Label>
    </FormGroup>

  )
}
