import React, { useContext, useState, useEffect } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Badge, Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Label, UncontrolledDropdown } from 'reactstrap'
import axios from '../shared/axios'
import { ChevronLeft, ChevronRight, Menu } from 'react-feather'
import { store } from '../../stores/share'

// Wraps a number around a range (min inclusive, max exclusive)
const wrap = (n, max) => ((n % max) + max) % max

export default function ShareTextarea({
  message,
  onChange,
  onPromptLogIdChange,
  platforms,
  className,
  error,
  minRows = 3,
  maxRows = 8,
}) {
  const [suggestions, setSuggestions] = useState([])
  const [suggestionsLoading, setSuggestionsLoading] = useState(false)
  const [activeSuggestion, setActiveSuggestion] = useState(null)
  const [originalMessage, setOriginalMessage] = useState(null)
  const [containerHeight, setContainerHeight] = useState(100)
  const [isFocused, setIsFocused] = useState(false)
  const [fetchError, setFetchError] = useState(false)

  useEffect(() => {
    if (activeSuggestion !== null && suggestions?.length) {
      onChange(suggestions[activeSuggestion].message)
    }
  }, [activeSuggestion, suggestions])

  // TODO: if reason == "disabled", show as disabled with different message
  // TODO: if reason == "upgrade_plan", show upgrade message with ability to dismiss
  const { aiSuggestionsPolicy: policy, suggestionsIcon } = useContext(store)

  const getSuggestions = async () => {
    if (suggestionsLoading || platforms.length === 0) {
      return
    }

    try {
      setSuggestionsLoading(true)
      setFetchError(false)
      setSuggestions([])
      let response = await axios.post(`shares/suggestions`, { platforms })

      const startTime = Date.now()
      const timeoutDuration = 30_000 // The job gets cancelled if queued longer than 30s, see Prompts::GraphicShareContent
      let pollIntervals = [3_000, 1_000, 1_000, 2_000]

      while (response.data.poll_path) {
        if (Date.now() - startTime >= timeoutDuration) {
          throw new Error("Request timed out")
        }

        await new Promise((resolve) => setTimeout(resolve, pollIntervals.shift() || 3_000))
        response = await axios.get(response.data.poll_path)
      }

      if (response.data.status != 'completed') {
        setFetchError(true)
      } else {
        setSuggestions(response.data.messages || [])
        setActiveSuggestion(0)
        onPromptLogIdChange(response.data.prompt_log_id)
      }

      setSuggestionsLoading(false)

      if (originalMessage === null) {
        setOriginalMessage(message)
      }
    } catch (error) {
      setFetchError(true)
      setSuggestionsLoading(false)
    }
  }

  const defaultIcon = <svg className="sid-top sid-top-offset" width="55" height="41" viewBox="0 0 417 311">
    <use xlinkHref={`${suggestionsIcon}#default`} />
  </svg>


  const thinkingIcon = <svg className="sid-top sid-top-offset" width="55" height="41" viewBox="0 0 417 311">
    <use xlinkHref={`${suggestionsIcon}#thinking`} />
  </svg>

  const successIcon = <svg className="sid-top sid-top-offset" width="55" height="41" viewBox="0 0 417 311">
    <use xlinkHref={`${suggestionsIcon}#success`} />
  </svg>

  const handleCancelSuggestions = () => {
    setSuggestions([])
    setActiveSuggestion(null)
    onChange(originalMessage)
    onPromptLogIdChange(null)
    setOriginalMessage(null)
  }

  const handleRevertSuggestion = () => {
    onChange(suggestions[activeSuggestion].message)
  }

  const handleTextareaHeightChange = (height) => {
    // 65px leaves room for Sid and his suggestions
    // 2px prevents the textarea from overlapping the focus indicator
    setContainerHeight(policy.can ? height + 65 : height + 2)
  }

  return (
    <div className="share-textarea">
      <Label className="sr-only" htmlFor="message">Post Message</Label>
      <div aria-live="polite">{error ? (<p className="fw-bold text-danger">{error}</p>) : null }</div>
      <div
        className={`form-control rounded-3 position-relative p-1 pt-0 ${className} ${error ? 'is-invalid' : ''} ${isFocused ? 'focus' : 'border'}`}
        style={{ height: containerHeight }}
      >
        <TextareaAutosize
          className="form-control border-0 rounded-3 shadow-none line-height-lg form-control-lg ps-2"
          id="message"
          name="message"
          placeholder="Post message..."
          maxRows={maxRows}
          minRows={minRows}
          value={message}
          onHeightChange={handleTextareaHeightChange}
          onChange={(e) => onChange(e.target.value)}
          style={{ lineHeight: '1.2' }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {policy.can && (
          <div className="position-absolute bottom-0 start-0 end-0 bg-white rounded-bottom-3 p-2">
            {suggestions.length > 0 ? (
              <div className="d-flex align-items-center">
                <Button
                  color="outline-muted"
                  className="d-none d-md-block ms-1"
                  onClick={handleRevertSuggestion}
                  disabled={message == suggestions[activeSuggestion].message}
                >
                  Revert
                </Button>
                <Button
                  color="outline-muted"
                  className="d-none d-md-block ms-2 me-auto"
                  onClick={handleCancelSuggestions}
                >
                  Cancel
                </Button>
                <UncontrolledDropdown className="d-md-none ms-1 me-auto">
                  <DropdownToggle color="outline-muted">
                    <Menu size="20" className="icon-valign icon-btn-size" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      disabled={message == suggestions[activeSuggestion].message}
                      onClick={handleRevertSuggestion}
                      >Revert
                    </DropdownItem>
                    <DropdownItem onClick={handleCancelSuggestions}>Cancel</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <ButtonGroup className="btn-group-outline ms-1">
                  <Button
                    color="outline-muted"
                    onClick={() => setActiveSuggestion(i => wrap(i - 1, suggestions.length))}
                  >
                    <ChevronLeft size="20" className="icon-valign icon-btn-size" />
                  </Button>
                  <Button
                    color="outline-muted"
                    onClick={() => setActiveSuggestion(i => wrap(i + 1, suggestions.length))}
                  >
                    <ChevronRight size="20" className="icon-valign icon-btn-size" />
                  </Button>
                  <Button
                    color="outline-muted"
                    className="arrow-right text-dark"
                    disabled={true}
                  >
                    <span className="d-none d-md-inline">Option </span>{activeSuggestion + 1} of {suggestions.length}
                  </Button>
                </ButtonGroup>
                <div className="ms-1">
                  {successIcon}
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-end">
                {suggestionsLoading && (
                  <>
                    <Button
                      color="outline-muted arrow-right"
                      className="text-dark"
                      disabled={true}
                      onClick={getSuggestions}
                    >
                      <div className="typing">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </Button>
                    <div className="ms-1">
                      {thinkingIcon}
                    </div>
                  </>
                )}
                {!suggestionsLoading && !fetchError && (
                  <>
                    <Button
                      color="outline-muted arrow-right"
                      className="text-dark"
                      onClick={getSuggestions}
                      disabled={platforms.length === 0}
                    >
                      Want Suggestions?
                      <span className="badge bg-gray-darker text-muted ms-2">
                        BETA
                      </span>
                    </Button>
                    <div className="ms-1">
                      {defaultIcon}
                    </div>
                  </>
                )}
                {!suggestionsLoading && fetchError && (
                  <>
                    <Button
                      color="outline-muted arrow-right"
                      className="text-danger"
                      onClick={getSuggestions}
                      disabled={platforms.length === 0}
                    >
                      Yikes, something went wrong. Try again?
                    </Button>
                    <div className="ms-1">
                      {thinkingIcon}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
