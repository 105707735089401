import React from 'react'

import { StateProvider } from '../stores/share'
import ShareScheduled from './Share/ShareScheduled'

export default function TeamScheduled(props) {
  return (
    <StateProvider {...props}>
      <ShareScheduled title="All Scheduled Shares" />
    </StateProvider>
  )
}
