import React from 'react'
import SelectWithPhotoField from './SelectWithPhotoField'

export default function LimitedSponsor(props) {

  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const updatedProps = {
    ...props.field,
    field: {
      search: false,
    },
  }

  const options = [
    {
      photo: 'https://boxout-assets.s3.amazonaws.com/graphics/bbb.png',
      value: 'built_by_box_out',
      text: 'Built By Box Out',
    },
  ]

  return (
    <SelectWithPhotoField key={`sponsor-${props.field.key}`} {...updatedProps} options={options} onChange={onChange} disabled={true} />
  )
}