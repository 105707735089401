import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToTop() {
  const location = useLocation()
  useEffect(() => {
    const scroller = document.querySelector('.bo-content')
    if (scroller) {
      scroller.scrollTo(0, 0)
    }
  }, [location])

  return (null)
}

export default ScrollToTop
