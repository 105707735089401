import React, { useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap'

export default function FontOptions(props) {
  const { baseUrl, fonts, genericFonts, limitGenericFonts, isAdmin } = props
  
  const token = document.querySelector('[name="csrf-token"]').getAttribute('content')
  const adminFonts = fonts.filter(f => f.admin)
  const boxOutFonts = fonts.filter(f => !f.admin)

  const [selectedFontSlugs, setSelectedFontSlugs] = useState(boxOutFonts.map(f => f.slug))
  const [unlinkModalOpen, setUnlinkModalOpen] = useState(false)
  const [fontToUnlink, setFontToUnlink] = useState(null)

  const [dropdownSelected, setDropdownSelected] = useState(!limitGenericFonts ? 'all' : (limitGenericFonts && selectedFontSlugs.length ? 'selected' : 'none'))

  const allowAll = dropdownSelected == 'all'
  const limitToSelected = dropdownSelected == 'selected'
  const disableBoxOut = dropdownSelected == 'none'

  const disableSubmit = limitToSelected && selectedFontSlugs.length == 0 && adminFonts.length == 0

  const openUnlinkModal = (font) => {
    setFontToUnlink(font)
    setUnlinkModalOpen(true)
  }

  const updateSelectedFont = (font, checked) => {
    if (checked) {
      setSelectedFontSlugs([...selectedFontSlugs, font.slug])
    } else {
      setSelectedFontSlugs(selectedFontSlugs.filter(slug => slug != font.slug))
    }
  }

  return (
    <Card>
      <CardHeader className="bg-white">
        <Row className="align-items-center">
          <Col>
            <strong>Available Fonts for Graphics</strong>
          </Col>
          <Col xs="auto">
            <UncontrolledDropdown>
              <DropdownToggle size="sm" color="outline-secondary" caret>
                {allowAll && 'Allow All Box Out Fonts'}
                {limitToSelected && 'Limit to Selected Fonts'}
                {disableBoxOut && 'Disable All Box Out Fonts'}
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem active={allowAll} onClick={() => setDropdownSelected('all')}>Allow All Box Out Fonts</DropdownItem>
                <DropdownItem active={limitToSelected} onClick={() => setDropdownSelected('selected')}>Limit to Selected Fonts</DropdownItem>
                {!!adminFonts.length && <DropdownItem active={disableBoxOut} onClick={() => setDropdownSelected('none')}>Disable All Box Out Fonts</DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush={true}>
          {!!adminFonts.length && <ListGroupItem className="bg-light font-group-header">
            <small><strong>CUSTOM FONTS</strong></small>
          </ListGroupItem>}
          {adminFonts.map(f => (
            <ListGroupItem key={f.slug}>
              <Row className="align-items-center">
                <Col xs="auto" className="pe-0" id={`font-${f.slug}`}>
                  <Input type="checkbox" className="list-group-checkbox" checked={true} disabled={true} />
                </Col>
                <Col><img src={f.preview_url} alt={f.name} className="font-preview" /></Col>
                {isAdmin && (
                  <Col xs="auto" className="ps-0">
                    <ButtonGroup>
                      <Button color="outline-secondary" size="sm" onClick={() => window.location = `${baseUrl}/${f.slug}/edit`}>Edit</Button>
                      <Button color="outline-secondary" size="sm" onClick={() => openUnlinkModal(f)}>Unlink</Button>
                    </ButtonGroup>
                  </Col>
                )}
              </Row>
              <UncontrolledTooltip placement="right" target={`font-${f.slug}`}> 
                Custom fonts cannot be disabled.
              </UncontrolledTooltip>
            </ListGroupItem>
          ))}
          {dropdownSelected != 'none' && (
            <>
              <ListGroupItem className="bg-light font-group-header">
                <small><strong>BOX OUT FONTS</strong></small>
              </ListGroupItem>
              {genericFonts.map(f => (
                <ListGroupItem key={f.slug}>
                  <label className="m-0 d-block" htmlFor={`select-font-${f.slug}`}>
                    <Row className="align-items-center">
                      <Col xs="auto" className="pe-0" id={`font-${f.slug}`}>
                        <Input 
                          type="checkbox" 
                          id={`select-font-${f.slug}`} 
                          className="list-group-checkbox" 
                          checked={allowAll ? true : selectedFontSlugs.includes(f.slug)} 
                          onChange={(e) => updateSelectedFont(f, e.target.checked)}
                          disabled={allowAll} />
                      </Col>
                      <Col><img src={f.preview_url} alt={f.name} className="font-preview" /></Col>
                    </Row>
                  </label>
                  {allowAll && (
                    <UncontrolledTooltip placement="right" target={`font-${f.slug}`}> 
                      Set the dropdown to &ldquo;Limit to Selected Fonts&rdquo; to disable this font.
                    </UncontrolledTooltip> 
                  )}
                </ListGroupItem>
              ))}
            </>
          )}
        </ListGroup>
      </CardBody>
      <CardFooter className="py-3">
        <Form action={`${baseUrl}/update_generic_fonts`} method="post" id="submit-form">
          <input type="hidden" name="_method" value="post" />
          <input type="hidden" name="authenticity_token" value={token} />
          <input type="hidden" name="limit_generic_fonts" value={allowAll ? 'false' : 'true'} />
          {selectedFontSlugs.map(slug => <input key={slug} type="hidden" name="font_slugs[]" value={slug} />)}
          <Button color="primary" type="submit" className="w-100" disabled={disableSubmit}>Save Font Options</Button>
          {disableSubmit && (
            <UncontrolledTooltip placement="top" target="submit-form"> 
              Select at least one font to save your changes.
            </UncontrolledTooltip>
          ) }
        </Form>
        
      </CardFooter>
      <Modal isOpen={unlinkModalOpen} toggle={() => setUnlinkModalOpen(false)} className="modal-sm">
        <ModalHeader toggle={() => setUnlinkModalOpen(false)} tag="h3">Unlink Font</ModalHeader>
        <form action={`${baseUrl}/${fontToUnlink?.slug}`} method="post">
          <input type="hidden" name="_method" value="delete" />
          <input type="hidden" name="authenticity_token" value={token} />
          <ModalBody>
            Are you sure you want to unlink <strong>{fontToUnlink?.name}</strong>?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" type="submit">Unlink</Button>
            <Button color="outline-secondary" onClick={() => setUnlinkModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </form>
      </Modal>
    </Card>
  )
}
