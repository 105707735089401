import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { templatesForCategoryAndTier } from '../../lib/templates'
import { store } from '../../stores/templates'
import Fuse from 'fuse.js'
import TemplatesBreadcrumb from './TemplatesBreadcrumb'
import TemplatePreviews from './TemplatePreviews'
import TemplatesFilter from './TemplatesFilter'
import TemplatesNav from './TemplatesNav'
import ErrorModal from '../shared/ErrorModal'

export default function TemplatesPlanCategory() {
  const [query, setQuery] = useState('')
  const isFiltering = query.trim() != ''

  const {
    templateCategories,
    templates,
    plans,
    hasSubscriptionAccess,
    canManageTemplates,
    basename,
    subscriptionTier,
    isAdmin,
    subscribed,
    subscriptionLink,
    errorModalOpen,
    errorMessage,
    errorMessageHeader,
    dispatch,
    actions,
  } = useContext(store)

  const { plan, category } = useParams()
  const categoryObject = templateCategories.find(c => c.attributes.slug == category)

  const displayTemplates = templatesForCategoryAndTier(templates, categoryObject, plans[plan])

  const fuse = new Fuse(displayTemplates, {
    keys: ['attributes.title', 'attributes.tags'],
    threshold: 0.3,
  })

  const closeErrorModal = () => {
    dispatch({ type: actions.CLOSE_ERROR_MODAL })
  }

  useEffect(() => {
    document.title = `${categoryObject.attributes.name} Templates | Template Library | Box Out Sports`
  }, [plan, category])

  const filteredTemplates = isFiltering ? fuse.search(query.trim()).map(f => f.item) : null

  const filterResults = isFiltering ? (
    <TemplatePreviews
      isAdmin={isAdmin}
      subscribed={subscribed}
      subscriptionTier={subscriptionTier}
      hasSubscriptionAccess={hasSubscriptionAccess}
      canManageTemplates={canManageTemplates}
      templates={filteredTemplates}
      templateCategories={templateCategories}
      basename={basename}
      templateLink={`/${plan}/:category/:slug`} />
  ) : null

  const normalResults = isFiltering ? null : (
    <TemplatePreviews
      isAdmin={isAdmin}
      subscribed={subscribed}
      subscriptionTier={subscriptionTier}
      hasSubscriptionAccess={hasSubscriptionAccess}
      canManageTemplates={canManageTemplates}
      templates={displayTemplates}
      templateCategories={templateCategories}
      basename={basename}
      templateLink={`/${plan}/:category/:slug`} />
  )

  return (
    <>
      <TemplatesNav active="library" />
      <TemplatesFilter
        subscriptionTier={subscriptionTier}
        subscribed={subscribed}
        subscriptionLink={subscriptionLink}
        isAdmin={isAdmin}
        plans={plans}
        templates={templates}
        templateCategories={templateCategories}
        onChange={setQuery} />
      <TemplatesBreadcrumb templateCategories={templateCategories} query={query} />
      {filterResults}
      {normalResults}
      <ErrorModal isOpen={errorModalOpen} message={errorMessage} header={errorMessageHeader} toggle={closeErrorModal} />
    </>
  )
}
