import React, { useState, Fragment } from 'react'
import { Card, CardBody, CardFooter, Input, Table, Button } from 'reactstrap'
import RecentColorDelete from './RecentColorDelete'
import axios from '../shared/axios'

export default function RecentColorsList(props) {
  const { colors, showLimitOptions } = props
  const [limitedAt, setLimitedAt] = useState(props.limitedAt)

  const [deleteData, setDeleteData] = useState({
    color: {},
    open: false,
  })

  const showDeleteConfirmation = (color) => {
    setDeleteData({
      color: color,
      open: true,
    })
  }

  const hideDeleteConfirmation = () => {
    setDeleteData({
      color: {},
      open: false,
    })
  }

  const deleteColor = (color) => {
    props.setColors(prev => prev.filter(c => c.id != color.id))
  }

  const handleShare = async (event, color) => {
    const target = event.target
    const params = {}

    if (event.target.checked) {
      params.checked = 'true'
    }

    target.disabled = true

    const response = await axios.post(`${props.basePath}/recent_colors/${color.id}/share.json`, params)

    props.setColors(prev => (
      prev.map(c => color.id == c.id ? response.data.color : c)
    ))

    target.disabled = false
  }

  const handleLimitChange = async (event) => {
    const target = event.target
    const value = target.options[target.selectedIndex].value
    const params = {
      limit: value,
    }
    target.disabled = true
    target.blur()
    await axios.post(`${props.basePath}/recent_colors/limit_at.json`, params)
    setLimitedAt(value)
    target.disabled = false
  }

  const rows = colors.map(color => {
    return (
      <tr key={`color-${color.id}`}>
        <td className="pe-0" width={1}>
          <div className="color-swatch color-swatch-preview">
            <div
              className="color-swatch-foreground"
              style={{ backgroundColor: color.color }} />
          </div>
        </td>
        <td>{color.color}</td>
        {showLimitOptions ? (
          <td>
            <Input
              type="checkbox"
              className="sized-checkbox ms-0"
              checked={color['org?']}
              onChange={(event) => handleShare(event, color)} />
          </td>
        ) : null}
        <td className="text-end">
          <Button onClick={() => showDeleteConfirmation(color)} outline color="subtle-danger" size="sm">Delete</Button>
        </td>
      </tr>
    )
  })

  const limitOptions = (
    <CardFooter className="card-footer-table">
      <Input type="select" id="exampleCustomSelect" name="customSelect" defaultValue={limitedAt} onChange={handleLimitChange}>
        <option value="none">
          Do not limit color selections for this team
        </option>
        <option value="team">
          Limit color options on this team to this list
        </option>
        <option value="org">
          Limit color options on ALL teams in this organization
        </option>
      </Input>
    </CardFooter>
  )

  const colorList = (
    <Card className="mb-4">
      
      <CardBody className="p-0">
        <Table className="m-0">
          <thead className="border-bottom table-light">
            <tr>
              <th colSpan="2">Color</th>
              <th colSpan="2">Share with all teams?</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </CardBody>
      {showLimitOptions ? limitOptions : null}
    </Card>
  )

  const emptyList = (
    <div className="rounded bg-darken p-5 text-center text-muted mb-4">
      No colors have been added
    </div>
  )

  return (
    <Fragment>
      {colors.length ? colorList : emptyList}
      <RecentColorDelete
        colorToDelete={deleteData.color}
        isOpen={deleteData.open}
        closeModal={hideDeleteConfirmation}
        deleteColor={deleteColor}
        basePath={props.basePath}
        setColors={props.setColors}
      />
    </Fragment>
  )
}
