import React from 'react'
import CustomSelectField from './CustomSelectField'

export default function FontField(props) {
  const mappedOptions = props.fonts.map(font => {
    return {
      value: font.slug,
      optionElement: <>
        <img src={font.preview_url} alt={font.name} className="font-preview" />
      </>,
    }
  })

  return (
    <CustomSelectField {...props} options={mappedOptions} />
  )
}
