import React, { useContext, useEffect } from 'react'
import { store } from '../../stores/share'

export default function ShareDownloadMultiFormat(props) {
  const { states } = useContext(store)
  const { state, formats } = props

  useEffect(() => {
    if (props.setMetadata) {
      props.setMetadata({
        formats
      })
    }
  }, [])
  
  const stateNotifiers = {
    [states.ready]: null,
    [states.processing]: null,
    [states.error]: (
      <div className="text-danger fw-bold mt-2 text-center">
        Something went wrong, try again
      </div>
    ),
    [states.complete]: null,
  }

  return stateNotifiers[state]
}
